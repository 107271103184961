import styled from "styled-components";

const BadgeCircleStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .tsg-badge-rounded {
    padding: 4px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    margin-right: 5px;
    background-color: ${(props) => props.color};
  }
  .tsg-badge-rounded img {
    filter: invert(${({ theme }) => theme.invert});
  }
  .tsg-badge-lg {
    width: 20px;
    height: 20px;
  }
  .tsg-badge-md {
    width: 15px;
    height: 15px;
  }
  .tsg-badge-sm {
    width: 10px;
    height: 10px;
  }
  .tsg-badge-rounded.purple {
    background-color: ${({ theme }) => theme.chartColors.purple};
  }
  .tsg-badge-rounded.success {
    background-color: ${({ theme }) => theme.chartColors.success};
  }
  .tsg-badge-rounded.danger {
    background-color: ${({ theme }) => theme.chartColors.danger};
  }
  .tsg-badge-rounded.yellow {
    background-color: ${({ theme }) => theme.chartColors.warning};
  }
  .tsg-badge-rounded.lightBlue {
    background-color: ${({ theme }) => theme.chartColors.lightBlue};
  }
  .tsg-badge-rounded.darkSlateGrey {
    background-color: ${({ theme }) => theme.chartColors.darkSlateGrey};
  }

  .tsg-badge-rounded.darkOrange {
    background-color: ${({ theme }) => theme.chartColors.darkOrange};
  }

  .tsg-badge-rounded.slateBlue {
    background-color: ${({ theme }) => theme.chartColors.slateBlue};
  }

  .tsg-badge-rounded.navy {
    background-color: ${({ theme }) => theme.chartColors.navy};
  }

  .tsg-badge-rounded.stateBlue {
    background-color: ${({ theme }) => theme.chartColors.stateBlue};
  }
  .tsg-badge-rounded.cyan {
    background-color: ${({ theme }) => theme.chartColors.cyan};
  }
  .tsg-badge-rounded.warning {
    background-color: ${({ theme }) => theme.chartColors.warning};
  }

  .tsg-badge-rounded.brown {
    background-color: ${({ theme }) => theme.chartColors.brown};
  }
`;

export default BadgeCircleStyle;
