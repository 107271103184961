import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text.base};
    font-family: ${({ theme }) => theme.fonts.family.primary.medium};
    transition: all 0.20s linear;
    user-select: text;
  }

  .primary-color{
    color:#1196c2 !important
  }

  .react-time-picker__clock react-time-picker__clock--open {
    position: relative !important;
  }

  .form-label {
    // color: ${({ theme }) => theme.chartColors.info};
    color: ${({ theme }) => theme.text.base};
  }
  .field-error {
    color:${({ theme }) => theme.chartColors.danger};
    font-size:14px;
    padding-left:10px
  }
  // Single Reusable Styles

  .spinner-center {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stick-to-bottom {
    position: absolute; bottom: 0;
  }

  .border-bottom-light {
    border-bottom: 1px solid ${({ theme }) => theme.border_Light};
  }

  .border-left-sq {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }

  .border-right-rounded {
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .mr-0 {
    margin-right: 0 !important;
  }

  .icon-color {
     filter: ${({ theme }) => theme.icon_color}
  }

  .background-primary {
    background: ${({ theme }) => theme.card_background} !important;
    color: ${({ theme }) => theme.text.base};
  }

  .background-secondary {
    background: ${({ theme }) => theme.modal_background} !important;
    color: ${({ theme }) => theme.text.base};
  }

  .bg-transparent {
    background: transparent !important;
  }

  .border-primary {
    border: 1px solid ${({ theme }) => theme.chartColors.info} !important;
  }

  .card-primary {
    background: ${({ theme }) => theme.card_background} !important;
  }
  
  .card-secondary {
    background: ${({ theme }) => theme.modal_background} !important;
    padding: 30px 15px;
    border-radius: 8px;
    margin-bottom: 30px;
  }

  .color-light {
    color: ${({ theme }) => theme.text.light} !important;
  }

  .color-dark {
    color: ${({ theme }) => theme.text.dark} !important;
  }

  .text-underline {
    text-decoration: underline;
  }

  .overflow-y-auto {
    overflow-y: auto;
  }

  .overflow-x-auto {
    overflow-x: auto;
  }

  .overflow-x-hidden {
    overflow-x: hidden;
  }

  .overflow-y-hidden {
    overflow-y: hidden; 
  }

  .text-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  // Icons
  .icon-mode {
    filter: brightness(0.5);
  }
  // Dropdown
  .dropdown .dropdown-menu {
    background-color: ${({ theme }) => theme.card_background};
  }
  .conditional-dropdown {
    position: relative !important;
    transform: none !important;
  }
  .css-yg1lgq-control,  .css-l0kado-control, .progress {
    background-color: ${({ theme }) => theme.card_background} !important;
  }
  .dropdown .tsg-dropdown {
    color: ${({ theme }) => theme.text.base};
    border-color: ${({ theme }) => theme.dropdown_border};
    font-size: 1rem;
    padding: 0.5rem 1.125rem;
  }
  .dropdown.no-border .tsg-dropdown {
    border: none;
    background-color: ${({ theme }) => theme.card_background};
  }
  .dropdown.no-border .tsg-dropdown:hover,.dropdown.no-border .tsg-dropdown:active, .dropdown.no-border .tsg-dropdown:focus {
    border: none;
    background-color: ${({ theme }) => theme.card_background};
  }
  .dropdown .tsg-dropdown:hover, .dropdown .tsg-dropdown:focus, .dropdown .tsg-dropdown:active {
    color: ${({ theme }) => theme.text.base};
    background-color: transparent;
    border-color: ${({ theme }) => theme.dropdown_border_hover};
  }
  .custom-control-label:hover {
    color: ${({ theme }) => theme.chartColors.info};
  }
  .dropdown .dropdown-menu .dropdown-item .custom-control-label {
    cursor: pointer;
  }

  //======= custom Toggle Switch for Workflow Manager ============
  
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 34px;
    height: 20px;
    pointer-events: all;
    border-radius: 1rem;
    }

    .custom-switch .custom-control-label::after {
    top: calc(0.32rem + 1px);
    left: calc(-2.2rem + 1px);
    width: 16px;
    height: 16px;
    border-radius: 1rem;
    transition: transform 0.15s ease-in-out;
    }

    .custom-switch input:checked ~ .custom-control-label::after {
    transform: translateX(14px);
    }

  //======= Custom Date Filter ============
  .custom-date-picker {
    width: 100%;
    height: 52px;
    text-align: left;
    margin-top: 0px;
    border-radius: 8px;
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text.base};
    border: 1px solid ${({ theme }) => theme.input_border};
    padding-left:10px;
    cursor:pointer;
  }
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text.base} !important;
    border: 1px solid ${({ theme }) => theme.input_border};
  }
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: ${({ theme }) => theme.text.base} !important;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: ${({ theme }) => theme.text.base};
  }
  .react-datepicker__month {
    background: ${({ theme }) => theme.body};
    margin:0px !important;
  }
  .react-datepicker {
    border: 1px solid ${({ theme }) => theme.input_border};
    display:flex
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background: #118AB2 !important;
    color:#fff !important;
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    color: #fff !important;
  }
  // .react-datepicker-popper {
  //   width:96% !important;
  // }
  .react-datepicker__month-container{
    float:none !important;
  }
//======= Table ============
  .cursor-context-menu {
    cursor: context-menu;
  }
  .table-dark {
    background-color: ${({ theme }) => theme.body};
  }
  .table-gray {
    background-color: #454545;
  }
  .table-dim-dark {
    background-color: ${({ theme }) => theme.card_background};
  }
  .table thead th {
    background: ${({ theme }) => theme.table_background};
    vertical-align: top;
    color: #777;
    font-size: 12px;
    font-family: "Montserrat SemiBold";
    padding: 15px;
    border-top: 0;
    border-bottom: 0;
    border: 0;
  }
  // .table tr:hover {
  //   background-color: rgba(0, 0, 0, 0.1);
  // }
  .table.table-card-background td,  .table.table-card-background th {
    background: ${({ theme }) => theme.table_card_background};
  }
  
.table td {
  color: ${({ theme }) => theme.text.bright};
  font-size: 0.875rem;
  font-family: "Montserrat Regular";
  cursor: pointer;
  background: ${({ theme }) => theme.table_background};
  padding: 0.75rem;
  border-top: 0;
  border-bottom: 0;
  border: 0;
}

  

  .table td, .table th {
    white-space: nowrap;
  }


  .table.modal-table th,
  .table.modal-table td {
    background: ${({ theme }) => theme.card_background};
  }

//======= table-pagination ============
  .react-bootstrap-table-pagination .page-item.disabled .page-link {
    border: none;
    background-color: ${({ theme }) => theme.card_background};
  }
  .react-bootstrap-table-pagination .page-link {
    border: none;
    color: ${({ theme }) => theme.text.bright};
    background-color: ${({ theme }) => theme.card_background};
  }
  .page-item.active .page-link {
    background-color: ${({ theme }) => theme.chartColors.info};
    border-color: transparent;
  }

  // Modal
  .modal-content  {
    background-color: ${({ theme }) => theme.modal_background};
  }
  .modal-footer {
    border: none;
  }
  .modal-header {
    font-size: 20px;
    font-family: 'Montserrat SemiBold';
    color: ${({ theme }) => theme.text.bright};
  }
  .modal-90mw{
    min-width: 90%;
  }
  // Checkbox
  .custom-control-input {
    width: 100%;
    z-index: 1;
    cursor: pointer;
  }
  .custom-control-label::before {
    background-color: transparent;
    border: ${({ theme }) => theme.chartColors.info} solid 2px;
  }
  .custom-region-label{
    cursor: pointer;
    margin-left: 5px !important;
    margin-bottom: 0px !important;
  }
  .custom-region-label:hover {
    color: ${({ theme }) => theme.chartColors.info};
  }
  // .custom-region-checkbox{
  //   border: ${({ theme }) => theme.chartColors.info} solid 2px;
  //   background-color: ${({ theme }) => theme.chartColors.info};
  //   cursor: pointer;
  // }
  .custom-region-checkbox[type="checkbox"]:before {
    border: ${({ theme }) => theme.chartColors.info} solid 2px;
    background: ${({ theme }) => theme.chartColors.info}
  }
  .matching-box-shadow {
    width: 99%;
    box-shadow: 7px 9px 7px 0px lightblue !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: ${({ theme }) => theme.chartColors.info};
    background-color: ${({ theme }) => theme.chartColors.info};
  }
  .graph-label-sm {
    font-size: 14px;
    color: ${({ theme }) => theme.text.base};
  }

  .graph-sm-number {
  font-size: 28px;
  color: ${({ theme }) => theme.text.base};
  font-family: "Montserrat SemiBold";
  margin-top: 2px;
  margin-bottom: 5px;
}

  // Input
  .form-control:hover,.form-control:active,.form-control:focus {
    background-color: ${({ theme }) => theme.card_background};
    color: ${({ theme }) => theme.text.base};
  }
  .form-control:focus {
    box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 10%);
    border-color: initial;
  }
  .form-control {
    background-color: ${({ theme }) => theme.card_background};
    color: ${({ theme }) => theme.text.base};
    border-color: initial;
  }
  .form-control::placeholder {
    color: ${({ theme }) => theme.text.base};
  }
  .form-control:disabled{
    background-color: ${({ theme }) => theme.card_background};
    color: ${({ theme }) => theme.text.base};
    opacity: 0.7;
  }
  
  .form-check{
    width: 60px;
  }

  // Userprofilemanaement
  .search-input-card {
    background: ${({ theme }) => theme.modal_background} !important;
    border: 1px solid #ccc;
    padding: 8px 15px;
    border-radius: 5px;
    color: ${({ theme }) => theme.text.base};
    outline: none;

    &:active{
      background: red
    }
  }
  .nav-tabs {
    border-bottom: 0;
  }
  .nav-tabs .nav-link.active {
    border-color: transparent;
    background: #118AB2;
    color: #fff;
  }
  .nav-tabs .nav-link {
    margin-right: 12px;
    margin-bottom: 12px;
    border: 1px solid ${({ theme }) => theme.text.border};
    color: ${({ theme }) => theme.text.base};
    border-radius: 28px;
    font-size: 0.9375rem;
  }
  .rs-input {
    background: ${({ theme }) => theme.card_background};
    border: 1px solid ${({ theme }) => theme.input_border};
    padding: 12px 15px;
    border-radius: 8px;
    color: ${({ theme }) => theme.text.bright};
    outline: none;
    font-family: "Montserrat Regular"
  }
  .highlight {
    color:#000 !important;
    opacity:1 !important;
  }
  .highlight:disabled {
    color:#000 !important;
    opacity:1 !important;
  }

  .rs-input::placeholder {
    color: ${({ theme }) => theme.input_placeholder};
    font-family: "Montserrat Regular"
  }
  .rs-input:hover,.rs-input:focus {
    border: 1px solid ${({ theme }) => theme.text.hover};
  }
  .rs-basic-card {
    background: ${({ theme }) => theme.card_background};
    padding: 0.9375rem;
    border-radius: 10px;
    min-height: 100%;
  }
  .refresh-btn {
    color: ${({ theme }) => theme.text.bright};
    background: ${({ theme }) => theme.card_background};
    font-size: 0.7375rem;
    border-radius: 10px;
    margin-top: 13px;
    width: 102px;
    height: 32px;
  }
  .refresh-btn:hover {
    background: #f2f3f8;
  }
  
  // Search
  .search {
    background: ${({ theme }) => theme.card_background};
    border: 2px solid #128ab2;
    width: 88%;
    padding: 5px;
    border-radius: 10px;
    margin-left: 10px;
  }
  .textArea {
    background: ${({ theme }) => theme.card_background};
    color: ${({ theme }) => theme.input_placeholder};
    width: 100%;
    padding: 5px;
    border-radius: 5px;
  }
  .search-input {
    border-radius: 25.5px;
    padding: 10px 25px;
    font-size: 14px;
    min-width: 272px;
    background-color: ${({ theme }) => theme.background};
    border: none;
    font-family: "Montserrat medium";
    color: ${({ theme }) => theme.text.base};
    margin-right: 25px;
    ::placeholder {
      font-family: ${({ theme }) => theme.fonts.family.primary.medium};
      color: ${({ theme }) => theme.text.base};
      letter-spacing: 0.5px;
    }
  }

  .width-74vw {
    width: 74vw;
  }
  .table-layout-auto {
    table-layout: auto !important;
  }

  // Notes
  .rs-notes-container {
    padding: 0;
  }
  .rs-notes-item{
    background: ${({ theme }) => theme.card_background};
    padding: 10px 10px;
    border-radius: 8px;
    margin-right: 9px;
    margin-bottom: 11px;
  }
  .rs-notes-item h6 {
    font-size: 12px;
    color: ${({ theme }) => theme.text.bright};
  }
  .rs-notes-item sub {
    font-size: 12px;
    color: ${({ theme }) => theme.text.base};
  }
  .rs-notes-item .note-comment {
    color: ${({ theme }) => theme.text.base};
    overflow-wrap: break-word;
  }
}

// Info bar

.rs-info-bar {
  padding: 12px 15px;
  border-radius: 8px;
  background: ${({ theme }) => theme.modal_background};
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'Montserrat Regular';
}

// Login

.auth-container {
  height: 100vh;
  background: #f8f8f8;
}

.auth-card {
  width: 30%;
  margin: 0 auto;
  float: none;
  border-radius: 49px;
  border: none;
  box-shadow: 1px -1px 11px rgb(0 0 0 / 10%);
}

.fancy-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.fancy-scroll::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  background-color: #89898961;
}
.fancy-scroll.auto::-webkit-scrollbar-thumb {
  background-color: #89898900;
}
.fancy-scroll.auto:hover::-webkit-scrollbar-thumb {
  background-color: #89898961;
}


.notes-wrapper {
  border: 1px solid ${({ theme }) => theme.dropdown_border};
  padding: 10px;
  border-radius: 10px;
}

.filter-btn-wrapper {
  justify-content: flex-start !important;
}
.btn-disabled {
  pointer-events: none;
  opacity: 0.5;
  background: #85878a !important;
  border: none !important;
}
.btn-gen-report{
  color: white !important;
}

.info-tile {
  color: ${({ theme }) => theme.text.base};
  font-size: 1rem;
  padding: 0.78rem 1.125rem;
  background: ${({ theme }) => theme.card_background};
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  margin-top: 8px;
  font-family: "Montserrat Medium";
  max-height: 49px;
  text-transform: capitalize;
}

.info-tile input[type="text"] {
  border: none;
  width: 100%;
  color: ${({ theme }) => theme.text.base};
  background: ${({ theme }) => theme.card_background};
}

.info-tile input[type="text"]:focus {
  outline: none
}

.info-tile input[type="text"]::placeholder {
  color: ${({ theme }) => theme.placeholder};
}

.info-tile input[type="password"] {
  border: none;
  width: 100%;
  color: ${({ theme }) => theme.text.base};
  background: ${({ theme }) => theme.card_background};
}

.info-tile input[type="password"]:focus {
  outline: none
}

.info-tile input[type="password"]::placeholder {
  color: ${({ theme }) => theme.placeholder};
}

/* legend */
.legend-wrapper .legend-title {
  font-size: 12px;
  font-family: "Montserrat SemiBold";
  margin-left: 5px;
  margin-right: 15px;
}

.log-desc {
  font-size: 16px;
  font-family: "Montserrat Regular";
  color: ${({ theme }) => theme.text.dark};
}
.log-desc.success {
  color: #00af91;
}
.log-desc.info{
  color: ${({ theme }) => theme.chartColors.info}
}
.log-desc.warning{
  color: #ffd166};
}
.log-desc.error{
  color: #ef476f;
}

.log-wrapper {
  margin-bottom: 10px;
  margin-left: 10px;
  
}

.logs-div{
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.log-date {
  font-size: 18px;
  color: ${({ theme }) => theme.text.dark};
  font-family: "Montserrat Medium";
  margin-bottom: 10px;
}

.log-time {
  font-size: 18px;
  font-family: "Montserrat Medium";
  color: ${({ theme }) => theme.text.light};
}

.color-gray-dim {
  color: #777;
}
.color-gray-bright {
  color: #ccc;
}
.color-success {
  color: #00af91;
}
.color-danger {
  color: #ef476f;
}
.color-purple {
  color: #845ec2;
}

.notification-dot{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.chartColors.info};
  position: absolute;
  right: 25px;
}

// Lgoin

.login-via-wrapper {
  display: inline-flex;
  flex-direction: column;
  border: 1px solid #ccc;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 100%;
}
.login-via-wrapper.active {
  border: 1px solid ${({ theme }) => theme.chartColors.info};
}
.login-via-wrapper.active span{
  color: ${({ theme }) => theme.chartColors.info};
}
.login-via-wrapper img {
  width: 24px;
}

.login-via-wrapper span {
  font-size: 14px;
  margin-top: 5px;
  font-family: Montserrat Regular;
  text-align: center;
}

.hide-filter .filter-item:not(:nth-child(-n+4)) {
  display: none;
}

.dateTimeDropdown {
  color: ${({ theme }) => theme.text.base};
  border: 1px solid ${({ theme }) => theme.dropdown_border};
  border-radius: 8px;
  background-color: transparent;
  padding: 0.75rem 1.125rem
}

.dateTimeDropdown:hover, .dateTimeDropdown:focus, .dateTimeDropdown:active {
    color: ${({ theme }) => theme.text.base};
    background-color: transparent;
    border-color: ${({ theme }) => theme.dropdown_border_hover};
    cursor: pointer;
  }

.dateTimeField{
  color: ${({ theme }) => theme.text.base};
  border: none;
  background-color: transparent;
  }

.dateTimeField:hover, .dateTimeField:focus, .dateTimeField:active {
  color: ${({ theme }) => theme.text.base};
  background-color: transparent;
  cursor: pointer;
  outline: none;
  }

.reportList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.reportList.selected { 
  color: ${({ theme }) => theme.chartColors.info};
  background-color: ${({ theme }) => theme.modal_background};
}

.jumpToPage{
    border-radius: 25.5px;
    padding: 10px 25px;
    font-size: 14px;
    min-width: 272px;
    background-color: ${({ theme }) => theme.card_background};
    border: none;
    font-family: "Montserrat medium";
    color: ${({ theme }) => theme.text.base};
    margin-right: 25px;
    ::placeholder {
      font-family: ${({ theme }) => theme.fonts.family.primary.medium};
      color: ${({ theme }) => theme.text.base};
      letter-spacing: 0.5px;
    }
}
.rs-general-wrapper {
  color: ${({ theme }) => theme.text.base};
  background: ${({ theme }) => theme.body};
}

.workflowManagerModalMultipleEntry{
  height: 100px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  padding: 5px;

  .cell{
    border: 1px solid #ccc;
    width: fit-content;
    border-radius: 10px;
    font-size: 12px;
    padding: 4px;
    margin-right: 5px;
  }

  .cell-button{
    border: none;
    background: transparent;
    width: 10px;
  }
}
.workflowModalTitle{
  font-size: 10px;
  font-family: Montserrat SemiBold;
  margin-bottom: 5px;
  color: #1196c2;
  margin-left: 2px;
}
.formTitle{
  font-size: 10px;
  font-family: Montserrat SemiBold;
  margin-bottom: 5px;
  color: #1196c2;
  margin-left: 2px;
}

.table-custom-style td{
  padding: 5px 16px;
  cursor:default !important
  background-color: '#f4511e';
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.expand-cell{
  color: ${({ theme }) => theme.chartColors.info} !important;
  font-weight:bold !important;
}


.custom-table-background td {
  /* Grey background for odd rows */
  // background-color: #f2f2f2;
  background: ${({ theme }) => theme.customTableBg};
 
}

.custom-table-background tr:nth-child(even) td {
  /* White background for even rows */
  background-color: ${({ theme }) => theme.card_background};
}

.nostro-reconciler-table .gray-row td {
  color: ${({ theme }) => theme.text.bright};
  font-size: 0.875rem;
  font-family: "Montserrat Regular";
  cursor: pointer;
  // background: #f2f2f2;
  background-color: ${({ theme }) => theme.card_background};
  padding: 0.75rem;
  border-top: 0;
  border-bottom: 0;
  border: 0;
}

.nostro-reconciler-table .white-row td {
  color: ${({ theme }) => theme.text.base};
  font-size: 0.875rem;
  font-family: "Montserrat Regular";
  cursor: pointer;
  background: ${({ theme }) => theme.customTableBg};
  padding: 0.75rem;
  border-top: 0;
  border-bottom: 0;
  border: 0;
}




.nostro-reconciler-table {
  width: 100%;
  border-collapse: collapse;
}

.nostro-reconciler-table th,
.nostro-reconciler-table td {
  border: 1px solid #ddd; /* Add borders as needed */
  padding: 8px;
  text-align: left;
}

.nostro-reconciler-table .gray-row {
  background-color: ${({ theme }) => theme.customTableBg};
}

.nostro-reconciler-table .white-row {
  background-color: ${({ theme }) => theme.card_background};;
}

.collapse-btn {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  transition: left 0.3s ease; /* Add a transition for smooth animation */
}

.icon {
  color: ${({ theme }) => theme.text.base};
  font-size: 20px;
}

.collapse-div{
  display: flex;
  align-items: end;
  justify-content: end;
  background-color: ${({ theme }) =>
    theme.background === "#F2F3FA" ? "#f7f7fc" : "#121212"};
}
.progress-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.progress-bar-container {
  background-color: #f2f2f2; /* Light grey background */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Hide overflow */
}

.progress-bar {
  height: 100%;
  background-color: #118ab2; /* Blue background color */
  transition: width 0.3s ease; /* Smooth transition */
}

.progress-bar.active, .progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-outer{
    background: #fff;
    border: 3px solid #bebfbf;
    border-radius: 45px;
    position: relative;
    width: 100%;
}
.progress-text {
  margin-left: 10px; /* Add some spacing */
}

@keyframes increaseWidth {
  from {
    width: 0;
  }
}

.form-control.is-warning {
  border-color: #ffc107; /* Bootstrap's warning color */
  padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ffc107' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ffc107' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-warning:focus {
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25); /* Slight transparency for a glow effect */
}

.form-control.is-warning + .invalid-feedback {
  display: block;
  color: #ffc107;
}

.visibility-collapse {
  visibility: collapse;
 }
.draggable-false{
  -webkit-user-drag: none;
}

.custom-tab{
  min-width: 140px !important;
  text-align: center;
}
.custom-bg-light {
  background: #f7f7fc !important;
  text-align:center !important;
}
.cron_builder {
  max-width:100%;
}

.greyscale {
  filter: grayscale(1);
}

.css-13cymwt-control {
  border-radius:8px !important;
}
.css-qbdosj-Input {
  min-height:34px !important;
}

.blink td{
  animation: blinkAnimation 1s infinite;
}

.input-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input-icon {
  position: absolute;
  left: 10px; /* Adjust based on your design */
  pointer-events: none; /* Make sure the icon doesn't block input clicks */
}

.with-icon {
  padding-left: 25px; /* Adjust based on your icon size and position */
}


.code-editor-container {
  position: relative;
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.code-editor-textarea {
  width: 100%;
  height: 100%;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  border: none;
  padding: 10px;
  resize: none;
  outline: none;
  line-height: 1.5;
  tab-size: 4;
  white-space: pre;
}

@keyframes blinkAnimation {
  0% {
    background-color: #14a2d1;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: #14a2d1;
  }
}

.cursor-pointer {
  cursor: pointer;
}

// Media Queries
@media (max-width: 1100px) and (min-width: 769px){ 
  .user-dropdown-content {
    display: none;
  }
}
@media (max-width: 1100px) { 
  .auth-card {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .br-sm-none {
   border-right: none !important
}
.auth-card {
  width: 100%;
  border-radius: 0px;
  border: none;
  box-shadow: none;
 }
 .auth-container {
  background: #fff;
 }
}

@media (max-width: 740px) {
  html {
    font-size: 14px;
  }
  .dropdown.d-flex {
    position: absolute;
    height: 100%;
    top: 76px;
    width: 100%;
    left: 0;
    background: ${({ theme }) => theme.card_background};
    padding: 10px;
    box-shadow: -1px 3px 4px rgb(0 0 0 / 20%);
  }
  .dropdown .search-input {
    border: 1px solid;
    width : 100%;
  }
  .nav-tabs .nav-link {
    margin-bottom: 10px;
  }
}


@media (min-width: 276px) {
.d-xs-none {
  display: none;
}
}

  `;
