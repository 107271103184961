import React from "react";
import ReconScheduleStyle from "./ReconScheduleStyle";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import { useDateTime } from "../../../components/Helper/DateTime";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DateFormat, TimeFormat } from "../../../utils/formatDateTime";

const tooltip = (description) => <Tooltip id="tooltip">{description}</Tooltip>;
// Set tooltip color here
const changeColor = (e) => {
  e.children[0].style.borderTopColor = "#000";
  e.children[1].style.backgroundColor = "#777";
};

export default function ReconSchedule(props) {
  // console.log("props in recon scheule...", props);
  const { date, time } = useDateTime();
  const history = useHistory();
  return (
    <div className="row">
      <div className="col-12">
        {/* {props?.loading && <LoaderComponent />} */}
        <div className="row">
          <div className="col-12">
            {/* {props?.loading && <LoaderComponent />} */}

            {props?.interfaceGroupByDate && props?.interfaceGroupByDate ? (
              <ReconScheduleStyle
                id="data-collection-schedule"
                className={`card recon-schedule-card fancy-scroll`}
              >
                <h5 className="recon-schedule-h">Data Collection Schedule</h5>
                {props?.loading ? (
                  <LoaderComponent />
                ) : (
                  <div
                    className="row"
                    style={{ opacity: !props.loading ? "1" : "0.07" }}
                  >
                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="d-flex align-items-center mt-2">
                        <span className="rounded-indicator"></span>
                        <span className="date">
                          {/* Yesterday: {moment().subtract(1, "day").format(date)} */}
                          Yesterday:{" "}
                          {DateFormat(
                            date,
                            props?.interfaceGroupByDate[0]?.yesterdayDate
                          )}
                        </span>
                      </div>

                      <div className="recon-schedule-item-container">
                        {props?.interfaceGroupByDate
                          // ?.sort((a, b) => a.scheduleTime.localeCompare(b.scheduleTime))
                          ?.filter((item) => item.yesterday === true)
                          .map((val) => {
                            const singleFileStatusReport =
                              val?.interfaceFilesStatusReport?.find(
                                (item) => item.scheduleType === "yesterday"
                              );
                            return (
                              <div
                                id={
                                  val?.interfaceName
                                    ? val?.interfaceName?.replace(/\s+/g, "-") +
                                      "-Yesterday"
                                    : ""
                                }
                                className="recon-schedule-item-wrapper"
                                key={val?.interfaceId}
                                onClick={() => {
                                  history.push(`/operations`);
                                }}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltip(
                                    singleFileStatusReport?.description
                                  )}
                                  onEntering={changeColor}
                                >
                                  <div
                                    className="recon-schedule-item"
                                    style={{
                                      borderLeft: `2px solid ${singleFileStatusReport?.status}`,
                                    }}
                                  >
                                    <div className="d-flex flex-column justify-content-center">
                                      <span className="time">
                                        {TimeFormat(
                                          time,
                                          singleFileStatusReport?.hour
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center ml-4 align-items-end">
                                      <span className="run text-right">
                                        {val?.interfaceName}
                                      </span>
                                      <span className="last-received text-right">
                                        {val?.dataSource}
                                      </span>
                                    </div>
                                  </div>
                                </OverlayTrigger>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="d-flex align-items-center mt-2">
                        <span className="rounded-indicator"></span>
                        {/* <span className="date">Today: {moment().format(date)}</span> */}
                        <span className="date">
                          Today:{" "}
                          {DateFormat(
                            date,
                            props?.interfaceGroupByDate[0]?.todayDate
                          )}
                        </span>
                      </div>

                      <div className="recon-schedule-item-container">
                        {props?.interfaceGroupByDate
                          ?.filter((item) => item.today === true)
                          // ?.sort((a, b) => a.scheduleTime.localeCompare(b.scheduleTime))
                          .map((val) => {
                            const singleFileStatusReport =
                              val?.interfaceFilesStatusReport?.find(
                                (item) => item.scheduleType === "today"
                              );
                            return (
                              <div
                              id={
                                val?.interfaceName
                                  ? val?.interfaceName?.replace(/\s+/g, "-") +
                                    "-Today"
                                  : ""
                              }
                                className="recon-schedule-item-wrapper"
                                key={val?.interfaceId}
                                onClick={() => {
                                  history.push(`/operations`);
                                }}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltip(
                                    singleFileStatusReport?.description
                                  )}
                                  onEntering={changeColor}
                                >
                                  <div
                                    className="recon-schedule-item"
                                    style={{
                                      borderLeft: `2px solid ${singleFileStatusReport?.status}`,
                                    }}
                                  >
                                    <div className="d-flex flex-column justify-content-center">
                                      <span className="time">
                                        {TimeFormat(
                                          time,
                                          singleFileStatusReport?.hour
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center ml-4 align-items-end">
                                      <span className="run text-right">
                                        {val.interfaceName}
                                      </span>
                                      <span className="last-received text-right">
                                        {val.dataSource}
                                      </span>
                                    </div>
                                  </div>
                                </OverlayTrigger>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12">
                      <div className="d-flex align-items-center mt-2">
                        <span className="rounded-indicator"></span>
                        <span className="date">
                          {/* Tomorrow: {moment().add(1, "day").format(date)} */}
                          Tomorrow:{" "}
                          {DateFormat(
                            date,
                            props?.interfaceGroupByDate[0]?.tomorrowDate
                          )}
                        </span>
                      </div>

                      <div className="recon-schedule-item-container">
                        {props?.interfaceGroupByDate
                          ?.filter((item) => item.tomorrow === true)
                          // ?.sort((a, b) => a.scheduleTime.localeCompare(b.scheduleTime))
                          .map((val) => {
                            const singleFileStatusReport =
                              val?.interfaceFilesStatusReport?.find(
                                (item) => item.scheduleType === "tomorrow"
                              );
                            return (
                              <div
                              id={
                                val?.interfaceName
                                  ? val?.interfaceName?.replace(/\s+/g, "-") +
                                    "-Tomorrow"
                                  : ""
                              }
                                className="recon-schedule-item-wrapper"
                                key={val?.interfaceId}
                                onClick={() => {
                                  history.push(`/operations`);
                                }}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltip(
                                    singleFileStatusReport?.description
                                  )}
                                  onEntering={changeColor}
                                >
                                  <div
                                    className="recon-schedule-item"
                                    style={{
                                      borderLeft: `2px solid ${singleFileStatusReport?.status}`,
                                    }}
                                  >
                                    <div className="d-flex flex-column justify-content-center">
                                      <span className="time">
                                        {TimeFormat(
                                          time,
                                          singleFileStatusReport?.hour
                                        )}
                                      </span>
                                      {/* <span className="recur">{val.scheduleTiming}</span> */}
                                    </div>
                                    <div className="d-flex flex-column justify-content-center ml-4 align-items-end">
                                      <span className="run text-right">
                                        {val.interfaceName}
                                      </span>
                                      <span className="last-received text-right">
                                        {val.dataSource}
                                      </span>
                                    </div>
                                  </div>
                                </OverlayTrigger>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
              </ReconScheduleStyle>
            ) : (
              <ReconScheduleStyle className={`card recon-schedule-card p-0`}>
                <div className="spinner-center">{"No data found!"}</div>
              </ReconScheduleStyle>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
