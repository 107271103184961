import React from "react";
import { Modal } from "react-bootstrap";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";

const PswViewModal = ({
  showModal,
  setShowModal,
  selectedRow,
  setSelectedRow,
  dsaAccount,
  dsbAccount,
}) => {
  const reconcileStatus = (status) => (
    <BadgeRounded
      color={
        status === "MATCHED"
          ? "success"
          : status === "MISSING"
          ? "warning"
          : "danger"
      }
      title={status}
    />
  );
  const formatNumber = (number) => {
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
      });
    }
  };

  return (
    <>
      <div className="col-md-12">
        <Modal
          backdrop={true}
          size="xl"
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setSelectedRow(null);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>Transaction Details</Modal.Header>
          <Modal.Body scrollable="true">
            <div className="row">
              <div className="col-4">
                <div className="row">
                  <div className="col-sm-12 col-lg-5">
                    <div className="key text-info">Recon Status :</div>
                  </div>
                  <div className="col-sm-12 col-lg-4">
                    <div className="value color-dark ">
                      {reconcileStatus(selectedRow?.status)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="row">
                  <div className="col-sm-12 col-lg-5">
                    <div className="key text-info">Matched By :</div>
                  </div>
                  <div className="col-sm-12 col-lg-6">
                    <div className="value color-dark">
                      {selectedRow?.matchedBy ?? "N/A"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-12">
                <div className="row mt-4">
                  <div className="col-12">
                    <div
                      className={`table-responsive fancy-scroll`}
                      style={{ overflowY: "hidden" }}
                    >
                      <table className="table table-dim-dark table-bordered m-0">
                        <thead>
                          <tr>
                            <th className="text-info"></th>
                            {/* <th className="text-info">Matched By</th>
                            <th className="text-info">Status</th> */}
                            <th className="text-info">Date</th>
                            <th className="text-info">Details</th>
                            <th className="text-info">Reference</th>
                            <th className="text-info text-right">Credit</th>
                            <th className="text-info text-right">Debit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedRow?.dsA?.map((item, index) => {
                            return selectedRow?.status === "MISSING" ||
                              item?.date ||
                              item?.details ||
                              item?.entryRef ? (
                              <tr key={index}>
                                <td className="py-1">
                                  {index === 0
                                    ? dsaAccount?.type +
                                      " - " +
                                      dsaAccount?.number
                                    : ""}
                                </td>
                                {/* <td>{selectedRow?.matchedBy ?? "-"}</td>
                                <td>{reconcileStatus(selectedRow?.status)}</td> */}

                                <td className="py-1">{item?.date ?? "-"}</td>
                                <td className="py-1">{item?.details ?? "-"}</td>
                                <td className="py-1">
                                  {item?.entryRef ?? "-"}
                                </td>
                                <td className="text-right py-1">
                                  {item?.credit
                                    ? formatNumber(item?.credit)
                                    : item?.credit === null
                                    ? "-"
                                    : item?.credit}
                                </td>
                                <td className="text-right py-1">
                                  {item?.debit
                                    ? formatNumber(item?.debit)
                                    : item?.debit === null
                                    ? "-"
                                    : item?.debit}
                                </td>
                              </tr>
                            ) : (
                              ""
                            );
                          })}
                          <tr>
                            <td className="p-0">
                              <hr />
                            </td>
                            <td className="p-0">
                              <hr />
                            </td>
                            <td className="p-0">
                              <hr />
                            </td>
                            <td className="p-0">
                              <hr />
                            </td>
                            <td className="p-0">
                              <hr />
                            </td>
                            <td className="p-0">
                              <hr />
                            </td>
                          </tr>
                          {selectedRow?.dsB?.map((item, index) => {
                            return selectedRow?.status === "MISSING" ||
                              item?.date ||
                              item?.details ||
                              item?.entryRef ? (
                              <tr
                                key={index}
                                style={{ backgroundColor: "white" }}
                              >
                                <td className="py-1">
                                  {" "}
                                  {index === 0
                                    ? dsbAccount?.type +
                                      " - " +
                                      dsbAccount?.number
                                    : ""}
                                </td>
                                {/* <td>{selectedRow?.matchedBy ?? "-"}</td>
                                <td>{reconcileStatus(selectedRow?.status)}</td> */}
                                <td className="py-1">{item?.date ?? "-"}</td>
                                <td className="py-1">{item?.details ?? "-"}</td>
                                <td className="py-1">
                                  {item?.entryRef ?? "-"}
                                </td>
                                <td className="text-right py-1">
                                  {item?.credit
                                    ? formatNumber(item?.credit)
                                    : item?.credit === null
                                    ? ""
                                    : item?.credit}
                                </td>
                                <td className="text-right py-1">
                                  {item?.debit
                                    ? formatNumber(item?.debit)
                                    : item?.debit === null
                                    ? "-"
                                    : item?.debit}
                                </td>
                              </tr>
                            ) : (
                              ""
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-4">
                <div className="notes-wrapper">
                  <h5 className="mb-3 color-gray-dim">Notes</h5>
                  <div
                    className="overflow-auto fancy-scroll"
                    style={{
                      height: "22em",
                    }}
                  >
                    <div
                      className="mt-3 position-relative"
                      style={{
                        minHeight: "95%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div className="mt-2">
                        {notes?.map(({ author, comment, date, time }, i) => (
                          <ul className="rs-notes-container" key={i}>
                            <li className="rs-notes-item">
                              <h6>{author}</h6>
                              <div className="note-comment">{comment}</div>
                              <sub>
                                {date} {time}
                              </sub>
                            </li>
                          </ul>
                        ))}
                      </div>
                      <textarea
                        placeholder="Write a Note"
                        rows="3"
                        id="note"
                        className="textArea"
                      ></textarea>
                      <div className="w-100 mt-2">
                        <ButtonBasic
                          classes={"primary px-4 w-100"}
                          title={"Add Notes"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <ButtonBasic
                title="Close"
                onClick={() => {
                  setShowModal(false);
                  setSelectedRow(null);
                }}
              />
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default PswViewModal;
