import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import Pagination from "../../components/pagination/pagination";
import Toast from "../../components/Toast/Toast";
import { useDateTime } from "../../components/Helper/DateTime";
import TransactionlogModal from "../Transactions/TransactionlogModal";
import { Modal } from "react-bootstrap";
// import classes from "./style.module.scss";
const SearchResult = (props) => {
  const { setRefresh } = useDateTime();
  const [showModal, setShowModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [matchingData, setMatchingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { query } = queryString.parse(props.location.search);

  const handleRedirect = (name, id) => {
    if (name === "devices") {
      history.push(`/device-view?id=${id}`);
    } else if (name === "transaction") {
      setShowModal(true);
      setTransactionId(id);
      // history.push(`/transaction-log?id=${id}`);
    }
  };
  const getId = (link) => link?.split("/").pop();
  const getName = (link) => link?.split("/")[link?.split("/").length - 2];
  const handlePageChange = (page, pageSize, tableName) => {
    setLoading(true);
    const payload = { page, pageSize, tableName };
    axiosInstance
      .post(`/recon/search?searchTerm=${query}`, payload)
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
          setLoading(false);
        } else {
          const data = [...matchingData];
          const index = data.findIndex(
            (item) => item.label === res.data.matchingData[0].label
          );
          if (index > -1) {
            data[index] = res.data.matchingData[0];
            setMatchingData(data);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getSearchResult = () => {
    setLoading(true);
    axiosInstance
      .get(`/recon/search?searchTerm=${query ?? ""}`)
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
          setLoading(false);
        } else {
          setMatchingData(res.data.matchingData);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getSearchResult();
    setRefresh(() => () => {
      getSearchResult();
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, [query]);
  return (
    <div>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div className="row mt-3">
        <div style={{ fontSize: "18px" }} className="text-bright ml-3 mb-4">
          Search result
        </div>
        <div className="col-12">
          <div
            style={{
              opacity: !loading ? "1" : "0.07",
            }}
          >
            {query && matchingData.length > 0 ? (
              matchingData.map((item, i) => {
                let columns = Object.keys(item.row[0].rowData);
                return (
                  <div className="row mb-3" key={i}>
                    <div className="col-12">
                      <div>
                        <h5 className="text text-info text-bold">
                          {item.label}
                        </h5>
                        <div className="overflow-y-auto fancy-scroll">
                          <table className="table">
                            <thead>
                              <tr>
                                {columns.map((column, i) => (
                                  <th key={i}>
                                    {column.split("_").join(" ").toUpperCase()}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {item.row.map((data, i) => (
                                <tr
                                  key={i}
                                  onClick={() =>
                                    handleRedirect(
                                      getName(data.rowMetadata.hyperlink),
                                      getId(data.rowMetadata.hyperlink)
                                    )
                                  }
                                >
                                  {columns.map((column, i) => (
                                    <td key={i}>{data.rowData[column]}</td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <Pagination
                          setPage={handlePageChange}
                          totalPages={item.page.totalPages + 1}
                          tableName={item.label}
                          pageSize={item.page.size}
                          page={item.page.number}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p style={{ textAlign: "center" }}>No data found!</p>
            )}
          </div>
          <div className="col-md-12">
            <TransactionlogModal
              show={showModal}
              onHide={() => setShowModal(false)}
              transactionId={transactionId}
              setShowModal={setShowModal}
            ></TransactionlogModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
