import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function CustomTooltip(props) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <OverlayTrigger
      placement={props.placement || "top"}
      overlay={
        <Tooltip id={props.tooltipId}>
          {props.disabled ? props.disabledMessage : props.children}
        </Tooltip>
      }
      show={showTooltip}
    >
      <span
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ display: "inline-block" }}
      >
        <button
          className="btn btn-info"
          disabled={props.disabled}
          onClick={props.onClick}
          style={props.style}
        >
          {props.buttonText}
        </button>
      </span>
    </OverlayTrigger>
  );
}
