import React, { useState, useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import axiosInstance from "../../../utils/axiosInstance";
import Pagination from "../../../components/pagination/pagination";
import BadgeCircle from "../../../components/BadgeCircle/BadgeCircle";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import FilterIcon from "../../../assets/images/filter.png";
import CSVIcon from "../../../assets/images/csv1.png";
import TransactionlogModal from "./TransactionlogModal";
import { CSVLink } from "react-csv";
import NewFilter from "../../../components/FilterDropdown/NewFilter";
import BadgeRounded from "../../../components/BadgeRounded/BadgeRounded";
import doneIcon from "../../../assets/images/done.png";
import CurrencyFormat from "react-currency-format";
import { useDateTime } from "../../../components/Helper/DateTime";
import queryString from "query-string";
import { DateTimeFormat } from "../../../utils/formatDateTime";

const TransactionLog = (props) => {
  const { dateTime, currency, setRefresh } = useDateTime();
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [filter, setFilter] = useState({});
  const [id, setId] = useState("");
  const [business_date_default, setBusinessDateDefault] = useState("");
  const hrefFromNotification = props.location.state?.href;

  const rowEvent = {
    onClick: (e, row) => {
      setShowModal(true);
      setId(row.id);
    },
  };

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const fetchTransactionData = useCallback(
    (filter, flag) => {
      setLoading(true);
      let allFilters = "";
      for (let key in filter) {
        if (filter[key]?.filter?.length > 0) {
          if (key !== "business_day") {
            allFilters += `${key}=${filter[key]?.filter?.join(",")}&`;
          }
        }
      }
      allFilters = allFilters.slice(0, -1);
      const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
      let url = hrefFromNotification
        ? hrefFromNotification
        : `/transactions?page=${
            flag ? 0 : page - 1
          }&size=${pageSize}&${allFilters}${
            sort.sort_by && sort.sort_order ? order : ""
          }`;
      axiosInstance
        .get(url)
        .then((res) => {
          if (res.message.status !== "200") {
            // Toast(res.message.description, "error");
            setTransactionData([]);
            setLoading(false);
          } else {
            setTransactionData(res?.data?.transactions || []);
            setPage(res?.page?.number + 1 || 1);
            setTotalPages(res?.page?.totalPages || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    },
    // eslint-disable-next-line
    [page, pageSize, sort]
  );

  const getFilters = useCallback(() => {
    setLoading(true);
    let data;
    if (props.location?.state?.data) {
      data = props.location.state.data;
    }
    axiosInstance
      .get("hierarchical/filters?screen_id=transaction_log")
      .then((res) => {
        if (res.message.status !== "200") {
          // Toast(res.message.description, "error");
          fetchTransactionData();
        } else {
          let obj = {};
          res.data.filters.forEach((filterIn) => {
            if (filterIn.filterName === "business_date") {
              setBusinessDateDefault(filterIn.filterDefaultValue);
            }
            obj = {
              ...obj,
              [filterIn.filterName]: {
                data: filterIn.filterData.map((obj) => ({
                  ...obj,
                  value: obj.codeValue,
                })),
                filter:
                  filterIn.filterName === "business_date"
                    ? [filterIn.filterDefaultValue]
                    : filterIn.filterData
                        .filter((obj) => (obj.param1 === "1" ? true : false))
                        .map((obj) => obj.codeValue),
              },
            };
          });
          if (data) {
            if (data.date) {
              setBusinessDateDefault(data.date);
            }
            if (data.id) {
              obj.matching.filter = [data.id];
            }
            if (data.channel) {
              obj.channel.filter = [data.channel];
            }
            fetchTransactionData(obj, true);
          } else {
            fetchTransactionData(obj);
          }
          setFilter(obj);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [props.location.state]);

  const handleSelectChange = (e, type) => {
    let obj = { ...filter };
    if (type === "business_date") {
      setBusinessDateDefault(e);
      obj.business_date = { ...obj.business_date, filter: [e] };
    } else {
      if (e.flag) obj[type].filter.push(e.id);
      else obj[type].filter = obj[type].filter.filter((id) => id !== e.id);
    }
    setFilter(obj);
  };

  const reconStatus = (row) => (
    <BadgeRounded
      color={
        row === "MATCHED" ? "success" : row === "MISSING" ? "warning" : "danger"
      }
      title={row}
    />
  );
  const matchInStatus = (cell, row) => (
    <div className="page-header d-flex justify-content-around">
      {row.matchedIn.includes(1) && (
        <BadgeCircle icon={doneIcon} color="lightBlue" />
      )}
      {row.matchedIn.includes(2) && (
        <BadgeCircle icon={doneIcon} color="success" />
      )}
      {row.matchedIn.includes(3) && (
        <BadgeCircle icon={doneIcon} color="purple" />
      )}
      {row.matchedIn.includes(4) && (
        <BadgeCircle icon={doneIcon} color="yellow" />
      )}
    </div>
  );
  const formatAmount = (cell, row) => (
    <>
      <div className="d-flex justify-content-between">
        <span className="item">{currency}</span>
        <span className="item">
          <CurrencyFormat
            value={row.amount ?? 0}
            displayType={"text"}
            thousandSeparator={true}
          />
        </span>
      </div>
    </>
  );

  const columns = [
    { hidden: true, dataField: "accountNumber", text: "ID" },
    {
      dataField: "dateTime",
      text: "Date Time",
      formatter: (cell) => DateTimeFormat(dateTime, cell),
      sort: false,
    },
    {
      dataField: "transactionType",
      text: "Transaction",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "pan",
      text: "Card",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: formatAmount,
      sort: true,
      headerAlign: "right",
    },
    {
      dataField: "stan",
      text: "STAN",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "responseCode",
      text: "Response Code",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "reconStatus",
      text: "Recon Status",
      formatter: reconStatus,
      sort: true,
    },
    {
      dataField: "matchedIn",
      text: "Matched In",
      formatter: matchInStatus,
      sort: true,
    },
  ];
  const headers = [
    {
      label: "Date Time",
      key: "dateTime",
    },
    {
      label: "Transaction",
      key: "transactionType",
    },
    {
      label: "Card",
      key: "pan",
    },
    {
      label: "Amount",
      key: "amount",
    },
    {
      label: "STAN",
      key: "stan",
    },
    {
      label: "Other Ref",
      key: "otherRef",
    },
    {
      label: "Recon Status",
      key: "reconStatus",
    },
    {
      label: "Matched In",
      key: "matchedIn",
    },
  ];

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  useEffect(() => {
    fetchTransactionData(filter);
    // eslint-disable-next-line
  }, [fetchTransactionData]);
  useEffect(() => {
    setRefresh(() => () => {
      fetchTransactionData(filter, true);
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, [fetchTransactionData, filter]);
  useEffect(() => {
    const { id } = queryString.parse(props.location.search);
    if (id) {
      setId(id);
      setShowModal(true);
    }
  }, [props.location.search]);

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div style={{ opacity: !loading ? "1" : "0.07" }}>
        <div className="row mb-3 align-items-center d-xs-none d-lg-flex">
          <div className="filter-item col-3 mt-2" style={{ minWidth: "210px" }}>
            <NewFilter
              title="Business Day"
              type="date"
              date={
                business_date_default ? new Date(business_date_default) : null
              }
              onChange={(e) => handleSelectChange(e, "business_date")}
            />
          </div>
          <div className="filter-item col-3 mt-2" style={{ minWidth: "210px" }}>
            <NewFilter
              title="Matching"
              type="checkbox"
              data={filter?.matching?.data}
              filter={filter?.matching?.filter}
              onChange={(e) => handleSelectChange(e, "matching")}
              isMulti
            />
          </div>
          <div className="filter-item col-3 mt-2" style={{ minWidth: "210px" }}>
            <NewFilter
              title="Transaction Type"
              type="checkbox"
              data={filter?.transaction_type?.data}
              filter={filter?.transaction_type?.filter}
              onChange={(e) => handleSelectChange(e, "transaction_type")}
              isMulti
            />
          </div>
          <div className="d-flex justify-content-start mt-2">
            <ButtonBasic
              wrapperClass={"d-flex justify-content-end"}
              icon={FilterIcon}
              title="Filter"
              onClick={() => {
                fetchTransactionData(filter, true);
              }}
            />
          </div>
        </div>
        
        <div className="row page-header d-flex justify-content-between">
          <div className="col-sm-12 col-lg-6 page-header d-flex justify-content-between m-0">
            <div className="title">Transaction Logs</div>
          </div>
          <div className="col-sm-12 col-lg-4 legend-wrapper d-flex align-items-center mt-sm-2 mt-lg-0">
            <BadgeCircle color="lightBlue" size="md" />
            <div className="legend-title color-gray-dim">EJ</div>
            <BadgeCircle color="success" size="md" />
            <div className="legend-title color-gray-dim">Switch</div>
            <BadgeCircle color="purple" size="md" />
            <div className="legend-title color-gray-dim">Core Bank</div>
            <BadgeCircle color="yellow" size="md" />
            <div className="legend-title color-gray-dim">VISA</div>
          </div>
        </div>
        <BootstrapTable
          columns={columns}
          bootstrap4={true}
          keyField="id"
          bordered={false}
          classes="rs-table table-layout-auto table-custom-style"
          wrapperClasses="overflow-y-auto fancy-scroll"
          data={transactionData}
          rowEvents={rowEvent}
          remote={{ sort: true }}
          onTableChange={onSortTable}
          noDataIndication={"No data found!"}
        />
        <div className="row">
          <div className="col-2">
            {transactionData.length > 0 && (
              <CSVLink
                data={transactionData}
                filename={"Transaction Report"}
                headers={headers}
                target="_blank"
                className="mr-2"
              >
                <img
                  width={25}
                  height={25}
                  src={CSVIcon}
                  style={{ backgroundColor: "white" }}
                  className="right-btn mr-2"
                  alt="rightbtn"
                />
              </CSVLink>
            )}
          </div>
          <div className="col-10">
            <Pagination setPage={setPage} page={page} totalPages={totalPages} />
          </div>
        </div>
        <div className="col-md-12">
          <TransactionlogModal transactionId={id} showModal={showModal} setShowModal={setShowModal} />
        </div>
      </div>
    </>
  );
};
export default TransactionLog;
