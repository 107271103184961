import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import BreadcrumbsStyle from "./BreadcrumbsStyle";

class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeRoute(route) {
    this.props.history.push(route);
  }

  render() {
    const { data, onClick } = this.props;
    return (
      <BreadcrumbsStyle className={`rs-breadcrumb`}>
        <Breadcrumb>
          {data &&
            data.map((crumb, i) => (
              <Breadcrumb.Item
                id={"active-crumb-" + i}
                key={i}
                active={crumb?.isActive}
                onClick={() => {
                  onClick && onClick();
                  !crumb?.isActive && crumb?.routeTo && this?.changeRoute(crumb.routeTo);
                }}
              >
                {crumb.title}
              </Breadcrumb.Item>
            ))}
        </Breadcrumb>
      </BreadcrumbsStyle>
    );
  }
}

export default Breadcrumbs;
