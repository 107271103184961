export default function miniChart({ chartData, loader }) {
  let noData = true;
  chartData?.series?.data?.forEach((data) => {
    if (noData) {
      noData = data == null;
    }
  });
  const chart = {
    title: {
      text: !loader && noData ? `No Data available ` : "",
      left: "center",
      top: "center",
      textStyle: {
        color: "#777",
        fontFamily: "Montserrat Bold",
        fontSize: "13px",
        overflow: "break",
        width: noData ? 200 : 400,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        show: false,
        data: noData ? [] : chartData?.xAxis?.data,
      },
    ],
    yAxis: [
      {
        type: "value",
        show: false,
      },
    ],
    series: [
      {
        symbol: "circle",
        symbolSize: 8,
        itemStyle: {
          color: "#118AB2",
          fontFamily: "Montserrat Regular",
        },
        type: "line",
        lineStyle: {
          color: "#118AB2",
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(17,138,178,100%)", // color at 0% position
              },
              {
                offset: 0.2,
                color: "rgba(17,138,178,80%)", // color at 0% position
              },
              {
                offset: 0.4,
                color: "rgba(17,138,178,60%)", // color at 0% position
              },
              {
                offset: 0.6,
                color: "rgba(17,138,178,40%)", // color at 0% position
              },
              {
                offset: 0.8,
                color: "rgba(17,138,178,20%)", // color at 0% position
              },
            ],
          },
        },
        emphasis: {
          focus: "series",
        },
        data: chartData?.series?.data,
      },
    ],
  };
  return chart;
}
