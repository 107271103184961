import styled from "styled-components";

const CardSmStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  background: ${({ theme }) => theme.card_background};
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0.75rem 1rem;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgb(0, 0, 0, 0.1);
  min-height: 80px;
  .title {
    text-transform: capitalize;
    font-size: 13px;
    // white-space: nowrap;
  }
  .value {
    margin-top: 10px;
    color: ${({ theme }) => theme.text.hover};
    font-size: 14px;
  }
  .smallValue {
    margin-top: 10px;
    color: ${({ theme }) => theme.text.hover};
    font-size: 14px;
  }
`;

export default CardSmStyle;
