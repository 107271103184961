export const OneLinkATMData = [
  {
    id: 1,
    type: "Settlement",
    issuer: "TIMESTREAM BANK",
    acquirer: "ABL-589430",
    transaction_count: 30,
    issuer_transaction_amount: 222000,
    issuer_fee_payable: 703.2,
    acquirer_fee: 562.5,
    network_fee: 140.7,
    issuer_net: 222706.95,
    acquirer_net: 222562.5,
    count: 30,
    amount: 222000,
    status: "BALANCED",
  },
  {
    id: 2,
    type: "Settlement",
    issuer: "TIMESTREAM BANK",
    acquirer: "ABPL-639530",
    transaction_count: 13,
    issuer_transaction_amount: 114500,
    issuer_fee_payable: 304.72,
    acquirer_fee: 243.75,
    network_fee: 60.97,
    issuer_net: 114806.345,
    acquirer_net: 114743.75,
    count: 13,
    amount: 114500,
    status: "UNBALANCED",
  },
  {
    id: 3,
    type: "Settlement",
    issuer: "TIMESTREAM BANK",
    acquirer: "ACBL-603011",
    transaction_count: 40,
    issuer_transaction_amount: 360500,
    issuer_fee_payable: 937.6,
    acquirer_fee: 750,
    network_fee: 187.6,
    issuer_net: 361442.6,
    acquirer_net: 361250,
    count: 40,
    amount: 360500,
    status: "BALANCED",
  },
  {
    id: 4,
    type: "Settlement",
    issuer: "TIMESTREAM BANK",
    acquirer: "BAF-627100",
    transaction_count: 52,
    issuer_transaction_amount: 355000,
    issuer_fee_payable: 1218.88,
    acquirer_fee: 975,
    network_fee: 243.88,
    issuer_net: 356225.38,
    acquirer_net: 355975,
    count: 52,
    amount: 355000,
    status: "BALANCED",
  },
];
export const OneLinkIBFTData = [
  {
    id: 1,
    type: "Settlement",
    sender: "TIMESTREAM BANK",
    receiver: "ABL-589430",
    transaction_count: 90,
    sender_transaction_amount: 2832014,
    sender_fee_payable: 556.89,
    receiver_fee: 562.5,
    network_fee: 556.89,
    sender_net: 2832582.14,
    receiver_net: 2832014,
    count: 30,
    amount: 222000,
    status: "BALANCED",
  },
  {
    id: 2,
    type: "Settlement",
    sender: "TIMESTREAM BANK",
    receiver: "ABPL-639530",
    transaction_count: 6,
    sender_transaction_amount: 225500,
    sender_fee_payable: 45.88,
    receiver_fee: 243.75,
    network_fee: 45.88,
    sender_net: 225546.63,
    receiver_net: 225500,
    count: 13,
    amount: 114500,
    status: "BALANCED",
  },
  {
    id: 3,
    type: "Settlement",
    sender: "TIMESTREAM BANK",
    receiver: "ACBL-603011",
    transaction_count: 37,
    sender_transaction_amount: 1474184,
    sender_fee_payable: 296.85,
    receiver_fee: 750,
    network_fee: 296.85,
    sender_net: 1474485.48,
    receiver_net: 1474184,
    count: 40,
    amount: 360500,
    status: "BALANCED",
  },
  {
    id: 4,
    type: "Settlement",
    sender: "TIMESTREAM BANK",
    receiver: "BAF-627100",
    transaction_count: 83,
    sender_transaction_amount: 4279805,
    sender_fee_payable: 910.35,
    receiver_fee: 975,
    network_fee: 910.35,
    sender_net: 4280725.73,
    receiver_net: 4279805,
    count: 52,
    amount: 355000,
    status: "BALANCED",
  },
];
