import BadgeRounded from "../../../components/BadgeRounded/BadgeRounded";
const status = (row) => (
  <BadgeRounded
    color={
      row === "Balanced" ? "success" : row === "Shortage" ? "warning" : "danger"
    }
    title={row}
  />
);
export const columns = [
  { hidden: true, dataField: "id", text: "ID" },
  {
    dataField: "branch_id",
    text: "Branch ID",
  },
  {
    dataField: "name",
    text: "Name",
  },
  {
    dataField: "location",
    text: "Location",
  },
  {
    dataField: "deposits",
    // formatter:
    text: "Deposits",
  },
  {
    dataField: "withdrawals",
    // formatter:
    text: "Withdrawals",
  },
  {
    dataField: "status",
    formatter: status,
    text: "Status",
  },
  {
    dataField: "branch_GL_balance",
    // formatter:
    text: "Branch GL Balance",
  },
];
