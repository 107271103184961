import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./ProvideAuth";

// screen if you're not yet authenticated.
const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLogin = useAuth();
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      exact
      render={(props) =>
        isLogin ? (
          <Component exact {...props} {...rest} />
        ) : (
          <Redirect push to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
