export const generalSettings = [
  { label: "First Name", key: "firstName" },
  { label: "Middle Name", key: "middleName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Work Phone", key: "officePhone" },
  { label: "Extention", key: "officePhoneExt" },
  { label: "Mobile Phone", key: "mobileNo" },
  { label: "Emp Code", key: "empCode" },
];

export const user_preferences = [
  {
    label: "Date format",
    type: "date",
    value: [
      { label: "MM-DD-YYYY", value: "MM-DD-YYYY" },
      { label: "DD-MM-YYYY", value: "DD-MM-YYYY" },
      { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
    ],
  },
  {
    label: "Time format",
    type: "time",
    value: [
      { label: "HH:mm:ss", value: "HH:mm:ss" },
      { label: "HH:mm", value: "HH:mm" },
    ],
  },
  {
    label: "Date/Time format",
    type: "dateTime",
    value: [
      { label: "MM-DD-YYYY HH:mm:ss", value: "MM-DD-YYYY HH:mm:ss" },
      { label: "DD-MM-YYYY HH:mm:ss", value: "DD-MM-YYYY HH:mm:ss" },
      { label: "DD-MM-YYYY HH:mm", value: "DD-MM-YYYY HH:mm" },
      { label: "MM-DD-YYYY HH:mm", value: "MM-DD-YYYY HH:mm" },
      { label: "YYYY-MM-DD HH:mm:ss", value: "YYYY-MM-DD HH:mm:ss" },
    ],
  },
];
