import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import AddIcon from "../../assets/images/add-icon.png";
import TrashIcon from "../../assets/images/trash.png";
import EditIcon from "../../assets/images/edit.png";
import CrossIcon from "../../assets/images/close.png";
import CheckIcon from "../../assets/images/done.png";
import RightArrowIcon from "../../assets/images/chevron_right.png";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import BadgeCircle from "../../components/BadgeCircle/BadgeCircle";
import ButtonTransparent from "../../components/Buttons/ButtonTransparent";
import { RegionManagementStyle } from "./AdminStyles";
import {
  createRegion,
  deleteRegion,
  getRegionsData,
  updateRegion,
} from "../../services/regionManagementService";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import ShowForPermission from "../../utils/permissionsWrapper";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import withDateTime from "../../utils/timedatehoc";
class RegionManagement extends React.Component {
  state = {
    loading: true,
    modalShow: false,
    editModalShow: false,
    passwordModalShow: false,
    isShowDialog: false,
    regions: {},
    regionTree: {
      subRegion1: [],
      subRegion2: [],
      subRegion3: [],
      subRegion4: [],
      subRegion5: [],
      subRegion6: [],
      subRegion7: [],
    },
    subRegion: undefined,
    subSubRegion: undefined,
    subSubRegion1: undefined,
    subSubRegion2: undefined,
    subSubRegion3: undefined,
    subSubRegion4: undefined,
    subSubRegion5: undefined,
    subSubRegion6: undefined,
    subSubRegion7: undefined,
    newRegion: "",
    subRegionValue1: "",
    subRegionValue2: "",
    subRegionValue3: "",
    subRegionValue4: "",
    subRegionValue5: "",
    subRegionValue6: "",
    subRegionValue7: "",
    isEdit: false,
    isEdit1: false,
    isEdit2: false,
    isEdit3: false,
    isEdit4: false,
    isEdit5: false,
    isEdit6: false,
    isEdit7: false,
    deleteConfirmationId: -1,
  };

  componentDidMount() {
    this.callRegionApi();
    this.props.dateTime.setRefresh(() => () => {
      this.setState({ loading: true }, () => {
        this.callRegionApi();
      });
    });
  }

  componentWillUnmount() {
    this.props.dateTime.setRefresh(() => () => {});
  }

  async callRegionApi() {
    try {
      const regionsData = await getRegionsData();
      this.setState({
        regions: { ...regionsData.data.filters[0] },
        loading: false,
      });
    } catch (err) {
      this.setState({ loading: false });
      console.log("err ", err);
    }
  }

  showSubRegion(regionId) {
    const { regions } = this.state;
    const selectedRegion = this.findRegion(regions.filterData, regionId);
    this.setState({
      subRegion: {
        regions: selectedRegion.subClass,
        parentId: selectedRegion.id,
      },
      subSubRegion: undefined,
      subSubRegion2: undefined,
      subSubRegion3: undefined,
      subSubRegion4: undefined,
      subSubRegion5: undefined,
      subSubRegion6: undefined,
    });
  }

  showSubSubRegion(regionId) {
    const { regions } = this.state;
    const selectedRegion = this.findRegion(regions.filterData, regionId);
    this.setState({
      subSubRegion: {
        regions: selectedRegion.subClass,
        parentId: selectedRegion.id,
      },
      subSubRegion2: undefined,
      subSubRegion3: undefined,
      subSubRegion4: undefined,
      subSubRegion5: undefined,
      subSubRegion6: undefined,
    });
  }

  showSubSubRegion1(regionId) {
    const { regions } = this.state;
    const selectedRegion = this.findRegion(regions.filterData, regionId);
    this.setState({
      subSubRegion2: {
        regions: selectedRegion.subClass,
        parentId: selectedRegion.id,
      },
      subSubRegion3: undefined,
      subSubRegion4: undefined,
      subSubRegion5: undefined,
      subSubRegion6: undefined,
    });
  }

  showSubSubRegion2(regionId) {
    const { regions } = this.state;
    const selectedRegion = this.findRegion(regions.filterData, regionId);
    this.setState({
      subSubRegion3: {
        regions: selectedRegion.subClass,
        parentId: selectedRegion.id,
      },
      subSubRegion4: undefined,
      subSubRegion5: undefined,
      subSubRegion6: undefined,
    });
  }

  showSubSubRegion3(regionId) {
    const { regions } = this.state;
    const selectedRegion = this.findRegion(regions.filterData, regionId);
    this.setState({
      subSubRegion4: {
        regions: selectedRegion.subClass,
        parentId: selectedRegion.id,
      },
      subSubRegion5: undefined,
      subSubRegion6: undefined,
    });
  }

  showSubSubRegion4(regionId) {
    const { regions } = this.state;
    const selectedRegion = this.findRegion(regions.filterData, regionId);
    this.setState({
      subSubRegion5: {
        regions: selectedRegion.subClass,
        parentId: selectedRegion.id,
      },
      subSubRegion6: undefined,
    });
  }

  onInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async createRegion(region, parentId, regionValue, regionArray, regionTarget) {
    this.setState({
      loading: true,
      // regions: {},
      // subRegion: undefined,
      // subSubRegion: undefined,
      // subSubRegion2: undefined,
      // subSubRegion3: undefined,
      // subSubRegion4: undefined,
      // subSubRegion5: undefined,
    });
    try {
      const data = {
        name: region,
        param1: "1",
      };
      if (parentId) {
        data["parentId"] = parentId;
      }
      const createResponse = await createRegion(data);
      if (createResponse.message.status === "200") {
        // if (createResponse.data.parentId === "1") {
        //   regions.filterData = [
        //     ...regions.filterData,
        //     { id: createResponse.data.id, label: createResponse.data.name },
        //   ];
        //   regions.filterData = regions.filterData.filter(
        //     (reg) => reg !== undefined
        //   );
        //   updatedRegion = regions;
        // } else {
        //   updatedRegion = this.findAndAddRegion(
        //     regions.filterData,
        //     createResponse.data.parentId,
        //     { id: createResponse.data.id, label: createResponse.data.name }
        //   );
        // }
        this.setState({
          [regionValue]: "",
          loading: false,
        });
        this.callRegionApi().then(() => {
          if (createResponse.data.parentId === "1") {
          } else if (regionValue === "subRegionValue1") {
            this.showSubRegion(createResponse.data.parentId);
          } else if (regionValue === "subRegionValue2") {
            this.showSubSubRegion(createResponse.data.parentId);
          } else if (regionValue === "subRegionValue3") {
            this.showSubSubRegion1(createResponse.data.parentId);
          } else if (regionValue === "subRegionValue4") {
            this.showSubSubRegion2(createResponse.data.parentId);
          } else if (regionValue === "subRegionValue5") {
            this.showSubSubRegion3(createResponse.data.parentId);
          } else if (regionValue === "subRegionValue6") {
            this.showSubSubRegion4(createResponse.data.parentId);
          }
        });
      }
    } catch (err) {
      this.setState({
        ...this.state,
        loading: false,
      });
      console.log("post region error ", err);
    }
  }

  showConfirmationDialog(regionId) {
    this.setState({ isShowDialog: true, deleteConfirmationId: regionId });
  }

  async deleteRegion() {
    this.setState({ loading: true });
    try {
      const { regions } = this.state;
      const response = await deleteRegion(this.state.deleteConfirmationId);
      if (response?.message && response?.message.status === "200") {
        regions.filterData = this.findAndRemoveRegion(
          regions.filterData,
          this.state.deleteConfirmationId
        );
        if (
          this.state.subRegion?.parentId === this.state.deleteConfirmationId
        ) {
          this.setState({
            subRegion: undefined,
          });
        } else if (
          this.state.subSubRegion?.parentId === this.state.deleteConfirmationId
        ) {
          this.setState({
            subSubRegion: undefined,
          });
        } else if (
          this.state.subSubRegion1?.parentId === this.state.deleteConfirmationId
        ) {
          this.setState({
            subSubRegion1: undefined,
          });
        } else if (
          this.state.subSubRegion2?.parentId === this.state.deleteConfirmationId
        ) {
          this.setState({
            subSubRegion2: undefined,
          });
        } else if (
          this.state.subSubRegion3?.parentId === this.state.deleteConfirmationId
        ) {
          this.setState({
            subSubRegion3: undefined,
          });
        } else if (
          this.state.subSubRegion4?.parentId === this.state.deleteConfirmationId
        ) {
          this.setState({
            subSubRegion4: undefined,
          });
        } else if (
          this.state.subSubRegion5?.parentId === this.state.deleteConfirmationId
        ) {
          this.setState({
            subSubRegion5: undefined,
          });
        }

        this.setState({
          regions,
        });
      }
    } catch (err) {
      console.log("err delete region ", err);
    }
    this.setState({
      isShowDialog: false,
      loading: false,
    });
  }

  editRegion(regionId, targetName, isEditTarget, e) {
    this.setState({ loading: true });
    e.stopPropagation();
    const { regions } = this.state;
    const regionValue = this.findRegion(regions.filterData, regionId);
    this.setState({
      modalShow: true,
      [targetName]: regionValue.label,
      [isEditTarget]: true,
      editRegionId: regionId,
      loading: false,
    });
  }

  async updateRegion(value, id, isEditTarget, targetName) {
    this.setState({ loading: true });
    try {
      const { regions } = this.state;
      const regionFound = this.findRegion(this.state.regions.filterData, id);
      const response = await updateRegion({
        name: value,
        parentId: regionFound.parentId,
        id: regionFound.id,
      });
      if (response && response.message && response.message.status === "200") {
        this.findAndUpdateRegion(regions.filterData, id, value);
      }
      this.setState({
        [isEditTarget]: false,
        [targetName]: "",
        regions,
        loading: false,
      });
    } catch (err) {}
  }

  findAndRemoveRegion(regions, regionId) {
    for (let i = 0; i < regions.length; i++) {
      if (regions[i]) {
        if (regions[i]?.id === regionId) {
          delete regions[i];
        } else if (regions[i]?.subClass !== undefined) {
          this.findAndRemoveRegion(regions[i].subClass, regionId);
        }
      }
    }
    return regions;
  }

  findRegion(regions, regionId) {
    for (let i = 0; i < regions.length; i++) {
      if (regions[i]) {
        if (regions[i].id === regionId) {
          return regions[i];
        } else if (regions[i]?.subClass !== undefined) {
          const found = this.findRegion(regions[i].subClass, regionId);
          if (found) return found;
        }
      }
    }
    // return regions;
  }

  findAndAddRegion(regions, regionParentId, data) {
    if (regions && regions.length > 0) {
      for (let i = 0; i < regions.length; i++) {
        if (regions[i]) {
          if (regions[i].id === regionParentId) {
            if (regions[i]?.subClass) {
              regions[i].subClass.push(data);
            } else {
              regions[i]["subClass"] = [{ ...data }];
            }
            break;
          } else if (regions[i]?.subClass !== undefined) {
            this.findAndAddRegion(regions[i].subClass, regionParentId, data);
          }
        }
      }
      return regions;
    }
  }

  findAndUpdateRegion(regions, regionId, value) {
    for (let i = 0; i < regions.length; i++) {
      if (regions[i]) {
        if (regions[i].id === regionId) {
          regions[i].label = value;
        } else if (regions[i]?.subClass !== undefined) {
          this.findAndUpdateRegion(regions[i].subClass, regionId, value);
        }
      }
    }
    return regions;
  }

  render() {
    const {
      regions,
      subRegion,
      subSubRegion,
      subSubRegion2,
      subSubRegion3,
      subSubRegion4,
      subSubRegion5,
    } = this.state;
    this.columns2 = [
      { hidden: true, dataField: "atmId", text: "ID" },

      {
        dataField: "atmid",
        text: "ReconStream",
        sort: true,
      },
      {
        dataField: "stan",
        text: <BadgeRounded title="admin" />,
        sort: true,
      },
      {
        dataField: "transactionType",
        text: "recon@gmail.com",
        sort: true,
      },
      {
        dataField: "amount",
        text: <BadgeRounded color="danger" title="Blocked" />,
        sort: true,
      },
      {
        dataField: "transactionType",
        text: (
          <div
            onClick={() => this.setState({ passwordModalShow: true })}
            style={{
              color: "#118AB2",
              textDecoration: "underline",
              fontSize: "14px",
              fontFamily: "Montserrat Regular",
            }}
          >
            reset password
          </div>
        ),
        sort: true,
      },
      {
        dataField: "amount",
        text: (
          <div>
            <ShowForPermission permission="clk_region_del">
              <img
                width={24}
                height={24}
                src={TrashIcon}
                className="right-btn mr-3"
                alt="rightbtn"
              />
            </ShowForPermission>
            <ShowForPermission permission="clk_region_edit">
              <img
                width={24}
                height={24}
                src={EditIcon}
                className="right-btn mr-2"
                alt="rightbtn"
                onClick={() => this.setState({ editModalShow: true })}
              />
            </ShowForPermission>
          </div>
        ),
        sort: true,
      },
    ];
    this.column1 = [
      { hidden: true, dataField: "atmId", text: "ID" },

      {
        dataField: "atmid",
        text: (
          <div style={{ fontSize: "14px", color: "#ccc" }}>ATM Dashboard</div>
        ),
        sort: true,
      },
      {
        dataField: "stan",
        text: <BadgeCircle color="success" icon={CheckIcon} />,
        sort: true,
      },
      {
        dataField: "transactionType",
        text: <BadgeCircle color="success" icon={CheckIcon} />,
        sort: true,
      },
      {
        dataField: "amount",
        text: <BadgeCircle color="success" icon={CheckIcon} />,
        sort: true,
      },
      {
        dataField: "transactionType",
        text: <BadgeCircle color="danger" icon={CrossIcon} />,
        sort: true,
      },
      {
        dataField: "amount",
        text: <BadgeCircle color="danger" icon={CrossIcon} />,
        sort: true,
      },
    ];
    
    return (
      <RegionManagementStyle>
        {this.state.loading && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div
          className="rs-basic-card p-0"
          style={{
            opacity: !this.state.loading ? "1" : "0.07",
          }}
        >
          <div className="region-container fancy-scroll">
            <div className="region-bar-wrapper fancy-scroll">
              <div className="region-bar-title">Regions</div>
              {regions?.filterData?.map((region) => (
                <div
                  key={region.id}
                  className={`region-bar-item-wrapper ${
                    subRegion?.parentId === region?.id
                      ? "background-secondary primary-color"
                      : ""
                  }`}
                  onClick={() => this.showSubRegion(region.id)}
                >
                  <div className="region-bar-item-title text-truncate">
                    {region.label}
                  </div>
                  <div className="region-bar-item-right">
                    <div className="region-bar-item-icon mr-2">
                      <ShowForPermission permission="clk_region_edit">
                        <img
                          width={24}
                          height={24}
                          src={EditIcon}
                          alt="edit"
                          onClick={(e) =>
                            this.editRegion(region.id, "newRegion", "isEdit", e)
                          }
                        />
                      </ShowForPermission>
                    </div>
                    <div className="region-bar-item-icon mr-1">
                      <ShowForPermission permission="clk_region_del">
                        <img
                          width={24}
                          height={24}
                          src={TrashIcon}
                          alt="delete"
                          onClick={() => this.showConfirmationDialog(region.id)}
                        />
                      </ShowForPermission>
                    </div>
                    <div className="mr-0">
                      <img
                        width={24}
                        height={24}
                        src={RightArrowIcon}
                        alt="arrow-right"
                        onClick={() => this.showSubRegion(region.id)}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {this.state.modalShow && (
                <div className="d-flex align-items-center mt-3 region-input-wrapper">
                  <input
                    className="rs-input mr-2"
                    type="text"
                    placeholder="New Region"
                    value={this.state.newRegion}
                    name="newRegion"
                    onChange={(e) => this.onInputChange(e)}
                  />
                  <ButtonTransparent
                    title={this.state.isEdit ? "Update" : "Add"}
                    onClick={() =>
                      this.state.isEdit
                        ? this.updateRegion(
                            this.state.newRegion,
                            this.state.editRegionId,
                            "isEdit",
                            "newRegion"
                          )
                        : this.createRegion(
                            this.state.newRegion,
                            regions?.filterId,
                            "newRegion",
                            regions?.filterDatam,
                            "regions"
                          )
                    }
                  />
                </div>
              )}
              <div className="region-bar-btn-wrapper">
                <ShowForPermission permission="clk_region_add">
                  <ButtonTransparent
                    onClick={() =>
                      this.setState({ modalShow: !this.state.modalShow })
                    }
                    title={"Add Region"}
                    icon={AddIcon}
                  />
                </ShowForPermission>
              </div>
            </div>

            {/* sub region level 1 */}
            {subRegion && (
              <div className="region-bar-wrapper fancy-scroll">
                <div className="region-bar-title">Sub Region</div>
                {subRegion?.regions?.map((region) => (
                  <div
                    key={region.id}
                    className={`region-bar-item-wrapper ${
                      subSubRegion?.parentId === region?.id
                        ? "background-secondary primary-color"
                        : ""
                    }`}
                    onClick={() => this.showSubSubRegion(region.id)}
                  >
                    <div className="region-bar-item-title text-truncate">
                      {region.label}
                    </div>
                    <div className="region-bar-item-right">
                      <div className="region-bar-item-icon mr-2">
                        <ShowForPermission permission="clk_region_edit">
                          <img
                            width={24}
                            height={24}
                            src={EditIcon}
                            alt="edit"
                            onClick={(e) =>
                              this.editRegion(
                                region.id,
                                "subRegionValue1",
                                "isEdit1",
                                e
                              )
                            }
                          />
                        </ShowForPermission>
                      </div>
                      <div className="region-bar-item-icon mr-1">
                        <ShowForPermission permission="clk_region_del">
                          <img
                            width={24}
                            height={24}
                            src={TrashIcon}
                            alt="edit"
                            onClick={() =>
                              this.showConfirmationDialog(region.id)
                            }
                          />
                        </ShowForPermission>
                      </div>
                      <div className="mr-0">
                        <img
                          width={24}
                          height={24}
                          src={RightArrowIcon}
                          alt="arrow-right"
                          onClick={() => this.showSubSubRegion(region.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="d-flex align-items-center mt-3 region-input-wrapper">
                  <input
                    className="rs-input mr-2"
                    type="text"
                    placeholder="new sub region"
                    name="subRegionValue1"
                    value={this.state.subRegionValue1}
                    onChange={(e) => this.onInputChange(e)}
                  />
                  <ButtonTransparent
                    title={this.state.isEdit1 ? "Update" : "Add"}
                    onClick={() =>
                      this.state.isEdit1
                        ? this.updateRegion(
                            this.state.subRegionValue1,
                            this.state.editRegionId,
                            "isEdit1",
                            "subRegionValue1"
                          )
                        : this.createRegion(
                            this.state.subRegionValue1,
                            subRegion.parentId,
                            "subRegionValue1",
                            subRegion,
                            "subRegion"
                          )
                    }
                  />
                </div>
                <div className="region-bar-btn-wrapper">
                  <ShowForPermission permission="clk_region_add">
                    <ButtonTransparent
                      title={"Add Sub Region"}
                      icon={AddIcon}
                    />
                  </ShowForPermission>
                </div>
              </div>
            )}
            {/* sub region level 1 ends */}

            {subSubRegion && (
              <div className="region-bar-wrapper fancy-scroll">
                <div className="region-bar-title">Sub Region</div>
                {subSubRegion?.regions?.map((region) => (
                  <div
                    key={region.id}
                    className={`region-bar-item-wrapper ${
                      subSubRegion2?.parentId === region?.id
                        ? "background-secondary primary-color"
                        : ""
                    }`}
                    onClick={() => this.showSubSubRegion1(region.id)}
                  >
                    <div className="region-bar-item-title text-truncate">
                      {region.label}
                    </div>
                    <div className="region-bar-item-right">
                      <div className="region-bar-item-icon mr-2">
                        <ShowForPermission permission="clk_region_edit">
                          <img
                            width={24}
                            height={24}
                            src={EditIcon}
                            alt="edit"
                            onClick={(e) =>
                              this.editRegion(
                                region.id,
                                "subRegionValue2",
                                "isEdit2",
                                e
                              )
                            }
                          />
                        </ShowForPermission>
                      </div>
                      <div className="region-bar-item-icon mr-1">
                        <ShowForPermission permission="clk_region_del">
                          <img
                            width={24}
                            height={24}
                            src={TrashIcon}
                            alt="edit"
                            onClick={() =>
                              this.showConfirmationDialog(region.id)
                            }
                          />
                        </ShowForPermission>
                      </div>
                      <div className="mr-0">
                        <img
                          width={24}
                          height={24}
                          src={RightArrowIcon}
                          alt="arrow-right"
                          onClick={() => this.showSubSubRegion1(region.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="d-flex align-items-center mt-3 region-input-wrapper">
                  <input
                    className="rs-input mr-2"
                    type="text"
                    placeholder="new sub region"
                    name="subRegionValue2"
                    value={this.state.subRegionValue2}
                    onChange={(e) => this.onInputChange(e)}
                  />
                  <ButtonTransparent
                    title={this.state.isEdit2 ? "Update" : "Add"}
                    onClick={() =>
                      this.state.isEdit2
                        ? this.updateRegion(
                            this.state.subRegionValue2,
                            this.state.editRegionId,
                            "isEdit2",
                            "subRegionValue2"
                          )
                        : this.createRegion(
                            this.state.subRegionValue2,
                            subSubRegion.parentId,
                            "subRegionValue2",
                            subSubRegion,
                            "subSubRegion"
                          )
                    }
                  />
                </div>
                <div className="region-bar-btn-wrapper">
                  <ShowForPermission permission="clk_region_add">
                    <ButtonTransparent
                      title={"Add Sub Region"}
                      icon={AddIcon}
                    />
                  </ShowForPermission>
                </div>
              </div>
            )}

            {subSubRegion2 && (
              <div className="region-bar-wrapper fancy-scroll">
                <div className="region-bar-title">Sub Region</div>
                {subSubRegion2?.regions?.map((region) => (
                  <div
                    key={region.id}
                    className={`region-bar-item-wrapper ${
                      subSubRegion3?.parentId === region?.id
                        ? "background-secondary primary-color"
                        : ""
                    }`}
                    onClick={() => this.showSubSubRegion2(region.id)}
                  >
                    <div className="region-bar-item-title text-truncate">
                      {region.label}
                    </div>
                    <div className="region-bar-item-right">
                      <div className="region-bar-item-icon mr-1">
                        <ShowForPermission permission="clk_region_edit">
                          <img
                            width={24}
                            height={24}
                            src={EditIcon}
                            alt="edit"
                            onClick={(e) =>
                              this.editRegion(
                                region.id,
                                "subRegionValue3",
                                "isEdit3",
                                e
                              )
                            }
                          />
                        </ShowForPermission>
                      </div>
                      <div className="region-bar-item-icon mr-1">
                        <ShowForPermission permission="clk_region_del">
                          <img
                            width={24}
                            height={24}
                            src={TrashIcon}
                            alt="edit"
                            onClick={() =>
                              this.showConfirmationDialog(region.id)
                            }
                          />
                        </ShowForPermission>
                      </div>
                      <div className="mr-0">
                        <img
                          width={24}
                          height={24}
                          src={RightArrowIcon}
                          alt="arrow-right"
                          onClick={() => this.showSubSubRegion2(region.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="d-flex align-items-center mt-3 region-input-wrapper">
                  <input
                    className="rs-input mr-2"
                    type="text"
                    placeholder="new sub region"
                    name="subRegionValue3"
                    value={this.state.subRegionValue3}
                    onChange={(e) => this.onInputChange(e)}
                  />
                  <ButtonTransparent
                    title={this.state.isEdit3 ? "Update" : "Add"}
                    onClick={() =>
                      this.state.isEdit3
                        ? this.updateRegion(
                            this.state.subRegionValue3,
                            this.state.editRegionId,
                            "isEdit3",
                            "subRegionValue3"
                          )
                        : this.createRegion(
                            this.state.subRegionValue3,
                            subSubRegion2.parentId,
                            "subRegionValue3",
                            subSubRegion2,
                            "subSubRegion2"
                          )
                    }
                  />
                </div>
                <div className="region-bar-btn-wrapper">
                  <ShowForPermission permission="clk_region_add">
                    <ButtonTransparent
                      title={"Add Sub Region"}
                      icon={AddIcon}
                    />
                  </ShowForPermission>
                </div>
              </div>
            )}

            {subSubRegion3 && (
              <div className="region-bar-wrapper fancy-scroll">
                <div className="region-bar-title">Sub Region</div>
                {subSubRegion3?.regions?.map((region) => (
                  <div
                    key={region.id}
                    className={`region-bar-item-wrapper ${
                      subSubRegion4?.parentId === region?.id
                        ? "background-secondary primary-color"
                        : ""
                    }`}
                    onClick={() => this.showSubSubRegion3(region.id)}
                  >
                    <div className="region-bar-item-title text-truncate">
                      {region.label}
                    </div>
                    <div className="region-bar-item-right">
                      <div className="region-bar-item-icon mr-1">
                        <ShowForPermission permission="clk_region_del">
                          <img
                            width={24}
                            height={24}
                            src={TrashIcon}
                            alt="edit"
                            onClick={() =>
                              this.showConfirmationDialog(region.id)
                            }
                          />
                        </ShowForPermission>
                      </div>
                      <div className="region-bar-item-icon mr-2">
                        <ShowForPermission permission="clk_region_edit">
                          <img
                            width={24}
                            height={24}
                            src={EditIcon}
                            alt="edit"
                            onClick={(e) =>
                              this.editRegion(
                                region.id,
                                "subRegionValue4",
                                "isEdit4",
                                e
                              )
                            }
                          />
                        </ShowForPermission>
                      </div>
                      <div className="mr-0">
                        <img
                          width={24}
                          height={24}
                          src={RightArrowIcon}
                          alt="arrow-right"
                          onClick={() => this.showSubSubRegion3(region.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="d-flex align-items-center mt-3 region-input-wrapper">
                  <input
                    className="rs-input mr-2"
                    type="text"
                    placeholder="new sub region"
                    name="subRegionValue4"
                    value={this.state.subRegionValue4}
                    onChange={(e) => this.onInputChange(e)}
                  />
                  <ButtonTransparent
                    title={this.state.isEdit4 ? "Update" : "Add"}
                    onClick={() =>
                      this.state.isEdit4
                        ? this.updateRegion(
                            this.state.subRegionValue4,
                            this.state.editRegionId,
                            "isEdit4",
                            "subRegionValue4"
                          )
                        : this.createRegion(
                            this.state.subRegionValue4,
                            subSubRegion3.parentId,
                            "subRegionValue4",
                            subSubRegion3,
                            "subSubRegion3"
                          )
                    }
                  />
                </div>
                <div className="region-bar-btn-wrapper">
                  <ShowForPermission permission="clk_region_add">
                    <ButtonTransparent
                      title={"Add Sub Region"}
                      icon={AddIcon}
                    />
                  </ShowForPermission>
                </div>
              </div>
            )}

            {subSubRegion4 && (
              <div className="region-bar-wrapper fancy-scroll">
                <div className="region-bar-title">Sub Region</div>
                {subSubRegion4?.regions?.map((region) => (
                  <div
                    key={region.id}
                    className={`region-bar-item-wrapper ${
                      subSubRegion5?.parentId === region?.id
                        ? "background-secondary primary-color"
                        : ""
                    }`}
                    onClick={() => this.showSubSubRegion4(region.id)}
                  >
                    <div className="region-bar-item-title text-truncate">
                      {region.label}
                    </div>
                    <div className="region-bar-item-right">
                      <div className="region-bar-item-icon mr-1">
                        <ShowForPermission permission="clk_region_del">
                          <img
                            width={24}
                            height={24}
                            src={TrashIcon}
                            alt="edit"
                            onClick={() =>
                              this.showConfirmationDialog(region.id)
                            }
                          />
                        </ShowForPermission>
                      </div>
                      <div className="region-bar-item-icon mr-2">
                        <ShowForPermission permission="clk_region_edit">
                          <img
                            width={24}
                            height={24}
                            src={EditIcon}
                            alt="edit"
                            onClick={() =>
                              this.editRegion(
                                region.id,
                                "subRegionValue5",
                                "isEdit5"
                              )
                            }
                          />
                        </ShowForPermission>
                      </div>
                      <div className="mr-0">
                        <img
                          width={24}
                          height={24}
                          src={RightArrowIcon}
                          alt="arrow-right"
                          onClick={() => this.showSubSubRegion4(region.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="d-flex align-items-center mt-3 region-input-wrapper">
                  <input
                    className="rs-input mr-2"
                    type="text"
                    placeholder="new sub region"
                    name="subRegionValue5"
                    value={this.state.subRegionValue5}
                    onChange={(e) => this.onInputChange(e)}
                  />
                  <ButtonTransparent
                    title={this.state.isEdit5 ? "Update" : "Add"}
                    onClick={() =>
                      this.state.isEdit5
                        ? this.updateRegion(
                            this.state.subRegionValue5,
                            this.state.editRegionId,
                            "isEdit5",
                            "subRegionValue5"
                          )
                        : this.createRegion(
                            this.state.subRegionValue5,
                            subSubRegion4.parentId,
                            "subRegionValue5",
                            subSubRegion4,
                            "subSubRegion4"
                          )
                    }
                  />
                </div>
                <div className="region-bar-btn-wrapper">
                  <ShowForPermission permission="clk_region_add">
                    <ButtonTransparent
                      title={"Add Sub Region"}
                      icon={AddIcon}
                    />
                  </ShowForPermission>
                </div>
              </div>
            )}

            {subSubRegion5 && (
              <div className="region-bar-wrapper fancy-scroll">
                <div className="region-bar-title">Sub Region</div>
                {subSubRegion5?.regions?.map((region) => (
                  <div key={region.id} className="region-bar-item-wrapper">
                    <div className="region-bar-item-title text-truncate">
                      {region.label}
                    </div>
                    <div className="region-bar-item-right">
                      <div className="region-bar-item-icon mr-1">
                        <ShowForPermission permission="clk_region_del">
                          <img
                            width={24}
                            height={24}
                            src={TrashIcon}
                            alt="edit"
                            onClick={() =>
                              this.showConfirmationDialog(region.id)
                            }
                          />
                        </ShowForPermission>
                      </div>
                      <div className="region-bar-item-icon mr-2">
                        <ShowForPermission permission="clk_region_edit">
                          <img
                            width={24}
                            height={24}
                            src={EditIcon}
                            alt="edit"
                            onClick={() =>
                              this.editRegion(
                                region.id,
                                "subRegionValue6",
                                "isEdit6"
                              )
                            }
                          />
                        </ShowForPermission>
                      </div>
                      {/* <div className="mr-0">
                        <img
                          width={24}
                          height={24}
                          src={RightArrowIcon}
                          alt="arrow-right"
                        />
                      </div> */}
                    </div>
                  </div>
                ))}
                <div className="d-flex align-items-center mt-3 region-input-wrapper">
                  <input
                    className="rs-input mr-2"
                    type="text"
                    placeholder="new sub region"
                    name="subRegionValue6"
                    value={this.state.subRegionValue6}
                    onChange={(e) => this.onInputChange(e)}
                  />
                  <ButtonTransparent
                    title={this.state.isEdit6 ? "Update" : "Add"}
                    onClick={() =>
                      this.state.isEdit6
                        ? this.updateRegion(
                            this.state.subRegionValue6,
                            this.state.editRegionId,
                            "isEdit6",
                            "subRegionValue6"
                          )
                        : this.createRegion(
                            this.state.subRegionValue6,
                            subSubRegion5.parentId,
                            "subRegionValue6",
                            subSubRegion5,
                            "subSubRegion5"
                          )
                    }
                  />
                </div>
                <div className="region-bar-btn-wrapper">
                  <ShowForPermission permission="clk_region_add">
                    <ButtonTransparent
                      title={"Add Sub Region"}
                      icon={AddIcon}
                    />
                  </ShowForPermission>
                </div>
              </div>
            )}
          </div>
        </div>
        <ConfirmationModal
          title="Delete Region"
          isShow={this.state.isShowDialog}
          message="Are you sure you want to delete this region ?"
          confirmAction={() => this.deleteRegion()}
          hideModal={() => this.setState({ isShowDialog: false })}
        />
      </RegionManagementStyle>
    );
  }
}

export default withDateTime(RegionManagement);
