import React from "react";
import "../../App.css";
import ReactECharts from "echarts-for-react";
import SingleInfoCard from "../../components/SingleInfoCard/SingleInfoCard";
import $ from "jquery";
import FilterDropdown from "../../components/FilterDropdown/FilterDropdown";
// import { baseUrl } from "../../configuration/apiUrl";
import axiosInstance from "../../utils/axiosInstance";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import MatchingSummary from "./POS charts/MatchingSummary";
import Disputes from "./POS charts/Disputes";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import FilterIcon from "../../assets/images/filter.png";
import NewFilter from "../../components/FilterDropdown/NewFilter";
import withDateTime from "../../utils/timedatehoc";
import { mapdata } from "../../utils/regionHelper";
import { colorPalette } from "../../constants/ColorPalette";

class PosDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterParams: {},
      filter: {},
      business_date_default: "",
      POSDashboardAll: true,

      matchingSummaryLoading: true,
      matchingSummaryData: {
        title: "",
        data: [
          { value: 0, name: "Matched" },
          { value: 0, name: "UnMatched" },
        ],
      },
      regions: [],
      filters: [],
      otherFilters: "",
      regionFilters: "",
      deviceId: 0,
      gotResponse: false,
    }; // state end
  }

  async componentDidMount() {
    $(".count-num").each(function () {
      var $this = $(this);
      $({ Counter: 0 }).animate(
        { Counter: $this.text() },
        {
          duration: 2000,
          easing: "swing",
          step: function () {
            $this.text(Math.ceil(this.Counter));
          },
        }
      );
    });
    await this.getFilters();
    this.props.dateTime.setRefresh(() => async () => {
      await this.getFilters();
    });
  }
  componentWillUnmount() {
    this.props.dateTime.setRefresh(() => () => {});
  }

  async getFilters() {
    try {
      const res = await axiosInstance.get(
        `/hierarchical/filters?screen_id=pos_dashboard`
      );
      // debugger;
      let regions = res.data.filters.filter(
        ({ filterName }) => filterName === "region"
      );
      let obj = {};
      let business_date_default;
      res.data.filters.forEach((filterIn) => {
        if (filterIn.filterName === "business_date") {
          business_date_default = filterIn.filterDefaultValue;
        }
        if (filterIn.filterName === "region") {
        } else
          obj = {
            ...obj,
            [filterIn.filterName]: {
              data: filterIn.filterData.map((obj) => ({
                ...obj,
                value: obj.codeValue,
              })),
              filter:
                filterIn.filterName === "business_date"
                  ? [filterIn.filterDefaultValue]
                  : filterIn.filterData
                      .filter((obj) => (obj.param1 === "1" ? true : false))
                      .map((obj) => obj.codeValue),
              filterId: filterIn.filterId,
            },
          };
      });
      // let filter = "";
      if (regions.length > 0) {
        regions[0].filterData = mapdata(regions[0].filterData);
        regions[0].filterData.forEach((x) => {
          x.checked = x.param1 === "1" ? true : false;
        });
        // filter = `&region_id=${regions[0].filterData
        //   .map(({ codeValue }) => codeValue)
        //   .join(",")}`;
        // this.filter.current = filter;
      }
      // const filter = `region_id=${regions[0].filterData
      //   .map(({ codeValue }) => codeValue)
      //   .join(",")}&`;
      this.setState({
        filters: obj,
        business_date_default,
        regions,
        gotResponse: true,
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleSelectChange = (e, type) => {
    const obj = { ...this.state.filters };
    if (type === "business_date") {
      obj.business_date = { ...obj.business_date, filter: [e] };
    } else {
      if (e.flag) obj[type].filter.push(e.id);
      else obj[type].filter = obj[type].filter.filter((id) => id !== e.id);
    }
    this.setState({ filters: obj, business_date_default: "" });
  };

  // handleFilters = async () => {
  //   const { filter } = this.state;
  //   let allFilters = "";
  //   for (let key in filter) {
  //     if (filter[key]?.length > 0) {
  //       if (filter[key] === "business_day") {
  //         delete filter[key];
  //       }
  //       allFilters += `${key}=${filter[key]?.join(",")}&`;
  //     }
  //   }
  //   try {
  //     await axiosInstance.get(`/apiHere?${allFilters}page=0&size=10`);
  //   } catch (err) {
  //     console.log("error ", err);
  //   }
  // };

  render() {
    // const colorPalette = ["#00AF91", "#EF476F", "#FFD166", "#118AB2"];

    var { theme } = this.props;
    const { regions } = this.state;
    return (
      <>
        {!this.state.gotResponse && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div style={{ opacity: this.state.gotResponse ? "1" : "0.07" }}>
          <div className="row align-items-center d-xs-none d-lg-flex">
            {this.state.gotResponse && (
              <>
                {regions &&
                  regions.map((region) => {
                    return (
                      <div className="col-3">
                        <FilterDropdown
                          title={region.filterLabel}
                          type="checkbox"
                          data={region.filterData}
                          onDropdownChange={(value) => {
                            // this.onRegionDropdownChange(value)
                          }}
                        />
                      </div>
                    );
                  })}
                <div className="col-3">
                  <NewFilter
                    title={"Business Day"}
                    type="date"
                    date={
                      this.state.business_date_default
                        ? new Date(this.state.business_date_default)
                        : null
                    }
                    onChange={(e) =>
                      this.handleSelectChange(e, "business_date")
                    }
                  />
                </div>

                <div className="col-3">
                  <NewFilter
                    title={"POS Type"}
                    type="checkbox"
                    isMulti
                    data={this.state.filters?.pos_type?.data}
                    filter={this.state.filters?.pos_type?.filter}
                    onChange={(e) => this.handleSelectChange(e, "pos_type")}
                  />
                </div>

                <div className="col d-flex justify-content-start">
                  <ButtonBasic
                    icon={FilterIcon}
                    title="Filter"
                    //onClick={this.handleFilters}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <SingleInfoCard
                title={"Number of POS Devices"}
                value={"1600"}
                per={""}
                perDown={false}
              />
            </div>
            <div className="col-sm-6 col-lg-3">
              <SingleInfoCard
                title={"Purchase Count"}
                value={"14210"}
                per={"10%"}
                perDown={false}
              />
            </div>
            <div className="col-sm-6 col-lg-3">
              <SingleInfoCard
                title={"Refund Count"}
                value={"500"}
                per={"30%"}
                perDown={true}
              />
            </div>
            <div className="col-sm-6 col-lg-3">
              <SingleInfoCard
                title={"Chargebacks/Returned Payments"}
                value={"10"}
                per={"30%"}
                perDown={true}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={MatchingSummary({
                    theme: theme,
                    colorPalette: colorPalette,
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={this.state.previousDaySumOptionLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={Disputes({
                    theme: theme,
                    colorPalette: colorPalette,
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={this.state.previousDaySumOptionLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withDateTime(PosDashboard);
