// import iconUnauthorized from "../../assets/images/un_authorized_icon_1.png";

const EmptyPage = () => {
  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12"></div>
      </div>
    </div>
  );
};

export default EmptyPage;
