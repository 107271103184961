import iconUnauthorized from "../../assets/images/un_authorized_icon_1.png";

const MaintenancePage = () => {
  return (
    <div className="container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12">
          <img
            src={iconUnauthorized}
            width="150px"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            alt="unauthorized"
          />
          <hr style={{ width: "50%" }} />
          <h5 className="text-center">
            Sorry, the app is under maintenance now.
          </h5>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
