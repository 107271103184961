import React, { useState, useEffect, useRef, Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DropdownTreeSelect from "react-dropdown-tree-select";
import axiosInstance from "../../../utils/axiosInstance";
import NewFilterDropdown from "../../../components/FilterDropdown/NewFilter";
import Toast from "../../../components/Toast/Toast";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import chevronDown from "../../../assets/images/chevron-down.png";
import FilterDropdownStyle from "../user-profile-management/user/FilterDropdownStyle";

const WorkflowModal = ({ setShowModal, workflow, getWorkflowsData }) => {
  const initialReconValues = {
    reconName: workflow?.reconName || "",
    description: workflow?.description || "",
    outputTableName: workflow?.outputTableName || "",
    maxConcurrentAccounts: workflow?.maxConcurrentAccounts || "",
    maxConcurrentPairs: workflow?.maxConcurrentPairs || "",
    schedulerTriggerValue: workflow?.schedulerTriggerValue || "",
    reconDataType: workflow?.invokerCode?.toString() || "1400002",
    enabled: workflow && workflow?.enabled ? "true" : "false",
    useAccountSequence:
      workflow && workflow?.useAccountSequence ? "true" : "false",
    usePairSequence: workflow && workflow?.usePairSequence ? "true" : "false",
    keepMatchingData: workflow && workflow?.keepMatchingData ? "true" : "false",
    groupId: workflow?.groupId || "",
  };

  const [recon, setRecon] = useState(initialReconValues);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reconScheduleType, setReconScheduleType] = useState([]);
  const [selectedReconScheduleType, setSelectedReconScheduleType] =
    useState("");
  const [reconCode, setReconCode] = useState([]);
  const [selectedReconCode, setSelectedReconCode] = useState("");
  const selectedAccounts = useRef([]);
  const selectedDebit = useRef([]);
  const selectedCredit = useRef([]);
  const [reconGroups, setReconGroups] = useState([]);
  const [accountsHierarchy, setAccountsHierarchy] = useState([]);
  const [debitAccounts, setDebitAccounts] = useState([]);
  const [creditAccounts, setCreditAccounts] = useState([]);
  const addWorkflowValidationSchema = Yup.object().shape({
    reconName: Yup.string().required("Recon Name is required"),
    enabled: Yup.string().required("Enabled is required"),
    keepMatchingData: Yup.string().required("Keep Matching Data is required"),
    maxConcurrentAccounts: Yup.number()
      .optional()
      .typeError("Only Numbers allowed!")
      .min(1, "Value must be at least 1")
      .max(10, "Value must be at most 10"),
    maxConcurrentPairs: Yup.number()
      .optional()
      .typeError("Only Numbers allowed!")
      .min(1, "Value must be at least 1")
      .max(10, "Value must be at most 10"),
  });

  const checkAccount = (data, array, flag) => {
    let arr = data.map((x) => {
      if (flag) {
        x.checked = true;
      } else if (array.find((y) => y.codeValue == x.codeValue)) {
        x.checked = true;
      } else {
        x.checked = false;
      }
      let obj = {
        id: x.id,
        label: x.label,
        value: x.codeValue,
        codeValue: x.codeValue,
        checked: x.checked,
      };
      if (x.children) {
        obj.children = checkAccount(x.children, array, x.checked);
      }
      return obj;
    });

    arr.forEach((x) => {
      if (x.children) {
        x.checked = true;
        x.children.forEach((y) => {
          if (y.checked === false) {
            x.checked = false;
          }
        });
      }
    });

    return arr;
  };
  // const fetchAccountData = async () => {
  //   try {
  //     const accountTypesResponse = await axiosInstance.get(
  //       "/accounts/account-types?typeIndicator=recon"
  //     );
  //     const accountTypes = accountTypesResponse.data;
  //     const calls = accountTypes.map(async (accountType) => {
  //       const res = await axiosInstance.get(
  //         `/accounts?accountTypeIds=${accountType.accountId}&&limit=9999`
  //       );
  //       return res.data;
  //     });
  //     const accountDataResults = await Promise.allSettled(calls);
  //     let result = accountTypes.map((accountType, i) => {
  //       const accountData = accountDataResults[i].value;
  //       const children = accountData?.accountsList.map((account) => ({
  //         id: account.id,
  //         label: account.accountLabel,
  //         codeValue: account.id,
  //         value: account.id,
  //         checked: false,
  //       }));
  //       return {
  //         id: accountType.accountId,
  //         label: accountType.accountType,
  //         checked: false,
  //         codeValue: accountType.accountId,
  //         value: accountType.accountId,
  //         children: children,
  //       };
  //     });
  //     const response = await axiosInstance.get("/recon-group");
  //     setReconGroups(
  //       response?.data?.reconGroups.map((group) => ({
  //         id: group.id,
  //         label: group.groupName,
  //         codeValue: group.id,
  //         value: group.id,
  //         checked: false,
  //       }))
  //     );
  //     if (workflow) {
  //       const response = await axiosInstance.get(`/recon/${workflow.id}`);
  //       const reconAccountsData =
  //         response?.data?.reconAccounts?.map((x) => ({
  //           codeValue: x.reconAccountId,
  //         })) || [];
  //       selectedAccounts.current = reconAccountsData.map((x) => ({
  //         reconAccountId: x.codeValue,
  //       }));
  //       setAccounts(
  //         checkAccount(
  //           result.filter((x) => x.children),
  //           reconAccountsData
  //         )
  //       );
  //       setRecon({
  //         ...recon,
  //         groupId: response?.data?.groupId,
  //         maxConcurrentPairs:
  //           response?.data?.maxConcurrentPairs !== null
  //             ? response?.data?.maxConcurrentPairs
  //             : "",
  //       });
  //     } else {
  //       setAccounts(result.filter((x) => x.children));
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const fetchGroups = async () => {
    const response = await axiosInstance.get("/recon-group");
    setReconGroups(
      response?.data?.reconGroups.map((group) => ({
        id: group.id,
        label: group.groupName,
        codeValue: group.id,
        value: group.id,
        checked: false,
      }))
    );
  };
  const workflowReconData = async () => {
    const response = await axiosInstance.get(`/recon/${workflow?.id}`);
    if (response.data) {
      const reconAccountsData =
        response?.data?.reconAccounts?.map((x) => ({
          codeValue: x.reconAccountId,
        })) || [];
      selectedAccounts.current = reconAccountsData.map((x) => ({
        reconAccountId: x.codeValue,
      }));
      const creditAccountsData =
        response?.data?.creditAccounts?.map((x) => ({
          codeValue: x.reconAccountId,
        })) || [];
      selectedCredit.current = creditAccountsData.map((x) => ({
        reconAccountId: x.codeValue,
      }));

      const debitAccountsData =
        response?.data?.debitAccounts?.map((x) => ({
          codeValue: x.reconAccountId,
        })) || [];
      selectedDebit.current = debitAccountsData.map((x) => ({
        reconAccountId: x.codeValue,
      }));
      setAccountsHierarchy((prevState) =>
        checkAccount(prevState, reconAccountsData)
      );

      setCreditAccounts((prevState) =>
        checkAccount(prevState, creditAccountsData)
      );
      setDebitAccounts((prevState) =>
        checkAccount(prevState, debitAccountsData)
      );
      setRecon({
        ...recon,
        groupId: response?.data?.groupId,
        maxConcurrentPairs:
          response?.data?.maxConcurrentPairs !== null
            ? response?.data?.maxConcurrentPairs
            : "",
        maxConcurrentAccounts:
          response?.data?.maxConcurrentAccounts !== null
            ? response?.data?.maxConcurrentAccounts
            : "",
      });
    }
  };

  const fetchAccountsHierarchy = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        "/accounts/accounts-hierarchy?indicator=recon"
      );

      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setLoading(false);
      } else {
        const data = transformData(response?.data);
        setAccountsHierarchy(data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchAccountsCreditDebit = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        "/accounts/accounts-hierarchy?indicator=gl"
      );

      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        const data = transformData(response?.data);
        setCreditAccounts(data);
        setDebitAccounts(data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccountsHierarchy();
    fetchAccountsCreditDebit();
    fetchGroups();
    if (workflow && accountsHierarchy && creditAccounts && debitAccounts) {
      workflowReconData();
    }
  }, [workflow]);

  const addWorkflow = async (values) => {
    setLoading(true);
    try {
      const data = {
        ...values,
        enabled: values.enabled === "true",
        invokerCode: +values.reconDataType,
        reconAccounts: selectedAccounts.current,
        reconDataType: +selectedReconScheduleType,
        reconCode: +selectedReconCode,
        debitAccounts: selectedDebit.current,
        creditAccounts: selectedCredit.current,
      };
      const res = workflow
        ? await axiosInstance.put(`/recon`, {
            data: { ...data, id: workflow.id },
          })
        : await axiosInstance.post("/recon", { data });

      if (res.message.status !== "200") {
        setLoading(false);
        const errorMessage = workflow
          ? res.message.description
          : "Error while adding record!";
        Toast(errorMessage, "error");
      } else {
        setLoading(false);
        setShowModal();
        getWorkflowsData();
        const successMessage = workflow
          ? res.message.description
          : "Record added successfully!";
        Toast(successMessage, "success");
      }
    } catch (error) {
      Toast(error.response.data.message.description, "error");
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const getReconCode = async () => {
      try {
        const response = await axiosInstance.get(
          "/common/code?codeTypeId=1500"
        );
        setReconCode(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    const getReconScheduleType = async () => {
      try {
        const response = await axiosInstance.get(
          "/common/code?codeTypeId=1600"
        );
        setReconScheduleType(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getReconCode();
    getReconScheduleType();
  }, []);

  useEffect(() => {
    setSelectedReconScheduleType(workflow?.reconDataType?.toString() || "");
    setSelectedReconCode(workflow?.reconCode?.toString() || "");
  }, [workflow]);

  function formatData(data) {
    return data?.map((item) => ({
      ...item,
      children: formatData(item?.reconAccounts) || [],
    }));
  }

  function transformData(data) {
    return Array.isArray(data) && data.length
      ? data.map(
          ({ reconAccounts, accountType: label, accountTypeId: id }) => ({
            children: reconAccounts.map(({ accountLabel, id }) => ({
              label: accountLabel,
              id,
              value: id,
              codeValue: id,
              checked: false,
            })),
            label,
            id,
            value: id,
            codeValue: id,
            checked: false,
          })
        )
      : [];
  }
  // console.log("formatted data...", formattedData);

  const setSelected = (data) => {
    selectedAccounts.current = data || [];
  };

  const setCreditSelected = (data) => {
    selectedCredit.current = data || [];
  };

  const setDebitSelected = (data) => {
    selectedDebit.current = data || [];
  };

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div className="col-12" style={{ opacity: !loading ? "1" : "0.07" }}>
        <Formik
          initialValues={recon}
          enableReinitialize={true}
          validationSchema={addWorkflowValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            if (
              (recon.maxConcurrentAccounts !== "" ||
                recon.maxConcurrentAccounts !== 0 ||
                selectedReconScheduleType !== "") &&
              values.groupId !== "" &&
              selectedAccounts.current.length > 0
            ) {
              setSubmitting(true);
              addWorkflow(values);
            }
          }}
        >
          {({ values, errors, touched, handleSubmit, setValues }) => (
            <Form id="add-recon" className="form" onSubmit={handleSubmit}>
              <div className="d-flex mt-3 flex-row">
                <div className="col-6">
                  <div className="col-12">
                    <div className="workflowModalTitle">Recon Name</div>
                    <Field
                      id="reconName"
                      className={`form-control rs-input ${
                        touched.reconName && errors.reconName
                          ? "is-invalid"
                          : ""
                      }`}
                      type="text"
                      placeholder="Enter Name"
                      name="reconName"
                    />
                    <ErrorMessage
                      component="div"
                      name="reconName"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <div className="workflowModalTitle">Description</div>
                    <Field
                      id="description"
                      className="form-control rs-input"
                      type="text"
                      placeholder="Enter Recon Description"
                      name="description"
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <div className="workflowModalTitle">
                      Select Recon Accounts
                    </div>
                    {accountsHierarchy && (
                      <RegionDropDown
                        id="reconAccounts"
                        placeholder="Select Recon Accounts"
                        setAccounts={setAccountsHierarchy}
                        checkAccount={checkAccount}
                        data={accountsHierarchy}
                        setSelected={setSelected}
                      />
                    )}
                    {selectedAccounts.current.length < 1 && (
                      <div className="field-error">Select Recon Accounts*</div>
                    )}
                  </div>
                  <div className="col-12 mt-4">
                    <div className="workflowModalTitle">Code</div>
                    <NewFilterDropdown
                      id="reconCode"
                      title={
                        reconCode.find((x) => x.codeValue == selectedReconCode)
                          ?.description || "Select Code"
                      }
                      data={reconCode.map((x) => ({
                        label: x.description,
                        ...x,
                      }))}
                      type="checkbox"
                      filter={[selectedReconCode]}
                      onChange={(value) => setSelectedReconCode(value)}
                    />
                    {selectedReconCode === "" && (
                      <div className="field-error">Code is required *</div>
                    )}
                  </div>
                  <div className="col-12 mt-3">
                    <div className="workflowModalTitle">Record Type</div>
                    <NewFilterDropdown
                      id="reconScheduleType"
                      title={
                        reconScheduleType.find(
                          (x) => x.codeValue == selectedReconScheduleType
                        )?.description || "Select Type"
                      }
                      data={reconScheduleType.map((x) => ({
                        label: x.description,
                        ...x,
                      }))}
                      type="checkbox"
                      filter={[selectedReconScheduleType]}
                      onChange={(value) => setSelectedReconScheduleType(value)}
                    />
                    {selectedReconScheduleType === "" && (
                      <div className="field-error">
                        Record Type is required*
                      </div>
                    )}
                  </div>
                  <div className="col-12 mt-3">
                    <div className="workflowModalTitle">Output Table Name</div>
                    <Field
                      id="outputTableName"
                      className="form-control rs-input"
                      type="text"
                      placeholder="Enter Output Table Name"
                      name="outputTableName"
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <div className="workflowModalTitle">
                      Select Debit Accounts
                    </div>
                    {debitAccounts && (
                      <RegionDropDown
                        id="debitAccounts"
                        placeholder="Select Debit Accounts"
                        setAccounts={setDebitAccounts}
                        checkAccount={checkAccount}
                        data={debitAccounts}
                        setSelected={setDebitSelected}
                      />
                    )}
                  </div>
                  <div className="col-12 mt-3">
                    <div className="workflowModalTitle">
                      Select Credit Accounts
                    </div>
                    {creditAccounts && (
                      <RegionDropDown
                        id="creditAccounts"
                        placeholder="Select Credit Accounts"
                        setAccounts={setCreditAccounts}
                        checkAccount={checkAccount}
                        data={creditAccounts}
                        setSelected={setCreditSelected}
                      />
                    )}
                  </div>
                </div>

                <div className="col-6">
                  <div className="col-12">
                    <div className="workflowModalTitle">UI Group</div>
                    <NewFilterDropdown
                      id="uiGroupId"
                      title={
                        reconGroups.find((x) => x.codeValue == values.groupId)
                          ?.label || "Select UI Group"
                      }
                      data={reconGroups.map((x) => ({
                        label: x.label,
                        ...x,
                      }))}
                      type="checkbox"
                      filter={[values?.groupId]}
                      onChange={(value) =>
                        setValues({ ...values, groupId: value })
                      }
                    />
                    {values?.groupId === "" && (
                      <div className="field-error">UI Group is required *</div>
                    )}
                  </div>
                  <div className="col-12 mt-3">
                    <div className="row">
                      <div className="col-6">
                        <div className="workflowModalTitle">
                          Concurrent Accounts
                        </div>
                        <Field
                          id="maxConcurrentAccounts"
                          className={`form-control rs-input ${
                            touched.maxConcurrentAccounts &&
                            errors.maxConcurrentAccounts
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          placeholder=" Enter No."
                          name="maxConcurrentAccounts"
                        />
                        <ErrorMessage
                          component="div"
                          name="maxConcurrentAccounts"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-6">
                        <div className="workflowModalTitle">
                          Concurrent Pairs
                        </div>
                        <Field
                          id="maxConcurrentPairs"
                          className={`form-control rs-input ${
                            touched.maxConcurrentPairs &&
                            errors.maxConcurrentPairs
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          placeholder="Enter No."
                          name="maxConcurrentPairs"
                        />

                        <ErrorMessage
                          component="div"
                          name="maxConcurrentPairs"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <div className="workflowModalTitle">
                      Sequential Accounts
                    </div>
                    <div className="d-flex flex-row">
                      <div className="form-check">
                        <Field
                          id="useAccountSequenceTrue"
                          className="form-check-input"
                          type="radio"
                          name="useAccountSequence"
                          value="true"
                        />
                        <label className="form-check-label">True</label>
                      </div>
                      <div className="form-check ml-4">
                        <Field
                          id="useAccountSequenceFalse"
                          className="form-check-input"
                          type="radio"
                          name="useAccountSequence"
                          value="false"
                        />
                        <label className="form-check-label">False</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <div className="workflowModalTitle">Sequential Pairs </div>
                    <div className="d-flex flex-row">
                      <div className="form-check">
                        <Field
                          id="usePairSequenceTrue"
                          className="form-check-input"
                          type="radio"
                          name="usePairSequence"
                          value="true"
                        />
                        <label className="form-check-label">True</label>
                      </div>
                      <div className="form-check ml-4">
                        <Field
                          id="usePairSequenceFalse"
                          className="form-check-input"
                          type="radio"
                          name="usePairSequence"
                          value="false"
                        />
                        <label className="form-check-label">False</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <div className="workflowModalTitle">Keep Matching Data</div>
                    <div className="d-flex flex-row">
                      <div className="form-check">
                        <Field
                          id="keepMatchingDataTrue"
                          className="form-check-input"
                          type="radio"
                          name="keepMatchingData"
                          value="true"
                        />
                        <label className="form-check-label">True</label>
                      </div>
                      <div className="form-check ml-4">
                        <Field
                          id="keepMatchingDataFalse"
                          className="form-check-input"
                          type="radio"
                          name="keepMatchingData"
                          value="false"
                        />
                        <label className="form-check-label">False</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <div className="workflowModalTitle">Enabled</div>
                    <div className="d-flex flex-row">
                      <div className="form-check">
                        <Field
                          id="enabledTrue"
                          className="form-check-input"
                          type="radio"
                          name="enabled"
                          value="true"
                        />
                        <label className="form-check-label">True</label>
                      </div>
                      <div className="form-check ml-4">
                        <Field
                          id="enabledFalse"
                          className="form-check-input"
                          type="radio"
                          name="enabled"
                          value="false"
                        />
                        <label className="form-check-label">False</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-1 justify-content-end mt-5">
                <ButtonBasic id="cancel" title="Cancel" onClick={() => setShowModal()} />
                <button
                  id="save"
                  type="button"
                  name="save"
                  className="btn btn-info btn-md mr-2"
                  onClick={handleSubmit}
                  disabled={
                    selectedReconCode === "" ||
                    selectedAccounts.current.length === 0 ||
                    selectedReconScheduleType === ""
                  }
                >
                  {workflow ? "Update" : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default WorkflowModal;

class RegionDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = { data: this.props.data, ...props };
    this.arraysAreEqual = this.arraysAreEqual.bind(this);
  }

  arraysAreEqual(ary1, ary2) {
    return ary1.join("") === ary2.join("");
  }
  componentWillReceiveProps = (nextProps) => {
    if (!(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data });
    }
  };

  shouldComponentUpdate = (nextProps) => {
    return !this.arraysAreEqual(nextProps.data, this.state.data);
  };
  onChange = (currentNode, selectedNodes) => {
    const extractedNodes = selectedNodes
      ?.map((x) => {
        return x._children?.length > 0
          ? x._children.map((x) => ({ reconAccountId: x }))
          : { reconAccountId: x.id };
      })
      .flatMap((x) => x);
    this.state.setSelected(extractedNodes);
    this.state.setAccounts(
      this.state.checkAccount(this.state.data, selectedNodes)
    );
  };
  render() {
    const { data, ...rest } = this.props;

    return (
      <FilterDropdownStyle className={`dropdown `}>
        <button
          className={`d-flex align-items-center justify-content-between btn tsg-dropdown w-100`}
          type="button"
          id={"dropdownMenuButton"+this.props.id}
          data-toggle={"dropdown"}
          style={{ height: "40px" }}
        >
          <span>{this.props.placeholder ?? "Select Recon Accounts"}</span>
          <img className="dropdown-icon" src={chevronDown} alt={"chev"} />
        </button>

        <div
          className={`dropdown-menu fancy-scroll`}
          style={{ whiteSpace: "pre" }}
        >
          <DropdownTreeSelect
            id={"dropdownMenuButton"+this.props.id}
            onChange={this.onChange}
            className="bootstrap-demo"
            expanded="true"
            showDropdown="always"
            showPartiallySelected
            keepOpenOnSelect={true}
            clearSearchOnChange={true}
            inlineSearchInput={true}
            data={this?.props?.data || []}
            isDefaultValue={true}
            {...rest}
            radioSelect={true}
            searchPredicate={(node, searchText) => {
              return (
                node?.label && node?.label?.toLowerCase().includes(searchText)
              );
            }}
          />
        </div>
      </FilterDropdownStyle>
    );
  }
}
