import React, { useCallback, useEffect, useState } from "react";
import { DataCollection } from "./data-collection";
import { ReconStatus } from "./recon-status";
import { ReconDataSources } from "./recon-data-sources";
import { useDateTime } from "../../components/Helper/DateTime";
import axiosInstance from "../../utils/axiosInstance";
import {
  mapFilters,
  handleFilterChange,
  handleNewFilter,
  filtersParams,
} from "../../utils/filtersHelper";
import Filter from "../../components/FilterDropdown/GenericFilter";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import FilterIcon from "../../assets/images/filter.png";

const Operations = (props) => {
  const history = props.history;
  const [count, setCount] = useState(0);
  const [reconId, setReconId] = useState(null);
  const [dataCollectionId, setDataCollectionId] = useState(null);

  const [loading, setLoading] = useState(true);
  const { setRefresh } = useDateTime();
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [filter, setFilter] = useState({});
  const [showFilters, setShowFilters] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [params, setParams] = useState("");
  const [pageFilter, setPageFilter] = useState(false);
  const getFilters = useCallback(() => {
    setLoading(true);
    axiosInstance
      .get(`hierarchical/filters?screen_id=operations_screen`)
      .then((res) => {
        if (res.message.status !== "200") {
        } else {
          const filteredFilters = res.data.filters.filter(
            (x) => x.showOnScreen
          );
          const showFiltersData = res.data.filters.filter(
            (x) => !x.showOnScreen
          );
          setShowFilters(showFiltersData);
          const calendarDateParam = props.location.state?.href?.match(
            /[?&]calendar_date=([^&]+)/
          )[1];
          if (calendarDateParam) {
            var alterFilter = filteredFilters;
            alterFilter.find(
              (x) => x.filterName === "calendar_date"
            ).filterDefaultValue = calendarDateParam;
            setFilterData(alterFilter);
          } else {
            setFilterData(filteredFilters);
          }
          const obj = mapFilters(filteredFilters);
          setFilter(obj);
          setParams(filtersParams(obj));
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (
      props.location.state?.href.includes("/operations/recon-status") &&
      count !== 1
    ) {
      const searchParams = props.location.state?.href;
      const startIndex = searchParams?.indexOf("?") + 1; // Adding 1 to exclude the "?"
      const endIndex = searchParams?.indexOf("&");
      setReconId(searchParams?.substring(startIndex, endIndex));
      setCount(1);
      getFilters();
      history.replace(props.location.pathname, null);
    } else if (
      props.location.state?.href.includes("/operations/data-collection") &&
      count !== 1
    ) {
      const searchParams = props.location.state?.href;
      const startIndex = searchParams?.indexOf("?") + 1; // Adding 1 to exclude the "?"
      const endIndex = searchParams?.indexOf("&");

      setDataCollectionId(searchParams?.substring(startIndex, endIndex));
      setCount(1);
      getFilters();
      history.replace(props.location.pathname, null);
    } else {
      getFilters();
    }
  }, []);
  useEffect(() => {
    setRefresh(() => () => {
      getFilters();
      setRefreshFlag((prevState) => !prevState);
    });

    return () => {
      setRefresh(() => () => {});
    };
  }, []);
  return (
    <>
      <div className="row align-items-center d-lg-flex">
        {filterData &&
          filterData.map(
            ({
              filterName,
              filterType,
              filterLabel,
              showOnScreen,
              filterId,
            }) => (
              <div className="filter-item mt-2 ml-3 d-flex" key={filterId}>
                <Filter
                  title={filterLabel}
                  filterId={filterId}
                  type={filterType}
                  data={filter[filterName]?.data}
                  filter={filter[filterName]?.filter}
                  removable={!showOnScreen}
                  onChange={(e) =>
                    handleFilterChange(e, filterName, filter, setFilter)
                  }
                  onRemove={(e) =>
                    handleNewFilter(
                      e,
                      filterData,
                      showFilters,
                      filter,
                      setFilter,
                      setFilterData
                    )
                  }
                  isSearchable
                />
              </div>
            )
          )}
        <div className="filter-item mt-2 ml-3">
          <Filter
            title={""}
            type={1006}
            data={showFilters}
            filter={filterData?.map((x) => x.filterId)}
            field="filterId"
            onChange={(e) =>
              handleNewFilter(
                e,
                filterData,
                showFilters,
                filter,
                setFilter,
                setFilterData
              )
            }
          />
        </div>

        <div className="col d-flex justify-content-start mt-2">
          <ButtonBasic
            wrapperClass={"d-flex justify-content-end mr-0"}
            icon={FilterIcon}
            title="Filter"
            onClick={() => {
              setParams(filtersParams(filter));
              setPageFilter(true);
            }}
          />
        </div>
      </div>
      {/* {!loading && ( */}
      <>
        <DataCollection
          refresh={refreshFlag}
          filter={params}
          pageFilter={pageFilter}
          setPageFilter={setPageFilter}
          dataCollectionId={dataCollectionId}
          setDataCollectionId={setDataCollectionId}
        />
        <ReconStatus
          refresh={refreshFlag}
          filter={params}
          pageFilter={pageFilter}
          setPageFilter={setPageFilter}
          reconId={reconId}
          setReconId={setReconId}
        />
        <ReconDataSources
          refresh={refreshFlag}
          filter={params}
          pageFilter={pageFilter}
          setPageFilter={setPageFilter}
        />
      </>
      {/* )} */}
    </>
  );
};
export default Operations;
