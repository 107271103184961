import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import axiosInstance from "../../../utils/axiosInstance";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import queryString from "query-string";
import GoogleMap from "../../../containers/GoogleMap/NewMap";

const BranchManagement = (props) => {
  const [regions, setRegions] = useState([]);
  const [institutions, setInstitution] = useState([]);
  const [branch, setBranch] = useState(null);
  const [loader, setLoader] = useState(true);
  const [pos, setPos] = useState({ lat: "", lng: "" });
  const { id } = queryString.parse(props.location.search);
  const [glAccList, setGlAccList] = useState([]);
  const [breadCrumbState] = useState([
    {
      id: 0,
      title: "Branch Management",
      isActive: true,
      routeTo: "/branch-management",
    },
    {
      id: 1,
      title: "View Branch",
      isActive: false,
      routeTo: `/view-branch-management?id=${id}`,
    },
  ]);

  const mapRegions = (regions) => {
    const arr = [];
    // eslint-disable-next-line
    regions.map((region) => {
      arr.push({
        id: region.codeValue,
        label: region.label,
      });
      if (region.subClass) {
        arr.push(...mapRegions(region.subClass));
      }
    });
    return arr;
  };
  const getRegions = () => {
    axiosInstance
      .get("/hierarchical/filters?filter_name=region")
      .then((res) => {
        setRegions(mapRegions(res.data?.filters[0].filterData));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getInstitutions = () => {
    axiosInstance
      .get("/institutions")
      .then((res) => {
        setInstitution(
          res.data.institutions.map((institution) => ({
            id: institution.institutionId,
            label: institution.institutionName,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBranch = (id) => {
    axiosInstance
      .get(`/branches/${id}`)
      .then((res) => {
        setBranch(res.data.branch);
        setPos({
          lat: res.data.branch.latitude,
          lng: res.data.branch.longitude,
        });
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const getGlAccounts = async () => {
    axiosInstance
      .get(`/accounts/account-list?type_indicator=gl`)
      .then((res) => {
        setGlAccList(
          res?.data?.accounts?.map((i) => {
            return {
              label: i?.name,
              value: i?.id,
            };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!id) props.history.push("/branch-management");
    else getBranch(id);
    getRegions();
    getInstitutions();
    getGlAccounts();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loader && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div
        className="container-fluid"
        style={{
          borderRadius: "10px",
          opacity: !loader ? "1" : "0.07",
        }}
      >
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <Breadcrumbs
              data={breadCrumbState}
              history={props.history}
              onClick={(e) => this.breadcrumbOnClick(e)}
            />
          </div>
        </div>
        <div className="row mt-5">
          {/* Main Left side column started */}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="d-flex flex-column">
                  <label className="form-label">Branch Name</label>
                  <input
                     id="name"
                    className="rs-input"
                    type="text"
                    placeholder="Enter Branch Name"
                    name="name"
                    value={branch?.name}
                    disabled
                  />
                </div>
              </div>
              <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex flex-column">
                  <label className="form-label">Branch Code</label>
                  <input
                    id="branchCode"
                    className="rs-input"
                    type="text"
                    placeholder="Enter Branch Code"
                    name="branchCode"
                    value={branch?.branchCode}
                    disabled
                  />
                </div>
              </div>

              <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex flex-column">
                  <label className="form-label">GL Account</label>
                  <input
                    id="glAccount"
                    className="rs-input"
                    type="text"
                    placeholder="Enter GL Account"
                    name="glAccount"
                    value={
                      glAccList.find(
                        (i) =>
                          i?.value?.toString() === branch?.glAccount?.toString()
                      )?.label
                    }
                    disabled
                  />
                </div>
              </div>

              <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex flex-column">
                  <label className="form-label">Phone</label>
                  <input
                    id="phone"
                    className="rs-input"
                    type="text"
                    placeholder="Enter Phone Number"
                    name="phone"
                    value={branch?.phone}
                    disabled
                  />
                </div>
              </div>

              <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex flex-column">
                  <label className="form-label">Manager</label>
                  <input
                    id="manager"
                    className="rs-input"
                    type="text"
                    placeholder="Enter Manager"
                    name="manager"
                    value={branch?.manager}
                    disabled
                  />
                </div>
              </div>

              <div className="mt-3 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="d-flex flex-column">
                  <label className="form-label">Region</label>
                  <input
                    id="region"
                    className="rs-input"
                    type="text"
                    name="regionId"
                    value={
                      // eslint-disable-next-line
                      regions?.find((region) => region.id == branch?.regionId)
                        ?.label
                    }
                    disabled
                  />
                </div>
              </div>

              <div className="mt-3 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="d-flex flex-column">
                  <label className="form-label">Institution</label>
                  <input
                   id="institution"
                    className="rs-input"
                    type="text"
                    name="institutionId"
                    value={
                      institutions?.find(
                        // eslint-disable-next-line
                        (institution) => institution.id == branch?.institutionId
                      )?.label
                    }
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Main Left side column ended */}

          {/* Main Right side column started */}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex flex-column">
                  <label className="form-label">Branch Address 1</label>
                  <input
                    id="address1"
                    className="rs-input"
                    type="text"
                    placeholder="Enter Branch Address 1"
                    name="address1"
                    value={branch?.address1}
                    disabled
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex flex-column">
                  <label className="form-label">Branch Address 2</label>
                  <input
                    id="address2"
                    className="rs-input"
                    type="text"
                    placeholder="Enter Branch Address 2"
                    name="address2"
                    value={branch?.address2}
                    disabled
                  />
                </div>
              </div>
              <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex flex-column">
                  <label className="form-label">Branch City</label>
                  <input
                  id="city"
                    className="rs-input"
                    type="text"
                    placeholder="Enter Branch City"
                    name="city"
                    value={branch?.city}
                    disabled
                  />
                </div>
              </div>
              <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex flex-column">
                  <label className="form-label">Branch Province</label>
                  <input
                     id="province"
                    className="rs-input"
                    type="text"
                    placeholder="Enter Branch Province"
                    name="province"
                    value={branch?.province}
                    disabled
                  />
                </div>
              </div>
              <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex flex-column">
                  <label className="form-label">Branch Country</label>
                  <input
                  id="country"
                    className="rs-input"
                    type="text"
                    placeholder="Enter Branch Country"
                    name="country"
                    value={branch?.country}
                    disabled
                  />
                </div>
              </div>
              <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex flex-column">
                  <label className="form-label">PostCode</label>
                  <input
                   id="postCode"
                    className="rs-input"
                    type="text"
                    placeholder="Enter PostCode"
                    name="postCode"
                    value={branch?.postCode}
                    disabled
                  />
                </div>
              </div>
              {/* sub row ended*/}
              <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex flex-column">
                  <label className="form-label">Latitude</label>
                  <input
                     id="latitude"
                    disabled
                    className="rs-input"
                    type="text"
                    placeholder="Enter Latitude"
                    name="latitude"
                    value={pos?.lat}
                  />
                </div>
              </div>

              <div className="mt-3 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex flex-column">
                  <label className="form-label">Longitude</label>
                  <input
                      id="longitude"
                    disabled
                    className="rs-input"
                    type="text"
                    placeholder="Enter Longitude"
                    name="longitude"
                    value={pos.lng}
                  />
                </div>
              </div>
              <div
                className="row mt-3"
                style={{
                  width: "100%",
                }}
              >
                <GoogleMap
                  className="ml-4"
                  mapStyle={{ width: "100%", height: "50vh" }}
                  onClick={(lat, lng) => {
                    // setPos({ lat, lng });
                  }}
                  pos={pos}
                  center={{ lat: pos.lat, lng: pos.lng }}
                />
              </div>
            </div>
          </div>
          {/* Main Right side column ended */}
        </div>
      </div>
    </>
  );
};

export default BranchManagement;
