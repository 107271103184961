import React from "react";

import incDown from "../../assets/images/inc-down.png";
import incUp from "../../assets/images/inc-up.png";
import "./SingleInfoCard.css";
import SingleInfoCardStyle from "./SingleInfoCardStyle";

class SingleInfoCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animatedValue: 0,
    };

    this.targetValue = this.props.value;
    this.animationInterval = null;
  }

  componentDidMount() {
    this.startAnimation();
  }

  componentWillUnmount() {
    clearInterval(this.animationInterval);
  }

  startAnimation() {
    const animationDuration = 1500; // Duration in milliseconds
    const animationFrames = 60; // Number of animation frames
    const interval = animationDuration / animationFrames;

    const valueDifference = Math.abs(
      this.targetValue - this.state.animatedValue
    );
    const frameIncrement = Math.ceil(valueDifference / animationFrames);

    this.animationInterval = setInterval(() => {
      if (this.state.animatedValue < this.targetValue) {
        this.setState((prevState) => ({
          animatedValue: Math.min(
            prevState.animatedValue + frameIncrement,
            this.targetValue
          ),
        }));
      } else {
        clearInterval(this.animationInterval);
      }
    }, interval);
  }

  render() {
    const {
      id,
      title,
      per,
      fullWidth = true,
      perDown,
      customBody,
      children,
    } = this.props;
    return (
      <SingleInfoCardStyle
        id={id?.replace(/\s+/g, "-") ?? ""}
        className={`single-info-card-container ${fullWidth ? "w-100" : ""}`}
      >
        {!children ? (
          <div>
            <div
              id={id ? `${id?.replace(/\s+/g, "-") ?? ""}-title` : ""}
              className="single-info-card-title"
            >
              {title}
            </div>
            {!customBody ? (
              <div className="d-flex align-items-baseline">
                <div
                  id={id ? `${id?.replace(/\s+/g, "-") ?? ""}-value` : ""}
                  className="single-info-card-percentage"
                >
                  {this.state.animatedValue}
                </div>
                {per && (
                  <div className="single-info-card-turnout">
                    <img src={perDown ? incDown : incUp} alt="inc" />
                    <span
                      id={
                        id ? `${id?.replace(/\s+/g, "-") ?? ""}-percentage` : ""
                      }
                      className={perDown ? "color-danger" : "color-success"}
                    >
                      {per}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              customBody
            )}
          </div>
        ) : (
          children
        )}
      </SingleInfoCardStyle>
    );
  }
}

export default SingleInfoCard;
