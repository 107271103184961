import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
// import Pagination from "../../components/pagination/pagination";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import CurrencyFormat from "react-currency-format";
import { useDateTime } from "../../components/Helper/DateTime";
import { OneLinkIBFTData } from "./data";

const OneLinkIBFT = (props) => {
  const { currency, setRefresh } = useDateTime();
  const [data] = useState(OneLinkIBFTData);
  const [loading, setLoading] = useState(true);
  // const hrefFromNotification = props.location.state;

  const reconStatus = (cell) => (
    <BadgeRounded
      color={cell === "BALANCED" ? "success" : "danger"}
      title={cell}
    />
  );
  const formatAmount = (cell) => (
    <>
      <div className="d-flex justify-content-between">
        <span className="item px-3">{currency}</span>
        <span className="item">
          <CurrencyFormat
            value={cell ?? 0}
            displayType={"text"}
            thousandSeparator={true}
          />
        </span>
      </div>
    </>
  );
  const formateNumber = (cell) => (
    <CurrencyFormat
      value={cell ?? 0}
      displayType={"text"}
      thousandSeparator={true}
    />
  );

  const columns = [
    { hidden: true, dataField: "id", text: "ID" },
    {
      dataField: "sender",
      text: "Sender",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "receiver",
      text: "Receiver",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "transaction_count",
      text: "Transaction Count",
      formatter: formateNumber,
      sort: true,
    },
    {
      dataField: "sender_transaction_amount",
      text: "Sender Transaction Amount",
      formatter: formatAmount,
      sort: true,
    },
    {
      dataField: "sender_fee_payable",
      text: "Sender Fee Payable",
      formatter: formatAmount,
      sort: true,
    },
    {
      dataField: "receiver_fee",
      text: "Receiver Fee",
      formatter: formatAmount,
      sort: true,
    },
    {
      dataField: "network_fee",
      text: "Network Fee",
      formatter: formatAmount,
      sort: true,
    },
    {
      dataField: "sender_net",
      text: "Sender Net",
      formatter: formatAmount,
      sort: true,
    },
    {
      dataField: "receiver_net",
      text: "Receiver Net",
      formatter: formatAmount,
      sort: true,
    },
    {
      dataField: "count",
      text: "Count",
      formatter: (cell) => <div>{formateNumber(cell)}</div>,
      style: {
        backgroundColor: "rgb(147 201 219)",
        color: "#fff",
      },
      // headerStyle: {
      //   backgroundColor: "#1196c2",
      //   color: "#fff",
      // },
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell) => <div>{formatAmount(cell)}</div>,
      style: {
        backgroundColor: "rgb(147 201 219)",
        color: "#fff",
      },
      // headerStyle: {
      //   backgroundColor: "#1196c2",
      //   color: "#fff",
      // },
      sort: true,
      headerAlign: "right",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: reconStatus,
      sort: true,
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    setRefresh(() => () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div style={{ opacity: !loading ? "1" : "0.07" }}>
        <div className="row page-header d-flex justify-content-between">
          <div className="col-sm-12 col-lg-6 page-header d-flex justify-content-between m-0">
            <div className="title">1LINK IBFT</div>
          </div>
        </div>
        <BootstrapTable
          columns={columns}
          bootstrap4={true}
          keyField="id"
          bordered={false}
          classes="rs-table table-layout-auto table-custom-style table-custom-style"
          wrapperClasses="overflow-y-auto fancy-scroll"
          data={data}
          remote={{ sort: true }}
          noDataIndication={"No data found!"}
        />
        {/* <div className="col-12 mb-3">
          <Pagination
            setPage={setPage}
            page={page}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={totalPages}
            showCSV
            data={data || []}
            CSVHeaders={headers}
          />
        </div> */}
      </div>
    </>
  );
};
export default OneLinkIBFT;
