import React, { useState, useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import queryString from "query-string";
import { FormCheck, Tab, Tabs } from "react-bootstrap";
import axiosInstance from "../../utils/axiosInstance";
import Pagination from "../../components/pagination/pagination";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import FilterIcon from "../../assets/images/filter.png";
import Filter from "../../components/FilterDropdown/GenericFilter";
import { useDateTime } from "../../components/Helper/DateTime";
import { NostroAccountsMenu } from "./nostro-accounts-menu";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import moment from "moment";

import {
  mapFilters,
  handleFilterChange,
  filtersParams,
  handleNewFilter,
} from "../../utils/filtersHelper";
import NostroModal from "./NostroModal";
import Toast from "../../components/Toast/Toast";
import NostroViewModal from "./NostroViewModal";

const NostroAccounts = (props) => {
  const { currency, setRefresh, date } = useDateTime();
  const [summaryData, setSummaryData] = useState([]);
  const [nostroData, setNostroData] = useState([]);
  const [glData, setGLData] = useState([]);
  const [reconciledData, setReconciledData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [filter, setFilter] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [showFilters, setShowFilters] = useState([]);
  const [tabskey, setTabsKey] = useState("nostro");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const params = queryString.parse(props.location.search);
  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const fetchSummary = useCallback(
    (filter) => {
      if (
        selectedAccount?.reconAccountId !== undefined &&
        selectedAccount?.reconAccountId !== null
      ) {
        setLoading(true);
        let allFilters = filtersParams(filter, null, true);
        let url = `/accounts/summary?${allFilters}&accountIds=${selectedAccount?.reconAccountId},${selectedAccount?.glAccountId}`;
        axiosInstance
          .get(url)
          .then((res) => {
            if (res.message.status !== "200") {
              setSummaryData([]);
              setLoading(false);
            } else {
              setSummaryData(res?.data || []);
              setLoading(false);
            }
          })
          .catch((err) => {
            setSummaryData([]);
            setLoading(false);
          });
      }
    },
    // eslint-disable-next-line
    [page, pageSize, sort, selectedAccount]
  );

  const fetchNostroData = useCallback(
    (filter) => {
      if (
        selectedAccount?.reconAccountId !== undefined &&
        selectedAccount?.reconAccountId !== null
      ) {
        setLoading(true);
        let allFilters = filtersParams(filter);
        const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
        let url = `/nostro/statement/${selectedAccount?.reconAccountId}?page=${
          page - 1
        }&size=${pageSize}${allFilters}${
          sort.sort_by && sort.sort_order ? order : ""
        }`;

        axiosInstance
          .get(url)
          .then((res) => {
            if (res.message.status !== "200") {
              setNostroData([]);
              setTotalElements(0);
              setTotalPages(0);
              setLoading(false);
            } else {
              setNostroData(res?.data?.nostroStatements || []);
              setPage(res?.page?.number + 1 || 1);
              setTotalPages(res?.page?.totalPages || 0);
              setTotalElements(res?.page?.totalElements || 0);
              setLoading(false);
            }
          })
          .catch((err) => {
            setNostroData([]);
            setTotalElements(0);
            setTotalPages(0);
            setLoading(false);
          });
      }
    },
    // eslint-disable-next-line
    [page, pageSize, sort, selectedAccount]
  );

  const fetchGLData = useCallback(
    (filter) => {
      if (
        selectedAccount?.reconAccountId !== undefined &&
        selectedAccount?.reconAccountId !== null
      ) {
        setLoading(true);
        let allFilters = filtersParams(filter);
        const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
        let url = `/accounts/ledger/${selectedAccount?.glAccountId}?page=${
          page - 1
        }&limit=${pageSize}${allFilters}${
          sort.sort_by && sort.sort_order ? order : ""
        }`;

        axiosInstance
          .get(url)
          .then((res) => {
            if (res.message.status !== "200") {
              setGLData([]);
              setTotalElements(0);
              setTotalPages(0);
              setLoading(false);
            } else {
              setGLData(res?.data?.accountDetail || []);
              setPage(res?.page?.number + 1 || 1);
              setTotalPages(res?.page?.totalPages || 0);
              setTotalElements(res?.page?.totalElements || 0);
              setLoading(false);
            }
          })
          .catch((err) => {
            setGLData([]);
            setTotalElements(0);
            setTotalPages(0);
            setLoading(false);
          });
      }
    },
    // eslint-disable-next-line
    [page, pageSize, sort, selectedAccount]
  );

  const fetchReconcilerData = useCallback(
    (filter) => {
      if (
        selectedAccount?.reconAccountId !== undefined &&
        selectedAccount?.reconAccountId !== null
      ) {
        setLoading(true);
        let allFilters = filtersParams(filter);
        const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
        let url = `/nostro/reconciled?recon_account_id=${
          selectedAccount?.reconAccountId
        }&page=${page - 1}&size=${pageSize}${allFilters}${
          sort.sort_by && sort.sort_order ? order : ""
        }`;

        axiosInstance
          .get(url)
          .then((res) => {
            console.log("response...", res);
            if (res.message.status !== "200") {
              setReconciledData([]);
              setTotalElements(0);
              setTotalPages(0);
              setLoading(false);
            } else {
              setReconciledData(res?.data || []);
              setPage(res?.page?.number + 1 || 1);
              setTotalPages(res?.page?.totalPages || 0);
              setTotalElements(res?.page?.totalElements || 0);
              setLoading(false);
            }
          })
          .catch((err) => {
            setGLData([]);
            setTotalElements(0);
            setTotalPages(0);
            setLoading(false);
          });
      }
    },
    // eslint-disable-next-line
    [page, pageSize, sort, selectedAccount]
  );

  const getFilters = useCallback(() => {
    setLoading(true);
    axiosInstance
      .get(`hierarchical/filters?screen_id=nostro_dashboard`)
      .then((res) => {
        if (res.message.status !== "200") {
          setLoading(false);
        } else {
          const filteredFilters = res.data.filters.filter(
            (x) => x.showOnScreen
          );
          const showFiltersData = res.data.filters.filter(
            (x) => !x.showOnScreen
          );
          setShowFilters(showFiltersData);
          setFilterData(filteredFilters);
          const obj = mapFilters(filteredFilters);
          fetchSummary(obj);
          fetchNostroData(obj);
          fetchGLData(obj);
          fetchReconcilerData(obj);
          setFilter(obj);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [props.location.state]);

  const formatNumber = (number) => {
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
      });
    }
  };

  const columnsNostro = [
    { hidden: true, dataField: "id", text: "ID" },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "details",
      text: "Details",
      formatter: (cell) => (cell ? cell : "-"),
    },
    {
      dataField: "entryRef",
      text: "Reference",
      formatter: (cell) => (cell ? cell : "-"),
    },
    {
      dataField: "debit",
      text: "Debit",
      formatter: (cell) =>
        cell ? formatNumber(cell) : cell === null ? "-" : cell,
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "credit",
      text: "Credit",
      formatter: (cell) =>
        cell ? formatNumber(cell) : cell === null ? "-" : cell,
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "balance",
      text: "Balance",
      formatter: (cell) =>
        cell ? formatNumber(cell) : cell === null ? "-" : cell,
      align: "right",
      headerAlign: "right",
    },
  ];

  const headers = [
    {
      label: "Date",
      key: "date",
    },
    {
      label: "Details",
      key: "details",
    },
    {
      label: "Reference",
      key: "entryRef",
    },
    {
      label: "Debit",
      key: "debit",
    },
    {
      label: "Credit",
      key: "credit",
    },
    {
      label: "Balance",
      key: "balance",
    },
  ];

  const handleAccountSelection = (account) => {
    setSelectedAccount(account);
  };

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  useEffect(() => {
    if (filterData.length > 0) {
      if (tabskey === "nostro") fetchNostroData(filter);
      if (tabskey === "nostro_gl") fetchGLData(filter);
      if (tabskey === "reconciler-tab") fetchReconcilerData(filter);
    }
    // eslint-disable-next-line
  }, [fetchNostroData, fetchGLData, fetchReconcilerData, tabskey]);

  useEffect(() => {
    fetchSummary(filter);
  }, [fetchSummary]);

  useEffect(() => {
    setRefresh(() => () => {
      fetchSummary(filter);
      fetchNostroData(filter);
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, [fetchSummary, fetchNostroData, filter]);

  // const combinedData = reconciledData?.flatMap((obj) => {
  //   const combinedDsA = obj.dsA.map((item) => ({ ...item, dsType: "dsA" }));
  //   const combinedDsB = obj.dsB.map((item) => ({ ...item, dsType: "dsB" }));
  //   return [...combinedDsA, ...combinedDsB];
  // });

  // Find the maximum length of dsA and dsB arrays
  let maxDsALength = 0;
  let maxDsBLength = 0;
  reconciledData?.nostroReconciler?.forEach((item) => {
    maxDsALength = Math.max(maxDsALength, item.dsA.length);
    maxDsBLength = Math.max(maxDsBLength, item.dsB.length);
  });

  const reconcileStatus = (status) => (
    <BadgeRounded
      color={
        status === "MATCHED"
          ? "success"
          : status === "MISSING"
          ? "warning"
          : status === "SUGGESTED"
          ? "info"
          : "danger"
      }
      title={status}
    />
  );
  useEffect(() => {
    if (tabskey === "nostro") fetchNostroData(filter);
    else if (tabskey === "nostro_gl") fetchGLData(filter);
    else if (tabskey === "reconciler-tab") fetchReconcilerData(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount?.reconAccountId]);

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}

      <div style={{ opacity: !loading ? "1" : "0.07" }}>
        <div className="row">
          <div className={window.innerWidth > 1600 ? "col-2" : "col-3"}>
            <NostroAccountsMenu
              params={params}
              onAccountSelect={handleAccountSelection}
            />
          </div>
          <div className={window.innerWidth > 1600 ? "col-10" : "col-9"}>
            <div className="row align-items-center d-lg-flex">
              {filterData?.map(
                ({
                  filterName,
                  filterType,
                  filterLabel,
                  showOnScreen,
                  filterId,
                }) => (
                  <div
                    className="filter-item mt-2 ml-3 d-flex"
                    // style={{ minWidth: "210px" }}
                    key={filterId}
                  >
                    <Filter
                      title={filterLabel}
                      filterId={filterId}
                      type={filterType}
                      data={filter[filterName]?.data}
                      filter={filter[filterName]?.filter}
                      removable={!showOnScreen}
                      onChange={(e) =>
                        handleFilterChange(e, filterName, filter, setFilter)
                      }
                      onRemove={(e) =>
                        handleNewFilter(
                          e,
                          filterData,
                          showFilters,
                          filter,
                          setFilter,
                          setFilterData
                        )
                      }
                      isSearchable
                    />
                  </div>
                )
              )}
              <div className="col d-flex justify-content-start mt-2">
                <ButtonBasic
                  wrapperClass={"d-flex justify-content-end mr-0"}
                  icon={FilterIcon}
                  title="Filter"
                  onClick={() => {
                    fetchSummary(filter);
                    if (tabskey === "nostro") fetchNostroData(filter);
                    if (tabskey === "nostro_gl") fetchGLData(filter);
                    if (tabskey === "reconciler-tab")
                      fetchReconcilerData(filter);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-1 mt-3">
                {summaryData?.find((item) => item.accountType === "Nostro") && (
                  <h6 className="color-gray pt-5 text-left">Nostro</h6>
                )}
                {summaryData?.find((item) => item.accountType === "GL") && (
                  <h6
                    className={`color-gray text-left ${
                      summaryData?.length < 2 ? "pt-5" : "pt-3"
                    }`}
                  >
                    GL
                  </h6>
                )}
              </div>
              <div className="col-11">
                <div className="table-responsive">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th className="custom-bg-light">Account No.</th>
                        <th className="custom-bg-light">Statement No.</th>
                        <th className="custom-bg-light">Currency</th>
                        <th className="custom-bg-light">
                          Opening Balance Date
                        </th>
                        <th className="custom-bg-light">Opening Balance</th>
                        <th className="custom-bg-light">
                          Closing Balance Date
                        </th>
                        <th className="custom-bg-light">Closing Balance</th>
                        <th className="custom-bg-light">Total Transactions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {summaryData
                        ?.filter(
                          (item) =>
                            item.accountId === selectedAccount?.reconAccountId
                        )
                        .map((item) => (
                          <tr key={item.accountId}>
                            <td className="text-center">
                              {item.accountNumber ?? "-"}
                            </td>
                            <td className="text-center">
                              {item.statementNumber ?? "-"}
                            </td>
                            <td className="text-center">
                              {item.currency ?? "-"}
                            </td>
                            <td className="text-center">
                              {item.openingBalanceDate
                                ? moment(item.openingBalanceDate).format(date)
                                : "-"}
                            </td>
                            <td className="text-center">
                              {item.openingBalance !== null
                                ? `${item.currency} ${
                                    item.openingBalance
                                      ? formatNumber(item.openingBalance)
                                      : "0"
                                  }`
                                : "-"}
                            </td>
                            <td className="text-center">
                              {item.closingBalanceDate
                                ? moment(item.closingBalanceDate).format(date)
                                : "-"}
                            </td>
                            <td className="text-center">
                              {item.closingBalance !== null
                                ? `${item.currency} ${
                                    item.closingBalance
                                      ? formatNumber(item.closingBalance)
                                      : "0"
                                  }`
                                : "-"}
                            </td>
                            <td className="text-center">
                              {item.totalTransactions}
                            </td>
                          </tr>
                        ))}

                      {summaryData &&
                        summaryData
                          ?.filter(
                            (item) =>
                              item.accountId === selectedAccount.glAccountId
                          )
                          ?.map((item) => {
                            return (
                              <tr>
                                <td className="text-center">
                                  {item.accountNumber ?? "-"}
                                </td>
                                <td className="text-center">
                                  {item.statementNumber ?? "-"}
                                </td>
                                <td className="text-center">
                                  {item.currency ?? "-"}
                                </td>
                                <td className="text-center">
                                  {item.openingBalanceDate
                                    ? moment(item.openingBalanceDate).format(
                                        date
                                      )
                                    : "-"}
                                </td>
                                <td className="text-center">
                                  {item.openingBalance !== null
                                    ? `${item.currency} ${
                                        item.openingBalance
                                          ? formatNumber(item.openingBalance)
                                          : "0"
                                      }`
                                    : "-"}
                                </td>
                                <td className="text-center">
                                  {item.closingBalanceDate
                                    ? moment(item.closingBalanceDate).format(
                                        date
                                      )
                                    : "-"}
                                </td>
                                <td className="text-center">
                                  {item.closingBalance !== null
                                    ? `${item.currency} ${
                                        item.closingBalance
                                          ? formatNumber(item.closingBalance)
                                          : "0"
                                      }`
                                    : "-"}
                                </td>
                                <td className="text-center">
                                  {item.totalTransactions}
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <Tabs
                  activeKey={tabskey}
                  onSelect={(key) => setTabsKey(key)}
                  defaultActiveKey="nostro"
                  id="nostro-tab"
                  className="mb-3"
                >
                  <Tab
                    className="mb-sm-2 mb-md-0"
                    eventKey="nostro"
                    title="Nostro"
                    tabClassName="custom-tab"
                  >
                    <BootstrapTable
                      bordered={false}
                      classes="rs-table table-layout-auto mb-0 table-custom-style custom-table-background"
                      wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                      keyField="id"
                      bootstrap4={true}
                      remote={{ pagination: true }}
                      data={nostroData ?? []}
                      columns={columnsNostro ?? []}
                      onTableChange={onSortTable}
                      noDataIndication="No data found!"
                    />
                    <Pagination
                      setPage={setPage}
                      page={page}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      totalPages={totalPages}
                      showCSV
                      totalElements={totalElements}
                      data={nostroData ?? []}
                      CSVHeaders={headers}
                      csvName="Nostro Account Data"
                    />
                  </Tab>

                  <Tab
                    className="mb-sm-2 mb-md-0"
                    eventKey="nostro_gl"
                    title="GL"
                    tabClassName="custom-tab"
                  >
                    <BootstrapTable
                      bordered={false}
                      classes="rs-table table-layout-auto mb-0 table-custom-style custom-table-background"
                      wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                      keyField="id"
                      bootstrap4={true}
                      data={glData?.[0]?.accountLedger ?? []}
                      columns={columnsNostro ?? []}
                      noDataIndication="No data found!"
                      remote={{ pagination: true }}
                      onTableChange={onSortTable}
                    />
                    <Pagination
                      setPage={setPage}
                      page={page}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      totalPages={totalPages}
                      showCSV
                      totalElements={totalElements}
                      data={glData?.[0]?.accountLedger ?? []}
                      CSVHeaders={headers}
                      csvName="GL Account Data"
                    />
                  </Tab>

                  <Tab
                    eventKey="reconciler-tab"
                    title="Reconciler"
                    tabClassName="custom-tab"
                  >
                    <div className="row justify-content-end align-items-end">
                      {selectedRow && !showViewModal && (
                        <>
                          <div className="col-5">
                            <button
                              className="btn btn-info btn-sm"
                              onClick={() => setShowModal(true)}
                              style={{ marginTop: "-90px" }}
                              disabled={
                                selectedRow?.status === "MATCHED" ||
                                selectedRow?.status === "ERROR"
                                  ? true
                                  : false
                              }
                            >
                              Match With Adjustment
                            </button>
                            <button
                              className="btn btn-info ml-3 btn-sm"
                              onClick={() => setShowViewModal(true)}
                              style={{ marginTop: "-90px" }}
                            >
                              View Details
                            </button>
                          </div>
                        </>
                      )}
                      <div className="col-12">
                        <div className="table-responsive fancy-scroll">
                          <table
                            className="table table-dark custom-table-background"
                            style={{ lineHeight: "12px" }}
                          >
                            <thead>
                              <tr>
                                <th colSpan={8}>
                                  <h6 className="text-info text-center">
                                    Nostro
                                  </h6>
                                </th>
                                <th colSpan={5}>
                                  <h6 className="text-danger text-center">
                                    GL
                                  </h6>
                                </th>
                              </tr>
                              <tr>
                                <th className="text-info"></th>
                                <th className="text-info">Matched By</th>
                                <th className="text-info">Status</th>
                                <th className="text-info">Date</th>
                                <th className="text-info">Details</th>
                                <th className="text-info">Reference</th>
                                <th className="text-info">Credit</th>
                                <th className="text-info">Debit</th>
                                <th className="text-danger">Date</th>
                                <th className="text-danger">Details</th>
                                <th className="text-danger">Reference</th>
                                <th className="text-danger">Credit</th>
                                <th className="text-danger">Debit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reconciledData?.nostroReconciler?.map(
                                (item, index) => {
                                  const maxRows = Math.max(
                                    item.dsA.length,
                                    item.dsB.length
                                  );
                                  return Array.from({ length: maxRows }).map(
                                    (_, i) => {
                                      const dsAItem = item.dsA[i] || {};
                                      const dsBItem = item.dsB[i] || {};
                                      const isChecked = selectedRow?.dsA[0]?.id
                                        ? selectedRow?.dsA[0]?.id ===
                                          dsAItem?.id
                                        : selectedRow?.dsB[0]?.id ===
                                          dsBItem?.id;
                                      return (
                                        <tr
                                          key={i}
                                          style={{
                                            backgroundColor:
                                              i % 2 === 0
                                                ? "#f9f9f9"
                                                : "#ffffff",
                                          }}
                                          onClick={(e) => {
                                            if (
                                              e.target.type !== "checkbox" &&
                                              e.target.id !== "checkbox"
                                            ) {
                                              setSelectedRow(item);
                                              setShowViewModal(true);
                                            }
                                          }}
                                        >
                                          {/* Render matchedBy and status only on the first row of dsA */}
                                          {i === 0 && (
                                            <>
                                              <td
                                                id="checkbox"
                                                className="text-left pt-2 m-1"
                                                rowSpan={maxRows}
                                                onClick={(e) => {
                                                  if (
                                                    e.target.id === "checkbox"
                                                  ) {
                                                    if (
                                                      selectedRow &&
                                                      selectedRow.dsA[0].id ===
                                                        item.dsA[0].id
                                                    ) {
                                                      setSelectedRow(null);
                                                    } else {
                                                      setSelectedRow(item);
                                                    }
                                                  }
                                                }}
                                              >
                                                <FormCheck
                                                  id="checkbox"
                                                  type="checkbox"
                                                  onChange={(e) => {
                                                    if (
                                                      selectedRow &&
                                                      selectedRow.dsA[0].id ===
                                                        item.dsA[0].id
                                                    ) {
                                                      setSelectedRow(null);
                                                    } else {
                                                      setSelectedRow(item);
                                                    }
                                                  }}
                                                  checked={
                                                    !showViewModal && isChecked
                                                  }
                                                />
                                              </td>
                                              <td
                                                className="text-center"
                                                rowSpan={maxRows}
                                                style={{
                                                  paddingTop: "0.8rem",
                                                }}
                                              >
                                                {item.matchedBy ?? "-"}
                                              </td>
                                              <td
                                                className="text-left pt-2"
                                                rowSpan={maxRows}
                                              >
                                                {/* {item.status ?? "-"} */}
                                                {reconcileStatus(item?.status)}
                                              </td>
                                            </>
                                          )}

                                          <td className="text-left">
                                            {dsAItem.date ?? ""}
                                          </td>
                                          <td className="text-left">
                                            {dsAItem.details ?? ""}
                                          </td>
                                          <td className="text-left">
                                            {dsAItem.entryRef ?? ""}
                                          </td>
                                          <td className="text-right">
                                            {dsAItem.credit
                                              ? formatNumber(dsAItem.credit)
                                              : dsAItem.credit === null
                                              ? ""
                                              : dsAItem.credit}
                                          </td>
                                          <td className="text-right">
                                            {dsAItem.debit
                                              ? formatNumber(dsAItem.debit)
                                              : dsAItem.debit === null
                                              ? ""
                                              : dsAItem.debit}
                                          </td>

                                          <td className="text-left">
                                            {dsBItem.date ?? ""}
                                          </td>
                                          <td className="text-left">
                                            {dsBItem.details ?? ""}
                                          </td>
                                          <td className="text-left">
                                            {dsBItem.entryRef ?? ""}
                                          </td>
                                          <td className="text-right">
                                            {dsBItem.credit
                                              ? formatNumber(dsBItem.credit)
                                              : dsBItem.credit === null
                                              ? ""
                                              : dsBItem.credit}
                                          </td>
                                          <td className="text-right">
                                            {dsBItem.debit
                                              ? formatNumber(dsBItem.debit)
                                              : dsBItem.debit === null
                                              ? ""
                                              : dsBItem.debit}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                        <Pagination
                          setPage={setPage}
                          page={page}
                          pageSize={pageSize}
                          setPageSize={setPageSize}
                          totalPages={totalPages}
                          totalElements={totalElements}
                          data={reconciledData?.nostroReconciler || []}
                          CSVHeaders={headers}
                        />
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <NostroModal
            showModal={showModal}
            setShowModal={setShowModal}
            selectedAccount={selectedAccount}
            selectedRow={selectedRow}
            dataSource={{
              batchId: reconciledData?.batchId,
              datasourceA: reconciledData?.datasourceA,
              datasourceAId: reconciledData?.datasourceAId,
              datasourceB: reconciledData?.datasourceB,
              datasourceBId: reconciledData?.datasourceBId,
            }}
            dsaAccount={{
              type: summaryData[0]?.accountType ?? "",
              number: summaryData[0]?.accountNumber ?? "",
            }}
            dsbAccount={{
              type: summaryData[1]?.accountType ?? "",
              number: summaryData[1]?.accountNumber ?? "",
            }}
          />
        )}
        {showViewModal && (
          <NostroViewModal
            showModal={showViewModal}
            setShowModal={setShowViewModal}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            dsaAccount={{
              type: summaryData[0]?.accountType ?? "",
              number: summaryData[0]?.accountNumber ?? "",
            }}
            dsbAccount={{
              type: summaryData[1]?.accountType ?? "",
              number: summaryData[1]?.accountNumber ?? "",
            }}
          />
        )}
      </div>
    </>
  );
};
export default NostroAccounts;
