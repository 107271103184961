import { combineReducers } from "redux";

import counterReducer from "./counter";
import regionReducer from "./region";

const allReducers = combineReducers({
  counter: counterReducer,
  region: regionReducer,
});

export default allReducers;
