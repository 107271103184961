import moment from "moment";

export const DateFormat = (format, date) => {
  if (!date) return;
  const validDate = moment(
    date,
    ["YYYY-MM-DD", "DD-MM-YYYY", "MM-DD-YYYY"],
    true
  );
  if (validDate.isValid()) {
    return validDate.format(format);
  } else {
    return moment(date).format(format);
  }
};

export const TimeFormat = (format, time) => {
  if (!time) return;
  const validTime = moment(time, ["HH:mm:ss", "HH:mm"], true);
  if (validTime.isValid()) {
    return validTime.format(format);
  } else {
    return moment(time).format(format);
  }
};

export const DateTimeFormat = (format, dateTime) => {
  if (!dateTime) return;
  const validDateTime = moment(
    dateTime,
    [
      "YYYY-MM-DD HH:mm:ss",
      "DD-MM-YYYY HH:mm:ss",
      "MM-DD-YYYY HH:mm:ss",
      "YYYY-MM-DD HH:mm",
      "DD-MM-YYYY HH:mm",
      "MM-DD-YYYY HH:mm",
    ],
    true
  );
  if (validDateTime.isValid()) {
    return validDateTime.format(format);
  } else {
    return moment(dateTime).format(format);
  }
};
