import React, { useState, memo } from "react";
import chevronDown from "../../assets/images/chevron-down.png";
import searchIcon from "../../assets/images/search.svg";
import resetIcon from "../../assets/images/reset.svg";
import FilterDropdownStyle from "./FilterDropdownStyle";
import SubFilterDropdown from "./SubFilterDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DropdownTreeSelect from "react-dropdown-tree-select";
// import { useDispatch } from "react-redux";
// import { setRegion } from "../../redux/actions/region";

const FilterDropdown = (props) => {
  const { classes, style, buttonClass, textClass, itemClass } = props;
  const [selectedDate, setSelectedDate] = useState(null);
  // const [data, setData] = useState([]);
  // const dispatch = useDispatch();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  // const onChange = (currentNode, selectedNodes) => {
  //   console.log("path::", currentNode.path);
  // };
  const onChangeNode = (currentNode, selectedNodes) => {
    // console.log("current Node::", currentNode);
    // console.log("selected node::", selectedNodes);
    props?.onDropdownChange(selectedNodes);
    // if (selectedNodes.length > 0) {
    //   dispatch(
    //     setRegion(
    //       `&region_id=${selectedNodes.map((node) => node.codeValue).join(",")}`
    //     )
    //   );
    // console.log(typeof selectedNodes);
    // console.log(typeof []);
    // }
  };
  const onAction = (node, action) => {
    // console.log("onAction::", action, node);
  };
  const onNodeToggle = (currentNode) => {
    // console.log("onNodeToggle::", currentNode);
  };

  const assignObjectPaths = (obj, stack) => {
    // if (typeof obj === "object") {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (typeof node === "object") {
        if (node) {
          node.path = stack ? `${stack}.${k}` : k;
          assignObjectPaths(node, node.path);
        }
      }
    });
    // }
  };
  // useEffect(() => {
  //   if (props.data !== data) {
  //     setData(props.data);
  //   }
  // }, []);
  // useEffect(() => {
  //   if (data.length > 0) {
  assignObjectPaths(props.data);
  //   }
  // }, [data]);
  // console.log("data::", data);
  // useEffect(() => {
  //   if (props.isTree) {
  //     document.getElementById(`dropdownMenuButton`).click();
  //   }
  // }, [props.isTree]);

  if (props.type === "date") {
    return (
      <FilterDropdownStyle className={`dropdown`}>
        <DatePicker
          selected={selectedDate}
          onChange={(e) => {
            handleDateChange(e);
            props.onDropdownChange(
              new Date(e)
                .toLocaleDateString("pt-br")
                .split("/")
                .reverse()
                .join("-")
            );
          }}
          customInput={
            <button
              className={`d-flex align-items-center justify-content-between btn tsg-dropdown w-100 h-100 px-2 py-1`}
            >
              <span>{props.title}</span>
              <img className="dropdown-icon" src={chevronDown} alt={"chev"} />
            </button>
          }
          className={`d-flex align-items-center justify-content-between custom-date-picker`}
        />
      </FilterDropdownStyle>
    );
  }
  if (props.type === "tree") {
    return (
      <FilterDropdownStyle className={`dropdown`}>
        <div
          className={`dropdown-menu fancy-scroll show dropdown-menu-tree-style`}
        >
          {props.isSearch && (
            <div className="position-relative">
              <img className="dropdown-search-icon" src={searchIcon} alt="" />
              <input
                className="dropdown-search"
                onChange={(e) => props.onSearchChange(e)}
                placeholder="Search"
              />
              <img className="dropdown-reset-icon" src={resetIcon} alt="" />
            </div>
          )}

          <DropdownTreeSelect
            data={props.data}
            onChange={onChangeNode}
            className="bootstrap-demo"
            expanded="true"
            showDropdown="always"
            keepOpenOnSelect={true}
            clearSearchOnChange={true}
            inlineSearchInput={true}
            onAction={onAction}
            onNodeToggle={onNodeToggle}
            // hierarchical={true}
          />
        </div>
      </FilterDropdownStyle>
    );
  }
  return (
    <FilterDropdownStyle className={`dropdown ${classes}`} style={style}>
      <button
        className={`d-flex align-items-center justify-content-between btn tsg-dropdown w-100 h-100 px-2 py-1 ${buttonClass}`}
        type="button"
        id="dropdownMenuButton"
        data-toggle={props.disabled ? "" : "dropdown"}
      >
        <span className={`${textClass}`}>{props.title}</span>
        <img className="dropdown-icon" src={chevronDown} alt={"chev"} />
      </button>
      <form>
        <div className={`dropdown-menu fancy-scroll`}>
          {props.isSearch && (
            <div className="position-relative">
              <img className="dropdown-search-icon" src={searchIcon} alt="" />
              <input
                className="dropdown-search"
                onChange={(e) => props.onSearchChange(e)}
                placeholder="Search"
              />
              <img className="dropdown-reset-icon" src={resetIcon} alt="" />
            </div>
          )}

          {/* {props.type === "checkbox" &&
            props?.data?.map((val, index) => (
              <div
                key={index}
                className={`dropdown-item ${itemClass ? itemClass : ""}`}
              >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input dropdown-checkbox"
                    value={val.id}
                    checked={val.param1 ? parseInt(val.param1) : false}
                    id={val.id}
                    onChange={(e) => props.onDropdownChange(e)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={val.id}
                    // onClick={(e) => checkDropDown(e)}
                  >
                    {val.label}
                  </label>
                  {val.subClass && (
                    <SubFilterDropdown
                      val={val}
                      onDropdownChange={(e) => props.onDropdownChange(e)}
                    />
                  )}
                </div>
              </div>
            ))} */}
          {props.type === "checkbox" && (
            <DropdownTreeSelect
              data={props.data}
              onChange={onChangeNode}
              className="bootstrap-demo"
              expanded="true"
              showDropdown="always"
              keepOpenOnSelect={true}
              clearSearchOnChange={true}
              inlineSearchInput={true}
              onAction={onAction}
              onNodeToggle={onNodeToggle}
              // hierarchical={true}
              radioSelect={true}
            />
          )}

          {props.type === "radio" &&
            props?.data?.map((val, index) => {
              return (
                <div
                  key={index}
                  className={`dropdown-item ${itemClass ? itemClass : ""}`}
                >
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input dropdown-checkbox"
                      value={val.id}
                      checked={val.param1 ? parseInt(val.param1) : false}
                      id={val.identifier ? val.identifier : val.id}
                      onChange={(e) => props.onDropdownChange(e)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={val.identifier ? val.identifier : val.id}
                      // onClick={(e) => checkDropDown(e)}
                    >
                      {val.label}
                    </label>
                    {val.subClass && (
                      <SubFilterDropdown
                        val={val}
                        onDropdownChange={(e) => props.onDropdownChange(e)}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          {props.type === "date" && (
            <div className={`dropdown-item`} style={{ height: "290px" }}>
              <div className="custom-control">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  open={true}
                />
              </div>
            </div>
          )}
        </div>
      </form>
    </FilterDropdownStyle>
  );
};

export default memo(FilterDropdown);
