import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import SummaryDashboard from "../../views/SummaryDashboard/SummaryDashboard";
import AtmDrillDown from "../../views/AtmDrillDown/AtmDrillDown";
import DeviceDetailView from "../../views/DetailView/AtmList";
import Sidebar from "./Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import TransactionLog from "../../views/Transactions/TransactionLog";
import Matching from "../../views/Transactions/Matching";
import Accounts from "../../views/Accounts/Accounts";
import NostroAccounts from "../../views/Nostro/nostro-accounts";
import NostroDynamicMenus from "../../views/nostro-dynamic-menus/nostro-accounts";
import RetailAccounts from "../../views/store/retail-accounts";
import PswAccounts from "../../views/psw-ups/psw-accounts";
// import RetailAccounts from "../../views/store/retail-accounts";
import UserProfileManagement from "../../views/Administration/user-profile-management";
import AccountManagement from "../../views/Administration/AccountManagement/AccountManagement";
import ReconAccountManagement from "../../views/Administration/ReconAccountManagement/ReconAccountManagement";
import AtmManagement from "../../views/Administration/AtmManagement/ATMManagementNEW";
import AtmManagementUpdate from "../../views/Administration/AtmManagement/ATMManagementUpdate";
import AtmManagementView from "../../views/Administration/AtmManagement/ATMManagementView";
// import ATMReplenishment from "../../views/DetailView/AtmList/atm-replenishment";
import MultiStepForm from "../../views/DetailView/AtmList/replenishment";
import BranchAdminManagement from "../../views/Administration/BrachManagement/BranchManagement";
import BranchAdminManagementUpdate from "../../views/Administration/BrachManagement/BranchManagementUpdate";
import BranchAdminManagementView from "../../views/Administration/BrachManagement/BranchManagementView";
import WorkflowManager from "../../views/Administration/WorkflowManager/WorkflowManager";
import WorkflowManagerPairs from "../../views/Administration/WorkflowManager";
// import Operations from "../../views/Operations/Operations";
import Operations from "../../views/Operations";
import RegionManagement from "../../views/Administration/RegionManagement";
import AtmList from "../../views/Administration/AtmManagement/AtmList";
import BranchManagement from "../../views/Administration/BrachManagement/BranchList";
import InterfaceConfigration from "../../views/Administration/InterfaceConfiguration/InterfaceConfigration";
import InstitutionManagement from "../../views/Administration/Institution/InstitutionManagement";
import MatchingPairs from "../../views/Transactions/MatchingPairs";
import CitManagement from "../../views/Administration/Cit/CitManagement";
import TeamManagement from "../../views/Administration/TeamManagement/TeamManagement";
import RoleManagement from "../../views/Administration/RoleManagement/RoleManagement";
import WorkQueue from "../../views/WorkQueue/WorkQueue";
import Settings from "../../views/Settings/Settings";
import AuditLog from "../../views/AuditLog/AuditLog";
import EventLog from "../../views/EventLog/EventLog";
import Reports from "../../views/Reports/Reports";
import PosDashboard from "../../views/Pos/PosDashboard";
import VisaDashboard from "../../views/Schemes/VisaDashboard";
import SchemeOneLinkDashboard from "../../views/Schemes/SchemeOneLinkDashboard";
import SchemeRaastDashboard from "../../views/Schemes/SchemeRaastDashboard";
import SchemeUPIDashboard from "../../views/Schemes/SchemeUPIDashboard";
import SchemeUaeSwitchDashboard from "../../views/Schemes/SchemeUaeSwitchDashboard";
import SchemeVisaDashboard from "../../views/Schemes/SchemeVisaDashboard";
import SchemeMasterCardDashboard from "../../views/Schemes/SchemeMasterCardDashboard";
import POSDetailView from "../../views/Pos/POSDetailView/POSDetailView";
import Search from "../../views/Search";
import PageNotFound from "../../views/PageNotFound";
import EmptyPage from "../../views/EmptyPage";
import BranchList from "../../views/Branch/BranchList/BranchList";
import DigitalPaymentsDashboard from "../../views/DigitalPayments/DigitalPaymentsDashboard";
import BranchDashboard from "../../views/Branch/BranchDashboard";
import Profile from "../../views/Profile/Profile";
import MerchantDashboard from "../../views/Merchant/PosDashboard";
import MerchantTransactions from "../../views/Merchant/POSDetailView/POSDetailView";
import Schedules from "../../views/Administration/Schedules";
import MaintenancePage from "../../views/MaintenancePage/MaintenancePage";
import MessageSender from "../../views/message-sender";
import OneLinkATM from "../../views/SattlementSummary/1linkAtm";
import UaeSwitch from "../../views/SattlementSummary/UaeSwitch";
import Visa from "../../views/SattlementSummary/Visa";
import MasterCard from "../../views/SattlementSummary/MasterCard";
import OneLinkIBFT from "../../views/SattlementSummary/1linkIBFT";
import { useAuth } from "../../navigation/ProvideAuth";
import IdleTimerContainer from "../../components/idle-timer-container";

function DefaultLayout(props) {
  const history = useHistory();
  const isLoggedIn = useAuth();

  const [navBar, showNavBar] = useState(false);
  const [toggleNavLink, showtoggleNavLink] = useState(false);
  const [togglePosLink, showtogglePosLink] = useState(false);
  const [toggleSchemesLink, showtoggleSchemesLink] = useState(false);
  const [toggleBranchLink, showtoggleBranchLink] = useState(false);
  const [togglePaymentLink, showtogglePaymentLink] = useState(false);
  const [toggleAdminLink, showtoggleAdminLink] = useState(false);
  const [toggleHisLink, showtoggleHisLink] = useState(false);
  const [toggleTransLink, showtoggleTransLink] = useState(false);
  const [toggleNostroLink, showtoggleNostroLink] = useState(false);
  const [toggleRetailPOSLink, setToggleRetailPOSLink] = useState(false);
  const [togglePswLink, setTogglePswLink] = useState(false);
  const [toggleAccountLink, showtoggleAccountLink] = useState(false);
  const [toggleNavBar, showToggleNavBar] = useState(false);
  const [toggleMerchantLink, showtoggleMerchantLink] = useState(false);
  const [toggleSattleLink, setToggleSattleLink] = useState(false);
  const [toggleDynamicMenu, setToggleDynamicMenu] = useState(false);

  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const allPermissionsData = userRoles?.data?.roles?.flatMap(
    (item) => item.permissions
  );

  const filteredMenus = allPermissionsData?.filter(
    (item) =>
      item.type === "menu" || item.type === "sub_menu" || item.type === "screen"
  );
  const filteredMenusIds = filteredMenus?.map((item) => item.id);

  const { theme, toggleTheme } = props;
  const routes = [
    {
      id: "scn_sum_dash",
      path: "/",
      name: "Summary Dashboard",
      exact: true,
      component: SummaryDashboard,
    },
    {
      id: "scn_atm_dash",
      path: "/atm-drill-down",
      exact: true,
      name: "ATM Dashboard",
      component: AtmDrillDown,
    },
    {
      id: "scn_pos_dash",
      path: "/pos-dashboard",
      exact: true,
      name: "POS Dashboard",
      component: PosDashboard,
    },
    {
      id: "scn_merchant_dash",
      path: "/merchant-dashboard",
      exact: true,
      name: "Merchant Dashboard",
      component: MerchantDashboard,
    },
    {
      id: "scn_merchant_dash",
      path: "/merchant-transactions",
      exact: true,
      name: "Merchant Transactions",
      component: MerchantTransactions,
    },
    {
      id: "scn_mrchnt_list",
      path: "/pos-detail-view",
      exact: true,
      name: "POS Device",
      component: POSDetailView,
    },

    {
      id: "sub_menu_scheme_1_link",
      path: "/schemes-1link",
      exact: true,
      name: "1-Link",
      component: SchemeOneLinkDashboard,
    },
    {
      id: "sub_menu_scheme_raast",
      path: "/schemes-raast",
      exact: true,
      name: "RAAST",
      component: SchemeRaastDashboard,
    },
    {
      id: "sub_menu_scheme_china_union_pay",
      path: "/schemes-upi",
      exact: true,
      name: "UPI",
      component: SchemeUPIDashboard,
    },
    {
      id: "sub_menu_scheme_uae_switch",
      path: "/schemes-uae-switch",
      exact: true,
      name: "UAE-Switch",
      component: SchemeUaeSwitchDashboard,
    },
    {
      id: "sub_menu_scheme_visa",
      path: "/schemes-visa",
      exact: true,
      name: "schemes-visa",
      component: SchemeVisaDashboard,
    },
    {
      id: "sub_menu_scheme_mastercard",
      path: "/schemes-master-card",
      exact: true,
      name: "schemes-master-card",
      component: SchemeMasterCardDashboard,
    },
    {
      id: "scn_pos_dash",
      path: "/schemes-visa",
      exact: true,
      name: "VISA",
      component: VisaDashboard,
    },
    {
      id: "mnu_atm_list",
      path: "/device-view",
      exact: true,
      name: "Device Reconciliation Insight",
      component: DeviceDetailView,
    },
    {
      id: "atm_cash_replenishment",
      path: "/cash-replenishment",
      exact: true,
      name: "Cash Replenishment",
      // component: ATMReplenishment,
      component: MultiStepForm,
    },
    {
      id: "scn_txns_log",
      path: "/transaction-log",
      exact: true,
      name: "Transaction Reconciliation Insight",
      component: TransactionLog,
    },
    {
      id: "scn_txns_match",
      path: "/matching",
      exact: true,
      name: "Matching",
      component: Matching,
    },
    {
      id: "scn_txns_match",
      path: "/matching-pairs",
      exact: true,
      name: "Matching Pairs",
      component: MatchingPairs,
    },
    {
      id: "scn_sub_acc",
      path: "/Accounts",
      exact: true,
      name: "Accounts",
      component: Accounts,
    },

    {
      id: "scn_nostro_acc",
      path: "/nostro-accounts",
      exact: true,
      name: "Nostro",
      component: NostroAccounts,
    },
    {
      id: "null",
      path: "/dynamic-recon",
      exact: true,
      name: "nostro-dynamic-menus",
      component: NostroDynamicMenus,
    },
    // {
    //   id: "mnu_retail_pos",
    //   path: "/retail-POS",
    //   exact: true,
    //   name: "RetailPOS",
    //   component: RetailAccounts,
    // },
    {
      id: "mnu_psw_ups",
      path: "/psw-ups",
      exact: true,
      name: "PSWUPS",
      component: PswAccounts,
    },
    {
      id: "mnu_retail_pos",
      path: "/retail-POS",
      exact: true,
      name: "RetailPOS",
      component: RetailAccounts,
    },
    {
      id: "scn_user_mgmt",
      path: "/user-profile-management",
      exact: true,
      name: "User Management",
      component: UserProfileManagement,
    },
    {
      id: "screen_admin_role_management",
      path: "/role-management",
      exact: true,
      name: "Role Management",
      component: RoleManagement,
    },
    {
      id: "scn_atm_mgmt",
      path: "/atm-management",
      exact: true,
      name: "ATM Management",
      component: AtmManagement,
    },
    {
      id: "scn_atm_mgmt",
      path: "/update-atm-management",
      exact: true,
      name: "ATM Management",
      component: AtmManagementUpdate,
    },
    {
      id: "scn_atm_mgmt",
      path: "/view-atm-management",
      exact: true,
      name: "ATM Management",
      component: AtmManagementView,
    },
    {
      id: "scn_branch_mgmt",
      path: "/add-branch-management",
      exact: true,
      name: "Branch Management",
      component: BranchAdminManagement,
    },
    {
      id: "scn_branch_mgmt",
      path: "/update-branch-management",
      exact: true,
      name: "Branch Management",
      component: BranchAdminManagementUpdate,
    },
    {
      id: "scn_branch_mgmt",
      path: "/view-branch-management",
      exact: true,
      name: "Branch Management",
      component: BranchAdminManagementView,
    },
    {
      id: "scn_account_mgmt",
      path: "/account-management",
      exact: true,
      name: "Account Management",
      component: AccountManagement,
    },
    {
      id: "scn_recon_account_mgmt",
      path: "/recon-accounts-management",
      exact: true,
      name: "Recon Account Management",
      component: ReconAccountManagement,
    },
    {
      id: "scn_atm_mgmt",
      path: "/atmlist",
      exact: true,
      name: "ATM List",
      component: AtmList,
    },
    {
      id: "scn_branch_mgmt",
      path: "/branch-management",
      exact: true,
      name: "Branch Management",
      component: BranchManagement,
    },
    {
      id: "scn_region_mgmt",
      path: "/region-management",
      exact: true,
      name: "Region Management",
      component: RegionManagement,
    },
    {
      id: "scn_inst_mgmt",
      path: "/institution-management",
      exact: true,
      name: "institution Management",
      component: InstitutionManagement,
    },
    {
      id: "scn_interface_mgr",
      path: "/interface-configuration",
      exact: true,
      name: "Interface Configuration",
      component: InterfaceConfigration,
    },
    {
      id: "scn_cit_mgmt",
      path: "/cit-management",
      exact: true,
      name: "CIT Management",
      component: CitManagement,
    },
    {
      id: "screen_admin_team_management",
      path: "/team-management",
      exact: true,
      name: "Team Management",
      component: TeamManagement,
    },
    {
      id: "scn_workflow_mgr",
      path: "/workflow-manager",
      exact: true,
      name: "Workflow Manager",
      component: WorkflowManager,
    },
    {
      id: "scn_workflow_pair",
      path: "/workflow-managerpairs",
      exact: true,
      name: "Workflow Manager Pairs",
      component: WorkflowManagerPairs,
    },
    {
      id: "scn_ops",
      path: "/operations",
      exact: true,
      name: "Operations",
      component: Operations,
    },
    {
      id: "scn_work_queue",
      path: "/workqueue",
      exact: true,
      name: "Workqueue",
      component: WorkQueue,
    },
    {
      id: "scn_settings",
      path: "/settings",
      exact: true,
      name: "settings",
      component: Settings,
    },

    {
      id: "scn_audit_log",
      path: "/audit-log",
      exact: true,
      name: "AuditLog",
      component: AuditLog,
    },

    {
      id: "scn_event_log",
      path: "/event-log",
      exact: true,
      name: "EventLog",
      component: EventLog,
    },
    {
      id: "scn_reports",
      path: "/reports",
      exact: true,
      name: "Reports",
      component: Reports,
    },
    {
      id: "scn_branch_list",
      path: "/branch-list",
      exact: true,
      name: "Branch List",
      component: BranchList,
    },
    {
      id: "scn_digital_dash",
      path: "/digital-platform",
      exact: true,
      name: "Digital Platform",
      component: DigitalPaymentsDashboard,
    },
    {
      id: "scn_branch_dash",
      path: "/branch-dashboard",
      exact: true,
      name: "Branch Dashboard",
      component: BranchDashboard,
    },
    {
      id: "null",
      path: "/profile",
      exact: true,
      name: "Profile",
      component: Profile,
    },
    {
      id: "null",
      path: "/schedules",
      exact: true,
      name: "Schedules",
      component: Schedules,
    },
    {
      id: "null",
      path: "/search",
      exact: true,
      name: "Search result",
      component: Search,
    },
    {
      id: "null",
      path: "/maintenance",
      exact: true,
      name: "Maintenance",
      component: MaintenancePage,
    },
    {
      id: "null",
      path: "/message-sender",
      exact: true,
      name: "Message",
      component: MessageSender,
    },
    {
      id: "sub_menu_1link_settlement_summary",
      path: "/1link-atm",
      exact: true,
      name: "1LINK ATM",
      component: OneLinkATM,
    },
    {
      id: "sub_menu_uae_switch_settlement_summary",
      path: "/settlement-summary-uae-switch",
      exact: true,
      name: "UAE Swtich",
      component: UaeSwitch,
    },
    {
      id: "sub_menu_visa_settlement_summary",
      path: "/settlement-summary-visa",
      exact: true,
      name: "VISA",
      component: Visa,
    },
    {
      id: "sub_menu_mastercard_settlement_summary",
      path: "/settlement-summary-master-card",
      exact: true,
      name: "Master Card",
      component: MasterCard,
    },
    {
      id: "null",
      path: "/1link-ibft",
      exact: true,
      name: "1LINK IBFT",
      component: OneLinkIBFT,
    },
    {
      id: "null",
      path: "*",
      exact: true,
      name: "404",
      component: !filteredMenusIds?.includes("scn_sum_dash")
        ? filteredMenusIds?.includes("scn_atm_dash")
          ? DeviceDetailView
          : EmptyPage
        : PageNotFound,
    },
  ];
  return (
    <div
      className={`as-main-container ${
        toggleNavBar === true ? "tsg-navbar-sm" : "tsg-navbar-lg"
      }`}
    >
      {isLoggedIn && <IdleTimerContainer />}
      <Router>
        <Sidebar
          theme={theme}
          filteredMenus={filteredMenus}
          navBar={navBar}
          showNavBar={showNavBar}
          toggleNavLink={toggleNavLink}
          togglePosLink={togglePosLink}
          toggleBranchLink={toggleBranchLink}
          togglePaymentLink={togglePaymentLink}
          toggleAdminLink={toggleAdminLink}
          toggleHisLink={toggleHisLink}
          toggleTransLink={toggleTransLink}
          toggleNostroLink={toggleNostroLink}
          toggleAccountLink={toggleAccountLink}
          toggleSchemesLink={toggleSchemesLink}
          showtoggleMerchantLink={showtoggleMerchantLink}
          toggleMerchantLink={toggleMerchantLink}
          showtoggleNavLink={showtoggleNavLink}
          showtoggleAdminLink={showtoggleAdminLink}
          showtogglePosLink={showtogglePosLink}
          showtoggleSchemesLink={showtoggleSchemesLink}
          showtoggleBranchLink={showtoggleBranchLink}
          showtogglePaymentLink={showtogglePaymentLink}
          showtoggleHisLink={showtoggleHisLink}
          showtoggleTransLink={showtoggleTransLink}
          showtoggleNostroLink={showtoggleNostroLink}
          showtoggleAccountLink={showtoggleAccountLink}
          toggleNavBar={toggleNavBar}
          setToggleSattleLink={setToggleSattleLink}
          toggleSattleLink={toggleSattleLink}
          toggleRetailPOSLink={toggleRetailPOSLink}
          setToggleRetailPOSLink={setToggleRetailPOSLink}
          togglePswLink={togglePswLink}
          setTogglePswLink={setTogglePswLink}
          toggleDynamicMenu={toggleDynamicMenu}
          setToggleDynamicMenu={setToggleDynamicMenu}
        />
        <div className="content-wrapper">
          <Navbar
            showNavBar={showNavBar}
            showToggleNavBar={showToggleNavBar}
            toggleNavBar={toggleNavBar}
            history={history}
            theme={theme}
            toggleTheme={toggleTheme}
          />
          <div className="py-4 px-3 content fancy-scroll">
            <div className="container-fluid h-100">
              <Switch>
                {routes
                  .filter(
                    (item) =>
                      item.id === "null" || filteredMenusIds?.includes(item.id)
                  )
                  .map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => (
                        <route.component
                          theme={theme}
                          toggleTheme={toggleTheme}
                          {...props}
                        />
                      )}
                    />
                  ))}
              </Switch>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default DefaultLayout;
