import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import BadgeRounded from "../../../../components/BadgeRounded/BadgeRounded";
import FilterIcon from "../../../../assets/images/filter.png";
import EditIcon from "../../../../assets/images/trash.png";
import TrashIcon from "../../../../assets/images/edit.png";
import Select from "react-select";
import ButtonBasic from "../../../../components/Buttons/ButtonBasic";
import AddUserModal from "./addModal";
import axiosInstance from "../../../../utils/axiosInstance";
import ResetPasswordModal from "./resetPasswordModal";
import EditUserModal from "./editModal";
import ShowForPermission from "../../../../utils/permissionsWrapper";
import Toast from "../../../../components/Toast/Toast";
import { useDateTime } from "../../../../components/Helper/DateTime";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
//testing for git build
const Users = ({
  users,
  setRoleIds,
  setUsers,
  getUsers,
  setQuery,
  showAddModal,
  setShowAddModal,
  usersCopy,
  onSortTable,
}) => {
  const { setRefresh } = useDateTime();
  const [roles, setRoles] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [user_Id, setUser_Id] = useState(undefined);
  const [userName, setUserName] = useState(undefined);
  const [userEmail, setUserEmail] = useState(undefined);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    username: "",
    password: "",
    empCode: "",
    mobileNo: "",
    officePhone: "",
    officePhoneExt: "",
  });
  const handleSelectServices = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const editUserModal = (id) => {
    const userRecord = users.find((item) => item.userId === id);
    const userRoles = [];
    userRecord.roles.forEach((item) => {
      userRoles.push(item.id);
      // console.log(item);
    });
    setUser_Id(id);
    setSelectedRoles(userRoles);
    setUser(userRecord);
    setShowEditModal(true);
  };
  const getRolesList = async () => {
    axiosInstance
      .get("/roles")
      .then(({ data, message }) => {
        if (message.status !== "200") {
          Toast(message.description, "error");
        } else {
          setRoles(data.roles);
        }
      })
      .catch((err) => console.log("error", err));
  };

  const getUserStatus = (cell, row) => (
    <BadgeRounded
      color={
        row?.statusId === 1 || row?.statusId === 3
          ? "success"
          : row?.statusId === 2 || row?.statusId === 6
          ? "warning"
          : "danger"
      }
      title={
        row?.statusId === 1
          ? "New"
          : row?.statusId === 3
          ? "Active"
          : row?.statusId === 4
          ? "Inactive"
          : row?.statusId === 2
          ? "Forgot Password"
          : row?.statusId === 5
          ? "Locked"
          : row.statusId === 6
          ? "Reset Password"
          : row.statusId === 7
          ? "Suspended"
          : "-"
      }
    />
  );

  const getFullName = (cell, row) => (
    <span>{`${row.firstName} ${row.lastName}`}</span>
  );
  const getEmail = (cell, row) => <span>{row.username}</span>;
  const getUserRole = (cell, row) => {
    return (
      <span>
        {row?.roles?.map((role, i) => (
          <BadgeRounded key={i} color="success" title={role?.name} />
        ))}
      </span>
    );
  };
  const getActions = (cell, row, rowIndex) => {
    return (
      <div className="d-flex">
        <ShowForPermission permission="clk_user_edit">
          <img
            id={"editIconRow" + rowIndex}
            width={24}
            height={24}
            src={TrashIcon}
            className="right-btn mr-2"
            alt="rightbtn"
            onClick={() => editUserModal(row.userId)}
          />
        </ShowForPermission>
        <ShowForPermission permission="clk_user_del">
          <img
            id={"deleteIconRow" + rowIndex}
            width={24}
            height={24}
            src={EditIcon}
            className="right-btn mr-3"
            alt="rightbtn"
            onClick={() => {
              setShowConfirmationModal(true);
              setUser_Id(row.userId);
            }}
          />
        </ShowForPermission>
      </div>
    );
  };
  const getUserEmail = (cell, row) => {
    return (
      <ShowForPermission permission="clk_user_resetpass">
        <div
          onClick={() => {
            setShowPasswordModal(true);
            setUserName(row.firstName + " " + row.lastName);
            setUserEmail(row.username);
          }}
          style={{
            color: "#118AB2",
            textDecoration: "underline",
            fontSize: "14px",
            fontFamily: "Montserrat Regular",
            wordWrap: "break-word",
          }}
        >
          reset password
        </div>
      </ShowForPermission>
    );
  };

  const updateStatus = (values, statusId) => {
    const data = {
      data: {
        userId: values.userId,
        statusId: statusId,
      },
    };

    axiosInstance
      .post(`/users/user-status`, data)
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          Toast(res.data.message, "success");
          getUsers();
        }
      })
      .catch((err) => {
        console.log(err);
        Toast(err.response.data.message.description, "error");
      });
  };

  const SuspendUser = (cell, row) => {
    return cell === 7 ? (
      <button
        style={{ width: "100px" }}
        className="btn btn-sm btn-info"
        onClick={() => updateStatus(row, 3)}
      >
        Re-activate
      </button>
    ) : cell === 2 || cell === 3 || cell === 6 ? (
      <button
        style={{ width: "100px" }}
        className="btn btn-sm btn-danger"
        onClick={() => updateStatus(row, 7)}
      >
        Suspend
      </button>
    ) : (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="button-tooltip-2">User is inactive</Tooltip>}
      >
        <button
          disabled
          style={{ width: "100px" }}
          className="btn btn-sm btn-danger"
          onClick={() => updateStatus(row, 7)}
        >
          Suspend
        </button>
      </OverlayTrigger>
    );
  };

  const columns = [
    { hidden: true, dataField: "userId", text: "ID" },
    {
      dataField: "firstName",
      text: "Name",
      formatter: getFullName,
      sort: true,
      headerAttrs: { id: "nameSort" },
    },
    {
      dataField: "statusId",
      text: "Status",
      formatter: getUserStatus,
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
      headerAttrs: { id: "statusSort" },
    },
    {
      dataField: "roles",
      text: "Roles",
      formatter: getUserRole,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "email",
      text: "Email",
      formatter: getEmail,
    },
    {
      dataField: "statusId",
      text: "Suspend Account",
      formatter: SuspendUser,
      style: {
        display: "flex",
        justifyContent: "center",
      },
    },
    {
      dataField: "username",
      text: "Reset Password",
      formatter: getUserEmail,
      headerStyle: () => {
        return { width: "12%" };
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: getActions,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
  ];
  useEffect(() => {
    getRolesList();
    setRefresh(() => () => {
      getRolesList();
      getUsers();
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, []);

  let labeledRoles = roles.map((role) => ({
    ...role,
    value: role.id,
    label: role.name,
  }));
  return (
    <div>
      <div className="mt-3 d-flex align-items-center justify-content-end">
        <div className="d-flex justify-content-end align-items-center">
          <input
            id="searchBar"
            type="search"
            placeholder="Search by First Name or Last Name"
            // value={query}
            className="search-input-card d-xs-none d-sm-none d-lg-flex mr-2"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setQuery(e.target.value);
              }
            }}
            style={{ width: "340px" }}
          />
        </div>
        <div>
          <Select
            id="filterRole"
            placeholder="Filter Role"
            value={labeledRoles.filter((obj) =>
              selectedValue.includes(obj.value)
            )}
            classNamePrefix={"filterRole"}
            options={labeledRoles}
            onChange={handleSelectServices}
            isMulti
            isClearable
            isSearchable
          />
        </div>
        <div className="ml-2">
          <div className="d-flex align-items-center">
            <ButtonBasic
              id="filter"
              icon={FilterIcon}
              title="Filter"
              onClick={() => {
                setRoleIds(selectedValue);
              }}
            />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <BootstrapTable
            id="userTable"
            columns={columns}
            bootstrap4={true}
            keyField="id"
            bordered={false}
            classes="rs-table table-layout-auto table-custom-style"
            wrapperClasses="overflow-y-auto fancy-scroll"
            data={users}
            remote={{ sort: true }}
            onTableChange={onSortTable}
            noDataIndication={"No data found!"}
          />
        </div>
      </div>
      {showAddModal && (
        <AddUserModal
          roles={roles}
          getUsers={getUsers}
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
        />
      )}
      <ResetPasswordModal
        user_Id={user_Id}
        userName={userName}
        userEmail={userEmail}
        getUsers={getUsers}
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
        setShowPasswordModal={setShowPasswordModal}
        showPasswordModal={showPasswordModal}
      />
      {showEditModal && (
        <EditUserModal
          getUsers={getUsers}
          user={user}
          user_Id={user_Id}
          labeledRoles={labeledRoles}
          selectedRoles={selectedRoles}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
        />
      )}
    </div>
  );
};

export default Users;
