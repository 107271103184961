import React, { useContext, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { Formik, Field } from "formik";
import { useDateTime } from "../../../../../components/Helper/DateTime";
import MultiStepFormContext from "./MultiStepFormContext";
import AddCassettesConfiguration from "./AddCassettesConfiguration";
import axiosInstance from "../../../../../utils/axiosInstance";
import Toast from "../../../../../components/Toast/Toast";
import LoaderComponent from "../../../../../components/Spinner/LoaderComponent";

const Review = () => {
  const {
    details,
    ejCashUnLoadData,
    citCashUnLoadData,
    ejCashLoadData,
    citCashLoadData,
    selectedDeviceId,
    devices,
    next,
    prev,
  } = useContext(MultiStepFormContext);

  const {
    ejCashUnLoadTotalAmount,
    citCashUnLoadTotalAmount,
    ejCashLoadTotalAmount,
    citCashLoadTotalAmount,
  } = details;
  const [loading, setLoading] = useState(false);
  const [cashUnloadWarning, setCashUnloadWarning] = useState(false);
  const [cashLoadWarning, setCashLoadWarning] = useState(false);
  const { currency, dateTime } = useDateTime();
  const warning =
    "Warning: Total Cash Unload amounts (EJ Slip & Physical Cash ) are not equal!";

  const convertedDateTime = moment(details?.dateTime);
  const formattedDateTime = convertedDateTime.format(dateTime);

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      "0"
    );

  const handleSaveAndPerform = async (isPerformBalancing) => {
    let data = {
      deviceId: selectedDeviceId,
      lastRef: details?.lastRef,
      dateTime: moment(details?.dateTime).format("YYYY-MM-DD HH:mm:ss"),
      performBalancing: isPerformBalancing,
      ejCashUnLoadData: ejCashUnLoadData,
      citCashUnLoadData: citCashUnLoadData,
      ejCashLoadData: ejCashLoadData,
      citCashLoadData: citCashLoadData,
      ejCashUnLoadTotalAmount: details?.ejCashUnLoadTotalAmount,
      citCashUnLoadTotalAmount: details?.citCashUnLoadTotalAmount,
      ejCashLoadTotalAmount: details?.ejCashLoadTotalAmount,
      citCashLoadTotalAmount: details?.citCashLoadTotalAmount,
      message: warning,
    };
    try {
      let response = await axiosInstance.post(
        "/devices/atm-cash-replenishment",
        {
          data,
        }
      );
      if (response.message.status !== "200") {
        setLoading(false);
        Toast(response.message.description, "error");
      } else {
        Toast(response.message.description, "success");
        next();
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
    }
  };

  useEffect(() => {
    if (ejCashUnLoadTotalAmount !== citCashUnLoadTotalAmount) {
      setCashUnloadWarning(true);
    }
    if (ejCashLoadTotalAmount !== citCashLoadTotalAmount) {
      setCashLoadWarning(true);
    }
  }, [
    citCashLoadTotalAmount,
    citCashUnLoadTotalAmount,
    ejCashLoadTotalAmount,
    ejCashUnLoadTotalAmount,
  ]);

  return (
    <>
      <Formik
        initialValues={details}
        // onSubmit={(values) => {

        // }}
      >
        {() => {
          return (
            <>
              {loading && (
                <div
                  className="spinner-center"
                  style={{ top: "0%", left: "0%", zIndex: 2000 }}
                >
                  <LoaderComponent />
                </div>
              )}
              <div className="container">
                <div className="row">
                  <div className="col-3">
                    <label className="form-label">Last Ref</label>
                    <Field
                      type="text"
                      placeholder="Ref Id"
                      name="lastRef"
                      value={details?.lastRef}
                      disabled={true}
                      style={{
                        padding: "10px",
                        borderRadius: "7px",
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <label className="form-label">Date & Time</label>
                    {/* <Field
                    type="text"
                    placeholder="Date"
                    name="dateTime"
                    value={details?.dateTime}
                    disabled={true}
                    style={{
                      padding: "10px",
                      borderRadius: "7px",
                    }}
                  /> */}
                    <div
                      style={{
                        padding: "11px",
                        border: "2px solid #b4b4b7",
                        borderRadius: "8px",
                        background: "#f4f4f8",
                      }}
                    >
                      {formattedDateTime}
                    </div>
                  </div>
                  <div className="col-3">
                    <label className="form-label">Device ID</label>
                    <Field
                      type="text"
                      placeholder="Device ID"
                      name="deviceId"
                      value={details?.deviceId}
                      disabled={true}
                      style={{
                        padding: "10px",
                        borderRadius: "7px",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  {/* 50% column left starts*/}
                  <div className="col-md">
                    <h5 className="pt-5">Cash Unload</h5> <br />
                    <h6 className="text-info">Ej</h6>
                    <div className="row mt-3 d-flex justify-content-start  align-items-start">
                      <div className="col-md">
                        <div className="text-left">Denom</div>
                      </div>
                      <div className="col-md">
                        <div className="text-left">Bill Count</div>
                      </div>

                      <div className="col-md">
                        <div className="text-left">Type</div>
                      </div>

                      <div className="col-md">
                        <div className="text-left">Total</div>
                      </div>
                    </div>
                    <AddCassettesConfiguration
                      rowsData={[ejCashUnLoadData]}
                      isCassetteType={true}
                      isReview={true}
                    />
                    <div className="row d-flex justify-content-center mt-4">
                      <div className="col-10 text-left">
                        Total Cash Unload:{" "}
                        {formatAmount(details?.ejCashUnLoadTotalAmount)}
                      </div>
                    </div>
                  </div>
                  {/* 50% column right starts*/}
                  <div className="col-md">
                    <h5 className="pt-5">Cash Load</h5> <br />
                    <h6 className="text-info">Ej</h6>
                    <div className="row mt-3 d-flex justify-content-start  align-items-start">
                      <div className="col-md">
                        <div className="text-left">Denom</div>
                        {/* <p className={"error__feedback"}>{errors.denomination}</p> */}
                      </div>
                      <div className="col-md">
                        <div className="text-left">Bill Count</div>
                      </div>

                      <div className="col-md">
                        <div className="text-left">Type</div>
                      </div>

                      <div className="col-md">
                        <div className="text-left">Total</div>
                      </div>
                    </div>
                    <AddCassettesConfiguration
                      rowsData={[ejCashLoadData]}
                      isCassetteType={true}
                      isReview={true}
                    />
                    <div className="row d-flex justify-content-center mt-4">
                      <div className="col-10 text-left">
                        Total Cash Load:{" "}
                        {formatAmount(details?.ejCashLoadTotalAmount)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container mt-5 pb-5">
                <div className="row">
                  {/* Cash Unload 50% column left starts*/}
                  <div className="col-md">
                    <h6 className="text-info">Physical Cash Count</h6>
                    <div className="row mt-3 d-flex justify-content-start  align-items-start">
                      <div className="col-md">
                        <div className="text-left">Denom</div>
                      </div>
                      <div className="col-md">
                        <div className="text-left">Bill Count</div>
                      </div>

                      <div className="col-md">
                        <div className="text-left">Total</div>
                      </div>
                    </div>
                    <AddCassettesConfiguration
                      rowsData={[citCashUnLoadData]}
                      isCassetteType={false}
                      isReview={true}
                    />
                    <div className="row d-flex justify-content-center mt-4">
                      <div className="col-10 text-left">
                        Total Cash Unload:{" "}
                        {formatAmount(details?.citCashUnLoadTotalAmount)}
                      </div>
                    </div>
                  </div>
                  {/*Cit Cash Load 50% column right starts*/}
                  <div className="col-md">
                    <h6 className="text-info">Physical Cash Count</h6>
                    <div className="row mt-3 d-flex justify-content-start  align-items-start">
                      <div className="col-md">
                        <div className="text-left">Denom</div>
                      </div>
                      <div className="col-md">
                        <div className="text-left">Bill Count</div>
                      </div>

                      <div className="col-md">
                        <div className="text-left">Total</div>
                      </div>
                    </div>
                    <AddCassettesConfiguration
                      rowsData={[citCashLoadData]}
                      isCassetteType={false}
                      isReview={true}
                    />
                    <div className="row d-flex justify-content-center mt-4">
                      <div className="col-10 text-left">
                        Total Cash Load:{" "}
                        {formatAmount(details?.citCashLoadTotalAmount)}
                      </div>
                    </div>
                  </div>
                </div>
                <button className="btn btn-info mt-5" onClick={prev}>
                  Back
                </button>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-12 d-flex justify-content-center ">
                    {cashUnloadWarning && (
                      <Alert variant="warning">
                        {`Warning: Total Cash Unload amounts (EJ Slip & Physical Cash Count ) are not equal!`}
                      </Alert>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 d-flex justify-content-center ">
                    {cashLoadWarning && (
                      <Alert variant="danger">
                        {`Error: Total Cash Load amounts (EJ & Physical Cash Count) are not equal. Go Back and correct Total amount values.`}
                      </Alert>
                    )}
                  </div>
                </div>
                <div className="row pb-5 d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-info"
                    onClick={() => handleSaveAndPerform(true)}
                    disabled={cashLoadWarning ? true : false}
                  >
                    Save & Perform Balancing
                  </button>
                  <button
                    className="btn btn-info ml-2"
                    onClick={() => handleSaveAndPerform(false)}
                    disabled={cashLoadWarning ? true : false}
                  >
                    Save
                  </button>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default Review;
