import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import axiosInstance from "../../../utils/axiosInstance";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ShowForPermission from "../../../utils/permissionsWrapper";
import Toast from "../../../components/Toast/Toast";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
import AddIcon from "../../../assets/images/add-icon.png";
import { Modal } from "react-bootstrap";
import WorkflowModal from "./WorkflowModal";
import withDateTime from "../../../utils/timedatehoc";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import Pagination from "../../../components/pagination/pagination";
import { useDateTime } from "../../../components/Helper/DateTime";

function WorkflowManager(props) {
  const { setRefresh } = useDateTime();
  const [loading, setLoading] = useState(true);
  const [workflows, setWorkflows] = useState([]);
  const [workflow, setWorkflow] = useState(undefined);
  const [id, setId] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [groups, setGroups] = useState([]);
  const getActions = (cell, row, rowIndex) => (
    <div
      className="d-flex justify-content-start"
      onClick={(e) => e.stopPropagation()}
    >
      <ShowForPermission permission="clk_workflow_edit">
        <i
          id={"editRow" + rowIndex}
          className="fa fa-pencil text-info px-2"
          aria-hidden="true"
          onClick={() => editWorkflowModal(row.id)}
          style={{ fontSize: "16px", cursor: "pointer" }}
        />
      </ShowForPermission>
      <ShowForPermission permission="clk_workflow_del">
        <i
          id={"deleteRow" + rowIndex}
          className="fa fa-trash text-danger px-2"
          aria-hidden="true"
          onClick={() => {
            setId(row.id);
            setIsDeleteModal(true);
          }}
          style={{ fontSize: "16px", cursor: "pointer" }}
        />
      </ShowForPermission>
    </div>
  );

  const rowEvents = {
    onClick: (e, row) => {
      setId(row?.id);
      props.history.push(`/workflow-managerpairs?id=${row?.id}`);
    },
  };

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const getWorkflowsData = useCallback(
    async (searchText = "" ) => {
      setLoading(true);
      try {
        const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
        const response = await axiosInstance.get(
          `/recon?page=${page - 1}&size=${pageSize}&search=${searchText ?? ""}${
            sort.sort_by && sort.sort_order ? order : ""
          }`
        );
        if (response.message.status !== "200") {
          Toast(response.message.description, "error");
          setLoading(false);
          setTotalElements(0);
          setTotalPages(0);
        } else {
          setWorkflows(response.data.reconStatus);
          setPage(response.page.number + 1);
          setPageSize(response.page.size);
          setTotalPages(response.page.totalPages);
          setTotalElements(response?.page?.totalElements);
          setLoading(false);
        }
      } catch (err) {
        setWorkflows([]);
        setLoading(false);
        setTotalPages(0);
        setTotalElements(0);
      }
    },
    [page, pageSize, sort.sort_by, sort.sort_order]
  );

  const deleteWorkflow = async () => {
    const response = await axiosInstance.delete(`/recon/${id}`);
    if (response.message.status !== "200") {
      Toast(response.message.description, "error");
    } else {
      Toast("Record deleted.", "success");
      getWorkflowsData(searchText);
    }
  };
  const getGroups = async () => {
    await axiosInstance
      .get("/recon-group")
      .then((res) => {
        setGroups(res?.data?.reconGroups);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setModalShow(!modalShow);
    setWorkflow(undefined);
  };

  const editWorkflowModal = (id) => {
    const workflow = workflows.find((x) => x.id === id);
    setModalShow(true);
    setWorkflow(workflow);
  };

  useEffect(() => {
    getWorkflowsData(searchText);
  }, [getWorkflowsData]);

  useEffect(() => {
    getWorkflowsData(searchText);
    getGroups();
    setRefresh(() => () => {
      getWorkflowsData(searchText);
      getGroups();
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, []);

  const columns2 = [
    { hidden: true, dataField: "id", text: "ID" },
    {
      dataField: "reconName",
      text: "Reconciliation",
      sort: true,
      formatter: (cell) => cell ?? "-",
      headerAttrs: { id: "reconNameSort" },
    },
    {
      dataField: "description",
      text: "Description",
      formatter: (cell) => cell ?? "-",
      sort: true,
      style: {
        whiteSpace: "normal", // or 'pre-wrap'
        wordWrap: "break-word",
        minWidth: "341px",
      },
      headerAttrs: { id: "descriptionSort" },
    },
    {
      dataField: "schedulerName",
      text: "Schedule",
      formatter: (cell) => cell ?? "-",
    },
    {
      dataField: "groupId",
      text: "UI Group",
      formatter: (cell) =>
        groups?.find((x) => x?.id === cell)?.groupName ?? "-",
    },
    {
      dataField: "schedulerTriggerValue",
      text: "Cron Expression",
      formatter: (cell) => cell ?? "-",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: getActions,
    },
  ];
  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}

      <div
        style={{
          opacity: !loading ? "1" : "0.07",
        }}
        className="row"
      >
        <div className="col d-flex justify-content-end align-items-center">
          <ShowForPermission permission="clk_workflow_add">
            <ButtonTransparent
              id="addRecon"
              onClick={() => setModalShow(true)}
              title={"Add Recon"}
              icon={AddIcon}
            />
          </ShowForPermission>
        </div>
        <div className="col-12 mt-3">
          <div className="row page-header d-flex justify-content-between mb-0">
            <div className="col-8 title text-bright">Reconciliation</div>
            <div className="col-4">
              <div class="form-group has-search">
                <span class="fa fa-search form-control-feedback"></span>
                <input
                  id="workflowSearch"
                  type="text"
                  class="form-control"
                  placeholder="Search by Recon Name"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setSearchText(e.target.value);
                      setPage(1);
                      getWorkflowsData(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="overflow-y-auto fancy-scroll">
            <BootstrapTable
              id="workflowTable"
              bootstrap4={true}
              bordered={false}
              classes="rs-table table-layout-auto mb-0 table-custom-style"
              wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
              keyField="atmId"
              data={workflows}
              columns={columns2}
              rowEvents={rowEvents}
              remote={{ pagination: true }}
              onTableChange={onSortTable}
              noDataIndication="No data found!"
            ></BootstrapTable>
            <Pagination
              setPage={setPage}
              page={page}
              pageSize={pageSize}
              setPageSize={setPageSize}
              totalPages={totalPages}
              // showCSV
              totalElements={totalElements}
              data={workflows || []}
              // CSVHeaders={csvHeaders}
            />
            <ConfirmationModal
              isShow={isDeleteModal}
              title={"Delete Workflow"}
              message={"Are you sure you want to delete this workflow?"}
              hideModal={() => {
                setIsDeleteModal(false);
              }}
              confirmAction={() => {
                setIsDeleteModal(false);
                deleteWorkflow();
              }}
            />
            <div className="col-md-12">
              <Modal
                backdrop={true}
                size="lg"
                show={modalShow}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <h3 className="color-dark">Setup a Reconciliation</h3>
                </Modal.Header>
                <Modal.Body scrollable="true" handleCloseModal={handleClose}>
                  <WorkflowModal
                    setShowModal={handleClose}
                    workflow={workflow}
                    getWorkflowsData={getWorkflowsData}
                  />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withDateTime(withRouter(WorkflowManager));
