export default function MatchingSummary({ theme, colorPalette }) {
  const matchingSummary = {
    title: {
      text: "Matching Summary",
      left: "left",
      textStyle: {
        color: "#777",
        fontSize: "14px",
        fontFamily: "Montserrat Bold",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    toolbox: {
      show: true,
      showTitle: false, // hide the default text so they don't overlap each other
      feature: {
        saveAsImage: {
          show: true,
          title: "Save As Image",
        },
      },
    },
    legend: {
      data: ["Matched", "Unmatched"],
      orient: "vertical",
      left: "right",
      top: "bottom",
      icon: "circle",
      textStyle: {
        color: "#777",
        fontFamily: "Montserrat Regular",
        fontSize: "10px",
      },
    },
    series: [
      {
        name: "Matching Summary",
        type: "pie",
        radius: "60%",
        itemStyle: {
          borderColor: theme === "light" ? "#fcfcfc" : "#1d1d1d",
          borderWidth: 1,
        },
        color: colorPalette,
        label: {
          color: "#777",
          fontFamily: "Montserrat Regular",
          fontSize: "10px",
        },
        data: [
          { value: 60640, name: "Matched Count" },
          { value: 840, name: "Unmatched Count" },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return matchingSummary;
}
