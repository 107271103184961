import styled from "styled-components";

const BreadcrumbsStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .breadcrumb {
    background-color: transparent;
    font-family: ${({ theme }) => theme.fonts.family.primary.medium};
    padding: 6px 0;
    font-size: 0.875rem;
  }
  .breadcrumb .breadcrumb-item a {
    color: ${({ theme }) => theme.chartColors.info};
  }
  .breadcrumb-item.active {
    color: ${({ theme }) => theme.text.base};
  }
`;

export default BreadcrumbsStyle;
