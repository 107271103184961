import React, { useContext } from "react";
import { useField, useFormikContext } from "formik";
import Select from "react-select";
import MultiStepFormContext from "./MultiStepFormContext";

export const DevicePicker = ({ options, ...props }) => {
  const {
    setSelectedDeviceId,
    setEjCashUnLoadData,
    setEjCashLoadData,
    setCitCashUnLoadData,
    setCitCashLoadData,
  } = useContext(MultiStepFormContext);
  const { setFieldValue } = useFormikContext();
  const [field] = useField({ name: "deviceId" });
  const value = options.find((option) => option.label === field.value);

  const customStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      height: "50px",
      borderRadius: "10px",
    }),
  };

  return (
    <Select
      {...field}
      {...props}
      options={options}
      value={value}
      onChange={(selectedOption) => {
        setFieldValue(field.name, selectedOption?.label || null);
        setSelectedDeviceId(selectedOption?.value);
        setEjCashUnLoadData([]);
        setEjCashLoadData([]);
        setCitCashUnLoadData([]);
        setCitCashLoadData([]);
      }}
      isClearable={true}
      className="no-border"
      styles={customStyles}
    />
  );
};

export default DevicePicker;
