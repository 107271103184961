import styled from "styled-components";

const ReconScheduleStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  padding: 15px 25px;
  margin-top: 15px;
  background: ${({ theme }) => theme.card_background};
  border-radius: 10px;
  min-height: 300px;
  max-height: 335px;
  overflow-y: auto;

  .recon-schedule-h {
    font-size: 14px;
  }
  .rounded-indicator {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.text.bright};
    display: inline-block;
    margin-right: 10px;
  }
  .date {
    color: ${({ theme }) => theme.text.bright};
    font-size: 16px;
  }
  .recon-schedule-item {
    display: flex;
    padding-left: 20px;
    border-left: 2px solid #56ccf2;
    justify-content: space-between;
    align-items: flex-start;
  }
  .recon-schedule-item-wrapper {
    padding: 5px 10px;
    margin-top: 20px;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
  .recon-schedule-item-wrapper:hover {
    background: ${({ theme }) => theme.hover};
  }
  .time {
    color: ${({ theme }) => theme.text.bright};
    font-size: 14px;
    margin-bottom: 5px;
    font-family: ${({ theme }) => theme.fonts.family.primary.medium};
  }
  .recur {
    font-size: 10px;
    font-family: ${({ theme }) => theme.fonts.family.primary.regular};
    color: ${({ theme }) => theme.text.bright};
  }
  .run {
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.family.primary.medium};
    color: ${({ theme }) => theme.text.bright};
  }
  .last-received {
    font-size: 10px;
    font-family: ${({ theme }) => theme.fonts.family.primary.regular};
    margin-top: 5px;
    color: ${({ theme }) => theme.text.bright};
  }
  .received {
    color: #00af91;
    font-size: 10px;
  }
  .missing {
    color: #ef476f;
    font-size: 10px;
    margin-top: 5px;
  }
  .awaiting {
    color: #ffd166;
    font-size: 10px;
  }
`;

export default ReconScheduleStyle;
