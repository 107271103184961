import React, { useState, useEffect } from "react";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import BootstrapTable from "react-bootstrap-table-next";
import axiosInstance from "../../../utils/axiosInstance";
import { useDateTime } from "../../../components/Helper/DateTime";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
import AddIcon from "../../../assets/images/add-icon.png";
import AddOrEdit from "./AddOrEdit";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import Toast from "../../../components/Toast/Toast";
import { DateTimeFormat } from "../../../utils/formatDateTime";

const Schedules = () => {
  const { setRefresh,dateTime } = useDateTime();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    setLoading(true);
    axiosInstance
      .get("/scheduler")
      .then((res) => {
        setData(res.data.schedulers);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
    setRefresh(() => () => {
      getData();
    });
    return () => {
      setRefresh(() => () => {});
    }; // eslint-disable-next-line
  }, []);

  const HandleDeleteScheduler = () => {
    setIsLoading(true);
    axiosInstance
      .delete(`/scheduler/${id}`)
      .then((res) => {
        // eslint-disable-next-line
        if (res.message.status == 200) {
          setData(data.filter((item) => item.id !== id));
          setIsDeleteModal(false);
          setIsLoading(false);
          Toast("Record deleted!", "success");
        } else {
          setIsLoading(false);
          setIsDeleteModal(false);
          Toast(res.message.description, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsDeleteModal(false);
      });
  };

  const schedulerType = (value, label) => {
    if (label === "triggerTypeId" && value?.toString() === "1") {
      return "Cron Expression";
    } else if (label === "triggerTypeId" && value?.toString() === "2") {
      return "Fixed Interval";
    } else if (label === "schedulerTypeId" && value?.toString() === "1") {
      return "Kafka";
    } else if (label === "schedulerTypeId" && value?.toString() === "2") {
      return "API";
    } else {
      return "-";
    }
  };

  const columns = (hideActionColumn) => {
    return [
      { hidden: true, dataField: "id", text: "ID" },
      {
        dataField: "schedulerName",
        text: "Schedule Name",
        formatter: (cell) => cell ?? "-",
        sort: true,
        style: { cursor: "default" },
      },
      {
        dataField: "code",
        text: "Code",
        formatter: (cell) => cell ?? "-",
        sort: true,
        style: { cursor: "default" },
      },
      {
        dataField: "groupCode",
        text: "Group",
        formatter: (cell) => cell ?? "-",
        // sort: true,
        style: { cursor: "default" },
      },
      {
        dataField: "schedulerTypeId",
        text: " Scheduler Type",
        formatter: (cell) =>
          cell ? schedulerType(cell, "schedulerTypeId") : "-",
        sort: true,
        style: { cursor: "default" },
      },
      {
        dataField: "triggerTypeId",
        text: "Trigger Type",
        formatter: (cell) =>
          cell ? schedulerType(cell, "triggerTypeId") : "-",
        sort: true,
        style: { cursor: "default" },
      },
      {
        dataField: "triggerValue",
        text: "Trigger Value",
        formatter: (cell) => cell ?? "-",
        sort: true,
        style: { cursor: "default" },
      },
      {
        dataField: "autoStart",
        text: "Auto Start",
        formatter: (cell) => cell ?? "-",
        sort: true,
        style: { cursor: "default" },
      },
      {
        dataField: "activeInd",
        text: "Active",
        formatter: (cell) => (cell === true ? "Active" : "Inactive" ?? "-"),
        sort: true,
        style: { cursor: "default" },
      },
      {
        dataField: "lastStartDatetime",
        text: "Last Start Time",
        formatter: (cell) => DateTimeFormat(dateTime, cell) ?? "-",
        sort: true,
        style: { cursor: "default" },
      },
      {
        dataField: "nextStartDatetime",
        text: "Next Start Time",
        formatter: (cell) =>  DateTimeFormat(dateTime, cell) ?? "-",
        sort: true,
        style: { cursor: "default" },
      },

      hideActionColumn === true && {
        dataField: "action",
        text: "Action",
        formatter: (cell, row) => {
          return (
            <div className="d-flex justify-content-around">
              {/* <ShowForPermission permission="clk_workflow_edit"> */}
              <i
                className="fa fa-pencil text-info"
                aria-hidden="true"
                onClick={() => {
                  setId(row.id);
                  setIsEdit(true);
                  setShowModal(true);
                }}
                style={{ fontSize: "16px", cursor: "pointer" }}
              />
              {/* </ShowForPermission> */}
              {/* <ShowForPermission permission="clk_workflow_del"> */}
              <i
                className="fa fa-trash text-danger"
                aria-hidden="true"
                onClick={() => {
                  setIsDeleteModal(true);
                  setId(row.id);
                }}
                style={{ fontSize: "16px", cursor: "pointer" }}
              />
              {/* </ShowForPermission> */}
            </div>
          );
        },
      },
    ];
  };
  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div style={{ opacity: !loading ? "1" : "0.07" }}>
        <div className="row page-header d-flex justify-content-between">
          <div className="col-sm-12 col-lg-12 page-header d-flex justify-content-between m-0">
            <div className="title">Recon Schedules</div>
            <div>
              <ButtonTransparent
                onClick={() => {
                  setIsEdit(false);
                  setShowModal(true);
                }}
                title={"Add Scheduler"}
                icon={AddIcon}
              />
              {showModal && (
                <AddOrEdit
                  showModal={showModal}
                  setShowModal={setShowModal}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  getData={getData}
                  id={id}
                  setId={setId}
                  data={data}
                  setData={setData}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <BootstrapTable
              bordered={true}
              bootstrap4={true}
              classes="rs-table table-layout-auto table-custom-style"
              wrapperClasses="overflow-y-auto fancy-scroll"
              keyField="id"
              data={data.filter((item) => item.groupCode === "recon-schedule")}
              columns={columns(true)}
              noDataIndication="No data found!"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <div className="title mb-3">Reporting Schedules</div>
            <BootstrapTable
              bordered={false}
              bootstrap4={true}
              classes="rs-table table-layout-auto table-custom-style"
              wrapperClasses="overflow-y-auto fancy-scroll"
              keyField="id"
              data={data.filter(
                (item) => item.groupCode === "reporting-schedule"
              )}
              columns={columns(true)}
              noDataIndication="No data found!"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="title mb-3">System Schedules</div>
            <BootstrapTable
              bordered={false}
              bootstrap4={true}
              classes="rs-table table-layout-auto table-custom-style"
              wrapperClasses="overflow-y-auto fancy-scroll"
              keyField="id"
              data={data.filter((item) => item.groupCode === "system-schedule")}
              columns={columns(false)}
              noDataIndication="No data found!"
            />
          </div>
        </div>
        <ConfirmationModal
          isShow={isDeleteModal}
          title={"Delete Scheduler"}
          message={"Are you sure you want to delete this scheduler?"}
          hideModal={() => {
            setIsDeleteModal(false);
          }}
          confirmAction={() => HandleDeleteScheduler()}
        />
      </div>
    </>
  );
};

export default Schedules;
