import React from "react";
import { Modal } from "react-bootstrap";
import ButtonBasic from "../Buttons/ButtonBasic";

export default function ConfirmationModal(props) {
  return (
    <Modal
      backdrop={true}
      size="md"
      show={props.isShow}
      onHide={() => props.hideModal()}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{props.message}</p>
      </Modal.Body>

      <Modal.Footer>
        <ButtonBasic id="noBtn" title={"No"} onClick={() => props.hideModal()} />
        <ButtonBasic id="yesBtn" title={"Yes"} onClick={() => props.confirmAction()} />
      </Modal.Footer>
    </Modal>
  );
}
