const ShowForPermission = (props) => {
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const allPermissions = userRoles?.data?.roles?.flatMap(
    (item) => item.permissions
  );
  const couldShow = allPermissions?.some(
    (item) => item.id === props.permission
  );
  return couldShow ? props.children : null;
};
export default ShowForPermission;
