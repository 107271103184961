import styled from "styled-components";

const TransactionsStyle = styled.div.attrs((props) => ({
  className: props.className,
}))``;

const RegionManagementStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .region-container {
    min-height: 100%;
    display: flex;
    overflow-x: auto;
  }

  .region-bar-title {
    font-family: ${({ theme }) => theme.fonts.family.primary.bold};
    color: ${({ theme }) => theme.text.bright};
  }

  .region-bar-wrapper {
    min-width: 350px;
    max-width: 350px;
    height: calc(100vh - 131px);
    padding-right: 20px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-right: 1px solid ${({ theme }) => theme.dropdown_border};
    overflow-y: auto;
  }

  .region-bar-title {
    padding: 15px;
  }

  .region-bar-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    padding: 0 15px;
    border-radius: 8px;
    cursor: pointer;
  }

  .region-bar-item-wrapper:hover {
    background: ${({ theme }) => theme.hover};
  }
  .region-bar-item-right {
    display: flex;
    align-items: center;
  }

  .region-bar-item-icon {
    padding: 5px;
    border-radius: 5px;
    background: ${({ theme }) => theme.card_background};
    display: none;
  }

  .region-bar-item-wrapper:hover .region-bar-item-icon {
    display: block;
  }

  .region-bar-btn-wrapper {
    margin-top: 20px;
  }

  @media (max-width: 500px) {
    .region-bar-wrapper {
      min-width: 300px;
    }
  }
`;

export { RegionManagementStyle, TransactionsStyle };
