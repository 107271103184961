import React from "react";
import Button from "react-bootstrap/Button";

import { ButtonCommonStyle } from "./ButtonsStyle";

class ButtonTransparent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { title = "Primary", icon, onClick, width, height } = this.props;
    return (
      <ButtonCommonStyle>
        <Button
          id={this.props.id}
          onClick={onClick}
          style={{
            background: "transparent",
            border: this.props.border ? "1px solid" : "none",
            color: "#118AB2",
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon && (
            <img
              width={width || 24}
              height={height || 24}
              src={icon}
              className="right-btn mr-2"
              alt="rightbtn"
            />
          )}
          <span className={`${icon && "d-xs-none d-sm-none"} d-lg-flex`}>
            {title}
          </span>
        </Button>
      </ButtonCommonStyle>
    );
  }
}

export default ButtonTransparent;
