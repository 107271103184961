import styled from "styled-components";

const SidebarStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  width: 270px;
  background: ${({ theme }) => theme.card_background};
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 101;
  overflow-y: auto;
  box-shadow: -1px 7px 6px 4px rgb(0 0 0 / 20%);

  .brand-name-wrapper {
    padding: 18px;
    .brand-name {
      font-family: ${({ theme }) => theme.fonts.family.primary.bold};
    }
  }
  ul {
    list-style: none;
    padding: 0;

    .parent-li {
      margin-top: 15px;
      &:hover {
        background: #9fa2b42b;
      }
      a.selectedItem {
        background: #9fa2b42b;
      }
      a {
        padding: 10px 15px;
        text-decoration: none;
        cursor: pointer;
        img {
          width: 21px;
          height: 21px;
        }
        span {
          font-family: "Montserrat Regular";
          margin-left: 18px;
          color: ${({ theme }) => theme.text.base};
          opacity: 1;
        }
      }
    }

    .child-li {
      list-style: disc;
      margin-left: 65px;
      padding: 10px 0;

      a {
        color: ${({ theme }) => theme.text.base};
        font-family: "Montserrat Medium";
        font-size: 14px;
        cursor: pointer;
        text-decoration: none;
      }
      a.selectedItem {
        color: #1196c2;
      }
    }
    .child-li:hover {
      a {
        color: #1196c2;
      }
    }
  }
`;

export default SidebarStyle;
