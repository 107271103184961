import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import Toast from "../Toast/Toast";
import axiosInstance from "../../utils/axiosInstance";
import logo from "../../assets/images/logo-sm.svg";
import { useHistory } from "react-router";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import queryString from "query-string";

const resetSchema = Yup.object().shape({
  otpValue: Yup.string().required(),
  newPassword: Yup.string()
    .required("password is required *")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .label("confirm password")
    .required()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

const NewResetPassword = (props) => {
  const history = useHistory();
  let params = queryString.parse(props.location?.search);
  const [resetError, setResetError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = async (values) => {
    setIsLoading(true);
    let req = {
      data: {
        otpValue: values.otpValue,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      },
    };
    await axiosInstance
      .post(
        `/users/set-password?token=${encodeURIComponent(params?.token)}`,
        req
      )
      .then((res) => {
        if (res.message.status !== "200") {
          setResetError(res.message.status);
          Toast(res.message.description, "error");
        } else {
          setIsLoading(false);
          Toast(res.message.description, "success");
          if (res.data && res.data.twoFactorAuthData.twoFactorAuthEnabled) {
            history.push("/2fa", {
              state: {
                twoFactorAuthData: res.data.twoFactorAuthData,
              },
            });
          } else {
            history.push("/login");
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setResetError(error.response.data.message.description);
        Toast(error.response.data.message.description, "error");
      });
  };

  return (
    <>
      {isLoading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div className="auth-container">
        <div className="d-flex align-items-center brand-name-wrapper">
          <img src={logo} alt="Logo" width="50" style={{ margin: "20px" }} />
        </div>
        <div id="login">
          <div className="card auth-card">
            <div className="card-body">
              <h3 className="text-center text-dark pt-5 font-weight-bold">
                Reset Password
              </h3>
              <div className="container mt-5">
                <div
                  id="login-row"
                  className="row justify-content-center align-items-center"
                >
                  <div id="login-column" className="col-md-12">
                    <div id="login-box" className="col-md-12">
                      <Formik
                        initialValues={{
                          newPassword: "",
                          confirmPassword: "",
                          otpValue: "",
                        }}
                        validationSchema={resetSchema}
                        onSubmit={(values, { resetForm }) => {
                          resetPassword(values);
                          resetForm();
                        }}
                        onReset={({ resetForm }) => resetForm()}
                      >
                        {({ errors, touched, handleSubmit, handleReset }) => (
                          <Form
                            id="reset-password"
                            className="form"
                            onSubmit={handleSubmit}
                            onReset={handleReset}
                          >
                            <div className="form-group">
                              <label htmlFor="OTPValue" className="text-info">
                                OTP:
                              </label>
                              <br />
                              <Field
                                type="text"
                                name="otpValue"
                                id="otpValue"
                                placeholder="123-123"
                                className="form-control rs-input"
                                style={{ borderRadius: "10px" }}
                              />
                              {errors.otpValue && touched.otpValue ? (
                                <div className="field-error">
                                  {errors.otpValue}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="newPassword"
                                className="text-info"
                              >
                                New Password:
                              </label>
                              <br />
                              <Field
                                id="newPassword"
                                type="password"
                                name="newPassword"
                                className="form-control rs-input"
                                placeholder="e.g ....."
                                style={{ borderRadius: "10px" }}
                              />
                              {errors.newPassword && touched.newPassword ? (
                                <div className="field-error">
                                  {errors.newPassword}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="confirmPassword"
                                className="text-info"
                              >
                                Confirm Password:
                              </label>
                              <br />
                              <Field
                                id="confirmPassword"
                                type="password"
                                name="confirmPassword"
                                className="form-control rs-input"
                                placeholder="e.g ....."
                                style={{ borderRadius: "10px" }}
                              />
                              {errors.confirmPassword &&
                              touched.confirmPassword ? (
                                <div className="field-error">
                                  {errors.confirmPassword}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <br />
                              <ButtonBasic
                                id="reset-password-button"
                                type="submit"
                                classes={"primary px-4 w-100"}
                                title={"Reset Password"}
                                onClick={handleSubmit}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                      {resetError && (
                        <div className="alert alert-danger" role="alert">
                          {resetError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(NewResetPassword);
