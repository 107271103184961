import React from "react";

const ProgressBar = ({ percentage }) => {

  return (
    <div className="progress-container">
      <div className="progress-outer">
        <div className="progress progress-bar-container">
          <div
            className={`progress-bar progress-bar-striped ${
              percentage === 100 ? "bg-success" : "active"
            }`}
            role="progressbar"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
      <div className="progress-text">{`${percentage}%`}</div>
    </div>
  );
};

export default ProgressBar;
