import React from "react";
import BadgeStyle from "./BadgeStyle";

class BadgeRounded extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const { title, color, customStyle } = this.props;
    //console.log("custom style....", customStyle);
    return (
      <BadgeStyle
        className={`tsg-badge  ${color}`}
        // onClick={toggleState}
        customStyle={customStyle}
      >
        {title}
      </BadgeStyle>
    );
  }
}

export default BadgeRounded;
