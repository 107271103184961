import React from "react";
import { Modal } from "react-bootstrap";

export default function AtmDetailModal(props) {
	return (
		<Modal
			backdrop={true}
			size="lg"
			show={props.isShow}
			onHide={() => props.hideModal()}
			aria-labelledby="example-modal-sizes-title-lg"
		>
			<Modal.Header closeButton>
				<Modal.Title>Device Detail</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="container p-2">
					<div className="row">
						<div className="col col-6 ">
							<div className="row">
								<div className="col-6">
									<label className="font-weight-bold">
										<u>Device Id:</u>
									</label>
								</div>
								<div className="col-6">{props.deviceDetail.deviceId}</div>
							</div>
						</div>
						<div className="col col-6 ">
							<div className="row">
								<div className="col-6">
									<label className="font-weight-bold">
										<u>Terminal Id:</u>
									</label>
								</div>
								<div className="col-6">{props.deviceDetail.terminalId}</div>
							</div>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col col-6">
							<div className="row">
								<div className="col-6">
									<label className="font-weight-bold">
										<u>Device Region:</u>
									</label>
								</div>
								<div className="col-6">{props.deviceDetail.deviceRegion}</div>
							</div>
						</div>
						<div className="col col-6">
							<div className="row">
								<div className="col-6">
									<label className="font-weight-bold">
										<u>CIT Terminal ID:</u>
									</label>
								</div>
								<div className="col-6">{props.deviceDetail.citTerminalId}</div>
							</div>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col col-6">
							<div className="row">
								<div className="col-6">
									<label className="font-weight-bold">
										<u>Device Location:</u>
									</label>
								</div>
								<div className="col-6">{props.deviceDetail.deviceLocation}</div>
							</div>
						</div>
						<div className="col col-6 ">
							<div className="row">
								<div className="col-6">
									<label className="font-weight-bold">
										<u>Device Name:</u>
									</label>
								</div>
								<div className="col-6">{props.deviceDetail.deviceName}</div>
							</div>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col col-6">
							<div className="row">
								<div className="col-6">
									<label className="font-weight-bold">
										<u>ATM Cash GL Account:</u>
									</label>
								</div>
								<div className="col-6">
									{props.deviceDetail.atmCashGlAccount}
								</div>
							</div>
						</div>
						<div className="col col-6">
							<div className="row">
								<div className="col-6">
									<label className="font-weight-bold">
										<u>Status:</u>
									</label>
								</div>
								<div className="col-6">{props.deviceDetail.status}</div>
							</div>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col col-6 ">
							<div className="row">
								<div className="col-6">
									<label className="font-weight-bold">
										<u>IP Port:</u>
									</label>
								</div>
								<div className="col-6">{props.deviceDetail.ipPort}</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}
