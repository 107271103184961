import React, { useState, useEffect } from "react";
import { useDateTime } from "../../components/Helper/DateTime";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import axiosInstance from "../../utils/axiosInstance";
import Toast from "../../components/Toast/Toast";
import { render } from "@testing-library/react";
const Settings = () => {
  const [loading, setLoading] = useState(true);
  const {
    settings,
    setRefresh,
    setSettings,
    setSystemSettings,
    setCurrency,
    setCurrencyName,
  } = useDateTime();
  const [useSettings, setUseSettings] = useState([]);

  const getSetting = () => {
    setLoading(true);
    setTimeout(() => {
      settings && setUseSettings([...settings]);
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    getSetting();
    setRefresh(() => () => {
      getSetting();
    });

    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, [settings]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const settingsForPayload = { data: [...useSettings] };
      const response = await axiosInstance.put("/common/settings", {
        data: {
          type: "general_settings",
          settings: JSON.stringify(settingsForPayload),
        },
      });
      if (response) {
        setSettings(useSettings);
        const currencySetting = useSettings.find(
          (setting) => setting.id === "currencySettings"
        );
        const systemSettings = useSettings.find(
          (setting) => setting.id === "systemSettings"
        );
        if (systemSettings) setSystemSettings(systemSettings);
        if (currencySetting) {
          let symbol = currencySetting.subType.find(
            (currency) => currency.id === "currencySymbol"
          );
          let code = currencySetting.subType.find(
            (currency) => currency.id === "currencyCode"
          );
          setCurrency(symbol.selectedValue);
          setCurrencyName(code.selectedValue);
          setLoading(false);
          Toast(response.message.description, "success");
        }
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      Toast(error.response.data.message.description, "error");
    }
  };

  function renderForm(
    { label, type = "text", selectedValue, values },
    index2,
    index1,
    index3,
    index4
  ) {
    return (
      <>
        <div className="col-lg-5 col-xl-6">
          <div className="info-tile value color-dark">{label}</div>
        </div>
        <div className="col-lg-6 col-xl-6 border-2 ">
          <div className="info-tile value color-dark border-rounded border border-secondary">
            {type === "dropdown" ? (
              <select
                style={{ marginTop: "-7px", width: "30%" }}
                className="form-control"
                onChange={(e) => {
                  let dummySettings = [...useSettings];
                  if (index4 !== undefined) {
                    dummySettings[index1].subType[index2].subType[
                      index3
                    ].subType[index4].selectedValue = e.target.value;
                  } else if (index3 !== undefined) {
                    dummySettings[index1].subType[index2].subType[
                      index3
                    ].selectedValue = e.target.value;
                  } else {
                    dummySettings[index1].subType[index2].selectedValue =
                      e.target.value;
                  }
                  setUseSettings(dummySettings);
                }}
                defaultValue={selectedValue}
                // style={{ width: "100%", border: "none" }}
              >
                {values.map((name) => (
                  <option key={name} className="">
                    {name}
                  </option>
                ))}
              </select>
            ) : type === "radio" || type.toLowerCase() === "checkbox" ? (
              <div className="d-flex">
                {values.map((value, idx) => (
                  <div className="mr-4" key={idx}>
                    <input
                      type={type}
                      value={value.toString()}
                      checked={value === selectedValue}
                      onChange={(e) => {
                        let dummySettings = [...useSettings];
                        if (index4 !== undefined) {
                          dummySettings[index1].subType[index2].subType[
                            index3
                          ].subType[index4].selectedValue =
                            e.target.value === "true";
                        } else if (index3 !== undefined) {
                          dummySettings[index1].subType[index2].subType[
                            index3
                          ].selectedValue = e.target.value === "true";
                        } else {
                          dummySettings[index1].subType[index2].selectedValue =
                            e.target.value === "true";
                        }
                        setUseSettings(dummySettings);
                      }}
                    />
                    <label className="ml-2">{value.toString()}</label>
                  </div>
                ))}
              </div>
            ) : (
              <input
                className="value color-dark"
                type={"text"}
                onChange={(e) => {
                  let dummySettings = [...useSettings];
                  if (index4 !== undefined) {
                    dummySettings[index1].subType[index2].subType[
                      index3
                    ].subType[index4].selectedValue = e.target.value;
                  } else if (index3 !== undefined) {
                    dummySettings[index1].subType[index2].subType[
                      index3
                    ].selectedValue = e.target.value;
                  } else {
                    dummySettings[index1].subType[index2].selectedValue =
                      e.target.value;
                  }
                  setUseSettings(dummySettings);
                }}
                value={selectedValue}
              />
            )}
          </div>
        </div>
      </>
    );
  }

  function showDefaultValue({ label, subType, selectedValue }, index2, index1) {
    if (subType && subType.length > 0) {
      return (
        <>
          <div className="col-12">
            <div className="text-dark my-4 font-weight-bolder">{label}</div>
          </div>
          {subType && subType.length > 0
            ? subType.map((setting, index3) => (
                <>
                  {setting.subType && (
                    <div className="col-12 my-3 ml-2">
                      <div className="text-dark font-weight-bolder">
                        {setting.label}
                      </div>
                    </div>
                  )}
                  {setting.subType && setting.subType.length > 0
                    ? setting.subType.map((setting2, index4) => (
                        <>
                          {setting2.type ? (
                            renderForm(setting2, index2, index1, index3, index4)
                          ) : (
                            <>
                              <div className="col-lg-6 col-xl-6">
                                <div className="info-tile value color-dark">
                                  {setting2.label}
                                </div>
                              </div>
                              <div className="col-lg-6 col-xl-6">
                                <div className="info-tile value color-dark">
                                  {setting2.selectedValue}
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ))
                    : renderForm(setting, index2, index1, index3)}
                </>
              ))
            : null}
        </>
      );
    } else {
      return (
        <>
          <div className="col-lg-6 col-xl-6">
            <div className="info-tile value color-dark">{label}</div>
          </div>
          <div className="col-lg-6 col-xl-6">
            <div className="info-tile value color-dark">{selectedValue}</div>
          </div>
        </>
      );
    }
  }

  function checkType(settings, index2, index1) {
    if (settings.type !== undefined) {
      return renderForm(settings, index2, index1);
    } else {
      return showDefaultValue(settings, index2, index1);
    }
  }

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div
        className="card-secondary"
        style={{
          opacity: !loading ? "1" : "0.07",
        }}
      >
        {useSettings?.map(({ label, subType }, index1) => (
          <>
            <div
              className="row border mb-4 border-secondary rounded p-4"
              key={index1}
            >
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    style={{ fontSize: "18px" }}
                    className="text-info mb-2 font-weight-bold"
                  >
                    {label}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    {subType.length > 0 &&
                      subType.map((setting, index2) =>
                        checkType(setting, index2, index1)
                      )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
        <div className="row d-flex justify-content-end">
          <div className="col-sm-1 col-lg-1 ">
            <button className="btn btn-lg btn-info" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
