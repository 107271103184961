export default function renderChartFunction(event, props, date, channel,destination) {
  let name = event?.data?.name?.toLowerCase() || "";
  let seriesName = event?.seriesName?.toLowerCase() || "";
  let data = {};
  let path = "";
  data.matching = "matching";
  data.business_date = "business_date";
  data.date = date;
  data.destination=destination;
  if (
    name === "balanced" ||
    seriesName === "balanced" ||
    name === "matched" ||
    seriesName === "matched"
  ) {
    data.id = 140001;
    data.channel = channel;
    path = "/transaction-log";
  } else if (name === "error" || seriesName === "error") {
    data.id = 140008;
    data.channel = channel;
    path = "/transaction-log";
  } else if (
    name === "shortage" ||
    seriesName === "shortage" ||
    name === "unmatched" ||
    seriesName === "unmatched"
  ) {
    data.id = 140002;
    data.channel = channel;
    path = "/transaction-log";
  } else if (name === "missing" || seriesName === "missing") {
    data.id = 140003;
    data.channel = channel;
    path = "/transaction-log";
  } else if (name === "Download Status" || seriesName === "Download Status") {
    data.id = 140003;
    path = "/operations";
  } else {
    path = "/matching-pairs";
  }

  props.history.push({
    pathname: path,
    state: { data },
  });
}
