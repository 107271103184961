import styled from "styled-components";

const ButtonCommonStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  button {
    background-color: ${({ theme }) => theme.chartColors.info};
    border-color: ${({ theme }) => theme.chartColors.info};
    font-family: ${({ theme }) => theme.fonts.family.primary.regular};
    cursor: pointer !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button.transparent {
    background-color: transparent !important;
    border: none;
  }
  button.transparent:focus {
    box-shadow: none;
  }
  button:hover,
  button:focus,
  button:active,
  button:not(:disabled):not(:disabled):active {
    background-color: ${({ theme }) => theme.chartColors.info};
    opacity: 0.9;
  }
`;
const ButtonBasicStyle = styled.div.attrs((props) => ({
  className: props.className,
}))``;

export { ButtonBasicStyle, ButtonCommonStyle };
