module.exports = {
  CURRENCY: "PKR",
  CURRENCYNAME: "Rupees",
  TOKEN: "Token",
  USERID: "userId",
  NAME: "name",
  USER_ROLE: "",

  transactionFlowNames: [
    { id: 1, name: "ATM EJ" },
    { id: 2, name: "Switch" },
    { id: 3, name: "Host" },
  ],
};
