export default function twoLinesChart({
  theme,
  colorPalette,
  loader,
  chartData,
  label,
  isMobile,
}) {
  let noData = true;
  chartData?.series?.forEach((series) => {
    series?.data?.forEach((data) => {
      if (noData) {
        noData = data == null;
      }
    });
  });
  const roundToNearestHundred = (value) => {
    return Math?.ceil(value / 1000) * 1000;
  };
  const chart = {
    title: {
      text:
        !loader && noData
          ? `No Data available for ${chartData?.title} `
          : chartData?.title,
      left: noData ? "center" : "left",
      top: noData ? "center" : "top",
      textStyle: {
        color: "#777",
        fontSize: "13px",
        fontFamily: "Montserrat Bold",
        overflow: "break",
        width: noData ? 200 : isMobile ? 150 : 400,
      },
    },
    tooltip: {
      trigger: "axis",
      order: "seriesDesc",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      data: noData ? [] : label,
      orient: "horizontal",
      left: "right",
      top: "top",
      icon: "circle",
      padding: [0, 50, 10, 0],
      textStyle: {
        color: "#777",
        fontFamily: "Montserrat Regular",
        fontSize: "10px",
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: noData ? [] : chartData?.xAxis?.data,
        axisLine: {
          show: noData ? false : true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        scale: true,
        min: 0,
        max: (value) => roundToNearestHundred(Math?.ceil(value?.max * 4)),
        alignTicks: true,
        splitLine: {
          show: true,
          lineStyle: {
            color: theme === "light" ? "#ccc" : "#333",
          },
        },
        axisLabel: {
          formatter: (value) => `${parseInt(value)?.toString()}`,
        },
        axisLine: {
          lineStyle: {
            color: colorPalette[1],
          },
        },
        axisPointer: {
          label: {
            formatter: (params) => {
              return `${parseInt(params?.value).toString()}`;
            },
          },
        },
      },
      {
        type: "value",
        scale: true,
        min: 0,
        max: (value) => roundToNearestHundred(Math?.ceil(value?.max * 1.2)),
        splitLine: {
          show: true,
          lineStyle: {
            color: theme === "light" ? "#ccc" : "#333",
          },
        },
        axisLabel: {
          formatter: (value) => `${parseInt(value)?.toString()}`,
        },
        axisLine: {
          lineStyle: {
            color: colorPalette[0],
          },
        },
        axisPointer: {
          label: {
            formatter: (params) => {
              return `${parseInt(params?.value)?.toString()}`;
            },
          },
        },
      },
    ],
    series: [
      {
        symbol: "circle",
        symbolSize: 8,
        itemStyle: { color: colorPalette[0] },
        name: label[0],
        yAxisIndex: 1,
        type: "line",
        lineStyle: {
          color: colorPalette[0],
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(0,175,145,100%)", // color at 0% position
              },
              {
                offset: 0.2,
                color: "rgba(0,175,145,80%)", // color at 0% position
              },
              {
                offset: 0.4,
                color: "rgba(0,175,145,60%)", // color at 0% position
              },
              {
                offset: 0.6,
                color: "rgba(0,175,145,40%)", // color at 0% position
              },
              {
                offset: 0.8,
                color: "rgba(0,175,145,20%)", // color at 0% position
              },
            ],
          },
        },
        emphasis: {
          focus: "series",
        },
        data: noData ? [] : chartData?.series?.[0]?.data,
      },
      {
        symbol: "circle",
        symbolSize: 8,
        itemStyle: { color: colorPalette[1] },
        yAxisIndex: 0,
        name: label[1],
        type: "line",
        //   color: "#00AF91",
        lineStyle: {
          color: colorPalette[1],
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,

            colorStops: [
              {
                offset: 0,
                color: "rgba(239,71,111,100%)", // color at 0% position
              },
              {
                offset: 0.2,
                color: "rgba(239,71,111,80%)", // color at 0% position
              },
              {
                offset: 0.4,
                color: "rgba(239,71,111,60%)", // color at 0% position
              },
              {
                offset: 0.6,
                color: "rgba(239,71,111,40%)", // color at 0% position
              },
              {
                offset: 0.8,
                color: "rgba(239,71,111,20%)", // color at 0% position
              },
            ],
          },
        },
        emphasis: {
          focus: "series",
        },
        animationDelay: function (idx) {
          return idx * 100;
        },
        data: noData ? [] : chartData?.series?.[1]?.data,
      },
      {
        symbol: "circle",
        symbolSize: 8,
        itemStyle: { color: colorPalette[2] },
        name: label[2],
        yAxisIndex: 0,
        type: "line",
        lineStyle: {
          color: colorPalette[2],
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(255,209,102,100%)", // color at 0% position
              },
              {
                offset: 0.2,
                color: "rgba(255,209,102,80%)", // color at 0% position
              },
              {
                offset: 0.4,
                color: "rgba(255,209,102,60%)", // color at 0% position
              },
              {
                offset: 0.6,
                color: "rgba(255,209,102,40%)", // color at 0% position
              },
              {
                offset: 0.8,
                color: "rgba(255,209,102,20%)", // color at 0% position
              },
            ],
          },
        },
        emphasis: {
          focus: "series",
        },
        data: noData ? [] : chartData?.series?.[2]?.data,
      },
      {
        symbol: "circle",
        symbolSize: 8,
        itemStyle: { color: colorPalette[3] },
        name: label[3],
        yAxisIndex: 0,
        type: "line",
        lineStyle: {
          color: colorPalette[3],
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(215,0,64,100%)", // color at 0% position
              },
              {
                offset: 0.2,
                color: "rgba(215,0,64,80%)", // color at 0% position
              },
              {
                offset: 0.4,
                color: "rgba(215,0,64,60%)", // color at 0% position
              },
              {
                offset: 0.6,
                color: "rgba(215,0,64,40%)", // color at 0% position
              },
              {
                offset: 0.8,
                color: "rgba(215,0,64,20%)", // color at 0% position
              },
            ],
          },
        },
        emphasis: {
          focus: "series",
        },
        data: noData ? [] : chartData?.series?.[3]?.data,
      },
    ],
  };
  return chart;
}
