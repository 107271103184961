import { toast } from "react-toastify";

const Toast = (message, type) => {
  switch (type) {
    case "success":
      return toast.success(message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 7000,
      });
    case "error":
      return toast.error(message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 7000,
      });
    case "warning":
      return toast.warning(message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 7000,
      });
    default:
      return toast.warning(message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 7000,
      });
  }
};

export default Toast;
