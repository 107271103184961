import React, { useCallback, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import { ErrorMessage, Field, Formik } from "formik";
import axiosInstance from "../../../utils/axiosInstance";
import Toast from "../../../components/Toast/Toast";
import * as Yup from "yup";
import Select from "react-select";
const AddUpdateAccount = ({
  showModal,
  setShowModal,
  selectedAccountID,
  setSelectedAccount,
  getAccounts,
}) => {
  const [accountTypes, setAccountTypes] = useState([]);
  const [formValues, setFormValues] = useState({
    id: null,
    name: "",
    accountNumber: "",
    description: "",
    accountType: "",
    currency: "",
    activeInd: "true",
    associatedAccounts: [],
  });
  const formValidation = Yup.object().shape({
    name: Yup.string()
      .required("Required*")
      .typeError("Required*")
      .trim()
      .matches(/^\S/, "Account Name cannot consist of only blank spaces"),
    accountNumber: Yup.string()
      .required("Required*")
      .typeError("Required*")
      .trim()
      .matches(/^\S/, "Account Number cannot consist of only blank spaces"),
    accountType: Yup.string().required("Select Account Type"),
    currency: Yup.string()
      .required("Required*")
      .typeError("Required*")
      .trim()
      .matches(/^\S/, "Currency cannot consist of only blank spaces"),
    activeInd: Yup.string().required("Required*").typeError("Required*"),
  });
  const getAccountsIndicator = async () => {
    try {
      axiosInstance
        .get("/accounts/account-types?typeIndicator=gl")
        .then((res) => {
          let indicator = res?.data?.map((i) => {
            return {
              label: i?.accountType,
              value: i?.accountId,
            };
          });
          setAccountTypes(indicator);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getSingleAccount = useCallback(async () => {
    try {
      axiosInstance.get(`/accounts/${selectedAccountID}`).then((res) => {
        setFormValues({
          ...res?.data,
          associatedAccounts: res?.data?.associatedAccounts
            ? res?.data?.associatedAccounts
            : [],

          activeInd:
            res?.data?.activeInd !== null
              ? res?.data?.activeInd === true
                ? "true"
                : "false"
              : "",
        });
      });
    } catch (e) {
      console.log(e);
    }
  }, [selectedAccountID]);

  const addUpdateAccount = (values) => {
    const payload = {
      data: {
        id: selectedAccountID ? values?.id : null,
        accountType: values?.accountType,
        name: values?.name,
        accountNumber: values?.accountNumber,
        description: values?.description,
        currency: values?.currency,
        activeInd: values?.activeInd.toString() === "true" ? true : false,
        associatedAccounts: values?.associatedAccounts,
      },
    };
    selectedAccountID
      ? axiosInstance
          .put(`/accounts/${values?.id}`, payload)
          .then((res) => {
            if (res.message.status !== "200") {
              Toast(res.message.description, "error");
            } else {
              Toast("Account Updated Successfully", "success");
              setFormValues({
                id: null,
                name: "",
                accountNumber: "",
                description: "",
                accountType: "",
                currency: "",
                activeInd: "true",
                associatedAccounts: [],
              });
              setSelectedAccount(null);
              setShowModal(false);
              getAccounts();
            }
          })
          .catch((err) => {
            Toast(err.response.data.message.description, "error");
          })
      : axiosInstance
          .post("/accounts", payload)
          .then((res) => {
            if (res.message.status !== "200") {
              Toast(res.message.description, "error");
            } else {
              Toast("Account Added Successfully", "success");
              setFormValues({
                id: null,
                name: "",
                accountNumber: "",
                description: "",
                accountType: "",
                currency: "",
                activeInd: "true",
                associatedAccounts: [],
              });
              setSelectedAccount(null);
              setShowModal(false);
              getAccounts();
            }
          })
          .catch((err) => {
            Toast(err.response.data.message.description, "error");
          });
  };

  useEffect(() => {
    getAccountsIndicator();
  }, []);
  useEffect(() => {
    if (showModal && selectedAccountID) {
      getSingleAccount();
    }
  }, [showModal, selectedAccountID, getSingleAccount]);
  return (
    <Modal
      backdrop={true}
      size="lg"
      show={showModal}
      onHide={() => {
        setSelectedAccount(null);
        setShowModal(false);
        setFormValues({
          id: null,
          name: "",
          accountNumber: "",
          description: "",
          accountType: "",
          currency: "",
          activeInd: "true",
          associatedAccounts: [],
        });
      }}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <h3 className="color-dark">
          {selectedAccountID ? "Edit Account" : "Add Account"}
        </h3>
      </Modal.Header>
      <Modal.Body scrollable="true">
        <>
          <Formik
            initialValues={formValues}
            enableReinitialize={true}
            validationSchema={formValidation}
            onSubmit={(values) => {
              addUpdateAccount(values);
            }}
          >
            {({ values, errors, setFieldValue, handleSubmit }) => (
              <Form id="add-account" className="form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-6">
                    <div className="workflowModalTitle">Account Type</div>
                    <Select
                      id="accountType"
                      options={accountTypes}
                      name="Account Type"
                      classNamePrefix={"automate"}
                      value={
                        accountTypes
                          ? accountTypes?.find(
                              (option) => option?.value === values?.accountType
                            )
                          : ""
                      }
                      onChange={(e) => setFieldValue("accountType", e.value)}
                      placeholder="Select Account Type"
                    />
                    <ErrorMessage
                      component="div"
                      name="accountType"
                      className="field-error"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mt-3">
                    <div className="workflowModalTitle">Account Name</div>
                    <Field
                      id="accountName"
                      className="form-control rs-input"
                      type="text"
                      placeholder="Account Name"
                      name="name"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      component="div"
                      name="name"
                      className="field-error"
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <div className="workflowModalTitle">Account Number</div>
                    <Field
                      id="accountNumber"
                      className="form-control rs-input"
                      type="text"
                      placeholder="Account No"
                      name="accountNumber"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      component="div"
                      name="accountNumber"
                      className="field-error"
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <div className="workflowModalTitle">Description</div>
                    <Field
                      id="accountDescription"
                      className="form-control rs-input"
                      type="text"
                      placeholder="Description"
                      name="description"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      component="div"
                      name="description"
                      className="field-error"
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <div className="workflowModalTitle">Currency</div>
                    <Field
                      id="currency"
                      className="form-control rs-input"
                      type="text"
                      placeholder="Currency"
                      name="currency"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      component="div"
                      name="currency"
                      className="field-error"
                    />
                  </div>

                  <div className="col-6 mt-3">
                    <div className="d-flex flex-column mt-2">
                      <div className="workflowModalTitle">
                        Select Active/Inactive
                      </div>
                      <Field
                        id="activeIndicator"
                        name="activeInd"
                        autocomplete="off"
                        render={({ field }) => (
                          <div className="d-flex">
                            <div className="form-check pl-1">
                              <input
                                {...field}
                                id="active"
                                value="true"
                                checked={field?.value?.toString() === "true"}
                                name="activeInd"
                                type="radio"
                              />
                              <label
                                className="form-check-label ml-1"
                                htmlFor="active"
                              >
                                Active
                              </label>
                            </div>

                            <div className="form-check">
                              <input
                                {...field}
                                id="inactive"
                                value="false"
                                name="activeInd"
                                checked={field?.value?.toString() === "false"}
                                type="radio"
                              />
                              <label
                                className="form-check-label ml-1"
                                htmlFor="inactive"
                              >
                                Inactive
                              </label>
                            </div>
                          </div>
                        )}
                      />
                      <ErrorMessage
                        component="div"
                        name="activeInd"
                        className="field-error"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <ButtonBasic
                    id="cancel-account-btn"
                    title="Cancel"
                    onClick={() => {
                      setFormValues({
                        id: null,
                        name: "",
                        accountNumber: "",
                        description: "",
                        accountType: "",
                        currency: "",
                        activeInd: true,
                        associatedAccounts: [],
                      });
                      setSelectedAccount(null);
                      setShowModal(false);
                    }}
                  />
                  <ButtonBasic
                    id="account-btn"
                    title={selectedAccountID ? "Update" : "Add"}
                    onClick={handleSubmit}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default AddUpdateAccount;
