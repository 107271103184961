export const useAuth = () => {
  const accessToken =
    JSON.parse(localStorage.getItem("userData"))?.accessToken &&
    JSON.parse(localStorage.getItem("userRoles"))?.data;
  if (accessToken) {
    return true;
  } else {
    return false;
  }
};
