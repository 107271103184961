// import axios from "axios";
// import { baseUrl } from "../configuration/apiUrl";
// import { TOKEN } from "../constants/Constants";
// import logOut from "./helpers/log-out";
// import Toast from "../components/Toast/Toast";
// var JSONbig = require("json-bigint")({ storeAsString: true });
// localStorage.setItem("isRefreshing", "false");
// let failedRequestsQueue = [];

// const axiosInstance = axios.create({
//   baseURL: baseUrl,
// });

// axiosInstance.interceptors.request.use(function (config) {
//   config.headers["Authorization"] = JSON.parse(
//     localStorage?.getItem("userData")
//   )?.accessToken
//     ? "Bearer " + JSON.parse(localStorage?.getItem("userData"))?.accessToken
//     : null;

//   config.transformResponse = [
//     (data) => {
//       // Attempt to parse the JSON response
//       let parsedData;
//       try {
//         parsedData = JSONbig.parse(data);
//       } catch (e) {
//         return data;
//       }

//       // Recursively handle all numeric values with Long
//       const transformData = (obj) => {
//         if (Array.isArray(obj)) {
//           return obj.map(transformData);
//         } else if (obj !== null && typeof obj === "object") {
//           return Object.fromEntries(
//             Object.entries(obj).map(([key, value]) => [
//               key,
//               transformData(value, key),
//             ])
//           );
//         }
//         return obj;
//       };
//       return transformData(parsedData);
//     },
//   ];

//   return config;
// });

// axiosInstance.interceptors.response.use(
//   (response) => {
//     if (response.data.message.status === "403") {
//       Toast("Permission denied. Please contact system administrator", "error");
//       return response.data;
//     }
//     if (response.data.message.status === "401") {
//       console.log("401 error...");
//       logOut();
//       return response.data;
//     }
//     if (response.data.message.status === "451") {
//       logOut(response.data.message);
//       return response.data;
//     }
//     return response.data;
//   },
//   async (error) => {
//     if (error.response.data.message.status === "401") {
//       logOut();
//     }
//     if (error.response.data.message.status === "451") {
//       logOut(error.response.data.message);
//     }
//     const originalRequest = error.config;
//     if (
//       error.response.data.message.status === "460" &&
//       !originalRequest._retry
//     ) {
//       try {
//         const accessToken = await refreshToken();
//         // Retry the failed request with the new access token
//         originalRequest.headers["Authorization"] = "Bearer " + accessToken;
//         return await axiosInstance(originalRequest);
//       } catch (err) {
//         return await Promise.reject(err);
//       }
//     }
//     if (error.response.data.message.status === "403") {
//       Toast("Permission denied. Please contact system administrator", "error");
//     }

//     return Promise.reject(error);
//   }
// );

// export const refreshToken = () => {
//   if (localStorage.getItem("isRefreshing") === "true") {
//     // If refresh token request is already in progress, return a Promise that waits for the token to be refreshed
//     return new Promise((resolve, reject) => {
//       failedRequestsQueue.push({ resolve, reject });
//     });
//   }

//   // Set the refresh token flag to prevent multiple requests
//   localStorage.setItem("isRefreshing", "true");

//   return new Promise((resolve, reject) => {
//     fetch(baseUrl + "/users/refresh-token", {
//       method: "POST",
//       headers: {
//         Authorization:
//           "Bearer " + JSON.parse(localStorage.getItem("userData")).refreshToken,
//         "Content-Type": "application/json",
//       },
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         if (res?.message?.status === "401") {
//           logOut();
//           reject(res);
//         } else if (res?.message?.status === "403") {
//           logOut();
//         } else {
//           localStorage.setItem("userData", JSON.stringify(res?.data));

//           // Set the refresh token flag to false to allow future requests
//           localStorage.setItem("isRefreshing", "false");

//           // Resolve all the pending requests
//           failedRequestsQueue?.forEach((request) => {
//             request.resolve(res?.data?.accessToken);
//           });
//           failedRequestsQueue = [];

//           // Return the new access token
//           resolve(res?.data?.accessToken);
//         }
//       })
//       .catch((err) => {
//         console.log("refresh catch error", err);

//         // Reject all the pending requests
//         failedRequestsQueue.forEach((request) => {
//           request.reject(err);
//         });
//         failedRequestsQueue = [];

//         reject(err);
//       });
//   });
// };

// export default axiosInstance;

import axios from "axios";
import { baseUrl } from "../configuration/apiUrl";
import logOut from "./helpers/log-out";
import Toast from "../components/Toast/Toast";
var JSONbig = require("json-bigint")({ storeAsString: true });

// Create a BroadcastChannel for cross-tab communication
const bc = new BroadcastChannel("auth_channel");

let failedRequestsQueue = [];
let refreshSubscribers = [];
let isRefreshing = false;
let isApiCallPaused = false; // New flag to pause API calls
let currentRefreshTimeValue = null; // Store the timeValue of the current refresh process
let otherRefreshTimeValue = null; // Store the timeValue of the other refresh process

// Listen for messages from other tabs
bc.onmessage = (event) => {
  if (event.data.type === "token_refreshed") {
    isRefreshing = false;
    isApiCallPaused = false;
    currentRefreshTimeValue = null;
    otherRefreshTimeValue = null;
    refreshSubscribers = [];
    processFailedRequestsQueue(event.data.newToken);
  } else if (event.data.type === "refresh_initiated") {
    otherRefreshTimeValue = event.data.timeValue;
    if (
      otherRefreshTimeValue !== null &&
      otherRefreshTimeValue < currentRefreshTimeValue
    ) {
      isRefreshing = false;
      isApiCallPaused = false;
    } else {
      isRefreshing = true;
      isApiCallPaused = true; // Pause API calls in other tabs
    }
  } else if (event.data.type === "logout") {
    isRefreshing = false;
    isApiCallPaused = false;
    currentRefreshTimeValue = null;
    otherRefreshTimeValue = null;
    refreshSubscribers = [];
    failedRequestsQueue = [];
    window.location.href = "/login";
  }
};

// Axios instance setup
const axiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use((config) => {
  if (isApiCallPaused) {
    return new Promise((resolve, reject) => {
      failedRequestsQueue.push({
        resolve: (token) => {
          config.headers["Authorization"] = "Bearer " + token;
          resolve(config);
        },
        reject,
      });
    });
  }

  const token = JSON.parse(localStorage.getItem("userData"))?.accessToken;
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  config.transformResponse = [
    (data) => {
      let parsedData;
      try {
        parsedData = JSONbig.parse(data);
      } catch (e) {
        return data;
      }

      const transformData = (obj) => {
        if (Array.isArray(obj)) {
          return obj.map(transformData);
        } else if (obj !== null && typeof obj === "object") {
          return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [
              key,
              transformData(value, key),
            ])
          );
        }
        return obj;
      };
      return transformData(parsedData);
    },
  ];

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.message.status === "451") {
      bc.postMessage({ type: "logout" });
      logOut(response.data.message);
      return response.data;
    }
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response?.data?.message?.status === "460" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isApiCallPaused = true; // Pause all API calls
        let newToken = "";
        try {
          const accessToken = await refreshToken();
          newToken = accessToken;
          originalRequest.headers["Authorization"] = "Bearer " + accessToken;
          return axiosInstance(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        } finally {
          if (newToken) {
            processFailedRequestsQueue(newToken);
            isApiCallPaused = false; // Resume API calls after refresh
            otherRefreshTimeValue = null;
            refreshSubscribers=[];
          }
        }
      } else {
        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            resolve: (token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              resolve(axiosInstance(originalRequest));
            },
            reject: (err) => {
              reject(err);
            },
          });
        });
      }
    }
    if (error.response?.data?.message?.status === "403") {
      Toast("Permission denied. Please contact system administrator", "error");
      return Promise.reject(error);
    }
    if (error.response?.data?.message?.status === "401") {
      bc.postMessage({ type: "logout" });
      logOut();
    }
    if (error.response?.data?.message?.status === "451") {
      bc.postMessage({ type: "logout" });
      logOut(error.response?.data?.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

const processFailedRequestsQueue = (newToken) => {
  failedRequestsQueue.forEach((request) => {
    request.resolve(newToken);
  });
  failedRequestsQueue = [];
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

export const refreshToken = async () => {
  if (isRefreshing) {
    return new Promise((resolve) => {
      addRefreshSubscriber(resolve);
    });
  }
  const timeValue = new Date().getTime();
  currentRefreshTimeValue = timeValue; // Store the timeValue of this refresh process
  // Lock the refresh process for other tabs
  isRefreshing = true;
  bc.postMessage({
    type: "refresh_initiated",
    timeValue: timeValue,
  });
  try {
    const response = await axios.post(baseUrl + "/users/refresh-token", null, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("userData")).refreshToken,
        "Content-Type": "application/json",
      },
    });

    const { accessToken, refreshToken } = response.data.data;

    if (
      response.data.message.status === "401" ||
      response.data.message.status === "403"
    ) {
      if (response.data.message.status === "403") {
        Toast(
          "Permission denied. Please contact the system administrator",
          "error"
        );
      }
      bc.postMessage({ type: "logout" });
      logOut();
    }

    // Store the new tokens locally
    const userData = JSON.parse(localStorage.getItem("userData"));
    userData.accessToken = accessToken;
    userData.refreshToken = refreshToken;
    localStorage.setItem("userData", JSON.stringify(userData));
    isRefreshing = false;
    processFailedRequestsQueue(accessToken);
    // Wait for 2 Seconds
    setTimeout(() => {
      bc.postMessage({ type: "token_refreshed", newToken: accessToken });
    }, 2000);
    refreshSubscribers = [];
    return accessToken;
  } catch (error) {
    if (
      error.response?.data?.message?.status === "401" ||
      error.response?.data?.message?.status === "403"
    ) {
      if (error.response?.data?.message?.status === "403") {
        Toast(
          "Permission denied. Please contact the system administrator",
          "error"
        );
      }
      isApiCallPaused = false;
      otherRefreshTimeValue = null;
      refreshSubscribers = [];
      bc.postMessage({ type: "logout" });
      logOut();
    }
    throw error;
  }
};
