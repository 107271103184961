import React, { createRef } from "react";
import "../../App.css";
import ReactECharts from "echarts-for-react";
import SingleInfoCard from "../../components/SingleInfoCard/SingleInfoCard";
import $ from "jquery";
import FilterDropdown from "../../components/FilterDropdown/FilterDropdown";
// import { baseUrl } from "../../configuration/apiUrl";
import axiosInstance from "../../utils/axiosInstance";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import { Tabs, Tab } from "react-bootstrap";
import TransactionCategorization from "./POS charts/TransactionCategorization";
import DeclineTransaction from "./POS charts/DeclineTransaction";
import Disputes from "./POS charts/Disputes";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import FilterIcon from "../../assets/images/filter.png";
import Filter from "../../components/FilterDropdown/GenericFilter";
import withDateTime from "../../utils/timedatehoc";
import { regionCheck, mapdata } from "../../utils/regionHelper";
import pieChart from "../../components/Charts/pieChart";
import barWithLineChart from "../../components/Charts/barWithLineChart";
import horizontalBarChart from "../../components/Charts/horizontalBarChart";
import {
  filtersParams,
  mapFilters,
  handleFilterChange,
} from "../../utils/filtersHelper";
import { colorPalette,PASTEL_PALETTE,REVERSE_PASTEL,YELLOW_PALETTE } from "../../constants/ColorPalette";

class PosDashboard extends React.Component {
  constructor(props) {
    super(props);
    var { theme } = this.props;
    this.filter = createRef();
    this.state = {
      filterParams: {},
      filter: {},
      business_date_default: "",
      POSDashboardAll: true,
      locationFilterData: {
        text: "Location",
        data: [
          {
            label: "On Site",
            value: "On Site",
            checked: true,
            isSearched: true,
          },
          {
            label: "Off Site",
            value: "Off Site",
            checked: true,
            isSearched: true,
          },
        ],
      },
      atmTypeFilterData: {
        text: "POS Type",
        data: [
          {
            label: "Cash Dispense",
            value: "Cash Dispense",
            checked: true,
            isSearched: true,
          },
          {
            label: "Cash Dispense",
            value: "Cash Dispense",
            checked: true,
            isSearched: true,
          },
        ],
      },

      atmKPITiles: {},

      topCashDispensedOptionsLoading: true,
      topCashDispensedOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      topCashDispensedCountOptionsLoading: true,
      topCashDispensedCountOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      topExceptionsOptionsLoading: true,
      topExceptionsOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      leastCashDispensedOptionsLoding: true,
      leastCashDispensedOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      leastCashDispensedCountOptionsLoading: true,
      leastCashDispensedCountOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      leastExceptionsOptionsLoading: true,
      leastExceptionsOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      peakTimeOptionLoading: true,
      peakTimeOptionData: {
        title: "",
        xAxis: {
          data: [],
        },
        series: [
          {
            data: [],
          },
          {
            data: [],
          },
        ],
      },

      cashSummaryOptionsLoading: true,
      cashSummaryOptionsData: {
        title: "",
        xAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      balancingSummaryOptionsLoading: true,
      balancingSummaryOptionsData: {
        title: "",
        data: [
          { value: 0, name: "Balanced" },
          { value: 0, name: "Unbalanced" },
        ],
      },

      matchingSummaryLoading: true,
      matchingSummaryData: {
        title: "",
        data: [
          { value: 0, name: "Matched" },
          { value: 0, name: "UnMatched" },
          { value: 0, name: "Missing" },
          { value: 0, name: "Error" },
        ],
      },

      transactionDestinationOptionsLoading: true,
      transactionDestinationOptionsData: {
        title: "",
        yAxis: {
          data: [],
        },
        series: {
          data: [],
        },
      },

      suspenseAccountOptionsLoading: true,
      suspenseAccountOptionsData: {
        title: "",
        data: [],
      },

      atmProfitabilityLoading: true,
      atmProfitabilityData: {
        title: {
          text: "",
          // subtext: `${CURRENCY} 10,000`,
          textStyle: {
            color: "#777",
            fontSize: "14px",
            fontFamily: "Montserrat Bold",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          left: "2%",
          top: "20%",
          bottom: "10%",
        },
        xAxis: [
          {
            show: false,
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            show: false,
            type: "value",
            data: ["0", "300", "600", "900", "1200", "1500", "1800"],
            splitLine: {
              lineStyle: {
                color: theme === "light" ? "#ccc" : "#333",
              },
            },
            axisLine: {
              lineStyle: {
                opacity: "0.8",
              },
            },
          },
        ],
        series: [
          {
            symbol: "circle",
            // animationDuration: 91000,
            symbolSize: 8,
            itemStyle: {
              color: "rgb(17,138,178)",
              fontFamily: "Montserrat Regular",
            },
            name: "Cash Utilization",
            type: "line",
            lineStyle: {
              color: "rgb(17,138,178)",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(17,138,178,100%)", // color at 0% position
                  },
                  {
                    offset: 0.2,
                    color: "rgba(17,138,178,80%)", // color at 0% position
                  },
                  {
                    offset: 0.4,
                    color: "rgba(17,138,178,60%)", // color at 0% position
                  },
                  {
                    offset: 0.6,
                    color: "rgba(17,138,178,40%)", // color at 0% position
                  },
                  {
                    offset: 0.8,
                    color: "rgba(17,138,178,20%)", // color at 0% position
                  },
                ],
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
        ],
      },
      regions: [],
      filters: {},
      filterData: [],
      deviceId: 0,
      gotResponse: false,
    }; // state end
  }
  getData() {
    this.setState(
      {
        atmProfitabilityLoading: true,
        matchingSummaryLoading: true,
        balancingSummaryOptionsLoading: true,
        cashSummaryOptionsLoading: true,
        peakTimeOptionLoading: true,
        leastExceptionsOptionsLoading: true,
        leastCashDispensedCountOptionsLoading: true,
        leastCashDispensedOptionsLoding: true,
        topExceptionsOptionsLoading: true,
        topCashDispensedCountOptionsLoading: true,
        topCashDispensedOptionsLoading: true,
      },
      async () => {
        let filter = filtersParams(this.state.filters, this.filter.current);
        await this.getAtmKpiTiles(filter);
        await this.getTopATM(filter);
        await this.getBottomATM(filter);
        await this.getAtmPeakTime(filter);
        await this.getTransactionsummary(filter);
        await this.getCashSummary(filter);
        await this.getAtmReconCharts(filter);
        await this.getTransactionDestination(filter);
        await this.getSuspenseSummary(filter);
      }
    );
  }
  componentDidMount() {
    $(".count-num").each(function () {
      var $this = $(this);
      $({ Counter: 0 }).animate(
        { Counter: $this.text() },
        {
          duration: 2000,
          easing: "swing",
          step: function () {
            $this.text(Math.ceil(this.Counter));
          },
        }
      );
    });
    // this.getData();
    this.getFilters();
    this.props.dateTime.setRefresh(() => () => {
      this.getData();
    });
  }
  componentWillUnmount() {
    this.props.dateTime.setRefresh(() => () => {});
  }

  async getFilters() {
    try {
      const res = await axiosInstance.get(
        `/hierarchical/filters?screen_id=pos_dashboard`
      );
      // debugger;
      let regions = res.data.filters.filter(
        ({ filterName }) => filterName === "region"
      );
      let otherFilters = res.data.filters.filter(
        ({ filterName }) => filterName !== "region"
      );
      let obj = mapFilters(res.data.filters);
      let filter = "";
      if (regions.length > 0) {
        regions[0].filterData = mapdata(regions[0].filterData);
        regions[0].filterData.forEach((x) => {
          x.checked = x.param1 === "1" ? true : false;
        });
        filter = `&region_id=${regions[0].filterData
          .map(({ codeValue }) => codeValue)
          .join(",")}`;
        this.filter.current = filter;
      }
      this.setState(
        {
          filterData: otherFilters,
          filters: obj,
          regions,
          gotResponse: true,
        },
        () => {
          this.getData();
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  async getAtmKpiTiles(url) {
    try {
      const response = await axiosInstance.get(
        `/charts/atm-kpi-tiles?channel=pos${url}`
      );
      // console.log("response atm kpi tiles ", response);
      this.setState({
        atmKPITiles:
          response.data && response.data && response.data.atmKPITiles
            ? response.data.atmKPITiles
            : {},
      });
    } catch (err) {
      console.log("/atm_kpi_tiles response error ", err);
    }
  }

  async getTransactionDestination(url) {
    try {
      const { transactionDestinationOptionsData } = this.state;
      const response = await axiosInstance.get(
        `/charts/transaction-destinations?channel=pos${url}`
      );

      const { atmTransactionDestinationDto, header } = response?.data;

      // transaction destination chart
      transactionDestinationOptionsData.yAxis.data = [];
      transactionDestinationOptionsData.series.data = [];
      transactionDestinationOptionsData.title = header.title;
      atmTransactionDestinationDto?.forEach((obj) => {
        transactionDestinationOptionsData.yAxis.data.push(obj.destination);
        transactionDestinationOptionsData.series.data.push(obj.count);
      });
      this.setState({
        transactionDestinationOptionsData,
        transactionDestinationOptionsLoading: false,
      });
    } catch (error) {
      console.log("transaction destination error", error);
      this.setState({ transactionDestinationOptionsLoading: false });
    }
  }

  async getSuspenseSummary(url) {
    try {
      const { suspenseAccountOptionsData } = this.state;
      const response = await axiosInstance.get(
        `/charts/suspense-summary?channel=pos${url}`
      );
      const { suspenseAccountsummary, header } = response.data;

      // converting object into array
      let data = Object.entries(
        suspenseAccountsummary.suspenseAccountsummary
      )?.map((entry) => ({
        name: entry[0],
        value: entry[1],
      }));
      suspenseAccountOptionsData.title = header?.title;
      suspenseAccountOptionsData.data = data;
      this.setState({
        suspenseAccountOptionsData,
        suspenseAccountOptionsLoading: false,
      });
    } catch (error) {
      console.log("suspense summary error", error);
      this.setState({ suspenseAccountOptionsLoading: false });
    }
  }

  // When click on Filter Button
  // appFilter() {
  //   const params = this.generateParams();
  //   this.getTopATM(params)
  // }

  async getTopATM(url) {
    try {
      const {
        topCashDispensedOptionsData,
        topCashDispensedCountOptionsData,
        topExceptionsOptionsData,
      } = this.state;

      const response = await axiosInstance.get(
        `/charts/top-charts?channel=pos${url}`
      );
      const { cashDispensedAmount, cashDispensedCount, topException } =
        response?.data?.topCharts;

      // for setting cash dispensed amount chart
      topCashDispensedOptionsData.title = cashDispensedAmount.header.title;
      cashDispensedAmount.chart.forEach((obj) => {
        topCashDispensedOptionsData.yAxis.data.push(obj.label);
        topCashDispensedOptionsData.series.data.push(obj.value);
      });

      // for setting top cash dispensed count
      topCashDispensedCountOptionsData.title = cashDispensedCount.header.title;
      cashDispensedCount.chart.forEach((obj) => {
        topCashDispensedCountOptionsData.yAxis.data.push(obj.label);
        topCashDispensedCountOptionsData.series.data.push(obj.value);
      });

      // for setting top exceptions
      topExceptionsOptionsData.title = topException.header.title;
      topException.chart.forEach((obj) => {
        topExceptionsOptionsData.yAxis.data.push(obj.label);
        topExceptionsOptionsData.series.data.push(obj.value);
      });

      this.setState({
        topCashDispensedOptionsData,
        topCashDispensedCountOptionsData,
        topExceptionsOptionsData,
        topCashDispensedOptionsLoading: false,
        topCashDispensedCountOptionsLoading: false,
        topExceptionsOptionsLoading: false,
      });
      // this.setState({ atmKPITiles: response.data && response.data.data && response.data.data.atmKPITiles ? response.data.data.atmKPITiles: {} })
    } catch (err) {
      console.log("/api/charts/top_atms response error ", err);
    }
  }

  async getBottomATM(url) {
    try {
      const {
        leastCashDispensedOptionsData,
        leastCashDispensedCountOptionsData,
        leastExceptionsOptionsData,
      } = this.state;
      const response = await axiosInstance.get(
        `/charts/bottom-charts?channel=pos${url}`
      );
      // console.log("response bottom atm response ", response);

      const { cashDispensedAmount, cashDispensedCount, exception } =
        response.data.bottomCharts;

      // for setting cash dispensed amount chart
      leastCashDispensedOptionsData.title = cashDispensedAmount.header.title;
      cashDispensedAmount.chart.forEach((obj) => {
        leastCashDispensedOptionsData.yAxis.data.push(obj.label);
        leastCashDispensedOptionsData.series.data.push(obj.value);
      });

      // for setting top cash dispensed count
      leastCashDispensedCountOptionsData.title =
        cashDispensedCount.header.title;
      cashDispensedCount.chart.forEach((obj) => {
        leastCashDispensedCountOptionsData.yAxis.data.push(obj.label);
        leastCashDispensedCountOptionsData.series.data.push(obj.value);
      });

      // for setting top exceptions
      leastExceptionsOptionsData.title = exception.header.title;
      exception.chart.forEach((obj) => {
        leastExceptionsOptionsData.yAxis.data.push(obj.label);
        leastExceptionsOptionsData.series.data.push(obj.value);
      });

      this.setState({
        leastCashDispensedOptionsData,
        leastCashDispensedCountOptionsData,
        leastExceptionsOptionsData,
        leastCashDispensedOptionsLoding: false,
        leastCashDispensedCountOptionsLoading: false,
        leastExceptionsOptionsLoading: false,
      });
    } catch (err) {
      console.log("/api/charts/BottomATMs response error ", err);
    }
  }

  async getAtmPeakTime(url) {
    try {
      const { peakTimeOptionData } = this.state;
      const response = await axiosInstance.get(
        `/charts/peak-times?channel=pos${url}`
      );

      const { atmPeakTimes } = response.data;
      //console.log("atm peak time ", atmPeakTimes);

      peakTimeOptionData.title = atmPeakTimes.header.title;
      peakTimeOptionData.xAxis.data = [];
      peakTimeOptionData.series[0].data = [];
      peakTimeOptionData.series[1].data = [];
      atmPeakTimes?.chart?.forEach((obj) => {
        peakTimeOptionData.xAxis.data.push(obj.time);
        peakTimeOptionData.series[0].data.push(obj.totalCount);
        peakTimeOptionData.series[1].data.push(obj.totalCount);
      });

      this.setState({ peakTimeOptionData, peakTimeOptionLoading: false });
    } catch (err) {
      console.log("/api/charts/atm_peak_times response error ", err);
    }
  }

  async getAtmReconCharts(url) {
    try {
      const { matchingSummaryData } = this.state;

      // previous day summary
      try {
        const response = await axiosInstance.get(
          `/charts/matching-summary?channel=pos${url}`
        );
        matchingSummaryData.data = [];
        matchingSummaryData.title = response.data?.header?.title;
        matchingSummaryData?.data?.push({
          name: "matched",
          value: response.data.chart.matchedCount,
        });
        matchingSummaryData?.data?.push({
          name: "unmatched",
          value: response.data.chart.unmatchedCount,
        });
        matchingSummaryData?.data?.push({
          name: "missing",
          value: response.data.chart.missingCount,
        });
        matchingSummaryData?.data?.push({
          name: "error",
          value: response.data.chart.errorCount,
        });
      } catch (error) {
        console.log("matching summary charts error", error);
      }

      this.setState({
        matchingSummaryData,
        matchingSummaryLoading: false,
      });
    } catch (err) {
      console.log("err ", err);
      this.setState({
        matchingSummaryLoading: false,
      });
    }
  }

  async getTransactionsummary(url) {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axiosInstance.get(
        `charts/transaction-summary?channel=pos${url}`
      );
      // console.log("response transaction summary response ", response);
      // this.setState({ atmKPITiles: response.data && response.data.data && response.data.data.atmKPITiles ? response.data.data.atmKPITiles: {} })
    } catch (err) {
      console.log("/api/charts/transaction_summary response error ", err);
    }
  }

  async getCashSummary(url) {
    try {
      const { cashSummaryOptionsData } = this.state;
      const response = await axiosInstance.get(
        `/charts/cash-summary?channel=pos${url}`
      );
      // console.log("response cash summary response ", response);
      const { cashSummaryChart } = response.data.cashSummary;

      cashSummaryOptionsData.title = cashSummaryChart.header.title;
      cashSummaryChart.chart.forEach((obj) => {
        cashSummaryOptionsData.xAxis.data.push(obj.date);
        cashSummaryOptionsData.series.data.push(obj.amount);
      });
      this.setState(
        { cashSummaryOptionsData, cashSummaryOptionsLoading: false }
        // console.log("cashSummaryOptionsData state: ", cashSummaryOptionsData)
      );
      // this.setState({ atmKPITiles: response.data && response.data.data && response.data.data.atmKPITiles ? response.data.data.atmKPITiles: {} })
    } catch (err) {
      console.log("/api/charts/cash_summary response error ", err);
    }
  }

  handleFilters = async () => {
    this.getData();
  };
  setRegionIds = (value) => {
    // this.setState({ regionFilters: `region_id=${data.join(",")}&` });
    if (value?.length > 0) {
      // console.log(value);
      this.filter.current = `&region_id=${value
        .map((node) => node.codeValue)
        .join(",")}`;
    } else {
      this.filter.current = "";
    }
    // ******** don't remove this code. commented for future use **********
    // const regions = this.state.regions;
    // regions.filterData = regionCheck(this.state.regions[0].filterData, value);
    // this.setState({ regions });
  };

  setFilter = (filters) => {
    this.setState({ filters });
  };

  render() {
    // const colorPalette = ["#00AF91", "#EF476F", "#FFD166", "#118AB2"];
    // const negativeColorPalette = ["#EF476F", "#52575D", "#845ec2", "#142D4C"];
    // const PASTEL_PALETTE = [
    //   "#FA8072",
    //   "#FFD700",
    //   "#98FB98",
    //   "#AFEEEE",
    //   "#FFC0CB",
    //   "#FFFACD",
    //   "#98FF98",
    //   "#E0BBE4",
    //   "#FFD700",
    //   "#E6E6FA",
    //   "#FFDAB9",
    //   "#FF6F61",
    //   "#C8A2C8",
    //   "#87CEEB",
    // ];
    // const REVERSE_PASTEL = [
    //   "#87CEEB",
    //   "#C8A2C8",
    //   "#FF6F61",
    //   "#FFDAB9",
    //   "#E6E6FA",
    //   "#FFD700",
    //   "#E0BBE4",
    //   "#98FF98",
    //   "#FFFACD",
    //   "#FFC0CB",
    //   "#AFEEEE",
    //   "#98FB98",
    //   "#FFD700",
    //   "#FA8072"
    // ];
    // const YELLOW_PALETTE = [
    //   "#FFCA08",
    //   "#F8931D",
    //   "#CE8D3E",
    //   "#EC7016",
    //   "#9C6A6A",
    // ];
    const {
      peakTimeOptionData,
      peakTimeOptionLoading,
      matchingSummaryData,
      matchingSummaryLoading,
      transactionDestinationOptionsData,
      transactionDestinationOptionsLoading,
      suspenseAccountOptionsData,
      suspenseAccountOptionsLoading,
      topCashDispensedOptionsData,
      topCashDispensedOptionsLoading,
      topCashDispensedCountOptionsData,
      topCashDispensedCountOptionsLoading,
      topExceptionsOptionsData,
      topExceptionsOptionsLoading,
      leastCashDispensedOptionsData,
      leastCashDispensedOptionsLoading,
      leastCashDispensedCountOptionsData,
      leastCashDispensedCountOptionsLoading,
      leastExceptionsOptionsData,
      leastExceptionsOptionsLoading,
    } = this.state;
    var { theme } = this.props;
    const transactionSummaryOptions = {
      title: {
        // text: 'Cash Summary',
        textStyle: {
          color: "#777",
          fontSize: "14px",
          fontFamily: "Montserrat Bold",
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      legend: {
        data: ["Volume"],
        orient: "horizontal",
        left: "right",
        top: "top",
        icon: "circle",
        padding: [10, 50, 10, 0],
        textStyle: {
          color: "#777",
          fontFamily: "Montserrat Regular",
          fontSize: "10px",
        },
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: [
            "20 March",
            "21 March",
            "22 March",
            "23 March",
            "24 March",
            "25 March",
            "26 March",
          ],
        },
      ],
      yAxis: [
        {
          min: 0,
          max: 100000,
          type: "value",
          data: ["0", "300", "600", "900", "1200", "1500", "1800"],
          splitLine: {
            lineStyle: {
              color: theme === "light" ? "#ccc" : "#333",
            },
          },
          axisLine: {
            lineStyle: {
              opacity: "0.8",
            },
          },
        },
      ],
      series: [
        {
          symbol: "circle",
          symbolSize: 8,
          itemStyle: {
            color: "rgb(17,138,178)",
            fontFamily: "Montserrat Regular",
          },
          name: "Volume",
          stack: "总量",
          type: "line",
          lineStyle: {
            color: "rgb(17,138,178)",
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(17,138,178,100%)", // color at 0% position
                },
                {
                  offset: 0.2,
                  color: "rgba(17,138,178,80%)", // color at 0% position
                },
                {
                  offset: 0.4,
                  color: "rgba(17,138,178,60%)", // color at 0% position
                },
                {
                  offset: 0.6,
                  color: "rgba(17,138,178,40%)", // color at 0% position
                },
                {
                  offset: 0.8,
                  color: "rgba(17,138,178,20%)", // color at 0% position
                },
              ],
            },
          },
          emphasis: {
            focus: "series",
          },
          data: [42720, 43890, 35251, 36134, 22190, 35260, 33290],
        },
      ],
    };
    const miniChartOptions = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
      },
      grid: {
        left: "2%",
        top: "0%",
      },
      xAxis: [
        {
          show: false,
          type: "category",
          boundaryGap: false,
          data: [
            "20 March",
            "21 March",
            "22 March",
            "23 March",
            "24 March",
            "25 March",
            "26 March",
          ],
        },
      ],
      yAxis: [
        {
          show: false,
          type: "value",
          data: ["0", "300", "600", "900", "1200", "1500", "1800"],
          splitLine: {
            lineStyle: {
              color: theme === "light" ? "#ccc" : "#333",
            },
          },
          axisLine: {
            lineStyle: {
              opacity: "0.8",
            },
          },
        },
      ],
      series: [
        {
          symbol: "circle",
          // animationDuration: 91000,
          symbolSize: 8,
          itemStyle: {
            color: "rgb(17,138,178)",
            fontFamily: "Montserrat Regular",
          },
          name: "Cash Utilization",
          type: "line",
          lineStyle: {
            color: "rgb(17,138,178)",
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(17,138,178,100%)", // color at 0% position
                },
                {
                  offset: 0.2,
                  color: "rgba(17,138,178,80%)", // color at 0% position
                },
                {
                  offset: 0.4,
                  color: "rgba(17,138,178,60%)", // color at 0% position
                },
                {
                  offset: 0.6,
                  color: "rgba(17,138,178,40%)", // color at 0% position
                },
                {
                  offset: 0.8,
                  color: "rgba(17,138,178,20%)", // color at 0% position
                },
              ],
            },
          },
          emphasis: {
            focus: "series",
          },
          data: [32220, 33200, 43251, 32134, 32190, 42260, 33290],
        },
      ],
    };

    const getTransactionsummary = () => {
      return transactionSummaryOptions;
    };
    const getMiniChart = () => {
      return miniChartOptions;
    };
    const { regions, filterData, filters } = this.state;
    return (
      <>
        {!this.state.gotResponse && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div style={{ opacity: this.state.gotResponse ? "1" : "0.07" }}>
          <div className="row align-items-center d-xs-none d-lg-flex">
            {this.state.gotResponse && (
              <>
                {regions &&
                  regions.map((region) => {
                    return (
                      <div className="filter-item col-sm-6 col-lg-2 mt-2">
                        <FilterDropdown
                          title={region.filterLabel}
                          type="checkbox"
                          data={region.filterData}
                          onDropdownChange={(value) => this.setRegionIds(value)}
                        />
                      </div>
                    );
                  })}
                {filterData.map(({ filterName, filterType, filterLabel }) => (
                  <div
                    className="filter-item col-sm-6 col-lg-3 mt-2"
                    style={{ maxWidth: "220px" }}
                  >
                    <Filter
                      title={filterLabel}
                      type={filterType}
                      data={filters[filterName]?.data}
                      filter={filters[filterName]?.filter}
                      onChange={(e) =>
                        handleFilterChange(
                          e,
                          filterName,
                          filters,
                          this.setFilter
                        )
                      }
                    />
                  </div>
                ))}

                <div className="col d-flex justify-content-start mt-2">
                  <ButtonBasic
                    icon={FilterIcon}
                    title="Filter"
                    onClick={this.handleFilters}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <SingleInfoCard
                title={"Number of POS Devices"}
                value={"1600"}
                per={""}
                perDown={false}
              />
            </div>
            <div className="col-sm-6 col-lg-3">
              <SingleInfoCard
                title={"Purchase Count"}
                value={"14210"}
                per={"10%"}
                perDown={false}
              />
            </div>
            <div className="col-sm-6 col-lg-3">
              <SingleInfoCard
                title={"Refund Count"}
                value={"500"}
                per={"30%"}
                perDown={true}
              />
            </div>
            <div className="col-sm-6 col-lg-3">
              <SingleInfoCard
                title={"Chargebacks/Returned Payments"}
                value={"10"}
                per={"30%"}
                perDown={true}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="mt-3">
                <SingleInfoCard>
                  <Tabs
                    defaultActiveKey="POSDashboardAll"
                    transition={false}
                    id="POS-dashboard"
                    className="mb-3"
                  >
                    <Tab eventKey="POSDashboardAll" title="All" unmountOnExit>
                      <div className="transaction-summary-wrapper">
                        <ReactECharts
                          onEvents={this.onChartClick}
                          option={getTransactionsummary()}
                          style={{
                            textTransform: "capitalize",
                            height: "300px",
                            width: "100%",
                            background:
                              theme === "light" ? "#fcfcfc" : "#1d1d1d",
                            borderRadius: "17px",
                            color: "#ccc",
                          }}
                          opts={{ renderer: "svg" }}
                        />
                        <div className="graph-sm-container">
                          <div className="row">
                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  On-us Transactions
                                </div>
                                <div>
                                  <div className="graph-sm-number">
                                    {/* {CURRENCY} 20,000 */}
                                    {this.props?.dateTime?.currency} 20,000
                                  </div>{" "}
                                  <div className="graph-sm-number-sub">
                                    Count 200
                                  </div>
                                </div>

                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  Not On-us Transactions
                                </div>

                                <div className="s">
                                  <div className="graph-sm-number">
                                    {this.props?.dateTime?.currency} 2,000
                                  </div>
                                  <div className="graph-sm-number-sub">
                                    Count 200
                                  </div>
                                </div>
                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  Declined Transactions
                                </div>

                                <div>
                                  <div className="graph-sm-number">
                                    {/* {CURRENCY} 3,000 */}
                                    {this.props?.dateTime?.currency} 3,000
                                    <div className="graph-sm-number-sub">
                                      Count 200
                                    </div>
                                  </div>
                                  {/* <div className="graph-sm-number">100</div> */}
                                </div>
                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  Approved Transactions
                                </div>
                                {/* <div className="d-flex justify-content-between mr-3">
                                <div className="mt-3 color-gray-bright graph-label-sm">
                                  Amount
                                </div>
                                <div className="mt-3 color-gray-bright graph-label-sm">
                                  Count
                                </div>
                              </div> */}
                                <div className="graph-sm-number">
                                  {this.props?.dateTime?.currency} 10,000
                                  <div className="graph-sm-number-sub">
                                    Count 200
                                  </div>
                                </div>
                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="row">
                        <div className="col-3">
                          <div className="graph-sm-wrapper">
                            <div className="graph-sm-title">
                              On-us Transactions Count
                            </div>
                            <div className="graph-sm-number">200</div>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="graph-sm-wrapper">
                            <div className="graph-sm-title">
                              Declined Transactions Count
                            </div>
                            <div className="graph-sm-number">100</div>
                          </div>
                        </div>

                        <div className="col-3">
                          <div className="graph-sm-wrapper">
                            <div className="graph-sm-title">
                              Not On-us Transactions Count
                            </div>
                            <div className="graph-sm-number">40</div>
                          </div>
                        </div>

                        <div className="col-3">
                          <div className="graph-sm-wrapper">
                            <div className="graph-sm-title">
                              Approved Transactions Count
                            </div>
                            <div className="graph-sm-number">1000</div>
                          </div>
                        </div>
                      </div> */}
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="POSDashboardVisa" title="Visa" unmountOnExit>
                      <div className="transaction-summary-wrapper">
                        <ReactECharts
                          onEvents={this.onChartClick}
                          option={getTransactionsummary()}
                          style={{
                            textTransform: "capitalize",
                            height: "300px",
                            width: "100%",
                            background:
                              theme === "light" ? "#fcfcfc" : "#1d1d1d",
                            borderRadius: "17px",
                            color: "#ccc",
                          }}
                          opts={{ renderer: "svg" }}
                        />
                        <div className="graph-sm-container">
                          <div className="row">
                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  On-us Transactions
                                </div>
                                <div>
                                  <div className="graph-sm-number">
                                    {/* {CURRENCY} 20,000 */}
                                    {this.props?.dateTime?.currency} 30,000
                                  </div>{" "}
                                  <div className="graph-sm-number-sub">
                                    Count 200
                                  </div>
                                </div>

                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  Not On-us Transactions
                                </div>

                                <div className="d-flex justify-content-between mr-3">
                                  <div className="graph-sm-number">
                                    {this.props?.dateTime?.currency} 4,000
                                  </div>
                                </div>
                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  Declined Transactions
                                </div>

                                <div className="d-flex justify-content-between mr-3">
                                  <div className="graph-sm-number">
                                    {/* {CURRENCY} 3,000 */}
                                    {this.props?.dateTime?.currency} 8,000
                                  </div>
                                  {/* <div className="graph-sm-number">100</div> */}
                                </div>
                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  Approved Transactions
                                </div>

                                <div className="graph-sm-number">
                                  {this.props?.dateTime?.currency} 16,000
                                </div>
                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="POSDashMasterCard"
                      title="Mastercard"
                      unmountOnExit
                    >
                      <div className="transaction-summary-wrapper">
                        <ReactECharts
                          onEvents={this.onChartClick}
                          option={getTransactionsummary()}
                          style={{
                            textTransform: "capitalize",
                            height: "300px",
                            width: "100%",
                            background:
                              theme === "light" ? "#fcfcfc" : "#1d1d1d",
                            borderRadius: "17px",
                            color: "#ccc",
                          }}
                          opts={{ renderer: "svg" }}
                        />
                        <div className="graph-sm-container">
                          <div className="row">
                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  On-us Transactions
                                </div>
                                <div>
                                  <div className="graph-sm-number">
                                    {/* {CURRENCY} 20,000 */}
                                    {this.props?.dateTime?.currency} 70,000
                                  </div>{" "}
                                  <div className="graph-sm-number-sub">
                                    Count 200
                                  </div>
                                </div>

                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  Not On-us Transactions
                                </div>

                                <div className="d-flex justify-content-between mr-3">
                                  <div className="graph-sm-number">
                                    {this.props?.dateTime?.currency} 5,000
                                  </div>
                                  <div className="graph-sm-number-sub">
                                    Count 200
                                  </div>
                                </div>
                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  Declined Transactions
                                </div>

                                <div className="d-flex justify-content-between mr-3">
                                  <div className="graph-sm-number">
                                    {/* {CURRENCY} 3,000 */}
                                    {this.props?.dateTime?.currency} 8,000
                                  </div>
                                  {/* <div className="graph-sm-number">100</div> */}
                                </div>
                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  Approved Transactions
                                </div>

                                <div className="graph-sm-number">
                                  {this.props?.dateTime?.currency} 9,000
                                </div>
                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="POSDashPayPak" title="PayPak">
                      <div className="transaction-summary-wrapper">
                        <ReactECharts
                          onEvents={this.onChartClick}
                          option={getTransactionsummary()}
                          style={{
                            textTransform: "capitalize",
                            height: "300px",
                            width: "100%",
                            background:
                              theme === "light" ? "#fcfcfc" : "#1d1d1d",
                            borderRadius: "17px",
                            color: "#ccc",
                          }}
                          opts={{ renderer: "svg" }}
                        />
                        <div className="graph-sm-container">
                          <div className="row">
                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  On-us Transactions
                                </div>
                                <div>
                                  <div className="graph-sm-number">
                                    {/* {CURRENCY} 20,000 */}
                                    {this.props?.dateTime?.currency} 25,000
                                  </div>{" "}
                                  <div className="graph-sm-number-sub">
                                    Count 200
                                  </div>
                                </div>

                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  Not On-us Transactions
                                </div>

                                <div className="d-flex justify-content-between mr-3">
                                  <div className="graph-sm-number">
                                    {this.props?.dateTime?.currency} 1,000
                                  </div>
                                </div>
                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  Declined Transactions
                                </div>

                                <div className="d-flex justify-content-between mr-3">
                                  <div className="graph-sm-number">
                                    {/* {CURRENCY} 3,000 */}
                                    {this.props?.dateTime?.currency} 6,000
                                  </div>
                                  {/* <div className="graph-sm-number">100</div> */}
                                </div>
                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                              <div className="graph-sm-wrapper">
                                <div className="graph-sm-title">
                                  Approved Transactions
                                </div>

                                <div className="graph-sm-number">
                                  {this.props?.dateTime?.currency} 7,000
                                </div>
                                <ReactECharts
                                  onEvents={this.onChartClick}
                                  option={getMiniChart()}
                                  style={{
                                    textTransform: "capitalize",
                                    height: "140px",
                                    width: "100%",
                                    background:
                                      theme === "light" ? "#fcfcfc" : "#1d1d1d",
                                  }}
                                  opts={{ renderer: "svg" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </SingleInfoCard>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={pieChart({
                    theme: theme,
                    colorPalette: colorPalette,
                    chartData: matchingSummaryData,
                    loader: matchingSummaryLoading,
                    label: ["matched", "unmatched", "missing", "error"],
                    radius: "70%",
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  opts={{ renderer: "svg" }}
                  lazyUpdate={true}
                  showLoading={matchingSummaryLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                />
              </div>
            </div>

            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={TransactionCategorization({
                    theme: theme,
                    colorPalette: REVERSE_PASTEL,
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={this.state.previousDaySumOptionLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>

            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={DeclineTransaction({
                    theme: theme,
                    colorPalette: PASTEL_PALETTE,
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={this.state.previousDaySumOptionLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={Disputes({
                    theme: theme,
                    colorPalette: colorPalette,
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={this.state.previousDaySumOptionLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>

            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[4],
                    chartData: transactionDestinationOptionsData,
                    loader: transactionDestinationOptionsLoading,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={transactionDestinationOptionsLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>

            <div className="col-sm-12 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={pieChart({
                    theme: theme,
                    colorPalette: YELLOW_PALETTE,
                    chartData: suspenseAccountOptionsData,
                    loader: suspenseAccountOptionsLoading,
                    label: [""],
                    radius: "70%",
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  // loadingOption={this.getLoader}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  showLoading={suspenseAccountOptionsLoading}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[4],
                    loader: topCashDispensedCountOptionsLoading,
                    chartData: topCashDispensedCountOptionsData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fff" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  lazyUpdate={true}
                  showLoading={topCashDispensedOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[4],
                    loader: topCashDispensedOptionsLoading,
                    chartData: topCashDispensedOptionsData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  lazyUpdate={true}
                  showLoading={topCashDispensedOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[1],
                    loader: topExceptionsOptionsLoading,
                    chartData: topExceptionsOptionsData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  lazyUpdate={true}
                  showLoading={topExceptionsOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[4],
                    loader: leastCashDispensedOptionsLoading,
                    chartData: leastCashDispensedOptionsData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  showLoading={leastCashDispensedOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[4],
                    loader: leastCashDispensedCountOptionsLoading,
                    chartData: leastCashDispensedCountOptionsData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  showLoading={leastCashDispensedCountOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={horizontalBarChart({
                    theme: theme,
                    color: colorPalette[1],
                    loader: leastExceptionsOptionsLoading,
                    chartData: leastExceptionsOptionsData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  showLoading={leastExceptionsOptionsLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mt-3">
                <ReactECharts
                  onEvents={this.onChartClick}
                  option={barWithLineChart({
                    theme: theme,
                    loader: peakTimeOptionLoading,
                    chartData: peakTimeOptionData,
                    label: [""],
                  })}
                  style={{
                    textTransform: "capitalize",
                    height: "279px",
                    width: "100%",
                    background: theme === "light" ? "#fcfcfc" : "#1d1d1d",
                    padding: "15px",
                    borderRadius: "17px",
                    color: "#ccc",
                  }}
                  showLoading={peakTimeOptionLoading}
                  loadingOption={{
                    text: "loading",
                    color: "#c23531",
                    textColor: "#000",
                    maskColor: `${
                      theme === "light"
                        ? "rgba(7, 6, 6, 0.1)"
                        : "rgba(7, 6, 6, 0.8)"
                    }`,
                    zlevel: 0,

                    // Font size. Available since `v4.8.0`.
                    fontSize: 12,
                    // Show an animated "spinner" or not. Available since `v4.8.0`.
                    showSpinner: true,
                    // Radius of the "spinner". Available since `v4.8.0`.
                    spinnerRadius: 10,
                    // Line width of the "spinner". Available since `v4.8.0`.
                    lineWidth: 5,
                    // Font thick weight. Available since `v5.0.1`.
                    fontWeight: "normal",
                    // Font style. Available since `v5.0.1`.
                    fontStyle: "normal",
                    // Font family. Available since `v5.0.1`.
                    fontFamily: "sans-serif",
                  }}
                  lazyUpdate={true}
                  opts={{ renderer: "svg" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="mt-3"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withDateTime(PosDashboard);
