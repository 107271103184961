import React, { useEffect } from "react";
import TabContainer from "react-bootstrap/TabContainer";
import TabContent from "react-bootstrap/TabContent";
import Nav from "react-bootstrap/Nav";
import { Row, Col } from "react-bootstrap";
import VerticalTabsStyle from "./VerticalTabsStyle";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import { useState } from "react";
import axiosInstance from "../../utils/axiosInstance";

export const RetailPOSAccountsMenu = (props) => {
  const { onAccountSelect, params } = props;
  const [loading, setLoading] = useState(true);
  const [nostroTypes, setNostroTypes] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredAccounts = nostroTypes
    ?.filter((item) => item.financialInstitution === params?.retailPOS)
    ?.flatMap((item) => item.posAccounts);

  const filteredData = filteredAccounts?.filter((item) =>
    item.screenName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (selectedAccount == null) {
      setSelectedAccount(filteredData[0]);
      onAccountSelect(selectedAccount);
    } else {
      onAccountSelect(selectedAccount);
    }
  }, [selectedAccount, onAccountSelect, filteredData]);

  useEffect(() => {
    setSelectedAccount(filteredData[0]);
    onAccountSelect(selectedAccount);
  }, [params.retailPOS]);

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <VerticalTabsStyle className="h-100">
      <TabContainer
        className="h-100"
        id="left-tabs-example"
        defaultActiveKey="1001"
      >
        <Row className="h-100">
          <Col
            // className={`nav-container ${
            //   showSidebar === true
            //     ? "d-xs-block-sm-block d-lg-block-sm-block"
            //     : "d-xs-none d-sm-none"
            // } d-xl-block`}
            className={`nav-container d-xs-block-sm-block d-lg-block-sm-block d-xl-block`}
            sm={12}
          >
            {loading ? (
              <div className="spinner-center">
                <LoaderComponent />
              </div>
            ) : (
              <div className="nav-wrapper fancy-scroll">
                <Nav variant="pills" className="flex-column">
                  <div className="d-flex justify-content-end align-items-center">
                    <input
                      type="search"
                      placeholder="Search..."
                      value={searchQuery}
                      className="search-input-card w-100 mb-2"
                      onChange={handleSearchQuery}
                    />
                  </div>
                  {filteredData &&
                    filteredData?.map((item) => (
                      <Nav.Item
                        key={item.id}
                        className="mt-2"
                        onClick={() => {
                          setSelectedAccount(item);
                        }}
                      >
                        <Nav.Link
                          eventKey={item.id}
                          //   active={item.id === this.state.accountId}
                          active={item?.id === selectedAccount?.id}
                        >
                          {item.screenName}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
            )}
          </Col>
        </Row>
      </TabContainer>
    </VerticalTabsStyle>
  );
};
