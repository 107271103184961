import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import store from "./redux/store";
import { Provider } from "react-redux";
// import { popper } from "@popperjs/core";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import App from "./App";
import "font-awesome/css/font-awesome.min.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import DateTimeContextProvider from "./components/Helper/DateTime";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "fr", "ar"],
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    react: { useSuspense: false },
  });

// function App() {
//   const { t } = useTranslation();

//   return <h2>{t("Welcome_to_react")}</h2>;
// }
// if (process.env.NODE_ENV === 'development') {
// const { worker } = require("./mocks/browser");
// worker.start({
//   onUnhandledRequest: "bypass",
//   onUnhandledResponse: "bypass",
//   onUnhandledError: "bypass",
//   onUnhandledRejection: "bypass",
//   onUnhandledPromiseRejection: "bypass",
//   onUnhandledFetch: "bypass",
// });
// }
const loadingMarkup = (
  <div className="py-4 text-center">
    <h3>Loading.........</h3>
  </div>
);

ReactDOM.render(
  <DateTimeContextProvider
    value={{
      date: "DD-MM-YYYY",
      time: "HH:mm:ss",
      dateTime: "DD-MM-YYYY HH:mm:ss",
      currency: "",
      currencyName: "",
    }}
  >
    <Provider store={store}>
      <Suspense fallback={loadingMarkup}>
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </Suspense>
    </Provider>
  </DateTimeContextProvider>,
  document.getElementById("root")
);
