import React, { useState, useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import axiosInstance from "../../utils/axiosInstance";
import Pagination from "../../components/pagination/pagination";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import FilterIcon from "../../assets/images/filter.png";
import Filter from "../../components/FilterDropdown/GenericFilter";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { useDateTime } from "../../components/Helper/DateTime";
import queryString from "query-string";
import {
  mapFilters,
  handleFilterChange,
  filtersParams,
  handleNewFilter,
} from "../../utils/filtersHelper";
import { setPlatformAPI } from "echarts";

const OneLinkATM = (props) => {
  const { dateTime, currency, setRefresh } = useDateTime();
  const [oneLinkAtmData, setoneLinkAtmData] = useState([]);
  const [oneLinkColumns, setoneLinkColumns] = useState(null);
  const [oneLinkCount, setoneLinkCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [isAtmSelected, setIsAtmSelected] = useState(true);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [filter, setFilter] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [showFilters, setShowFilters] = useState([]);
  const [id, setId] = useState("");
  // const hrefFromNotification = props.location.state;

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const fetchoneLinkAtmData = useCallback(
    (filter, flag) => {
      setLoading(true);
      let allFilters = filtersParams(filter);
      const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
      let url = `/settlement/onelink?page=${
        flag ? 0 : page - 1
      }&size=${pageSize}${allFilters}${
        sort.sort_by && sort.sort_order ? order : ""
      }`;

      axiosInstance
        .get(url)
        .then((res) => {
          if (res.message.status !== "200") {
            // Toast(res.message.description, "error");
            setoneLinkAtmData([]);
            setoneLinkColumns([
              {
                dataField: "Header",
                text: "No Header Data",
              },
            ]);
            setLoading(false);
            setTotalElements(0);
          } else {
            // setoneLinkAtmData(
            //   res?.data?.settlement[0]?.summaries.map((x) => {
            //     console.log("x...", x);
            //     let obj = { ...x };
            //     for (let val in obj) {
            //       if (
            //         obj[val] &&
            //         !isNaN(obj[val]) &&
            //         val !== "txnCount" &&
            //         val !== "count"
            //       ) {
            //         obj[val] = `${currency} ${numberWithCommas(obj[val])}`;
            //       }
            //     }
            //     return obj;
            //   }) || []
            // );

            setoneLinkAtmData(res?.data?.settlement[0]?.summaries || []);

            setoneLinkColumns(res?.data?.settlement[0]?.labels || [
              {
                dataField: "Header",
                text: "No Header Data",
              },
            ]);
            setPage(res?.page?.number + 1 || 1);
            setTotalPages(res?.page?.totalPages || 0);
            setTotalElements(res?.page?.totalElements || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });

      axiosInstance
        .get(`/settlement/onelink-counts?${allFilters}`)
        .then((res) => {
          if (res.message.status !== "200") {
            setoneLinkCount([]);
          } else {
            setoneLinkCount(res.data || []);
          }
        })
        .catch((err) => console.log("err of onelink count api....", err));
    },

    // eslint-disable-next-line
    [page, pageSize, sort]
  );

  const getFilters = useCallback(() => {
    setLoading(true);
    let data;
    if (props.location?.state?.data) {
      data = props.location.state.data;
    }
    axiosInstance
      .get("hierarchical/filters?screen_id=settlement_summary_1link")
      .then((res) => {
        if (res.message.status !== "200") {
          // Toast(res.message.description, "error");
          fetchoneLinkAtmData();
        } else {
          const filteredFilters = res?.data?.filters.filter(
            (x) => x.showOnScreen
          );
          const showFiltersData = res?.data.filters.filter(
            (x) => !x.showOnScreen
          );
          setShowFilters(showFiltersData);
          setFilterData(filteredFilters);
          const obj = mapFilters(filteredFilters);
          if (data) {
            if (data.date) {
              obj.business_date.data = data.date;
            }
            if (data.id) {
              obj.matching.filter = [data.id];
            }
            if (data.channel) {
              obj.channel.filter = [data.channel];
            }
            fetchoneLinkAtmData(obj, true);
          } else {
            fetchoneLinkAtmData(obj);
          }
          setFilter(obj);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    // eslint-disable-next-line
  }, [props.location.state]);

  const validateDateTime = (cell) => {
    return cell ? moment(cell).format(dateTime) : "-";
  };
  const reconStatus = (row) => (
    <BadgeRounded
      color={
        row === "MATCHED" ? "success" : row === "MISSING" ? "warning" : "danger"
      }
      title={row}
    />
  );
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const formatAmount = (cell, amount) => {
    const formattedValue = cell < 0 ? Math.abs(cell) : cell;
    return (
      <CurrencyFormat
        value={cell ?? "-"}
        // value={formattedValue ? formattedValue : amount ? "-" : 0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    );
  };

  // const formatNumber = (cell) => (
  //   <CurrencyFormat
  //     value={cell ?? 0}
  //     displayType={"text"}
  //     thousandSeparator={true}
  //   />
  // );

  // const columns = [
  //   { hidden: true, dataField: "id", text: "ID" },

  //   {
  //     dataField: "issuerSender",
  //     text: "Issuer",
  //     sort: true,
  //   },
  //   {
  //     dataField: "acquirerReceiver",
  //     text: "Acquirer",
  //     sort: true,
  //   },
  //   {
  //     dataField: "senderIssuerNet",
  //     text: "Issuer Net",
  //     sort: true,
  //   },
  //   {
  //     dataField: "senderIssuerFeePayable",
  //     text: "Issuer Fee Payable",
  //     sort: true,
  //   },
  //   {
  //     dataField: "acquirerReceiverFeeRec",
  //     text: "Acquirer Fee",
  //     sort: true,
  //   },
  //   {
  //     dataField: "networkFee",
  //     text: "Network Fee",
  //     sort: true,
  //   },
  //   {
  //     dataField: "acquirerReceiverNet",
  //     text: "Acquirer Net",
  //     sort: true,
  //   },
  //   {
  //     dataField: "txnCount",
  //     text: "TXN Count",
  //     sort: true,
  //   },
  //   {
  //     dataField: "senderIssuerTxnAmountPayable",
  //     text: "Issuer Transaction Amount",
  //     sort: true,
  //   },
  //   {
  //     dataField: "count",
  //     text: "Count",
  //     sort: true,
  //   },
  //   {
  //     dataField: "amount1",
  //     text: "Amount1",
  //     sort: true,
  //   },
  //   {
  //     dataField: "amount2",
  //     text: "Amount2",
  //     sort: true,
  //   },
  //   {
  //     dataField: "reconStatus",
  //     text: "Status",
  //     sort: true,
  //   },
  // ];

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  useEffect(() => {
    if (filterData.length > 0) fetchoneLinkAtmData(filter);
    // eslint-disable-next-line
  }, [fetchoneLinkAtmData]);

  useEffect(() => {
    const { id } = queryString.parse(props.location.search);
    if (id) {
      setId(id);
    }
  }, [props.location.search]);
  useEffect(() => {
    setRefresh(() => () => {
      fetchoneLinkAtmData(filter, true);
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, [fetchoneLinkAtmData, filter]);

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div style={{ opacity: !loading ? "1" : "0.07" }}>
        <div className="row align-items-center d-lg-flex">
          {filterData.map(
            ({
              filterName,
              filterType,
              filterLabel,
              showOnScreen,
              filterId,
            }) => (
              <div
                className="filter-item mt-2 ml-3 d-flex"
                // style={{ minWidth: "210px" }}
                key={filterId}
              >
                <Filter
                  title={filterLabel}
                  filterId={filterId}
                  type={filterType}
                  data={filter[filterName]?.data}
                  filter={filter[filterName]?.filter}
                  removable={!showOnScreen}
                  onChange={(e) =>
                    handleFilterChange(e, filterName, filter, setFilter)
                  }
                  onRemove={(e) =>
                    handleNewFilter(
                      e,
                      filterData,
                      showFilters,
                      setFilter,
                      setFilterData
                    )
                  }
                />
              </div>
            )
          )}
          <div className="filter-item mt-2 ml-3">
            <Filter
              title={""}
              type={1006}
              data={showFilters}
              filter={filterData.map((x) => x.filterId)}
              field="filterId"
              onChange={(e) =>
                handleNewFilter(
                  e,
                  filterData,
                  showFilters,
                  setFilter,
                  setFilterData
                )
              }
            />
          </div>
          <div className="col d-flex justify-content-start mt-2">
            <ButtonBasic
              wrapperClass={"d-flex justify-content-end mr-0"}
              icon={FilterIcon}
              title="Filter"
              onClick={() => {
                fetchoneLinkAtmData(filter, true);
              }}
            />
          </div>
        </div>
        <div className="row page-header d-flex justify-content-between mt-4">
          <div className="col-sm-12 col-lg-6 page-header d-flex justify-content-between m-0">
            <div className="title">Settlement Summary</div>
          </div>
        </div>
        {oneLinkColumns && currency !== "" && (
          <BootstrapTable
            columns={oneLinkColumns.map((item) => {
              return item.dataField === "reconStatus" || item.text === "Status"
                ? {
                    ...item,
                    formatter: reconStatus,
                  }
                : item;
            })}
            bootstrap4={true}
            keyField="id"
            bordered={false}
            classes="rs-table table-layout-auto table-custom-style table-custom-style"
            wrapperClasses="overflow-y-auto fancy-scroll"
            data={
              oneLinkAtmData
                ? oneLinkAtmData?.map((x) => {
                    console.log("x...", x);
                    let obj = { ...x };
                    for (let val in obj) {
                      if (
                        obj[val] &&
                        !isNaN(obj[val]) &&
                        val !== "txnCount" &&
                        val !== "count"
                      ) {
                        obj[val] = formatAmount(obj[val]);
                      }
                    }
                    return obj;
                  })
                : []
            }
            remote={{ sort: true }}
            onTableChange={onSortTable}
            noDataIndication={"No data found!"}
          />
        )}
        <div className="col-12 mb-3">
          <Pagination
            setPage={setPage}
            page={page}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={totalPages}
            showCSV
            totalElements={totalElements}
            data={oneLinkAtmData || []}
            // CSVHeaders={headers}
          />
        </div>
      </div>
      {!loading && (
        <>
          <div className="overflow-y-auto fancy-scroll">
            <table className="table">
              <thead>
                <tr>
                  {oneLinkCount &&
                    oneLinkCount?.labels?.map((item) => (
                      <th key={item.dataField}>{item.text}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {oneLinkCount?.totalTxnCount &&
                oneLinkCount?.totalIssuerTxnAmount &&
                oneLinkCount?.totalIssuerFeePayable &&
                oneLinkCount?.totalAcquirerFee &&
                oneLinkCount?.totalNetworkFee &&
                oneLinkCount?.totalIssuerNet &&
                oneLinkCount?.totalAcquirerNet ? (
                  <tr>
                    <td>{oneLinkCount?.totalTxnCount}</td>
                    <td>{formatAmount(oneLinkCount?.totalIssuerTxnAmount)}</td>
                    <td>{formatAmount(oneLinkCount?.totalIssuerFeePayable)}</td>
                    <td>{formatAmount(oneLinkCount?.totalAcquirerFee)}</td>
                    <td>{formatAmount(oneLinkCount?.totalNetworkFee)}</td>
                    <td>{formatAmount(oneLinkCount?.totalIssuerNet)}</td>
                    <td>{formatAmount(oneLinkCount?.totalAcquirerNet)}</td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={7} align="center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="col-12 mb-3">
            <Pagination
              setPage={setPage}
              page={page}
              pageSize={pageSize}
              setPageSize={setPageSize}
              totalPages={totalPages}
              showCSV
              totalElements={totalElements}
              data={oneLinkAtmData || []}
              // CSVHeaders={headers}
            />
          </div>
        </>
      )}
    </>
  );
};
export default OneLinkATM;
