import React, { useEffect, useState } from "react";
import TabContainer from "react-bootstrap/TabContainer";
import Nav from "react-bootstrap/Nav";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import VerticalTabsStyle from "./VerticalTabsStyle";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import axiosInstance from "../../utils/axiosInstance";
import { useLocation } from "react-router-dom";

export const NostroDynamicAccountsMenu = ({
  onAccountSelect,
  params,
  collapsed,
  setCollapsed,
}) => {
  const location = useLocation();
  const dynamicMenuItems = location?.state?.subItem;
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [reconAccounts, setReconAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const fetchReconAccounts = async () => {
    try {
      setLoading(true);
      setReconAccounts([]);
      setSelectedAccount(null);
      const response = await axiosInstance.get(
        `recon/accounts/${dynamicMenuItems?.reconId}`
      );
      if (response?.message?.status !== "200") {
        setReconAccounts([]);
        setSelectedAccount(null);
      } else {
        const accounts = response?.data?.accounts || [];
        setReconAccounts(accounts);
        setSelectedAccount(accounts[0] || null);
      }
    } catch (err) {
      setReconAccounts([]);
      setSelectedAccount(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReconAccounts();
  }, [dynamicMenuItems]);

  useEffect(() => {
    if (selectedAccount == null && reconAccounts?.length > 0) {
      setSelectedAccount(reconAccounts[0]);
    }
  }, [reconAccounts]);

  // useEffect(() => {
  //   if (reconAccounts?.length > 0) {
  //     const initialSelectedAccount = reconAccounts.find(
  //       (item) => item?.name?.toLowerCase() === params.bank?.toLowerCase()
  //     );
  //     setSelectedAccount(initialSelectedAccount || reconAccounts[0]);
  //   }
  // }, [params.bank]);

  useEffect(() => {
      onAccountSelect(selectedAccount);
  }, [onAccountSelect, selectedAccount]);

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = reconAccounts.filter((item) =>
    item?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  return (
    <>
      {!loading && (
        <div className="collapse-div">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {collapsed ? "Show Accounts Menu" : "Hide Accounts Menu"}
              </Tooltip>
            }
          >
            <button
              className="collapse-btn icon"
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? (
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
              )}
            </button>
          </OverlayTrigger>
        </div>
      )}
      <VerticalTabsStyle className="h-100" collapsed={collapsed}>
        <div className="nav-container">
          <TabContainer
            className="h-100"
            id="left-tabs-example"
            defaultActiveKey="1001"
          >
            <Row className="h-100">
              <Col
                className={`nav-container d-xs-block-sm-block d-lg-block-sm-block d-xl-block`}
                sm={12}
              >
                {loading ? (
                  <div className="spinner-center">
                    <LoaderComponent />
                  </div>
                ) : (
                  <div
                    className="nav-wrapper fancy-scroll"
                    style={{ maxHeight: "95%" }}
                  >
                    <Nav variant="pills" className="flex-column">
                      <div className="d-flex justify-content-end align-items-center">
                        <input
                          type="search"
                          placeholder="Search..."
                          value={searchQuery}
                          className="search-input-card w-100 mb-2"
                          onChange={handleSearchQuery}
                        />
                      </div>
                      {filteredData.map((item) => (
                        <Nav.Item
                          key={item.id}
                          className="mt-2"
                          onClick={() => setSelectedAccount(item)}
                        >
                          <Nav.Link
                            eventKey={item.id}
                            active={item?.id === selectedAccount?.id}
                          >
                            {item.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </div>
                )}
              </Col>
            </Row>
          </TabContainer>
        </div>
      </VerticalTabsStyle>
    </>
  );
};
