import styled from "styled-components";

const BadgeStyle = styled.div.attrs((props) => ({
  className: props.className,
  style: props.customStyle,
}))`
  padding: 4px;
  text-align: center;
  border-radius: 20px;
  font-size: 10px;
  color: ${({ theme }) => theme.badge};
  font-family: "Montserrat Medium";
  white-space: nowrap;
  background: ${({ className }) =>
    className === "tsg-badge danger"
      ? "#ef476f"
      : "tsg-badge warning"
      ? "#FFD166"
      : "#00af91"};
`;

export default BadgeStyle;
