import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import FilterDropdown from "../../components/FilterDropdown/FilterDropdown";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import axiosInstance from "../../utils/axiosInstance";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import FilterIcon from "../../assets/images/filter.png";
import moment from "moment";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import withDateTime from "../../utils/timedatehoc";
import Toast from "../../components/Toast/Toast";
import NewFilter from "../../components/FilterDropdown/NewFilter";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import { DateTimeFormat } from "../../utils/formatDateTime";

class AuditLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      logsLoader: true,
      users: [],
      selectedUser: "all",
      fromDate: "",
      toDate: "",
      sortField: "performedAt",
      sortOrder: "desc",
      selectedUserName: "All",
      error: false,
    };
    this.pagination = {
      onPageChange: this.onPageChanges,
      onSizePerPageChange: this.onSizePerPageChange,
      pageStartIndex: 1, // where to start counting the pages
      totalSize: 0,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      showTotal: true,
      alwaysShowAllBtns: true,
      sizePerPage: 15,
      sizePerPageList: [
        {
          text: "15",
          value: 15,
        },
        {
          text: "30",
          value: 30,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
      page: 1,
    };
  }

  async componentDidMount() {
    await this.setDateTime();
    await this.getUsers();
    await this.getLogs();
    this.props.dateTime.setRefresh(() => async () => {
      await this.getLogs();
    });
  }

  componentWillUnmount() {
    this.props.dateTime.setRefresh(() => () => {});
  }

  async getUsers() {
    let { users } = this.state;
    try {
      let response = await axiosInstance.get("/users/list");
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        users = this.formatUsers(response.data);
        this.setState({ users });
      }
    } catch (error) {
      console.log(error);
    }
  }

  setDateTime() {
    let { fromDate, toDate } = this.state;
    fromDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    toDate = moment().format("YYYY-MM-DD");
    this.setState({ fromDate, toDate });
  }

  formatUsers(users) {
    let formattedUsers = users.map((user) => {
      return {
        id: user.id,
        label: user.name,
        param1: "0",
      };
    });
    formattedUsers.unshift({
      id: "all",
      label: "All",
      param1: "1",
    });
    return formattedUsers;
  }

  async getLogs() {
    this.setState({ logsLoader: true });
    let { logs, fromDate, toDate, sortOrder, sortField, selectedUser } =
      this.state;
    let { page, sizePerPage } = this.pagination;
    fromDate = moment(fromDate).format("YYYY-MM-DDTHH:mm:ss");
    toDate = moment(toDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss");
    let url = `/log/audit-log?date_from=${fromDate}&date_to=${toDate}&page=${
      page - 1
    }&size=${sizePerPage}&sort_order=${sortOrder}&sort_by=${sortField}`;
    if (selectedUser !== "all" && selectedUser !== "") {
      url += `&user_id=${selectedUser}`;
    }
    try {
      const response = await axiosInstance.get(url);
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        this.setState({ logs: [], logsLoader: false });
      } else {
        logs = [];
        logs = response.data;
        let pageData = response.page;
        this.pagination = {
          ...this.pagination,
          sizePerPage: pageData.size,
          totalSize: pageData.totalElements,
        };
        this.setState({ logs, logsLoader: false, error: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ logs: [], logsLoader: false, error: true });
    }
  }

  selectUsers(e) {
    let { selectedUser, users, selectedUserName } = this.state;
    let id = e.target.value;
    // eslint-disable-next-line
    users.map((user) => {
      if (user.id === id) {
        if (user.param1 === "0") {
          user.param1 = "1";
          selectedUser = user.id;
          selectedUserName = user.label;
        }
      } else if (user.id === parseInt(id)) {
        if (user.param1 === "0") {
          user.param1 = "1";
          selectedUser = user.id;
          selectedUserName = user.label;
        }
      } else {
        user.param1 = "0";
      }
    });
    this.setState({ users, selectedUser, selectedUserName });
  }

  onPageChanges = (page) => {
    this.setState({ logsLoader: true }, () => {
      this.pagination = {
        ...this.pagination,
        page: page,
      };
      this.getLogs();
    });
  };

  onSizePerPageChange = (sizePerPage) => {
    this.setState({ logsLoader: true }, () => {
      this.pagination = {
        ...this.pagination,
        sizePerPage: sizePerPage,
      };
      this.getLogs();
    });
  };

  onSortTable(type, data) {
    this.setState({ logsLoader: true }, () => {
      let { sortOrder, sortField } = this.state;
      if (type === "sort") {
        sortField = data.sortField;
        sortOrder = data.sortOrder;
        this.setState({ sortField, sortOrder }, () => {
          this.getLogs();
        });
      }
    });
  }

  impact = (cell, row) => (
    <BadgeRounded
      color={cell === "Successful" ? "success" : "danger"}
      title={cell ?? "-"}
    />
  );

  actionFormatter = (cell, row) => {
    // First, check if the cell contains a valid JSON string
    let parsedAction;

    try {
      // Attempt to parse the outer JSON
      parsedAction = JSON.parse(cell);

      // Check if parsedAction is still a string (meaning it's double-escaped JSON)
      if (typeof parsedAction === "string") {
        parsedAction = JSON.parse(parsedAction); // Parse the inner JSON
      }
    } catch (e) {
      // If parsing fails, assume it's plain text and return the cell as plain text
      return <span>{cell}</span>;
    }

    // Now handle the parsedAction if it contains the expected structure
    if (parsedAction && parsedAction.Summary && parsedAction.transactions) {
      const summary = parsedAction.Summary || "N/A";
      const businessDate = parsedAction.businessDate || "N/A";
      const adjustmentAmount = parsedAction.adjustmentAmount || "N/A";
      const transactions = parsedAction.transactions || [];

      return (
        <div style={{ fontFamily: "inherit", fontSize: "14px" }}>
          <p>
            <span style={{ fontWeight: "bold" }}>Summary:</span> {summary}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Business Date:</span>{" "}
            {businessDate}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Adjustment Amount:</span>{" "}
            {adjustmentAmount}
          </p>

          {/* Render transaction tables */}
          {transactions.length > 0 &&
            transactions.map((transaction, index) => (
              <div key={index}>
                <h5>{transaction.title}</h5>
                <div style={{ overflowX: "auto" }}>
                  <table
                    className="table table-bordered table-sm"
                    style={{ tableLayout: "fixed", width: "100%" }}
                  >
                    <thead>
                      <tr>
                        {transaction.columns.map((col, idx) => (
                          <th
                            key={idx}
                            style={{
                              whiteSpace: "nowrap",
                              textAlign: "center",
                              minWidth: "120px",
                            }}
                          >
                            {col}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {transaction.rows.map((cellData, i) => (
                          <td
                            key={i}
                            style={{
                              whiteSpace: "nowrap",
                              textAlign: "center",
                            }}
                          >
                            {cellData || "--"}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
        </div>
      );
    }

    // If it doesn't match the expected structure, return it as plain text
    return <span>{cell}</span>;
  };

  render() {
    const {
      users,
      logsLoader,
      logs,
      fromDate,
      toDate,
      selectedUserName,
      error,
    } = this.state;
    this.columns1 = [
      { hidden: true, dataField: "id", text: "ID" },
      {
        dataField: "action",
        text: "Action",
        sort: true,
        style: { cursor: "default" },
        formatter: this.actionFormatter,
      },
      {
        dataField: "performedAt",
        text: "Performed At",
        sort: true,
        style: { cursor: "default" },
        formatter: (cell) =>
          cell ? DateTimeFormat(this.props?.dateTime?.dateTime, cell) : "-",
      },
      {
        dataField: "actionType",
        text: "Action Type",
        sort: true,
        style: { cursor: "default" },
      },
      {
        dataField: "userName",
        text: "Action By",
        sort: true,
        style: { cursor: "default" },
      },
    ];
    return (
      <div>
        {logsLoader && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div
          style={{
            opacity: !logsLoader ? "1" : "0.07",
          }}
        >
          <div className="row mb-4 pl-2 d-flex justify-content-center align-items-center">
            <div className="col-sm-6 col-lg-3">
              <label>From Date</label>
              <NewFilter
                title={fromDate !== "" ? fromDate : "Select Date"}
                type="date"
                date={fromDate ? new Date(fromDate) : null}
                onChange={(e) => this.setState({ fromDate: e })}
              />
            </div>
            <div className="col-sm-6 col-lg-3">
              <label>To Date</label>
              <NewFilter
                title={toDate !== "" ? toDate : "Select Date"}
                type="date"
                date={toDate ? new Date(toDate) : null}
                onChange={(e) => this.setState({ toDate: e })}
              />
            </div>
            <div className="col-sm-6 col-lg-3">
              <label>Select User</label>
              <FilterDropdown
                title={selectedUserName !== "" ? selectedUserName : "Users"}
                type="radio"
                isSearch={false}
                data={users}
                onDropdownChange={(e) => this.selectUsers(e)}
              />
            </div>
            <div className="col-sm-6 col-lg-3 mt-4">
              <ButtonBasic
                title="Filter"
                classes={"btn btn-lg"}
                icon={FilterIcon}
                onClick={() => {
                  this.pagination.onPageChange(1);
                  this.setState({ logsLoader: true }, () => this.getLogs());
                }}
              />
            </div>
          </div>
          <div className="row mt-sm-0">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  style={{ fontSize: "18px" }}
                  className="text-bright ml-3 mb-4"
                >
                  Audit Log
                </div>
              </div>
              {!error && (
                <div className="col-12 ">
                  <div className="fancy-scroll">
                    <BootstrapTable
                      bordered={false}
                      bootstrap4={true}
                      classes="rs-table table-layout-auto mb-0 table-custom-style"
                      wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                      keyField="id"
                      data={logs}
                      columns={this.columns1}
                      remote={{ pagination: true, sort: true }}
                      onTableChange={(type, newState) =>
                        this.onSortTable(type, newState)
                      }
                      pagination={
                        logs.length > 0 && paginationFactory(this.pagination)
                      }
                      noDataIndication="No data found!"
                    ></BootstrapTable>
                  </div>
                </div>
              )}
              {error && (
                <div className="h-100 w-100 col-12 d-flex align-items-center justify-content-center">
                  <h1>Record Not Found</h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withDateTime(AuditLog);
