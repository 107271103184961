import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
import ball from "../../assets/images/ball.png";
import { mapsKey } from "../../configuration/mapKey";

const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${mapsKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `250px`, borderRadius: "17px" }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  if (props) {
    return (
      <GoogleMap
        defaultZoom={parseFloat(props?.zoom) || 6}
        defaultCenter={{
          lat: parseFloat(props?.lat) || 24.501743,
          lng: parseFloat(props?.lng) || 54.911286,
        }}
      >
        <MarkerClusterer gridSize={60} minimumClusterSize={2}>
          {props?.exceptionSummaryMap[0]?.map((val, i) => (
            <Marker
              key={i}
              position={{
                lat: parseFloat(val.latitude),
                lng: parseFloat(val.longitude),
              }}
              icon={{
                url: ball,
                scaledSize: {
                  height: val.bubbleSize + 10,
                  width: val.bubbleSize + 10,
                },
              }}
              onMouseOver={() => props.InfoToggleOpen(i)}
            >
              {props?.infoWindowToggle &&
                props.openInfoWindowMarkerId === i && (
                  <InfoWindow onCloseClick={() => props?.InfoToggleClose(i)}>
                    <div>
                      <div style={{ color: "black" }}>
                        Device: {val.deviceName}
                      </div>
                      <div style={{ color: "black" }}>
                        Count: {val.exceptionCount}
                      </div>
                      <div style={{ color: "black" }}>
                        Location: {val.deviceLocation}
                      </div>
                    </div>
                  </InfoWindow>
                )}
            </Marker>
          ))}
        </MarkerClusterer>
      </GoogleMap>
    );
  } else {
    window.gm_authFailure = () => {
      console.log("error....,");
    };
  }
});

export default class GoogleMapComponent extends React.PureComponent {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isMarkerShown: false,
      infoWindowToggle: false,
      openInfoWindowMarkerId: "",
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  componentDidMount() {
    this._isMounted = true;
    this.delayedShowMarker();
  }

  delayedShowMarker = () => {
    this.timeout = setTimeout(() => {
      this.setState({ isMarkerShown: true });
    }, 5000);
  };

  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false });
    this.delayedShowMarker();
  };

  toggleOpen = (i) => {
    this.setState({ infoWindowToggle: true, openInfoWindowMarkerId: i });
  };

  toggleClose = (i) => {
    this.setState({ infoWindowToggle: false, openInfoWindowMarkerId: i });
  };

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timeout);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "50vh",
            textAlign: "center",
          }}
        >
          <div style={{ width: "100%", height: "50px" }}>
            <h6>Google Maps not loaded.</h6>
          </div>
        </div>
      );
    }
    return (
      <MyMapComponent
        isMarkerShown={this.state.isMarkerShown}
        onMarkerClick={this.handleMarkerClick}
        exceptionSummaryMap={this.props?.exceptionSummaryMap}
        settings={this.props?.settings}
        zoom={this.props?.zoom}
        lat={this.props?.lat}
        lng={this.props?.lng}
        infoWindowToggle={this.state.infoWindowToggle}
        InfoToggleOpen={this.toggleOpen}
        InfoToggleClose={this.toggleClose}
        openInfoWindowMarkerId={this.state.openInfoWindowMarkerId}
      />
    );
  }
}
