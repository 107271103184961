import { Modal } from "react-bootstrap";
import logOut from "../../utils/helpers/log-out";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import ButtonBasic from "../Buttons/ButtonBasic";

const timeout = 600000; //10 minutes
const promptBeforeIdle = 15000; //15 sec

export default function IdleTimerContainer() {
  const [state, setState] = useState("Active");
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const onIdle = () => {
    setState("Idle");
    setOpen(false);
    logOut();
  };

  const onActive = () => {
    setState("Active");
    setOpen(false);
  };

  const onPrompt = () => {
    setState("Prompted");
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? "seconds" : "second";

  return (
    <>
      <Modal
        backdrop={true}
        size="lg"
        show={open}
        onHide={() => logOut()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> You have been Idle for a while</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container p-2">
            <div className="row">
              <p className="text-center">
                {" "}
                You'll be logged out in {remaining} seconds. If you want to stay
                active then click Stay Active button.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleStillHere}
            className={"btn btn-info btn-md mr-2"}
          >
            Stay Active
          </button>
          <button onClick={logOut} className={"btn btn-danger btn-md mr-2"}>
            Log Me Out
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
