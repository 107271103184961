import React, { useState, useEffect } from "react";
import { ProgressBar, Alert } from "react-bootstrap";
import moment from "moment";
import axiosInstance from "../../../../../utils/axiosInstance";
import { Provider } from "./MultiStepFormContext";
import Details from "./Details";
import Review from "./Review";
import EjCashUnload from "./ej-cash-unload";
import CitCashUnload from "./cit-cash-unload";
import EjCashLoad from "./ej-cash-load";
import CitCashLoad from "./cit-cash-load";
import { useDateTime } from "../../../../../components/Helper/DateTime";
import { DateTimeFormat } from "../../../../../utils/formatDateTime";

const detailsInitialState = {
  lastRef: "",
  dateTime: null,
  deviceId: "",
  deviceLabel: "",
  branchName: "",
  ejCashUnLoadTotalAmount: 0,
  citCashUnLoadTotalAmount: 0,
  ejCashLoadTotalAmount: 0,
  citCashLoadTotalAmount: 0,
};

const ejCashUnloadInitialState = [
  {
    cassetteType: "",
    denomination: "",
    billCount: "",
  },
];

const citCashUnloadInitialState = [
  {
    denomination: "",
    billCount: "",
  },
];

const ejCashLoadInitialState = [
  {
    cassetteType: "",
    denomination: "",
    billCount: "",
  },
];

const citCashLoadInitialState = [
  {
    denomination: "",
    billCount: "",
  },
];

const addressInitialState = {
  address1: "",
  address2: "",
  city: "",
};

const renderStep = (step) => {
  switch (step) {
    case 1:
      return <Details />;
    case 2:
      return <EjCashUnload />;
    case 3:
      return <CitCashUnload />;
    case 4:
      return <EjCashLoad />;
    case 5:
      return <CitCashLoad />;
    case 6:
      return <Review />;
    default:
      return null;
  }
};

const MultiStepForm = () => {
  const [details, setDetails] = useState(detailsInitialState);
  //cash unload
  const [ejCashUnLoadData, setEjCashUnLoadData] = useState(
    ejCashUnloadInitialState
  );
  const [citCashUnLoadData, setCitCashUnLoadData] = useState(
    citCashUnloadInitialState
  );
  //cash load
  const [ejCashLoadData, setEjCashLoadData] = useState(ejCashLoadInitialState);
  const [citCashLoadData, setCitCashLoadData] = useState(
    citCashLoadInitialState
  );
  const [cassetteUnits, setCassetteUnits] = useState([]);
  const [address, setAddress] = useState(addressInitialState);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [devicesData, setDevicesData] = useState(null);
  const [devices, setDevices] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const { dateTime } = useDateTime();
  const convertedDateTime = moment(details?.dateTime);

  const formattedDateTime = convertedDateTime.format(dateTime);

  const next = () => {
    if (currentStep === 6) {
      setDetails({});
      setEjCashUnLoadData(ejCashUnloadInitialState);
      setCitCashUnLoadData(citCashUnloadInitialState);
      setEjCashLoadData(ejCashLoadInitialState);
      setCitCashLoadData(citCashLoadInitialState);
      setAddress(addressInitialState);
      setDetails(detailsInitialState);
      setCurrentStep(1);
      return;
    }
    setCurrentStep(currentStep + 1);
  };
  const prev = () => setCurrentStep(currentStep - 1);
  const getDevices = async () => {
    try {
      let response = await axiosInstance.get("/devices/atm-devices");
      if (response.message.status !== "200") {
        setDevices([]);
      } else {
        setDevicesData(response.data);
        setDevices(
          response?.data?.map((item) => ({
            label: item.terminalId,
            value: item.deviceId,
          })) || []
        );
      }
    } catch (error) {
      console.log("devices error", error);
    }
  };

  useEffect(() => {
    if (currentStep === 1 && !devicesData) {
      // Only call the API if we're on step 1 and deviceData is not set yet
      getDevices();
    }
    if (currentStep === 2 && devicesData) {
      setDetails((prevState)=>{return {
        ...prevState,
        branchName: devicesData?.find(
          (item) => item?.terminalId === prevState.deviceId
        )?.branchName,
      }});
    }
  }, [currentStep, devicesData]);

  return (
    <Provider
      value={{
        details,
        setDetails,
        devices,
        setDevices,
        devicesData,
        setDevicesData,
        next,
        prev,
        address,
        setAddress,
        selectedDeviceId,
        setSelectedDeviceId,
        ejCashUnLoadData,
        setEjCashUnLoadData,
        citCashUnLoadData,
        setCitCashUnLoadData,
        ejCashLoadData,
        setEjCashLoadData,
        citCashLoadData,
        setCitCashLoadData,
        cassetteUnits,
        setCassetteUnits,
      }}
    >
      <div
        style={{
          paddingRight: "10px",
          marginRight: "10px",
          fontFamily: "Montserrat SemiBold",
        }}
      >
        {currentStep === 6
          ? "Cash Replenishment Overview"
          : "Cash Replenishment"}
      </div>
      <ProgressBar
        striped
        variant="info"
        now={currentStep * 16.6666666667}
        className="mb-2 mt-3"
      />
      <Alert
        variant="info"
        style={{ backgroundColor: "#108ab2", color: "#fff" }}
      >
        <div className="row">
          <div className="col-2"> Step {currentStep} of 6</div>
          {currentStep > 1 && (
            <div className="col-10 d-flex justify-content-between align-items-start">
              <div className="item">Last Ref: {details?.lastRef}</div>
              <div className="item">
                <b>Device ID:</b> {details?.deviceId}
              </div>
              <div className="item">Branch Name: {details?.branchName}</div>
              <div className="item">
                Date & Time: {DateTimeFormat(dateTime, formattedDateTime)}
              </div>
            </div>
          )}
        </div>
      </Alert>
      <main>{renderStep(currentStep)}</main>
    </Provider>
  );
};
export default MultiStepForm;
