import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import ButtonBasic from "../../../../components/Buttons/ButtonBasic";
import * as Yup from "yup";
import { errorStyling } from "../ValidationSchema";

const GroupingFieldModal = ({
  values,
  dsaFields,
  dsbFields,
  handleSubmit,
  show,
  onHide,
  editObject,
  matching,
}) => {
  const [formValues, setFormValues] = useState(values);
  const GroupingFieldValidationSchema = Yup.object().shape({
    ds_a_field_name:
      matching === "M:1" || matching === "M:M"
        ? Yup.string().required("DSA Field Name is required")
        : Yup.string().optional(),
    ds_b_field_name:
      matching === "1:M" || matching === "M:M"
        ? Yup.string().required("DSB Field Name is required")
        : Yup.string().optional(),
  });
  useEffect(() => {
    if (values) setFormValues(values);
  }, [values]);
  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={(values) => {
        values = Object.fromEntries(
          Object.entries(values).filter(([, v]) => v !== "")
        );
        handleSubmit(editObject?.path, values);
        setFormValues({});
        onHide();
      }}
      validationSchema={GroupingFieldValidationSchema}
    >
      {({ handleSubmit, errors, values, setFieldValue }) => (
        <Form id="groupingFieldForm" className="form" onSubmit={handleSubmit}>
          <Modal
            backdrop={true}
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            show={show}
            onHide={() => onHide()}
          >
            <Modal.Header closeButton>
              {editObject?.isNew
                ? "Add DSA Fetch Criteria Grouping Field"
                : "Edit DSA Fetch Criteria Grouping Field"}
            </Modal.Header>
            <Modal.Body scrollable="true">
              <div className="p-3 my-3">
                <div className="row">
                  <div className="col-6">
                    {/* DS A Field Name DropDown */}
                    <div className="workflowModalTitle">DSA Field Name</div>
                    <div title={values?.ds_a_field_name}>
                      <Select
                        options={dsaFields}
                        name={"ds A Field Name"}
                        value={dsaFields?.find(
                          (field) => field.value === values?.ds_a_field_name
                        )}
                        onChange={(e) =>
                          setFieldValue(`ds_a_field_name`, e?.value)
                        }
                        isClearable
                      />
                    </div>
                    <ErrorMessage
                      component={() => (
                        <div style={errorStyling}>
                          {errors?.ds_a_field_name}
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-6">
                    {/* DS B Field Name DropDown */}
                    <div className="workflowModalTitle">DSB Field Name</div>
                    <div title={values?.ds_b_field_name}>
                      <Select
                        options={dsbFields}
                        name={"ds B Field Name"}
                        value={dsbFields?.find(
                          (field) => field.value === values?.ds_b_field_name
                        )}
                        onChange={(e) =>
                          setFieldValue(`ds_b_field_name`, e?.value)
                        }
                        isClearable
                      />
                    </div>
                    <ErrorMessage
                      component={() => (
                        <div style={errorStyling}>
                          {errors?.ds_b_field_name}
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <ButtonBasic
                  id="cancel"
                  title="Cancel"
                  onClick={() => onHide()}
                />
              </div>
              <button
                id="submit"
                type="submit"
                name="submit"
                className={"btn btn-info btn-md mr-2"}
                onClick={handleSubmit}
              >
                {editObject?.isNew ? "Add" : "Update"}
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default GroupingFieldModal;
