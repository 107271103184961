import React, { useEffect, useState, useRef } from "react";
import CurrencyFormat from "react-currency-format";
import queryString from "query-string";
import VerticalTabs from "../../components/VerticalTabs/VerticalTabs";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import CardSm from "../../components/CardSm/CardSm";
import BootstrapTable from "react-bootstrap-table-next";
import AccountsStyle from "./AccountsStyle";
import HamburgerIcon from "../../assets/images/hamburger.png";
import axiosInstance from "../../utils/axiosInstance";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import Toast from "../../components/Toast/Toast";
import withDateTime from "../../utils/timedatehoc";
import Pagination from "../../components/pagination/pagination";
import FilterIcon from "../../assets/images/filter.png";
import Filter from "../../components/FilterDropdown/GenericFilter";
import { baseUrl } from "../../configuration/apiUrl";

import {
  mapFilters,
  filtersParams,
  handleFilterChange,
} from "../../utils/filtersHelper";
import { DateFormat } from "../../utils/formatDateTime";

const Accounts = (props) => {
  const linkRef = useRef(null);
  const [accountList, setAccountList] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [accountListLoader, setAccountListLoader] = useState(false);
  const [accountLedger, setAccountLedger] = useState({
    accountNumber: 0,
    sheetNumber: 0,
  });
  const [prevLocation, setPrevLocation] = useState("");
  const [filters, setFilters] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [query, setQuery] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setPrevLocation(props.location.search);
    getFilters();
    props.dateTime.setRefresh(() => () => {
      getAccountLedger(accountId);
    });

    return () => {
      props.dateTime.setRefresh(() => () => {});
    };
  }, []);

  useEffect(() => {
    getAllAccountsByQueryParams();
  }, []);

  useEffect(() => {
    if (prevLocation !== "" && props.location.search !== prevLocation) {
      getAllAccountsByQueryParams();
      setPrevLocation(props.location);
    }
  }, [props.location]);

  const getAllAccountsByQueryParams = () => {
    const paramValue = checkQueryParams();
    if (paramValue) {
      setLoader(true);
      setAccountListLoader(true);
      getAccountList(paramValue);
    }
  };

  const onSortTable = (type, data) => {
    setLoader(true);
    if (type === "sort") {
      const { sortOrder, sortField } = data;
      setSortField(sortField);
      setSortOrder(sortOrder);
      getAccountLedger(accountId);
    }
  };

  const checkQueryParams = () => {
    const params = queryString.parse(props.location.search);
    if (params.typeId) {
      return params.typeId;
    }
    return;
  };

  const getAccountList = async (id) => {
    let accountList = [];
    await axiosInstance
      .get(`/accounts?accountTypeIds=${id}&&limit=9999`)
      .then((response) => {
        if (response.message.status !== "200") {
          Toast(response.message.description, "error");
          setAccountList([]);
          setAccountId("");
          setAccountLedger({
            accountNumber: 0,
            sheetNumber: 0,
          });
          setLoader(false);
          setAccountListLoader(false);
        } else {
          let firstAccount = response.data.accountsList[0].id;
          accountList = response?.data?.accountsList
            ? response?.data?.accountsList
            : [];
          setAccountList(accountList);
          setAccountListLoader(false);
          setAccountId(firstAccount);
          getAccountLedger(firstAccount);
        }
      })
      .catch((err) => {
        console.log("err accounts list ", err);
        setAccountList([]);
        setAccountId("");
        setAccountLedger({
          accountNumber: 0,
          sheetNumber: 0,
        });
        setLoader(false);
        setAccountListLoader(false);
      });
    setAccountListLoader(false);
  };

  const getAccountLedger = async (id) => {
    const filter = filtersParams(filters);
    setLoader(true);
    let url = `/accounts/ledger/`;
    if (id) {
      url += id;
    } else {
      url += 1;
    }
    url += `?page=${page - 1}&limit=${limit}${filter}&search=${query}`;

    await axiosInstance
      .get(url)
      .then((response) => {
        if (response.message.status !== "200") {
          Toast(response.message.description, "error");
          setLoader(false);
          setAccountLedger((prevLedger) => ({
            ...prevLedger,
            accountLedger: [],
            accountNumber: 0,
            sheetNumber: 0,
          }));
        } else {
          setAccountLedger(
            response?.data?.accountDetail[0]
              ? response?.data?.accountDetail[0]
              : {
                  accountLedger: [],
                  accountNumber: 0,
                  sheetNumber: 0,
                }
          );
          setLoader(false);
          setTotalPages(response?.page?.totalPages || 0);
          setTotalElements(response?.page?.totalElements || 0);
        }
      })
      .catch((err) => {
        console.log("err accounts ledger ", err);
        setLoader(false);
        setAccountLedger((prevLedger) => ({
          ...prevLedger,
          accountLedger: [],
          accountNumber: 0,
          sheetNumber: 0,
        }));
      });
    setLoader(false);
  };

  useEffect(() => {
    getAccountLedger(accountId);
  }, [limit]);

  useEffect(() => {
    getAccountLedger(accountId);
  }, [page]);

  const getFilters = () => {
    const { business_date } = queryString.parse(props.location.search);
    const defaultDate = business_date ? { business_date } : null;
    axiosInstance
      .get("hierarchical/filters?screen_id=account_ledger")
      .then((res) => {
        const obj = mapFilters(res.data.filters, defaultDate);
        setFilters(obj);
        setFilterData(res.data.filters);
      })
      .catch((err) => {
        setLoader(false);
        setAccountListLoader(false);
        Toast("Something went wrong, Please try again", "error");
      });
  };

  const getAllExportedData = async () => {
    const business_date = filters?.business_date?.filter[0];

    const token = JSON.parse(localStorage.getItem("userData")).accessToken;
    try {
      const response = await fetch(
        baseUrl +
          `/accounts/export-ledger-data/${accountId}?business_date=${business_date}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const blob = await response.blob();
      const href = window.URL.createObjectURL(blob);
      const a = linkRef.current;

      a.href = href;
      a.download = `${accountId}-All General Ledger Data.csv`;
      a.click();
      a.href = "";
    } catch (error) {
      console.error(error);
    }
  };

  const setAccountById = (value) => {
    setAccountId(value);
    getAccountLedger(value);
  };

  const setFilter = (filters) => {
    setFilters(filters);
  };

  const handleFilter = () => {
    getAccountLedger(accountId);
  };

  const formatNumber = (number) => {
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
      });
    }
  };

  const formatCurrency = (field) => {
    const value = accountLedger[field] ?? 0;
    const formattedValue = value < 0 ? Math.abs(value) : value;
    return (
      <CurrencyFormat
        value={formattedValue}
        displayType="text"
        thousandSeparator={true}
        prefix={`${accountLedger.currency ?? ""} ${value < 0 ? "-" : ""}`}
      />
    );
  };

  const columns = [
    { hidden: true, dataField: "atmId", text: "ID" },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell) =>
        cell ? DateFormat(props?.dateTime?.date, cell) : "-",
    },
    {
      dataField: "details",
      text: "Details",
    },
    {
      dataField: "entryRef",
      text: "Reference",
      formatter: (cell) => (cell ? cell : "-"),
    },
    {
      dataField: "debit",
      text: "Debit",
      formatter: (cell) => (cell ? formatNumber(cell) : cell),
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "credit",
      text: "Credit",
      formatter: (cell) => (cell ? formatNumber(cell) : cell),
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "balance",
      text: "Balance",
      formatter: (cell) => (cell ? formatNumber(cell) : cell),
      align: "right",
      headerAlign: "right",
    },
  ];

  const headers = [
    {
      label: "Date",
      key: "date",
    },
    {
      label: "Details",
      key: "details",
    },
    {
      label: "Reference",
      key: "entryRef",
    },
    {
      label: "Debit",
      key: "debit",
    },
    {
      label: "Credit",
      key: "credit",
    },
    {
      label: "Balance",
      key: "balance",
    },
  ];
  return (
    <AccountsStyle className="h-100">
      <VerticalTabs
        tabList={accountList}
        setAccountById={(value) => {
          setAccountById(value);
          setPage(1);
          setTotalPages(0);
          setTotalElements(0);
        }}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        loader={accountListLoader}
        tabView={
          <div>
            {loader && (
              <div
                className="spinner-center"
                style={{ top: "0%", left: "0%", zIndex: 2000 }}
              >
                <LoaderComponent />
              </div>
            )}
            <>
              <div style={{ opacity: !loader ? "1" : "0.07" }}>
                <div className="row align-items-center d-flex flex-wrap mb-3">
                  {filterData?.map(
                    ({ filterName, filterType, filterLabel }) => (
                      <div
                        className="filter-item col-lg-5 col-sm-6 mt-2"
                        key={filterName}
                      >
                        <Filter
                          title={filterLabel}
                          type={filterType}
                          data={filters[filterName]?.data}
                          filter={filters[filterName]?.filter}
                          onChange={(e) =>
                            handleFilterChange(
                              e,
                              filterName,
                              filters,
                              setFilter
                            )
                          }
                        />
                      </div>
                    )
                  )}
                  <div className="col-2 d-flex justify-content-start mt-2">
                    <ButtonBasic
                      icon={FilterIcon}
                      title="Filter"
                      onClick={handleFilter}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="color-gray">General Ledger Statement</div>
                  <div>
                    <div className="d-flex justify-content-end h-100 align-items-center">
                      {/* <ShowForPermission permission="clk_export_acc">
                        <ButtonBasic title="Export Data" />
                      </ShowForPermission> */}
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12">
                    <CardSm
                      classes="mr-2 mt-2"
                      title="Account No."
                      value={accountLedger.accountNumber ?? "-"}
                    />
                    <CardSm
                      classes="mr-2 mt-2"
                      title="Statement No."
                      value={accountLedger.statementNumber ?? "-"}
                    />
                    <CardSm
                      classes="mr-2 mt-2"
                      title="Currency"
                      value={accountLedger.currency || "-"}
                    />
                    <CardSm
                      classes="mr-2 mt-2"
                      title="Opening Balance Date"
                      value={
                        accountLedger.openingBalanceDate
                          ? DateFormat(
                              props?.dateTime?.date,
                              accountLedger.openingBalanceDate
                            )
                          : "-"
                      }
                    />
                    <CardSm
                      classes="mr-2 mt-2"
                      title="Opening Balance"
                      value={formatCurrency("openingBalance")}
                    />
                    <CardSm
                      classes="mr-2 mt-2"
                      title="Closing Balance Date"
                      value={
                        accountLedger.closingBalanceDate
                          ? DateFormat(
                              props?.dateTime?.date,
                              accountLedger.closingBalanceDate
                            )
                          : "-"
                      }
                    />
                    <CardSm
                      classes="mr-2 mt-2"
                      title="Closing Balance"
                      value={formatCurrency("closingBalance")}
                    />
                    <CardSm
                      classes="mr-2 mt-2"
                      title="Adjusted Closing Balance"
                      value={formatCurrency("adjustedBalance")}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-5">
                  <div className="col-5">
                    <div class="form-group has-search">
                      <span class="fa fa-search form-control-feedback"></span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search by Details, Reference"
                        value={query}
                        onChange={(e) => {
                          setQuery(e.target.value);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            setPage(1);
                            handleFilter();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <button
                      className="btn btn-info btn-sm"
                      onClick={getAllExportedData}
                      disabled={accountLedger.accountLedger ? false : true}
                    >
                      Export all Data
                    </button>
                    <a ref={linkRef} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12">
                    <div className="overflow-y-auto fancy-scroll">
                      <div>
                        <BootstrapTable
                          bordered={false}
                          classes="rs-table table-layout-auto mb-0 table-custom-style"
                          wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                          keyField="atmId"
                          bootstrap4={true}
                          remote={{ pagination: true }}
                          data={
                            accountLedger.accountLedger
                              ? accountLedger.accountLedger
                              : []
                          }
                          columns={columns}
                          noDataIndication="No data found!"
                          onTableChange={onSortTable}
                        ></BootstrapTable>
                        <Pagination
                          setPageSize={setLimit}
                          page={page}
                          pageSize={limit}
                          setPage={setPage}
                          totalPages={totalPages}
                          totalElements={totalElements}
                          data={accountLedger.accountLedger || []}
                          CSVHeaders={headers}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        }
      />
    </AccountsStyle>
  );
};

export default withDateTime(Accounts);
