import React from "react";
import { Formik, Form, Field } from "formik";
import axiosInstance from "../../utils/axiosInstance";
import Toast from "../../components/Toast/Toast";
import * as Yup from "yup";

const messageSchema = Yup.object().shape({
  topic: Yup.string().required(),
});

const MessageSender = () => {
  const sendMessage = async (values) => {
    const data = {
      topic: values.topic,
      message: values.message,
    };
    axiosInstance
      .post("/common/sendtokafka", { data })
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          Toast("Message Sent successfully!", "success");
        }
      })
      .catch((error) => {
        console.log("error ", error);
        Toast("Error while sending message!", "error");
      });
  };

  return (
    <>
      <div style={{ fontSize: "18px" }} className="text-bright ml-3 mb-4">
        Message Sender
      </div>
      <Formik
        initialValues={{
          topic: "",
          message: "",
        }}
        validationSchema={messageSchema}
        onSubmit={(values, { resetForm }) => {
          sendMessage(values);
          resetForm();
          // setSelectedValue([]);
        }}
        onReset={({ resetForm }) => resetForm()}
      >
        {({ errors, touched, handleSubmit, handleReset }) => (
          <Form
            id="add-user"
            className="form"
            onSubmit={handleSubmit}
            onReset={handleReset}
          >
            <div style={{ fontSize: "18px" }} className="text-bright ml-3 mb-4">
              <div className="row mt-3">
                <div className="col-md-6 col-sm-12">
                  <div className="d-flex flex-column">
                    <label className="form-label">Topic Name</label>
                    <Field
                      className="rs-input"
                      type="text"
                      placeholder="Enter Topic"
                      name="topic"
                    />
                    {errors.topic && touched.topic ? (
                      <div className="field-error">{errors.topic}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6 col-sm-12">
                  <div className="d-flex flex-column">
                    <label className="form-label"></label>
                    <Field
                      component="textarea"
                      rows="4"
                      className="rs-input"
                      type="textarea"
                      placeholder="Message"
                      name="message"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3 ml-1">
                <button
                  type="submit"
                  name="submit"
                  className={"btn btn-info btn-md mr-2"}
                  onClick={handleSubmit}
                >
                  Send
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default MessageSender;
