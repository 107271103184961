import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import queryString from "query-string";
import axiosInstance from "../../../utils/axiosInstance";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import Toast from "../../../components/Toast/Toast";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import NewFilter from "../../../components/FilterDropdown/NewFilter";
import GoogleMap from "../../../containers/GoogleMap/NewMap";
import AddCassettesConfiguration from "./AddCassettesConfiguration";

const AtmSchema = Yup.object().shape({
  terminalId: Yup.string().required("Terminal Id is required"),
  ipPort: Yup.string().required("IP Port is required"),
  citTerminalId: Yup.string().required("CIT Terminal Id is required"),
  deviceLocation: Yup.string().required("Device Location is required"),
});

const AtmManagementView = (props) => {
  const [atm, setAtm] = useState([]);
  // eslint-disable-next-line
  const [atmLoader, setAtmLoader] = useState(true);
  const [regions, setRegions] = useState([]);
  const [regionId, setRegionId] = useState("");
  const [regionsLoader, setRegionsLoader] = useState(true);
  const [atmType, setAtmType] = useState([]);
  const [atmId, setAtmId] = useState("");
  const [atmTypeLoader, setAtmTypeLoader] = useState(true);
  const [cit, setCit] = useState([]);
  const [citId, setCitId] = useState("");
  const [citLoader, setCitLoader] = useState(true);
  const [branch, setBranch] = useState([]);
  const [branchId, setBranchId] = useState("");
  const [branchLoader, setBranchLoader] = useState(true);
  const [pos, setPos] = useState({ lat: "", lng: "" });
  const [vendorType, setVendorType] = useState("");
  const [rowsData, setRowsData] = useState([]);
  const [glAccounts, setGlAccounts] = useState([]);
  const addTableRows = () => {
    const rowsInput = {
      type: "",
      denom: "",
      currency: "",
      casUnit: "",
    };

    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  const [vendor] = useState([
    {
      id: 51,
      label: "NCR",
    },
    {
      id: 52,
      label: "Diebold-Nixdorf",
    },
  ]);
  const { deviceId } = queryString.parse(props.location.search);
  const [breadCrumbState] = useState([
    {
      id: 0,
      title: "ATM Management",
      isActive: false,
      routeTo: "/atmlist",
    },
    {
      id: 1,
      title: "View ATM",
      isActive: true,
      routeTo: `/view-atm-management?deviceId=${deviceId}`,
    },
  ]);

  const ATMInitialValues = {
    terminalId: atm.terminalId || "",
    citTerminalId: atm.citTerminalId || "",
    ipPort: atm.ipPort || "",
    atm: atm.deviceType?.atm,
    cashDepositModule: atm.deviceType?.cashDepositModule,
    chequeDepositModule: atm.deviceType?.chequeDepositModule,
    sdm: atm.deviceType?.sdm,
    deviceName: atm.deviceName || "",
    deviceLocation: atm.deviceLocation || "",
    replenishmentType: atm.replenishmentType || "",
    swap: atm.replenishmentType === "SWAP" ? true : false,
    add: atm.replenishmentType === "ADD" ? true : false,
    status: atm.status,
    atmCashGlAccount: atm.atmCashGlAccount || "",

    denom1: atm.atmCassetteConfig
      ? atm.atmCassetteConfig?.find((x) => x.type === "type_1")
      : "",
    denom2: atm.atmCassetteConfig
      ? atm.atmCassetteConfig?.find((x) => x.type === "type_2")
      : "",
    denom3: atm.atmCassetteConfig
      ? atm.atmCassetteConfig?.find((x) => x.type === "type_3")
      : "",
    denom4: atm.atmCassetteConfig
      ? atm.atmCassetteConfig?.find((x) => x.type === "type_4")
      : "",
    // cassetteId1: atm.atmCassetteConfig
    //   ? atm.atmCassetteConfig?.find((x) => x.type === "type_1")
    //   : "",
    // cassetteId2: atm.atmCassetteConfig
    //   ? atm.atmCassetteConfig?.find((x) => x.type === "type_")
    //   : "",
    // cassetteId3: atm.atmCassetteConfig
    //   ? atm.atmCassetteConfig[3].cassetteId
    //   : "",
    // cassetteId4: atm.atmCassetteConfig
    //   ? atm.atmCassetteConfig[0].cassetteId
    //   : "",
  };

  const getGlAccounts = async () => {
    axiosInstance
      .get(`/accounts/account-list?type_indicator=gl`)
      .then((res) => {
        setGlAccounts(
          res?.data?.accounts?.map((i) => {
            return {
              label: i?.name,
              value: i?.id,
            };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const mapRegions = (regions) => {
    const arr = [];
    // eslint-disable-next-line
    regions.map((region) => {
      arr.push({
        id: region.codeValue,
        label: region.label,
      });
      if (region.subClass) {
        arr.push(...mapRegions(region.subClass));
      }
    });
    return arr;
  };

  const getRegions = async () => {
    try {
      let response = await axiosInstance.get(
        "/hierarchical/filters?filter_name=region"
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setRegionsLoader(false);
      } else {
        setRegions(mapRegions(response.data?.filters[0].filterData));
        setRegionsLoader(false);
      }
    } catch (error) {
      console.log(error);
      setRegionsLoader(false);
    }
  };

  const getAtmType = async () => {
    try {
      let response = await axiosInstance.get(
        "/hierarchical/filters?filter_name=atm_type"
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setAtmTypeLoader(false);
      } else {
        setAtmType(mapRegions(response.data?.filters[0].filterData));
        setAtmTypeLoader(false);
      }
    } catch (error) {
      console.log(error);
      setAtmTypeLoader(false);
    }
  };

  const getCit = async () => {
    try {
      let response = await axiosInstance.get("/cits");
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setCitLoader(false);
      } else {
        let cit = response.data.cits.map((cit) => {
          return {
            id: cit.id,
            label: cit.name,
          };
        });
        setCit(cit);
        setCitLoader(false);
      }
    } catch (error) {
      console.log(error);
      setCitLoader(false);
    }
  };

  const getBranches = async () => {
    try {
      let response = await axiosInstance.get("/branches/concise");
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setBranchLoader(false);
      } else {
        let branches = response.data.branch.map((branch) => {
          return {
            id: branch.id,
            label: branch.name,
          };
        });
        setBranch(branches);
        setBranchLoader(false);
      }
    } catch (error) {
      console.log(error);
      setBranchLoader(false);
    }
  };

  const getATM = async (id) => {
    try {
      let response = await axiosInstance.get(`/devices/${id}`);
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        setAtmLoader(false);
      } else {
        let atmDetails = response.data.devices[0];
        setAtm(atmDetails);
        setRowsData(atmDetails?.atmCassetteConfig);
        setPos({ lat: atmDetails.latitude, lng: atmDetails.longitude });
        setRegionId(atmDetails.deviceRegion);
        setAtmId(atmDetails?.atmType);
        setCitId(atmDetails.citVendor);
        setVendorType(atmDetails.vendorType);
        setBranchId(atmDetails.branchId);
        setAtmLoader(false);
      }
    } catch (error) {
      console.log(error);
      setAtmLoader(false);
    }
  };

  const saveAtm = async (values) => {
    try {
      const data = {
        device: {
          terminalId: values.terminalId,
          citTerminalId: values.citTerminalId,
          ipPort: values.ipPort,
          vendorType: vendorType,
          deviceType: {
            atm: values.atm,
            cashDepositModule: values.cashDepositModule,
            chequeDepositModule: values.chequeDepositModule,
            sdm: values.sdm,
          },
          deviceName: values.deviceName,
          deviceLocation: values.deviceLocation,
          deviceRegion: regionId,
          longitude: pos.lng,
          latitude: pos.lat,
          replenishmentType:
            values.swap === true ? "SWAP" : values.add === true ? "ADD" : "",
          status: values.status,
          atmCashGlAccount:
            values.atmCashGlAccount === "" ? null : values.atmCashGlAccount,
          citVendor: citId === "" ? null : citId,
          branchId: branchId === "" ? null : branchId,
          atmType: atmId,
          atmCassetteConfig: [
            {
              type: "type_1",
              denom: values.denom1.denom,
              cassetteId: values.denom1.cassetteId1,
            },
            {
              type: "type_2",
              denom: values.denom2,
              cassetteId: values.denom2.cassetteId2,
            },
            {
              type: "type_3",
              denom: values.denom3,
              cassetteId: values.denom3.cassetteId3,
            },
            {
              type: "type_4",
              denom: values.denom4,
              cassetteId: values.denom4.cassetteId4,
            },
          ],
          cashDepositModuleCassetteConfig: {},
          chequeDepositModuleCassetteConfig: {},
        },
      };
      let response = await axiosInstance.post("/devices", { data });
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        Toast("ATM added successfully!", "success");
        props.history.push("/atmlist");
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
      console.log("err Save Atm ", err);
    }
  };

  useEffect(() => {
    if (!deviceId) props.history.push("/atmlist");
    else getATM(deviceId);
    getRegions();
    getCit();
    getBranches();
    getAtmType();
    getGlAccounts();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {citLoader && regionsLoader && branchLoader && atmTypeLoader && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div
        style={{
          opacity:
            !citLoader || !regionsLoader || !atmTypeLoader || !branchLoader
              ? "1"
              : "0.07",
        }}
        className="rs-basic-card"
      >
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <Breadcrumbs
              data={breadCrumbState}
              history={props.history}
              onClick={(e) => {}}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="d-flex align-items-center mt-3">
              <div
                style={{
                  paddingRight: "10px",
                  marginRight: "10px",
                  fontFamily: "Montserrat SemiBold",
                }}
              >
                ATM Records
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <Formik
              initialValues={ATMInitialValues}
              validationSchema={AtmSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                if (vendorType !== "" && regionId !== "") {
                  // console.log(
                  //   "Form is validated! Submitting the form...",
                  //   values
                  // );
                  saveAtm(values);
                }
              }}
            >
              {({ errors, touched, handleSubmit, values, setFieldValue }) => (
                <Form id="add-atm" className="form" onSubmit={handleSubmit}>
                  <Tabs
                    defaultActiveKey="user"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                  >
                    {/* //#region Form1 */}
                    <Tab
                      className="mb-sm-2 mb-md-0"
                      eventKey="user"
                      title="General Configuration"
                    >
                      <fieldset disabled>
                        <div className="row mt-3">
                          <div className="col-xl-6 col-12">
                            <div className="d-flex flex-row mt-3 flex-wrap align-items-end mt-4">
                              <div className="col-sm-6 col-12">
                                <label className="form-label">
                                  TERMINAL ID
                                </label>
                                <Field
                                  id="terminalId"
                                  className="form-control rs-input"
                                  type="text"
                                  placeholder="Enter Terminal Id"
                                  name="terminalId"
                                />
                                {touched.terminalId && errors.terminalId ? (
                                  <div className="field-error">
                                    {errors.terminalId}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-sm-6 col-12 mt-4">
                                <label className="form-label">
                                  CIT DEFINED TERMINAL ID
                                </label>
                                <Field
                                  id="citTerminalId"
                                  className="form-control rs-input"
                                  type="text"
                                  placeholder="Enter CIT TerminalId"
                                  name="citTerminalId"
                                />
                                {touched.citTerminalId &&
                                errors.citTerminalId ? (
                                  <div className="field-error">
                                    {errors.citTerminalId}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="d-flex flex-row flex-wrap align-items-end mt-4">
                              <div className="col-sm-6 col-12">
                                <label className="form-label">
                                  IP ADDRESS : PORT
                                </label>
                                <Field
                                  id="ipPort"
                                  className="form-control rs-input"
                                  type="text"
                                  placeholder="Enter IP Address:Port"
                                  name="ipPort"
                                />
                                {touched.ipPort && errors.ipPort ? (
                                  <div className="field-error">
                                    {errors.ipPort}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-sm-6 col-12 mt-4">
                                <label className="form-label">
                                  DEVICE NAME
                                </label>
                                <Field
                                  id="deviceName"
                                  className="form-control rs-input"
                                  type="text"
                                  placeholder="Enter Device Name"
                                  name="deviceName"
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-row flex-wrap align-items-end mt-4">
                              <div className="col-sm-6 col-12">
                                <label className="form-label">VENDOR</label>
                                <NewFilter
                                  id="AtmVendor"
                                  title={
                                    vendor.find((i) => i.label === vendorType)
                                      ?.label ?? "Select Vendor"
                                  }
                                  type={"checkbox"}
                                  field={"label"}
                                  data={vendor}
                                  filter={vendorType}
                                  onChange={(e) => setVendorType(e)}
                                  isSearchable
                                  searchField="label"
                                />
                              </div>

                              <div className="col-sm-6 col-12 mt-4">
                                <label className="form-label">CIT</label>
                                <NewFilter
                                  id="AtmCIT"
                                  title={
                                    cit.find((i) => i.id === citId)?.label ??
                                    "Select CIT"
                                  }
                                  type={"checkbox"}
                                  field={"id"}
                                  data={cit}
                                  filter={citId}
                                  onChange={(e) => setCitId(e)}
                                  isSearchable
                                  searchField="label"
                                />
                              </div>
                            </div>

                            <div className="d-flex flex-row flex-wrap align-items-end mt-4">
                              <div className="col-sm-6 col-lg-12">
                                <label className="form-label">
                                  DEVICE TYPE
                                </label>
                                <div className="d-flex flex-row flex-wrap">
                                  <div className="custom-control custom-checkbox mr-2">
                                    <Field
                                      id="atm"
                                      type="checkbox"
                                      className="custom-control-input"
                                      name="atm"
                                    />
                                    <label className="custom-control-label">
                                      ATM
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mr-2">
                                    <Field
                                      id="cashDepositModule"
                                      type="checkbox"
                                      className="custom-control-input"
                                      name="cashDepositModule"
                                    />
                                    <label className="custom-control-label">
                                      Cash Deposit Module
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mr-2">
                                    <Field
                                      id="chequeDepositModule"
                                      type="checkbox"
                                      className="custom-control-input"
                                      name="chequeDepositModule"
                                    />
                                    <label className="custom-control-label">
                                      Cheque Deposit Module
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mr-2">
                                    <Field
                                      id="sdm"
                                      type="checkbox"
                                      className="custom-control-input"
                                      name="sdm"
                                    />
                                    <label className="custom-control-label">
                                      SDM
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex flex-row flex-wrap align-items-end mt-4">
                              <div className="col-sm-6 col-12">
                                <label className="form-label">
                                  ATM CASH GL ACCOUNT
                                </label>
                                {/* <Field
                                  className="form-control rs-input"
                                  type="text"
                                  placeholder="Enter GL Account"
                                  name="atmCashGlAccount"
                                /> */}
                                <NewFilter
                                  id="AtmCashGL"
                                  title={
                                    glAccounts.find(
                                      (i) =>
                                        i?.value?.toString() ===
                                        values?.atmCashGlAccount?.toString()
                                    )?.label ?? "Select GL Account"
                                  }
                                  type={"checkbox"}
                                  field={"value"}
                                  data={glAccounts}
                                  filter={parseInt(values?.atmCashGlAccount)}
                                  onChange={(e) =>
                                    setFieldValue("atmCashGlAccount", e)
                                  }
                                  isSearchable
                                  searchField="label"
                                />
                              </div>
                              <div className="col-sm-6 col-12 mt-4">
                                <label className="form-label">BRANCH</label>
                                <NewFilter
                                  id="AtmBranch"
                                  title={
                                    branch.find((i) => i.id === branchId)
                                      ?.label ?? "Select Branch"
                                  }
                                  type={"checkbox"}
                                  field={"id"}
                                  data={branch}
                                  filter={branchId}
                                  onChange={(e) => setBranchId(e)}
                                  isSearchable
                                  searchField="label"
                                />
                              </div>
                            </div>

                            <div className="d-flex flex-row flex-wrap align-items-end mt-4">
                              <div className="col-sm-6 col-6">
                                <label className="form-label">
                                  REPLENISHMENT TYPE
                                </label>
                                <div className="d-flex flex-row flex-wrap">
                                  <div className="custom-control custom-checkbox mr-2">
                                    <Field
                                      id="swap"
                                      type="checkbox"
                                      className="custom-control-input"
                                      name="swap"
                                      onClick={() => {
                                        values.swap = true;
                                        values.add = false;
                                      }}
                                    />
                                    <label className="custom-control-label">
                                      Swap
                                    </label>
                                  </div>
                                  <div className="custom-control custom-checkbox mr-2">
                                    <Field
                                      id="add"
                                      type="checkbox"
                                      className="custom-control-input"
                                      name="add"
                                      onClick={() => {
                                        values.add = true;
                                        values.swap = false;
                                      }}
                                    />
                                    <label className="custom-control-label">
                                      Add
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 col-6">
                                <label className="form-label">ATM TYPE</label>
                                <NewFilter
                                  id="AtmType"
                                  title={
                                    atmType.find(
                                      (r) =>
                                        r?.id?.toString() === atmId?.toString()
                                    )?.label ?? "Select ATM Type"
                                  }
                                  type={"checkbox"}
                                  data={atmType}
                                  field={"id"}
                                  filter={atmId ? atmId?.toString() : ""}
                                  onChange={(e) => setAtmId(e)}
                                  searchField="label"
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-row flex-wrap align-items-end mt-4">
                              <div className="col-sm-12 col-md-12 col-lg-6">
                                <label className="form-label">STATUS</label>
                                <div className="d-flex">
                                  <div
                                    style={{ width: "100%" }}
                                    className="form-check"
                                  >
                                    <Field
                                      id="active"
                                      className="form-check-input"
                                      type="radio"
                                      name="status"
                                      value={"active"}
                                    />
                                    <label className="form-check-label">
                                      Active
                                    </label>
                                  </div>
                                  <div
                                    style={{ width: "100%" }}
                                    className="form-check ml-4"
                                  >
                                    <Field
                                      id="inActive"
                                      className="form-check-input"
                                      type="radio"
                                      name="status"
                                      value={"inActive"}
                                    />
                                    <label className="form-check-label">
                                      In Active
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 col-lg-6">
                            <div className="d-flex flex-row flex-wrap align-items-end mt-4 mt-3">
                              <div className="col-sm-6 col-12">
                                <label className="form-label">
                                  DEVICE LOCATION
                                </label>
                                <Field
                                  id="deviceLocation"
                                  className="form-control rs-input"
                                  type="text"
                                  placeholder="Enter Device Location"
                                  name="deviceLocation"
                                />
                                {touched.deviceLocation &&
                                errors.deviceLocation ? (
                                  <div className="field-error">
                                    {errors.deviceLocation}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-sm-6 col-12 mt-4">
                                <label className="form-label">
                                  DEVICE REGION
                                </label>
                                <NewFilter
                                  id="DeviceRegion"
                                  title={
                                    regions.find(
                                      (r) => parseInt(r.id) === regionId
                                    )?.label ?? "Select Region"
                                  }
                                  type={"checkbox"}
                                  data={regions}
                                  field={"id"}
                                  filter={regionId}
                                  onChange={(e) => setRegionId(e)}
                                  isSearchable
                                  searchField="label"
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-row flex-wrap   align-items-end mt-4">
                              <div className="col-sm-6 col-12">
                                <label className="form-label">LATITUDE</label>
                                <input
                                  id="latitude"
                                  className="form-control rs-input"
                                  type="text"
                                  placeholder="Enter Latitude"
                                  name="latitude"
                                  value={pos?.lat}
                                  onChange={(e) =>
                                    setPos({ ...pos, lat: e.target.value })
                                  }
                                />
                                {pos?.lat === "" ? (
                                  <div className="field-error">
                                    Required field*
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-sm-6 col-12 mt-4">
                                <label className="form-label">LONGITUDE</label>
                                <input
                                  id="longitude"
                                  className="form-control rs-input"
                                  type="text"
                                  placeholder="Enter Longitude"
                                  name="longitude"
                                  value={pos.lng}
                                  onChange={(e) =>
                                    setPos({ ...pos, lng: e.target.value })
                                  }
                                />
                                {pos?.lng === "" ? (
                                  <div className="field-error">
                                    Required field*
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div
                              className="mt-3"
                              style={{ borderRadius: "17px", width: "100%" }}
                            >
                              <GoogleMap
                                className="ml-4"
                                mapStyle={{ width: "100%", height: "35vh" }}
                                onClick={() => ""}
                                pos={pos}
                                center={{ lat: pos.lat, lng: pos.lng }}
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      {/* #endregion */}
                    </Tab>

                    <Tab
                      className="mb-sm-2 mb-md-0"
                      eventKey="permissions2"
                      title="ATM Cassette Configuration"
                    >
                      <fieldset disabled>
                        <div className="row mt-5 d-flex justify-content-center  align-items-center">
                          <div className="col-2">
                            <div className="text-center">CASSETTE UNIT</div>
                          </div>
                          <div className="col-2">
                            <div className="text-center">TYPE</div>
                          </div>
                          <div className="col-2">
                            <div className="text-center">CURRENCY</div>
                          </div>
                          <div className="col-2">
                            <div className="text-center">DENOMINATION</div>
                          </div>
                          <div className="col-2">
                            <button
                              id="addBtn"
                              className="btn btn-outline-success"
                              type="button"
                              onClick={addTableRows}
                            >
                              +
                            </button>
                          </div>
                        </div>

                        <AddCassettesConfiguration
                          rowsData={rowsData}
                          deleteTableRows={deleteTableRows}
                          handleChange={handleChange}
                        />
                      </fieldset>
                    </Tab>

                    {/* <Tab
                        className="mb-sm-2 mb-md-0"
                        eventKey="permissions"
                        title="ATM Cassette Configuration"
                      >
                        <div className="row mt-5 align-items-center">
                          <div className="col-12 mb-5">
                            <div className="text-center">DENOMINATION</div>
                          </div>
                        </div>
                        <div className="row mt-3 align-items-center d-flex justify-content-around">
                          <div>
                            <div className="text-center">CASSETTE 1</div>
                          </div>
                          <div>
                            <div className="text-center">
                              <Field
                                className="form-control rs-input"
                                type="number"
                                placeholder="10"
                                name="denom1.denom"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3 align-items-center d-flex justify-content-around">
                          <div>
                            <div className="text-center">CASSETTE 2</div>
                          </div>
                          <div>
                            <div className="text-center">
                              <Field
                                className="form-control rs-input"
                                type="number"
                                placeholder="10"
                                name="denom2.denom"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3 align-items-center d-flex justify-content-around">
                          <div>
                            <div className="text-center">CASSETTE 3</div>
                          </div>
                          <div>
                            <div className="text-center">
                              <Field
                                className="form-control rs-input"
                                type="number"
                                placeholder="10"
                                name="denom3.denom"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3 d-flex justify-content-around align-items-center">
                          <div>
                            <div className="text-center">CASSETTE 4</div>
                          </div>
                          <div>
                            <div className="text-center">
                              <Field
                                className="form-control rs-input"
                                type="number"
                                placeholder="10"
                                name="denom4.denom"
                              />
                            </div>
                          </div>
                        </div>
                      </Tab> */}
                  </Tabs>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AtmManagementView;
