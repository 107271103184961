import React from "react";
import "./RoundedTab.css";

class RoundedTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {id, title, showTransactionSummary, toggleState, state } = this.props;
    return (
      <div
        id={id}
        className={`tsg-tab ${showTransactionSummary === state && "active"}`}
        onClick={toggleState}
      >
        {title}
      </div>
    );
  }
}

export default RoundedTab;
