import Toast from "../components/Toast/Toast";
import axiosInstance from "../utils/axiosInstance";

export const getRegionsData = async () => {
  try {
    const response = await axiosInstance.get(
      "/hierarchical/filters?filter_name=region"
    );
    if (response.message.status !== "200") {
      Toast(response.message.description, "error");
    } else {
      return response;
    }
  } catch (err) {
    console.log("err in get region management ", err);
  }
};

export const getRegions = async () => {
  try {
    const response = await axiosInstance.get("/hierarchical");
    if (response.message.status !== "200") {
      Toast(response.message.description, "error");
    } else {
      return response;
    }
  } catch (err) {
    console.log("err in get region management ", err);
  }
};

export const createRegion = async (data) => {
  try {
    const response = await axiosInstance.post("/hierarchical ", {
      data: data,
    });
    if (response.message.status !== "200") {
      Toast(response.message.description, "error");
    } else {
      return response;
    }
  } catch (err) {
    Toast(err.response.data.message.description, "error");
  }
};

export const updateRegion = async (data, id) => {
  try {
    const response = await axiosInstance.put(`/hierarchical`, {
      data: data,
    });
    if (response.message.status !== "200") {
      Toast(response.message.description, "error");
    } else {
      return response;
    }
  } catch (err) {
    Toast(err.response.data.message.description, "error");
  }
};

export const deleteRegion = async (regionId) => {
  try {
    const response = await axiosInstance.delete(`/hierarchical/${regionId}?type=region`);
    if (response.message.status !== "200") {
      Toast(response.message.description, "error");
    } else {
      return response;
    }
  } catch (err) {
    Toast(err?.response?.data?.message?.description, "error");
  }
};
