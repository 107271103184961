import React from "react";

export default function SubFilterDropdown(props) {
  const { val } = props;
  return (
    <>
      {val.subClass &&
        val.subClass.map((val2) => (
          <div key={val2.id} className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input dropdown-checkbox"
              value={val.id}
              checked={val2.param1 ? parseInt(val2.param1) : false}
              id={val2.id}
              onChange={(e) => props.onDropdownChange(e)}
              // checked={val2.check}
            />
            <label
              className="custom-control-label"
              htmlFor={val2.id}
              //   onClick={(e) => checkDropDown(e)}
            >
              {val2.label}
            </label>
            {val2.subClass && (
              <SubFilterDropdown
                val={val2}
                onDropdownChange={(e) => props.onDropdownChange(e)}
              />
            )}
            {/* <SubFilterDropdown val={val} /> */}
            {/* {val2.subClass &&
                  val2.subClass.map((subval3) => (
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        value={subval3.id}
                        checked={parseInt(subval3.param1)}
                        id={subval3.id}
                        onChange={(e) => props.onDropdownChange(e)}
                        // checked={subval3.check}
                      />
                      <label
                        className="custom-control-label"
                        for={subval3.label}
                      >
                        {subval3.label}
                      </label>
                    </div>
                  ))} */}
          </div>
        ))}
    </>
  );
}
