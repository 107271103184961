import React from "react";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import { ButtonBasicStyle, ButtonCommonStyle } from "./ButtonsStyle";

class ButtonBasic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      id = "",
      title = "Primary",
      icon,
      classes,
      wrapperClass,
      onClick,
      type,
      name,
      style,
      spinner,
      disabled,
    } = this.props;
    return (
      <ButtonCommonStyle>
        <ButtonBasicStyle className={`mr-2 ${wrapperClass}`}>
          {type ? (
            <Button
              id={id}
              className={classes}
              onClick={onClick}
              type={type}
              name={name}
              style={style}
            >
              {title}
              {icon && (
                <img
                  width={24}
                  height={24}
                  src={icon}
                  className="right-btn ml-2"
                  alt="rightbtn"
                />
              )}
              <span style={{ position: "absolute", right: "35px" }}>
                {spinner && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </span>
            </Button>
          ) : (
            <Button
              id={id}
              className={classes}
              onClick={onClick}
              disabled={disabled}
            >
              {icon && (
                <img
                  width={24}
                  height={24}
                  src={icon}
                  className="right-btn"
                  alt="rightbtn"
                />
              )}
              <span className={`${icon && "d-xs-none d-sm-none"} d-lg-flex`}>
                {title}
              </span>
            </Button>
          )}
        </ButtonBasicStyle>
      </ButtonCommonStyle>
    );
  }
}

export default ButtonBasic;
