import styled from "styled-components";

const FilterDropdownStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .dropdown-menu {
    color: #777777;
    width: 100%;
    box-shadow: 1px 4px 10px 4px rgb(0 0 0 / 25%);
    border-radius: 12px;
    margin-top: 10px;
    min-width: 340px;
  }
  .dropdown-menu .dropdown-search {
    background-color: ${({ theme }) => theme.card_background};
    border-bottom: 1px solid ${({ theme }) => theme.dropdown_border};
    border-left: 0;
    color: ${({ theme }) => theme.text.base};
  }
  .dropdown-icon {
    filter: brightness(0.7);
  }
  .dropdown-menu-tree-style {
    margin-top: 0px !important;
    box-shadow: none !important;
    max-height: 320px;
  }

  .profile-dropdown {
    padding: 0px !important;
  }
`;

export default FilterDropdownStyle;
