import * as Yup from "yup";

export const errorStyling = {
  marginTop: "2px",
  fontSize: "12px",
  top: "100%",
  color: "red",
  left: 10,
};

const ReconValidationSchema = Yup.object().shape({
  matching: Yup.string().required("Relationship is required"),
  tolerance: Yup.number()
    .typeError("Only Numbers allowed!")
    .required("Missing Tolerance is required")
    .typeError("Tolerance must be a number")
    .min(0, "Tolerance must be a positive number"),
  batchSize: Yup.number()
    .typeError("Only Numbers allowed!")
    .required("Batch Size is required")
    .typeError("Batch Size must be a number")
    .min(0, "Batch Size must be positive number"),
  fromFetchCriteria: Yup.object().required("DS-A Fetch Criteria is required"),
  toFetchCriteria: Yup.object().required("DS-B Fetch Criteria is required"),
  pairExecution: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Rule Name is required"),
      reconType: Yup.string().required("Rule Type is required"),
      matchingBucket: Yup.string().required("Matching Bucket is required"),
    })
  ),
});

const DSAValidationSchema = Yup.object().shape({
  ds_a_field_name: Yup.string().required("DSA Field Name is required"),
  operator: Yup.string().required("Operator is required"),
});

const DSBValidationSchema = Yup.array().of(
  Yup.object().shape({
    ds_b_field_name: Yup.string().when("operator", {
      is: "IGNORE",
      then: Yup.string().optional(),
      otherwise: Yup.string().required("DSB Field Name is required"),
    }),
    operator: Yup.string().required("Operator is required"),
  })
);

const DSBORValidationSchema = Yup.object().shape({
  OR: Yup.array().of(
    Yup.object().shape({
      ds_b_field_name: Yup.string().when("operator", {
        is: "IGNORE",
        then: Yup.string().optional(),
        otherwise: Yup.string().required("DSB Field Name is required"),
      }),
      operator: Yup.string().required("Operator is required"),
    })
  ),
});

const AggregateValidationSchema = Yup.object().shape({
  ds_b_field_name: Yup.string().required("DSB Field Name is required"),
  operator: Yup.string().required("Operator is required"),
  resultset_col_name: Yup.string().required(
    "Resultset Column Name is required"
  ),
});

const MatchingRuleValidationSchema = Yup.object().shape({
  ds_a_field_name: Yup.string().when("operator", {
    is: "IGNORE",
    then: Yup.string().optional(),
    otherwise: Yup.string().required("DSA Field Name is required"),
  }),
  ds_b_field_name: Yup.string().when("operator", {
    is: "IGNORE",
    then: Yup.string().optional(),
    otherwise: Yup.string().required("DSB Field Name is required"),
  }),
  operator: Yup.string().required("Operator is required"),
  regex: Yup.string()
    .optional()
    .when("operator", {
      is: "REGEX-MATCH",
      then: Yup.string().required("Regex is required"),
    }),
});

export {
  DSAValidationSchema,
  DSBValidationSchema,
  DSBORValidationSchema,
  AggregateValidationSchema,
  MatchingRuleValidationSchema,
  ReconValidationSchema,
};
