
import styled from "styled-components";

const SummaryDashboardStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`

.chart-loader {
    background-color: ${({ theme }) => theme.background};
}

`;


export default SummaryDashboardStyle;


