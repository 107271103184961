import Toast from "../components/Toast/Toast";
import axiosInstance from "../utils/axiosInstance";

export const getInterfaces = async (page, pageSize, sort_by, sort_order) => {
  try {
    const response = await axiosInstance.get(
      `/interfaces/interfaces-list?page=${page ? page - 1 : 0}&size=${
        pageSize ?? 15
      }&sort_by=${sort_by ? sort_by : ""}&sort_order=${
        sort_order ? sort_order : ""
      }`
    );
    if (response.message.status !== "200") {
      Toast(response.message.description, "error");
    } else {
      return response;
    }
    if (response.message.status !== "200") {
      Toast(response.message.description, "error");
    } else {
      return response;
    }
  } catch (err) {
    console.log("err in get region management ", err);
  }
};

export const getDataSource = async () => {
  try {
    const response = await axiosInstance.get("/interfaces/datasources");
    if (response.message.status !== "200") {
      Toast(response.message.description, "error");
    } else {
      return response;
    }
  } catch (err) {
    console.log("err get data source ", err);
  }
};

export const createInterface = async (data) => {
  try {
    const response = await axiosInstance.post("/interfaces ", {
      data: data,
    });
    if (response.message.status !== "200") {
      Toast(response.message.description, "error");
    } else {
      return response;
    }
  } catch (err) {
    Toast(err.response.data.message.description, "error");
  }
};

export const updateInterface = async (data, interfaceId) => {
  try {
    const response = await axiosInstance.put(`/interfaces/${interfaceId}`, {
      data: data,
    });
    if (response.message.status !== "200") {
      Toast(response.data.message.description, "error");
    } else {
      return response;
    }
  } catch (error) {
    Toast(error.response.data.message.description, "error");
  }
};

export const deleteInterface = async (interfaceId) => {
  try {
    const response = await axiosInstance.delete(`/interfaces/${interfaceId}`);
    if (response.message.status !== "200") {
      Toast(response.data.message.description, "error");
    } else {
      return response;
    }
  } catch (error) {
    Toast(error.response.data.message.description, "error");
  }
};
