import styled from "styled-components";

const NavbarStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 20%);
  width: calc(100% - 270px);
  align-items: center;
  justify-content: space-between;
  padding: 12px 30px 12px 43px;
  background: ${({ theme }) => theme.card_background};

  @media (max-width: 764px) {
    width: 100%;
  }

  .overview {
    font-family: ${({ theme }) => theme.fonts.family.primary.semiBold};
    font-size: 20px;
  }
  .user-name {
    font-family: ${({ theme }) => theme.fonts.family.primary.semiBold};
    font-size: 1rem;
    color: ${({ theme }) => theme.text.bright};
  }
  .user-title {
    font-family: ${({ theme }) => theme.fonts.family.primary.regular};
    font-size: 14px;
  }
  .search-input {
    border-radius: 25.5px;
    padding: 10px 25px;
    font-size: 14px;
    min-width: 272px;
    background-color: ${({ theme }) => theme.background};
    border: none;
    font-family: "Montserrat medium";
    color: ${({ theme }) => theme.text.base};
    margin-right: 25px;
    ::placeholder {
      font-family: ${({ theme }) => theme.fonts.family.primary.medium};
      color: ${({ theme }) => theme.text.base};
      letter-spacing: 0.5px;
    }
  }
  .header-right {
    .search {
      margin-right: 24px;
      cursor: pointer;
    }
  }
  .notification-icon-wrapper {
    background: ${({ theme }) => theme.card_background};
    border: none;
    border-radius: 50%;
    background: ${({ theme }) => theme.card_background};
    border: none;

    width: 40px;
    height: 40px;
    align-self: center;
    justify-content: center;
    margin-right: 24px;
    cursor: pointer;
    &:hover {
      background: ${({ theme }) => theme.hover};
    }

    .notification-badge {
      display: flex;
      align-items: center;
      font-size: 11px;
      background: red;
      position: absolute;
      top: 5px;
      right: 30px;
      border-radius: 100%;
      width: 15px;
      height: 15px;
      justify-content: center;
      color: ${({ theme }) => theme.text.dark};
    }
  }

  .mark-as-read {
    font-size: 10px;
    align-self: flex-end;
    text-decoration: underline;
    cursor: pointer;
    color: ${({ theme }) => theme.chartColors.info};
  }

  .user-card-menu {
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    &:hover {
      background: ${({ theme }) => theme.hover};
    }
  }

  .rs-dropdown-menu.dropdown-menu {
    min-width: 350px;
    background-color: ${({ theme }) => theme.card_background};
  }
  .dropdown-menu.notification {
    min-width: 450px;
    min-height: 535px;
    padding-bottom: 15px;
  }
  .rs-dropdown-menu {
    .rs-dropdown-item {
      font-size: 14px;
    }
    .rs-dropdown-item.dark-mode {
      border-bottom: 1px solid gray;
      padding-bottom: 15px;
      cursor: pointer;
    }
    .rs-dropdown-item.logout {
      padding-top: 15px;
      border-top: 1px solid gray;
      cursor: pointer;
    }
    .rs-service-status {
      .rs-color-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-left: 10px;
      }
      .rs-color-circle.success {
        background-color: #27ae60;
      }
    }

    .rs-notification {
      .rs-notification-h {
        font-family: ${({ theme }) => theme.fonts.family.primary.semiBold};
        font-size: 20px;
        color: ${({ theme }) => theme.text.dark};
      }
      .rs-notification-link {
        color: #118ab2;
        text-decoration: underline;
      }
    }
    .rs-notification-category {
      padding: 15px 25px;
      background-color: ${({ theme }) => theme.background};
      font-family: ${({ theme }) => theme.fonts.family.primary.bold};
      font-size: 16px;
      color: ${({ theme }) => theme.text.black};
      text-transform: capitalize;
    }
    .notification-list {
      margin-top: 10px;
      .notification-list-item {
        color: ${({ theme }) => theme.text.black};
        font-family: ${({ theme }) => theme.fonts.family.primary.medium};
        padding: 20px 15px;
        font-size: 14px;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          background-color: ${({ theme }) => theme.hover};
        }
        .notification-time {
          font-family: ${({ theme }) => theme.fonts.family.primary.regular};
          font-size: 12px;
          color: ${({ theme }) => theme.text.base};
        }
      }
    }
  }
  @media (max-width: 764px) {
    .notification-icon-wrapper {
      margin-right: 10px;
    }
  }

  @media (min-width: 275px) {
    .dropdown-menu.notification {
      min-width: 300px;
      min-height: 300px;
    }
  }

  @media (min-width: 576px) {
    .dropdown-menu.notification {
      min-width: 350px;
      min-height: 350px;
    }
  }

  @media (min-width: 768px) {
    .dropdown-menu.notification {
      min-width: 400px;
    }
  }

  @media (min-width: 1100) {
    .dropdown-menu.notification {
      min-width: 425px;
    }
  }

  @media (min-width: 1200px) {
    .dropdown-menu.notification {
      min-width: 450px;
    }
  }
`;

export default NavbarStyle;
