import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import Select from "react-select";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import axiosInstance from "../../utils/axiosInstance";
import Filter from "../../components/FilterDropdown/GenericFilter";
import FilterDropdown from "../../components/FilterDropdown/FilterDropdown";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import FilterIcon from "../../assets/images/filter.png";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import { useDateTime } from "../../components/Helper/DateTime";
import { mapdata, regionCheck } from "../../utils/regionHelper";
import Toast from "../../components/Toast/Toast";
import NewFilter from "../../components/FilterDropdown/NewFilter";
import {
  mapFilters,
  filtersParams,
  handleFilterChange,
  handleNewFilter,
} from "../../utils/filtersHelper";
import { DateTimeFormat } from "../../utils/formatDateTime";
const EventLog = (props) => {
  const { dateTime, setRefresh } = useDateTime();
  const [logs, setLogs] = useState([]);
  const [logsLoader, setLogsLoader] = useState(true);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [levels, setLevels] = useState([
    { id: "all", label: "All", param1: "1" },
    { id: "info", label: "Info", param1: "0" },
    { id: "warn", label: "Warn", param1: "0" },
    { id: "error", label: "Error", param1: "0" },
  ]);
  const [selectedLevel, setSelectedLevel] = useState("all");
  const [selectedLevelName, setSelectedLevelName] = useState("All");
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [showFilters, setShowFilters] = useState([]);
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [regions, setRegions] = useState([]);
  const regionFilter = useRef("");

  const getLogs = (filter) => {
    let allFilters = filtersParams(filter, null, true);
    setLogsLoader(true);
    let url = `/log/event-log?${allFilters}&page=${page}&size=20`;
    if (selectedLevel !== "all") {
      url += `&level=${selectedLevel}`;
    }
    axiosInstance
      .get(url)
      .then((response) => {
        if (response.message.status !== "200") {
          // Toast(response.message.description, "error");
          setLogsLoader(false);
          setError(true);
        } else {
          let logs = response.data;
          if (logs !== null) {
            setLogs((oldLogs) => [...oldLogs, ...logs]);
            setLogsLoader(false);
          } else {
            setLogsLoader(false);
            setError(true);
          }
        }
      })
      .catch((error) => {
        setLogsLoader(false);
      });
  };
  // const fetchData = useCallback(
  //   (filter, flag) => {
  //     setLoading(true);
  //     let allFilters = filtersParams(filter, regionFilter.current);
  //     const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
  //     axiosInstance
  //       .get(
  //         `/apihere?page=${flag ? 0 : page - 1}&size=${pageSize}${
  //           sort.sort_by && sort.sort_order ? order : ""
  //         }${allFilters}`
  //       )
  //       .then((res) => {
  //         if (res.message.status !== "200") {
  //           Toast(res.message.description, "error");
  //           setLoading(false);
  //           setTotalElements(0);
  //         } else {
  //           setData(res.data.transactions);
  //           setPage(res.page.number + 1);
  //           setTotalPages(res.page.totalPages);
  //           setTotalElements(res?.page?.totalElements);
  //           setLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //         setLoading(false);
  //       });
  //   },
  //   [page, pageSize, sort]
  // );

  const getFilters = useCallback(() => {
    let data;
    if (props.location?.state?.data) {
      // eslint-disable-next-line
      data = props.location.state.data;
    }
    axiosInstance
      .get("hierarchical/filters?screen_id=event_log")
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          let regions = res?.data?.filters?.filter(
            ({ filterName }) => filterName === "region"
          );
          let otherFilters = res?.data?.filters?.filter(
            ({ filterName }) => filterName !== "region"
          );
          // debugger;
          let obj = mapFilters(res.data.filters.filter((x) => x.showOnScreen));
          if (regions.length > 0) {
            regions[0].filterData = mapdata(regions[0].filterData);
            regions[0].filterData.forEach((x) => {
              x.checked = true;
            });
            regionFilter.current = `&region_id=${regions[0].filterData
              .map(({ codeValue }) => codeValue)
              .join(",")}`;
          }
          setFilter(obj);
          // fetchData(obj);
          setRegions(regions);
          const filteredFilters = otherFilters.filter((x) => x.showOnScreen);
          const showFiltersData = otherFilters.filter((x) => !x.showOnScreen);
          setFilterData(filteredFilters);
          setShowFilters(showFiltersData);
          getLogs(obj);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [props.location.state]);

  // const setRegionIds = (value) => {
  //   if (value?.length > 0) {
  //     regionFilter.current = `&region_id=${value
  //       .map((node) => node.codeValue)
  //       .join(",")}`;
  //   } else {
  //     regionFilter.current = "";
  //   }
  //   const regionsData = { ...regions };
  //   regionsData.filterData = regionCheck(regions[0].filterData, value);
  //   setRegions(regionsData);
  // };

  // useEffect(() => {
  //   // fetchData(filter);
  //   // eslint-disable-next-line
  // }, [fetchData]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  useEffect(() => {
    page > 0 && getLogs(filter);
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    setRefresh(() => () => {
      setLogs([]);
      setPage(0);
      getLogs();
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, []);

  const handleScroll = (e) => {
    const target = e.target;
    if (
      Math.round(target.scrollTop + target.clientHeight) >= target.scrollHeight
    ) {
      console.log(
        Math.round(target.scrollTop + target.clientHeight),
        target.scrollHeight
      );
      setLogsLoader(true);
      setPage((oldPage) => oldPage + 1);
    }
  };

  const selectLevel = (e) => {
    let id = e.target.value;
    // eslint-disable-next-line
    levels.map((level) => {
      if (level.id === id) {
        if (level.param1 === "0") {
          level.param1 = "1";
          setSelectedLevelName(level.label);
          setSelectedLevel(level.id);
        }
      } else {
        level.param1 = "0";
      }
    });
    setLevels([...levels]);
  };

  const duration = [
    {
      label: "Off",
      value: 0,
    },
    {
      label: "5s",
      value: 5000,
    },
    {
      label: "10s",
      value: 10000,
    },
    {
      label: "20s",
      value: 20000,
    },
    {
      label: "30s",
      value: 30000,
    },
    {
      label: "60s",
      value: 60000,
    },
  ];

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(() => getLogs(filter), refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  return (
    <div>
      {logsLoader && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div
        style={{
          opacity: !logsLoader ? "1" : "0.07",
        }}
      >
        <div className="row mb-4 pl-2">
          {/* <div className="col-sm-6 col-lg-2">
            <label>Date</label>
            <NewFilter
              title={date !== "" ? date : "Select Date"}
              type="date"
              date={date ? new Date(date) : null}
              onChange={(e) => setDate(e)}
            />
          </div>
          <div className="col-sm-6 col-lg-2">
            <label>Severity</label>
            <FilterDropdown
              title={selectedLevelName}
              type="radio"
              isSearch={false}
              data={levels}
              onDropdownChange={(e) => selectLevel(e)}
            />
          </div> */}
          {filterData.map(
            ({
              filterName,
              filterType,
              filterLabel,
              showOnScreen,
              filterId,
            }) => (
              <div
                className="filter-item mt-2 ml-3 d-flex"
                // style={{ minWidth: "210px" }}
              >
                <Filter
                  title={filterLabel}
                  filterId={filterId}
                  type={filterType}
                  data={filter[filterName]?.data}
                  filter={filter[filterName]?.filter}
                  removable={!showOnScreen}
                  onChange={(e) =>
                    handleFilterChange(e, filterName, filter, setFilter)
                  }
                  onRemove={(e) =>
                    handleNewFilter(
                      e,
                      filterData,
                      showFilters,
                      setFilter,
                      setFilterData
                    )
                  }
                />
              </div>
            )
          )}
          <div className="filter-item mt-2 ml-3">
            <Filter
              title={""}
              type={1006}
              data={showFilters}
              filter={filterData.map((x) => x.filterId)}
              field="filterId"
              onChange={(e) =>
                handleNewFilter(
                  e,
                  filterData,
                  showFilters,
                  setFilter,
                  setFilterData
                )
              }
            />
          </div>
          <div className="col d-flex align-items-center justify-content-start mt-2">
            <ButtonBasic
              title="Filter"
              icon={FilterIcon}
              onClick={() => {
                setLogsLoader(true);
                setError(false);
                setPage(0);
                setLogs([]);
                getLogs(filter);
              }}
            />
          </div>
          <div className="col d-flex align-items-center justify-content-end mt-2">
            <Select
              options={duration}
              onChange={(e) => setRefreshInterval(e.value)}
              placeholder="Off"
            />
          </div>
        </div>
        <div style={{ fontSize: "18px" }} className="text-bright ml-3 mb-4">
          Event Logs
        </div>
        <div
          className="logs-div fancy-scroll"
          onScroll={(e) => (!error ? handleScroll(e) : null)}
          style={{ border: "1px solid lightgrey" }}
        >
          {logs.length > 0 &&
            logs.map((log) => (
              <div className="mt-4 log-wrapper d-flex row text-select">
                <div className="log-time col-sm-3 col-lg-3">
                  {log.eventTime ? DateTimeFormat(dateTime, log.eventTime) : ""}
                </div>
                <div className={`text-wrap col-sm-5 col-lg-5`}>
                  {log.message}
                </div>
                <span className="col-sm-2 col-lg-1">
                  <BadgeRounded
                    color={
                      log.severity === "INFO"
                        ? "info"
                        : "ERROR"
                        ? "danger"
                        : "warning"
                    }
                    title={log.severity}
                  />
                </span>
                <span className="log-time col-sm-3">
                  {log.generatorComponentId}
                </span>
              </div>
            ))}
          {/* {logsLoader && (
            <div>
              <LoaderComponent />
            </div>
          )} */}
          {error && logs.length > 0 && (
            <h5 className="mt-2 text-center">No More Logs</h5>
          )}
          {error && logs.length === 0 && (
            <h5 className="mt-2 text-center">No Logs Found</h5>
          )}
        </div>
      </div>
    </div>
  );
};
export default EventLog;
