import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import { useDateTime } from "../../components/Helper/DateTime";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import axiosInstance from "../../utils/axiosInstance";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import Toast from "../../components/Toast/Toast";
import { mapFilters } from "../../utils/filtersHelper";
import style from "./style.module.scss";

const NostroModal = ({
  showModal,
  setShowModal,
  selectedAccount,
  selectedRow,
  dataSource,
  dsaAccount,
  dsbAccount,
}) => {
  const [apiData, setApiData] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [debitAccount, setDebitAccount] = useState([]);
  const [selectedDebitAccount, setSelectedDebitAccount] = useState("");
  const [creditAccount, setCreditAccount] = useState([]);
  const [selectedCreditAccount, setSelectedCreditAccount] = useState("");
  const [selectedDSA, setSelectedDSA] = useState(null);
  const [selectedDSB, setSelectedDSB] = useState(null);
  const [filters, setFilters] = useState({});
  const { date } = useDateTime();
  const [notes, setNotes] = useState([]);
  const [noteFlag, setNoteFlag] = useState(false);

  const PopulateOptions = (data, selected) => {
    const ReconFlow = selected
      ? selected
      : data?.find((item) => item?.selectDefault === true) || {};
    setCategory(
      data?.map((item) => ({
        value: item.categoryId,
        label: item.category,
      }))
    );
    setSelectedCategory(ReconFlow?.categoryId);
    setDebitAccount(
      ReconFlow?.debitAccounts?.map(({ reconAccountId, name }) => ({
        value: reconAccountId,
        label: name,
      })) || []
    );
    setCreditAccount(
      ReconFlow?.creditAccounts?.map(({ reconAccountId, name }) => ({
        value: reconAccountId,
        label: name,
      })) || []
    );
    setSelectedDebitAccount(
      ReconFlow?.debitAccounts?.find(
        (instance) => instance.selectDefault === true
      )?.reconAccountId || null
    );
    setSelectedCreditAccount(
      ReconFlow?.creditAccounts?.find(
        (instance) => instance.selectDefault === true
      )?.reconAccountId || null
    );
  };
  const manualNostroReconcile = useCallback(async () => {
    if (selectedAccount?.reconAccountId) {
      try {
        const res = await axiosInstance.get(
          `/accounts/manual-recon-accounts?manual_recon_flow=Nostro-Manual-Matching&account_required=${selectedAccount?.reconAccountId}&adjustment_category=${selectedRow?.adjustmentCategory}`
        );
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          if (res?.data) {
            setApiData(res?.data);
            PopulateOptions(res?.data);
          }
        }
      } catch (err) {
        Toast(err.response.data.message.description, "error");
      }
    }
  }, [selectedAccount]);

  const reconcileStatus = (status) => (
    <BadgeRounded
      color={
        status === "MATCHED"
          ? "success"
          : status === "MISSING"
          ? "warning"
          : "danger"
      }
      title={status}
    />
  );
  const formatNumber = (number) => {
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
      });
    }
  };

  const addNote = () => {
    let now = moment();
    let comment = document.getElementById("note").value;
    if (comment !== "") {
      let note = {
        comment: comment,
        author: `${JSON.parse(localStorage.getItem("userData")).firstName} ${
          JSON.parse(localStorage.getItem("userData")).lastName
        }`,
        date: now.format(date),
        time: now.hour() + ":" + now.minute(),
      };
      setNotes((prevState) => [...prevState, note]);
      document.getElementById("note").value = "";
      setNoteFlag(true);
    }
  };

  const getFilters = async () => {
    try {
      const response = await axiosInstance.get(
        `/hierarchical/filters?screen_id=manual_matching`
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        let obj = mapFilters(response?.data?.filters);
        setFilters(obj);
      }
    } catch (e) {
      Toast(e.response.data.message.description, "error");
    }
  };

  const NostroManualMatching = async () => {
    let newNotes = [];
    if (selectedRow?.notes && noteFlag) {
      for (let i = selectedRow?.notes?.length; i < notes?.length; i++) {
        newNotes.push(notes[i]);
      }
    } else {
      newNotes = notes;
    }
    const data = {
      matchFilterApplied: filters?.matching_singleselection?.data
        ?.filter(
          (x) => selectedRow?.status?.toLowerCase() === x?.label?.toLowerCase()
        )
        .map((x) => x?.value)
        .join(""),
      raisedBy: parseInt(JSON.parse(localStorage.getItem("userData"))?.userId),
      notes: newNotes,
      useCaseApplied: "impacting",
      matching_flow: "Nostro-Manual-Matching",
      dsA: dataSource?.datasourceA,
      dsB: dataSource?.datasourceB,
      selected_ds: selectedDSA
        ? selectedDSA?.ds_id
        : selectedDSB
        ? selectedDSB?.ds_id
        : "",
      selected_ds_name: selectedDSA
        ? selectedDSA?.ds_name
        : selectedDSB
        ? selectedDSB?.ds_name
        : "",
      adjustmentCategory: selectedCategory,
      manualMatchTransactions: [
        {
          batchId: dataSource?.batchId,
          outputId: selectedRow?.outputId,
          reconAccountId: selectedAccount?.reconAccountId,
          matchingData: JSON.stringify([selectedRow?.dsA, selectedRow?.dsB]),
          id: selectedRow?.matchingId,
        },
      ],
      accountDetails: {
        creditAccount: selectedCreditAccount,
        debitAccount: selectedDebitAccount,
      },
    };
    if (!selectedDSA && !selectedDSB) {
      Toast("Please select one Data Source", "error");
    } else if (!noteFlag || notes?.length === selectedRow?.notes?.length) {
      Toast("Please add a note", "error");
    } else {
      try {
        let response = await axiosInstance.post("/matching", data);
        if (response.message.status !== "200") {
          Toast(response.message.description, "error");
        }
        Toast(response.message.description, "success");
        setSelectedDSA(null);
        setSelectedDSB(null);
        setShowModal(false);
      } catch (e) {
        Toast(e.response.data.message.description, "error");
      }
    }
  };
  useEffect(() => {
    manualNostroReconcile();
    if (showModal) {
      getFilters();
      setNotes(selectedRow?.notes ?? []);
    }
  }, [manualNostroReconcile, selectedAccount, showModal]);

  useEffect(() => {
    if (selectedRow?.status === "MISSING") {
      if (
        selectedRow?.dsA[0]?.date &&
        selectedRow?.dsA[0]?.details &&
        selectedRow?.dsA[0]?.entryRef
      ) {
        setSelectedDSB(null);
        setSelectedDSA({
          ds_name: dataSource?.datasourceA,
          ds_id: dataSource?.datasourceAId,
          ds: JSON.stringify(selectedRow?.dsA) || "",
        });
      } else if (
        selectedRow?.dsB[0]?.date &&
        selectedRow?.dsB[0]?.details &&
        selectedRow?.dsB[0]?.entryRef
      ) {
        setSelectedDSA(null);
        setSelectedDSB({
          ds_name: dataSource?.datasourceB,
          ds_id: dataSource?.datasourceBId,
          ds: JSON.stringify(selectedRow?.dsB) || "",
        });
      }
    }
  }, []);

  const dsBRef = useRef(null);
  const dsARef = useRef(null);

  const handleScroll1 = () => {
    if (dsARef.current) {
      dsBRef.current.scrollLeft = dsARef.current.scrollLeft;
    }
  };
  const handleScroll2 = () => {
    if (dsBRef.current) {
      dsARef.current.scrollLeft = dsBRef.current.scrollLeft;
    }
  };

  return (
    <>
      <div className="col-md-12">
        <Modal
          backdrop={true}
          size="xl"
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setSelectedDSA(null);
            setSelectedDSB(null);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>Match With Adjustment</Modal.Header>
          <Modal.Body scrollable="true">
            <div className="row">
              <div className="col-lg-8 col-8">
                <div className="row">
                  <div className="col-4">
                    <label style={{ fontSize: "14px" }}>
                      Adjustment Category
                    </label>
                    <Select
                      options={category}
                      isDisabled={category?.length > 0 ? false : true}
                      value={category?.filter(
                        (item) => item.value === selectedCategory
                      )}
                      onChange={(e) => {
                        setSelectedCategory(e.value);
                        PopulateOptions(
                          apiData,
                          ...apiData.filter((item) => {
                            return item.categoryId === e.value;
                          })
                        );
                      }}
                      className="no-border mt-2"
                    />
                  </div>
                  <div className="col-4">
                    <label style={{ fontSize: "14px" }}>Debit Account</label>
                    <Select
                      options={debitAccount}
                      isDisabled={debitAccount?.length > 0 ? false : true}
                      value={debitAccount?.filter(
                        (item) => item.value === selectedDebitAccount
                      )}
                      onChange={(e) => setSelectedDebitAccount(e.value)}
                      className="no-border mt-2"
                    />
                  </div>
                  <div className="col-4">
                    <label style={{ fontSize: "14px" }}>Credit Account</label>
                    <Select
                      options={creditAccount}
                      isDisabled={creditAccount?.length > 0 ? false : true}
                      value={creditAccount?.filter(
                        (item) => item.value === selectedCreditAccount
                      )}
                      onChange={(e) => setSelectedCreditAccount(e.value)}
                      className="no-border mt-2"
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="d-flex flex-row ml-4">
                      <div className="text-info">
                        {dsaAccount?.type + " - " + dsaAccount?.number}
                      </div>
                    </div>
                    <div
                      onScroll={handleScroll1}
                      ref={dsARef}
                      className={`table-responsive mt-1 mb-4 fancy-scroll ${
                        selectedDSA ? "matching-box-shadow" : ""
                      }`}
                      style={{ overflowY: "hidden" }}
                      onClick={() => {
                        if (selectedRow?.dsA[0]?.id) {
                          setSelectedDSB(null);
                          setSelectedDSA({
                            ds_name: dataSource?.datasourceA,
                            ds_id: dataSource?.datasourceAId,
                            ds: JSON.stringify(selectedRow?.dsA) || "",
                          });
                        }
                      }}
                    >
                      <table
                        className={`table table-dim-dark table-bordered table-striped ${style.tableStyle} m-0`}
                      >
                        <thead>
                          {/* <tr>
                            <th colSpan={7}>
                              <h6 className="text-info text-center">
                                {dsaAccount?.type + " - " + dsaAccount?.number}
                              </h6>
                            </th>
                          </tr> */}
                          <tr>
                            <th className="text-info">Matched By</th>
                            <th className="text-info">Status</th>
                            <th className="text-info">Date</th>
                            <th className="text-info">Details</th>
                            <th className="text-info">Reference</th>
                            <th className="text-info">Credit</th>
                            <th className="text-info">Debit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedRow?.dsA?.map((item, index) => {
                            return selectedRow?.status === "MISSING" ||
                              item?.date ||
                              item?.details ||
                              item?.entryRef ? (
                              <tr key={index}>
                                <td>{selectedRow?.matchedBy ?? "-"}</td>
                                <td>{reconcileStatus(selectedRow?.status)}</td>

                                <td>{item?.date ?? ""}</td>
                                <td>{item?.details ?? ""}</td>
                                <td>{item?.entryRef ?? ""}</td>
                                <td className="text-right">
                                  {item?.credit
                                    ? formatNumber(item?.credit)
                                    : item?.credit === null
                                    ? ""
                                    : item?.credit}
                                </td>
                                <td className="text-right">
                                  {item?.debit
                                    ? formatNumber(item?.debit)
                                    : item?.debit === null
                                    ? ""
                                    : item?.debit}
                                </td>
                              </tr>
                            ) : (
                              ""
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex flex-row ml-4">
                      <div className="text-danger">
                        {dsbAccount?.type + " - " + dsbAccount?.number}
                      </div>
                    </div>
                    <div
                      onScroll={handleScroll2}
                      ref={dsBRef}
                      className={`table-responsive mt-1 fancy-scroll ${
                        selectedDSB ? "matching-box-shadow" : ""
                      }`}
                      style={{ overflowY: "hidden" }}
                      onClick={() => {
                        if (selectedRow?.dsB[0]?.id) {
                          setSelectedDSA(null);
                          setSelectedDSB({
                            ds_name: dataSource?.datasourceB,
                            ds_id: dataSource?.datasourceBId,
                            ds: JSON.stringify(selectedRow?.dsB) || "",
                          });
                        }
                      }}
                    >
                      <table
                        className={`table table-dim-dark table-bordered table-striped ${style.tableStyle} m-0`}
                      >
                        <thead>
                          {/* <tr>
                            <th colSpan={7}>
                              <h6 className="text-danger text-center">
                                {dsbAccount?.type + " - " + dsbAccount?.number}
                              </h6>
                            </th>
                          </tr> */}
                          <tr>
                            <th className="text-danger">Matched By</th>
                            <th className="text-danger">Status</th>
                            <th className="text-danger">Date</th>
                            <th className="text-danger">Details</th>
                            <th className="text-danger">Reference</th>
                            <th className="text-danger">Credit</th>
                            <th className="text-danger">Debit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedRow?.dsB?.map((item, index) => {
                            return selectedRow?.status === "MISSING" ||
                              item?.date ||
                              item?.details ||
                              item?.entryRef ? (
                              <tr key={index}>
                                <td>{selectedRow?.matchedBy ?? "-"}</td>
                                <td>{reconcileStatus(selectedRow?.status)}</td>
                                <td>{item?.date ?? ""}</td>
                                <td>{item?.details ?? ""}</td>
                                <td>{item?.entryRef ?? ""}</td>
                                <td className="text-right">
                                  {item?.credit
                                    ? formatNumber(item?.credit)
                                    : item?.credit === null
                                    ? ""
                                    : item?.credit}
                                </td>
                                <td className="text-right">
                                  {item?.debit
                                    ? formatNumber(item?.debit)
                                    : item?.debit === null
                                    ? ""
                                    : item?.debit}
                                </td>
                              </tr>
                            ) : (
                              ""
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="notes-wrapper" style={{ height: "100%" }}>
                  <h5 className="mb-3 color-gray-dim">Notes</h5>
                  <div
                    className="overflow-auto fancy-scroll"
                    style={{
                      height: "90%",
                    }}
                  >
                    <div
                      className="position-relative"
                      style={{
                        height: "100%",
                        maxHeight: "100%",
                        minHeight: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div className="mt-2">
                        {notes?.map(({ author, comment, date, time }, i) => (
                          <ul className="rs-notes-container" key={i}>
                            <li className="rs-notes-item">
                              <h6>{author}</h6>
                              <div className="note-comment">{comment}</div>
                              <sub>
                                {date} {time}
                              </sub>
                            </li>
                          </ul>
                        ))}
                      </div>
                      <textarea
                        placeholder="Write a Note"
                        rows="3"
                        id="note"
                        className="textArea"
                      ></textarea>
                      <div className="w-100 mt-2">
                        <ButtonBasic
                          classes={"primary px-4 w-100"}
                          title={"Add Notes"}
                          onClick={() => addNote()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <ButtonBasic
                title="Cancel"
                onClick={() => {
                  setShowModal(false);
                  setSelectedDSA(null);
                  setSelectedDSB(null);
                }}
              />
              <ButtonBasic
                title="Done"
                onClick={() => {
                  NostroManualMatching();
                }}
              />
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default NostroModal;
