export const regionCheck = (data, array, flag) => {
  let arr = data.map((x) => {
    if (flag) {
      x.checked = true;
      // eslint-disable-next-line
    } else if (array.find((y) => y.codeValue == x.codeValue)) {
      x.checked = true;
    } else {
      x.checked = false;
    }
    let obj = {
      id: x.id,
      label: x.label,
      value: x.codeValue,
      codeValue: x.codeValue,
      checked: x.checked,
    };
    if (x.children) {
      obj.children = regionCheck(x.children, array, x.checked);
    }
    return obj;
  });
  return arr;
};

export const mapdata = (data) => {
  let arr = data.map((x) => {
    let obj = {
      param1: x.param1,
      id: x.id,
      label: x.label,
      codeValue: x.codeValue,
      value: x.codeValue,
    };
    if (x.subClass) {
      obj.children = mapdata(x.subClass);
    }
    return obj;
  });
  return arr;
};
