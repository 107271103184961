import React, { useState, useEffect } from "react";
import FilterDropdownStyle from "./FilterDropdownStyle";
import chevronDown from "../../assets/images/chevron-down.png";
import plus from "../../assets/images/plusIcon.png";
import DatePicker from "react-datepicker";
import searchIcon from "../../assets/images/search.svg";
import resetIcon from "../../assets/images/reset.svg";
import TimePicker from "react-time-picker";
import "react-datepicker/dist/react-datepicker.css";
const NewFilter = ({
  id = "",
  title,
  type,
  data,
  filter,
  onChange = () => {},
  classes = "",
  btnClasses = "",
  field = "codeValue",
  isSearchable,
  searchField = "label",
  positionRelative,
  removable = false,
  filterId,
  onRemove,
  selectAll = true,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateRange, setDateRange] = useState([
    new Date(new Date().setDate(new Date().getDate() - 5)),
    new Date(),
  ]);
  const [startDate, endDate] = dateRange;
  const [inputData, setInputData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [time, setTime] = useState("10:00:00");
  const handleDateChange = (d) => {
    data = null;
    setSelectedDate(d);
  };
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setInputData(
      data?.filter((item) => {
        return item[searchField]
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      })
    );
  };
  const closeCalendar = () => {};
  useEffect(() => {
    if (data?.length > 0 && data !== inputData) {
      setInputData(data);
    }
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    if (
      type === 1003 &&
      selectAll &&
      inputData?.length > 0 &&
      !inputData?.find((item) => item?.label === "Select All") &&
      !inputData?.find((item) => item?.param1 === "All")
    ) {
      setInputData([
        {
          label: "Select All",
          param1: "All",
          checked: false,
        },
        ...inputData,
      ]);
    }
  }, [inputData, selectAll, type]);
  if (type === 1007) {
    return (
      <FilterDropdownStyle className={`dropdown ${classes}`}>
        <DatePicker
          id={`dropdownMenuButton${id}`}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          maxDate={new Date()}
          onChange={(update) => {
            setDateRange(update);
            onChange([
              new Date(update[0])
                .toLocaleDateString("pt-br")
                .split("/")
                .reverse()
                .join("-"),
              update[1] === null
                ? new Date(update[0])
                    .toLocaleDateString("pt-br")
                    .split("/")
                    .reverse()
                    .join("-")
                : new Date(update[1])
                    .toLocaleDateString("pt-br")
                    .split("/")
                    .reverse()
                    .join("-"),
            ]);
          }}
          customInput={
            <button
              className={`d-flex align-items-center justify-content-between btn tsg-dropdown w-100 h-100 px-2 py-1`}
              onClick={() => {
                closeCalendar();
              }}
            >
              <span>{title}</span>
              <img className="dropdown-icon" src={chevronDown} alt={"chev"} />
            </button>
          }
          className={`d-flex align-items-center justify-content-between custom-date-picker`}
        />
      </FilterDropdownStyle>
    );
  } else if (type === 1001 || type === 3006) {
    return (
      <FilterDropdownStyle className={`dropdown ${classes}`}>
        <DatePicker
          id={`dropdownMenuButton${id}`}
          selected={
            selectedDate ??
            (data === undefined || data == null || data.length <= 0
              ? new Date()
              : data)
          }
          onChange={(date) => {
            onChange(
              new Date(date)
                .toLocaleDateString("pt-br")
                .split("/")
                .reverse()
                .join("-")
            );
            handleDateChange(date);
          }}
          customInput={
            <button
              className={`d-flex align-items-center justify-content-between btn tsg-dropdown w-100 h-100 px-2 py-1`}
              onClick={() => {
                closeCalendar();
              }}
            >
              <span>{title}</span>
              <img className="dropdown-icon" src={chevronDown} alt={"chev"} />
            </button>
          }
          className={`d-flex align-items-center justify-content-between custom-date-picker`}
        />
      </FilterDropdownStyle>
    );
  }

  return (
    <>
      <FilterDropdownStyle className={`dropdown ${classes}`}>
        <button
          className={`d-flex align-items-center justify-content-between btn tsg-dropdown w-100 h-100 px-2 py-1 dropdownMenuButton${title} ${btnClasses}`}
          type="button"
          id={`dropdownMenuButton${id}`}
          data-toggle="dropdown"
        >
          <span>{title}</span>
          {type === 1006 ? (
            <img src={plus} alt={"chev"} />
          ) : (
            <img className="dropdown-icon" src={chevronDown} alt={"chev"} />
          )}
        </button>

        <form>
          <div
            className={`dropdown-menu fancy-scroll ${
              positionRelative ? "conditional-dropdown" : ""
            }`}
          >
            {data?.length === 0 && <div className="dropdown-item">No data</div>}
            {isSearchable && (
              <div className="position-relative">
                <img className="dropdown-search-icon" src={searchIcon} alt="" />
                <input
                  className="dropdown-search"
                  onChange={handleSearch}
                  placeholder="Search"
                  value={searchValue}
                />
                <img
                  className="dropdown-reset-icon"
                  onClick={() => handleSearch({ target: { value: "" } })}
                  src={resetIcon}
                  alt=""
                />
              </div>
            )}
            {type === 1002 &&
              inputData.map((val, index) => (
                <div key={index} className={`dropdown-item`}>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input dropdown-checkbox"
                      value={val?.id}
                      checked={
                        filter &&
                        (filter[0]?.toString() === val[field]?.toString() ||
                          filter === val[field])
                          ? true
                          : false
                      }
                      id={id}
                      onChange={(e) => {
                        e.target.checked
                          ? onChange({
                              id: val[field],
                              flag: true,
                              isSingle: true,
                            })
                          : onChange({
                              id: val[field],
                              flag: false,
                              isSingle: true,
                            });
                      }}
                    />
                    <label className="custom-control-label" htmlFor={val?.id}>
                      {val?.label}
                    </label>
                  </div>
                </div>
              ))}
            {type === 1003 &&
              inputData.map((val, index) => (
                <div key={index} className={`dropdown-item`}>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input dropdown-checkbox"
                      value={val?.id}
                      checked={
                        (selectAll &&
                          val.param1 === "All" &&
                          inputData
                            ?.filter((value) => value?.param1 !== "All")
                            .every((value) =>
                              filter?.includes(value[field])
                            )) ||
                        filter?.includes(val[field])
                      }
                      id={(id + val?.label).replace(/\s+/g, "_")}
                      onChange={(e) => {
                        if (val?.param1 === "All") {
                          const filteredValues = inputData.filter(
                            (value) => value?.param1 !== "All"
                          );
                          const idsToUpdate = e.target.checked
                            ? filteredValues.filter(
                                (value) => !filter?.includes(value[field])
                              )
                            : filteredValues;

                          idsToUpdate.forEach((value) =>
                            onChange({
                              id: value[field],
                              flag: e.target.checked,
                            })
                          );
                        } else {
                          onChange({ id: val[field], flag: e.target.checked });
                        }
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={val?.id}
                      style={{
                        fontStyle: val?.param1 === "All" ? "italic" : "normal",
                      }}
                    >
                      {val?.label}
                    </label>
                  </div>
                </div>
              ))}
            {type === 1004 && (
              <div className={`dropdown-item`} style={{ height: "250px" }}>
                <TimePicker
                  id={`dropdownMenuButton${id}`}
                  name="Select time"
                  clockIcon={null}
                  amPmAriaLabel="Select AM/PM"
                  maxDetail="second"
                  onChange={setTime}
                  value={time}
                />
              </div>
            )}
            {type === 1006 &&
              inputData.map((val, index) => (
                <div key={index} className={`dropdown-item`}>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input dropdown-checkbox"
                      value={val?.filterId}
                      checked={filter?.includes(val[field]) ? true : false}
                      id={id}
                      onChange={(e) => {
                        e.target.checked
                          ? onChange({ id: val[field], flag: true })
                          : onChange({ id: val[field], flag: false });
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={val?.filterId}
                    >
                      {val?.filterLabel}
                    </label>
                  </div>
                </div>
              ))}
          </div>
        </form>
      </FilterDropdownStyle>
      {removable && (
        <span
          style={{ fontWeight: "bolder", color: "red", cursor: "pointer" }}
          onClick={() => onRemove({ id: filterId, flag: false })}
        >
          X
        </span>
      )}
    </>
  );
};

export default NewFilter;
