import styled from "styled-components";

const VerticalTabsStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .nav-wrapper {
    background: ${({ theme }) => theme.card_background};
    height: 100%;
    max-height: calc(100vh - 140px);
    padding: 15px 10px;
    border-radius: 8px;
    overflow: auto;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: ${({ theme }) => theme.chartColors.info};
    background-color: ${({ theme }) => theme.modal_background};
  }
  .nav-pills .nav-link {
    color: ${({ theme }) => theme.text.base};
  }

  @media (max-width: 1380px) {
    .nav-container {
      position: absolute;
      z-index: 100;
      max-height: calc(100vh - 95px);
      height: 100%;
      padding: 0;
    }
    .nav-container .nav-wrapper {
      width: 255px;
    }
  }
`;

export default VerticalTabsStyle;
