import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import axiosInstance from "../../utils/axiosInstance";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import moment from "moment";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import { nullChecker } from "../../components/Helper/NullChecker";
import withDateTime from "../../utils/timedatehoc";
import Toast from "../../components/Toast/Toast";
import Filter from "../../components/FilterDropdown/GenericFilter";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import FilterIcon from "../../assets/images/filter.png";

import {
  mapFilters,
  filtersParams,
  handleFilterChange,
} from "../../utils/filtersHelper";

class MatchingPairs extends React.Component {
  constructor(props) {
    super(props);
    this.formatLastReconRunDate = this.formatLastReconRunDate.bind(this);
    this.formatPostingDate = this.formatPostingDate.bind(this);

    this.state = {
      matchingPairsData: [],
      filters: {},
      filterData: [],
      loading: false,
      breadCrumbState: [
        {
          id: 0,
          title: "",
          isActive: true,
          routeTo: "/matching-pairs",
        },
      ],
      matchingPairsLoader: true,
    };
  }

  rowEvents = {
    onClick: (e, row) => {
      this.props.history.push(
        `/matching?id=${row.id}&business_date=${
          this.state.filters?.business_date?.filter[0].length > 0 &&
          this.state.filters?.business_date?.filter[0]
        }&from=${row.fromDatasourceId}&to=${row.toDatasourceId}`
      );
    },
  };

  componentDidMount() {
    this.getFilters();
    this.props.dateTime.setRefresh(() => () => {
      this.handleFilter();
    });
  }

  componentWillUnmount() {
    this.props.dateTime.setRefresh(() => () => {});
  }

  async getMatchingPairData(allFilters) {
    try {
      this.setState({ ...this.state, matchingPairsLoader: true });
      if (allFilters !== "") {
        const response = await axiosInstance.get(`/matching/pairs${
          allFilters !== "" ? "?" : ""
        }${allFilters}
      `);
        if (response.message.status !== "200") {
          Toast(response.message.description, "error");
          this.setState({
            ...this.state,
            matchingPairsLoader: false,
            loading: true,
          });
        } else {
          this.setState({
            ...this.state,
            loading: false,
            matchingPairsData: response?.data?.matchingPair?.sort(
              (a, b) => a.reconName.toLowerCase() - b.reconName.toLowerCase()
            ),
            matchingPairsLoader: false,
          });
        }
      }
    } catch (error) {
      this.setState({ matchingPairsLoader: false });
    }
  }
  async getFilters() {
    let data;
    if (this.props.location?.state?.data) {
      data = this.props.location.state.data;
    }
    axiosInstance
      .get("hierarchical/filters?screen_id=pair_summary")
      .then((res) => {
        let obj = mapFilters(res.data.filters);
        if (data) {
          if (data.date) {
            obj.business_date.data = new Date(data.date);
            obj.business_date.filter = [data.date]; //date passing in filter api as param.
            this.handleFilter();
          }
        }
        this.setState(
          {
            filters: obj,
            filterData: res.data.filters,
            matchingPairsLoader: true,
          },
          () => {
            this.handleFilter();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.handleFilter();
      });
  }

  formatPostingDate(cell, row) {
    if (row && row.postingDate) {
      let date = row.postingDate;

      return date ? moment(date).format(this.props?.dateTime?.date) : "-";
    } else {
      return "-";
    }
  }

  formatLastReconRunDate(cell, row) {
    if (row && row.lastReconRun) {
      let date = row.lastReconRun;
      return date ? moment(date).format(this.props?.dateTime?.date) : "-";
    } else {
      return "-";
    }
  }

  setFilter = (filters) => {
    this.setState({ filters });
  };

  handleFilter = async () => {
    let allFilters = filtersParams(this.state.filters, false, true);
    await this.getMatchingPairData(allFilters);
  };

  render() {
    const { matchingPairsData, filters, filterData } = this.state;
    this.columns2 = [
      { hidden: true, dataField: "atmId", text: "ID" },
      {
        dataField: "fromDatasource",
        text: "From Datasource",
        formatter: nullChecker,
        sort: true,
      },
      {
        dataField: "toDatasource",
        text: "To Datasource",
        formatter: nullChecker,
        sort: true,
      },
      {
        dataField: "postingDate",
        text: "Posting Date",
        formatter: this.formatPostingDate,
        sort: true,
      },
      {
        dataField: "lastReconRun",
        text: "Last Recon Run",
        formatter: this.formatLastReconRunDate,
        sort: true,
      },
      {
        dataField: "matchedCount",
        text: "Matched Count",
        formatter: nullChecker,
        style: { color: "#00AF91" },
        sort: true,
      },
      {
        dataField: "unmatchedCount",
        text: "Unmatched Count",
        formatter: nullChecker,
        style: { color: "#ef476f" },
        sort: true,
      },
      {
        dataField: "errorCount",
        text: "Error Count",
        formatter: nullChecker,
        style: { color: "#ef476f" },
        sort: true,
      },
      {
        dataField: "carryForwardRecords",
        text: "Carry Fwd Records",
        formatter: nullChecker,
        sort: true,
      },
      {
        dataField: "aged1Count",
        text: "1-day Aged Records",
        formatter: nullChecker,
        sort: true,
      },
      {
        dataField: "aged2Count",
        text: "2-day Aged Records",
        formatter: nullChecker,
        sort: true,
      },
      {
        dataField: "aged3Count",
        text: "3-day Aged Records",
        formatter: nullChecker,
        sort: true,
      },
    ];

    this.columnsDataSourceExpand = [
      {
        dataField: "reconName",
        text: "Recon Name",
      },
    ];

    const expandRow = {
      renderer: (row) => (
        <div className="col-12 mb-3">
          <div className="overflow-y-auto">
            <div id="anchor3">
              {row?.pairs !== null && (
                <BootstrapTable
                  bordered={false}
                  classes="rs-table table-layout-auto mb-0 table-custom-style"
                  wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                  keyField="id"
                  data={row?.pairs}
                  columns={this.columns2}
                  rowEvents={this.rowEvents}
                  noDataIndication="No data found!"
                ></BootstrapTable>
              )}
            </div>
          </div>
        </div>
      ),
      showExpandColumn: true,
      expanded: [
        ...this.state.matchingPairsData?.map((item) => item.reconName),
      ],
    };

    return (
      <>
        {this.state.matchingPairsLoader && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div
          style={{ opacity: !this.state.matchingPairsLoader ? "1" : "0.07" }}
        >
          <div className="row mb-3 align-items-center d-xs-none d-lg-flex">
            {filterData.map(({ filterName, filterType, filterLabel }) => (
              <div
                className="filter-item col-sm-6 col-lg-3 mt-2"
                style={{ minWidth: "210px" }}
              >
                <Filter
                  title={filterLabel}
                  type={filterType}
                  data={filters[filterName]?.data}
                  filter={filters[filterName]?.filter}
                  onChange={(e) =>
                    handleFilterChange(e, filterName, filters, this.setFilter)
                  }
                />
              </div>
            ))}
            <div className="col d-flex justify-content-start">
              <ButtonBasic
                icon={FilterIcon}
                title="Filter"
                onClick={this.handleFilter}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <Breadcrumbs
                data={this.state.breadCrumbState}
                history={this.props.history}
                onClick={(e) => this.breadcrumbOnClick(e)}
              />
              <div
                style={{ fontSize: "1.125rem" }}
                className="text-bright ml-3 mb-4"
              >
                Source Pairs
              </div>
              <div className="col-12">
                <div className="overflow-y-auto fancy-scroll">
                  {!this.state.matchingPairsLoader && (
                    <BootstrapTable
                      keyField="reconName"
                      data={matchingPairsData}
                      columns={this.columnsDataSourceExpand}
                      expandRow={expandRow}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withDateTime(MatchingPairs);
