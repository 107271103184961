import BadgeRounded from "../../../components/BadgeRounded/BadgeRounded";
const reconStatus = (row) => (
  <BadgeRounded
    color={
      row === "Balanced" ? "success" : row === "Shortage" ? "warning" : "danger"
    }
    title={row}
  />
);
export const columns = [
  { hidden: true, dataField: "id", text: "ID" },
  {
    dataField: "merchant_id",
    text: "Merchant ID",
  },
  {
    dataField: "name",
    text: "Name",
  },
  {
    dataField: "location",
    text: "Location",
  },
  {
    dataField: "transaction_count",
    // formatter:
    text: "Transaction (Count)",
  },
  {
    dataField: "transaction_amount",
    // formatter:
    text: "Transaction (Amount)",
  },
  {
    dataField: "recon_status",
    formatter: reconStatus,
    text: "Recon Status",
  },
  {
    dataField: "merchant_GL_balance",
    // formatter:
    text: "Merchant GL Balance",
  },
];
