import React, { useState, useEffect } from "react";
import Select from "react-select";
import NewFilter from "../../../components/FilterDropdown/NewFilter";
import axiosInstance from "../../../utils/axiosInstance";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import Toast from "../../../components/Toast/Toast";

const ReconModal = ({
  setShowModal,
  dsA,
  dsB,
  pairId,
  updateReconPair,
  masterDataSource,
}) => {
  const [datasourceA, setDatasourceA] = useState(dsA?.dsaId);
  const [datasourceB, setDatasourceB] = useState(dsB?.dsbId);
  const [toRecordTypes, setToRecordTypes] = useState([]);
  const [fromRecordTypes, setFromRecordTypes] = useState([]);
  const [selectedToRecordTypes, setSelectedToRecordTypes] = useState([]);
  const [selectedFromRecordTypes, setSelectedFromRecordTypes] = useState([]);
  const toDatasourceRecordTypes = (toDatasourceId) => {
    axiosInstance
      .get("recon/wfm-config-fields?masterDatasourceId=" + toDatasourceId)
      .then((res) => {
        if (res?.message?.status == 200) {
          setToRecordTypes(
            res?.data?.interfaceRecordTypes?.map((x) => ({
              label: x.recordType,
              value: x.recordType,
              ...x,
            }))
          );
          let recordType = res?.data?.interfaceRecordTypes?.map(
            (option) =>
              dsB?.dsBInterfaceFormatIds?.find(
                (item) => item == option?.interfaceFormatId
              ) &&
              dsB?.dsBInterfaceIds?.find(
                (item) => item == option?.interfaceId
              ) && {
                label: option?.recordType,
                value: option?.recordType,
                ...option,
              }
          );
          recordType = recordType?.filter((x) => x);
          setSelectedToRecordTypes(recordType || []);
        } else {
          setToRecordTypes([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fromDatasourceRecordTypes = (fromDatasourceId) => {
    axiosInstance
      .get("recon/wfm-config-fields?masterDatasourceId=" + fromDatasourceId)
      .then((res) => {
        if (res?.message?.status == 200) {
          setFromRecordTypes(
            res?.data?.interfaceRecordTypes?.map((x) => ({
              label: x.recordType,
              value: x.recordType,
              ...x,
            }))
          );
          let recordType = res?.data?.interfaceRecordTypes?.map(
            (option) =>
              dsA?.dsAInterfaceFormatIds?.find(
                (item) => item == option?.interfaceFormatId
              ) &&
              dsA?.dsAInterfaceIds?.find(
                (item) => item == option?.interfaceId
              ) && {
                label: option?.recordType,
                value: option?.recordType,
                ...option,
              }
          );
          recordType = recordType?.filter((x) => x);
          setSelectedFromRecordTypes(recordType || []);
        } else {
          setFromRecordTypes([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (
      masterDataSource?.find((r) => r.id === datasourceA)?.isPostStagingTable ==
      false
    ) {
      setSelectedFromRecordTypes(null);
      fromDatasourceRecordTypes(datasourceA);
    }
    if (
      masterDataSource?.find((r) => r.id === datasourceB)?.isPostStagingTable ==
      false
    ) {
      setSelectedToRecordTypes(null);
      toDatasourceRecordTypes(datasourceB);
    }
  }, [masterDataSource, datasourceA, datasourceB]);

  return (
    <>
      <div className="col-12">
        <div>
          <div className="row">
            <div className="col-12">
              <div className="workflowModalTitle">Data Source A</div>
              <NewFilter
                id="datasourceA"
                title={
                  masterDataSource.find((r) => r.id === datasourceA)
                    ?.datasource ?? "Data Source A"
                }
                type={"checkbox"}
                data={masterDataSource}
                field={"id"}
                filter={datasourceA}
                isSearchable
                searchField="datasource"
                onChange={(e) => setDatasourceA(e)}
              />
            </div>
            <div className="col-12 mt-3">
              <div className="workflowModalTitle">Data Source B</div>
              <NewFilter
                id="datasourceB"
                title={
                  masterDataSource.find((r) => r.id === datasourceB)
                    ?.datasource ?? "Data Source B"
                }
                type={"checkbox"}
                data={masterDataSource}
                field={"id"}
                filter={datasourceB}
                isSearchable
                searchField="datasource"
                onChange={(e) => setDatasourceB(e)}
              />
            </div>
          </div>
          <div className="row mt-3">
            {masterDataSource?.find((r) => r.id === datasourceA)
              ?.isPostStagingTable == false && (
              <div className="col-12">
                <div className="workflowModalTitle">DSA Record Type</div>
                <Select
                  id="fromRecordTypes"
                  classNamePrefix={"fromRecordTypes"}
                  options={fromRecordTypes}
                  value={selectedFromRecordTypes}
                  onChange={(e) => setSelectedFromRecordTypes(e)}
                  placeholder={"Select Record Type"}
                  isMulti
                />
              </div>
            )}
            {masterDataSource?.find((r) => r.id === datasourceB)
              ?.isPostStagingTable == false && (
              <div className="col-12 mt-3">
                <div className="workflowModalTitle">DSB Record Type</div>
                <Select
                  id="toRecordTypes"
                  classNamePrefix={"toRecordTypes"}
                  options={toRecordTypes}
                  value={selectedToRecordTypes}
                  onChange={(e) => setSelectedToRecordTypes(e)}
                  placeholder={"Select Record Type"}
                  isMulti
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <ButtonBasic id="cancelReconPair" title="Cancel" onClick={() => setShowModal(false)} />
          <ButtonBasic
            id="saveReconPair"
            title="Save"
            onClick={() => {
              if (
                masterDataSource?.find((r) => r.id === datasourceA)
                  ?.isPostStagingTable == false &&
                selectedFromRecordTypes?.length === 0
              ) {
                Toast("Please select DSA Record Type", "error");
                return;
              }
              if (
                masterDataSource?.find((r) => r.id === datasourceB)
                  ?.isPostStagingTable == false &&
                selectedToRecordTypes?.length === 0
              ) {
                Toast("Please select DSB Record Type", "error");
                return;
              }
              updateReconPair(
                masterDataSource.find((r) => r.id === datasourceA)?.datasource,
                masterDataSource.find((r) => r.id === datasourceB)?.datasource,
                datasourceA,
                datasourceB,
                pairId,
                selectedFromRecordTypes?.map(
                  (item) => item?.interfaceFormatId
                ) ?? [],
                selectedFromRecordTypes?.map((item) => item?.interfaceId) ?? [],
                selectedToRecordTypes?.map((item) => item?.interfaceFormatId) ??
                  [],
                selectedToRecordTypes?.map((item) => item?.interfaceId) ?? []
              );
              setShowModal(false);
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ReconModal;
