export default function TopException({ theme }) {
  const TopException = {
    title: {
      text: "Top Exceptions",
      left: "left",
      textStyle: {
        color: "#777",
        fontSize: "14px",
        fontFamily: "Montserrat Bold",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    toolbox: {
      show: true,
      showTitle: false, // hide the default text so they don't overlap each other
      feature: {
        saveAsImage: {
          show: true,
          title: "Save As Image",
        },
      },
    },
    legend: {
      data: ["Count"],
      orient: "vertical",
      left: "right",
      top: "top",
      icon: "circle",
      padding: [0, 40, 0, 0],
      textStyle: {
        color: "#777",
        fontFamily: "Montserrat Regular",
        fontSize: "10px",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      splitLine: {
        lineStyle: {
          color: theme === "light" ? "#ccc" : "#333",
        },
      },
    },
    yAxis: {
      type: "category",
      data: [
        "BRNCH 006",
        "BRNCH 005",
        "BRNCH 004",
        "BRNCH 003",
        "BRNCH 002",
        "BRNCH 001",
      ], // for labels
    },
    series: [
      {
        color: "#EF476F",
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: ["950", "900", "550", "900", "1100", "800"], // for data
      },
    ],
  };
  return TopException;
}
