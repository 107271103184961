import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import CurrencyFormat from "react-currency-format";
import axiosInstance from "../../utils/axiosInstance";
import Pagination from "../../components/pagination/pagination";
import { useDateTime } from "../../components/Helper/DateTime";
import moment from "moment";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import { DateTimeFormat } from "../../utils/formatDateTime";

const formatNumber = (row) => {
  return (
    <CurrencyFormat
      value={row !== null ? row : "-"}
      displayType={"text"}
      thousandSeparator={true}
    />
  );
};

const columnsDataSourceExpand = [
  { hidden: true, dataField: "id", text: "ID" },
  {
    dataField: "reconName",
    text: "Recon Name",
    style: {
      color: "#1196c2",
      textDecoration: "underline",
      fontWeight: "bold",
    },
  },
];

export const ReconDataSources = ({
  refresh,
  filter,
  pageFilter,
  setPageFilter,
}) => {
  const { dateTime, setRefresh } = useDateTime();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [loading, setLoading] = useState(false);
  const [tempQuery, setTempQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const getReconDataSources = useCallback(
    (filter, flag) => {
      const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
      let url = `/matching/pairs-operations?page=${
        flag ? 0 : page - 1
      }&size=${pageSize}${
        sort.sort_by && sort.sort_order ? order : ""
      }${filter}${searchQuery !== "" ? "&search=" + searchQuery : ""}`;
      axiosInstance
        .get(url)
        .then((res) => {
          if (res.message.status !== "200") {
            setData([]);
            setLoading(false);
            setTotalElements(0);
          } else {
            setData(res?.data?.recons || []);
            setPage(res?.page?.number + 1 || 1);
            setTotalPages(res?.page?.totalPages || 0);
            setTotalElements(res?.page?.totalElements || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    },
    [page, pageSize, sort, filter, searchQuery]
  );

  useEffect(() => {
    setLoading(true);
    getReconDataSources(filter, false);
  }, [page, pageSize, sort, getReconDataSources, refresh]);

  useEffect(() => {
    let timerId = setInterval(() => {
      getReconDataSources(filter, false);
    }, 5000);
    return () => {
      clearInterval(timerId);
    };
  });

  useEffect(() => {
    if (pageFilter) {
      setLoading(true);
      getReconDataSources(filter, true);
      setPageFilter(false);
    }
  });

  const columnsExpandRowDataSrouces = [
    { hidden: true, dataField: "id", text: "ID" },
    {
      dataField: "reconOn",
      text: "Recon On",
    },
    {
      dataField: "fromDs",
      text: "From Datasource",
    },
    {
      dataField: "toDs",
      text: "To Datasource",
    },
    {
      dataField: "lastReconRun",
      text: "Last Recon Run",
      formatter: (cell) => (cell ? DateTimeFormat(dateTime, cell) : "-"),
    },
    {
      dataField: "completedAt",
      text: "Completed At",
      formatter: (cell) => (cell ? DateTimeFormat(dateTime, cell) : "-"),
    },
    {
      dataField: "batchId",
      text: "Batch ID",
    },
    {
      dataField: "totalMatched",
      text: "Matched",
      style: { color: "#00AF91" },
      formatter: formatNumber,
    },
    {
      dataField: "totalUnmatched",
      text: "Unmatched",
      style: { color: "#ef476f" },
      formatter: formatNumber,
    },
    {
      dataField: "totalMissing",
      text: "Not Found",
      style: { color: "#ef476f" },
      formatter: formatNumber,
    },
    {
      dataField: "totalErrors",
      text: "Total Errors",
      style: { color: "#ef476f" },
      formatter: formatNumber,
    },
  ];

  const expandRow = {
    parentClassName: (isExpanded, row, rowIndex) => {
      if (rowIndex > 2) return "parent-expand-foo";
      return "parent-expand-bar";
    },
    renderer: (row) => (
      <div className="col-12 mb-3">
        <div className="overflow-y-auto">
          <div id="anchor3">
            {row?.pairs !== null && (
              <BootstrapTable
                bordered={false}
                classes="rs-table table-layout-auto mb-0 table-custom-style"
                wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                keyField="id"
                data={row?.pairs}
                columns={columnsExpandRowDataSrouces}
                // remote={{ pagination: true, sort: true }}
                onTableChange={onSortTable}
                noDataIndication={
                  loading ? <LoaderComponent /> : "No data found!"
                }
              ></BootstrapTable>
            )}
          </div>
        </div>
      </div>
    ),
    showExpandColumn: true,
    expanded: [...data?.map((item) => item.reconName)],
  };

  return (
    <>
      <div className="row mt-3 text-select">
          <div className="row col-12">
            <div className="col-8">
              <h5 className="title text-bright ml-3 mb-4">Recon Pairs</h5>
            </div>
            <div className="col-4">
              <div class="form-group has-search">
                <span class="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search by Batch ID"
                  value={tempQuery}
                  onChange={(e) => {
                    setTempQuery(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setSearchQuery(e.target.value);
                      setPage(1);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="overflow-y-auto">
              <div id="anchor1">
              <BootstrapTable
                keyField="reconName"
                bootstrap4={true}
                bordered={false}
                classes="rs-table table-layout-auto table-custom-style table-custom-style"
                wrapperClasses="overflow-y-auto fancy-scroll"
                data={data}
                columns={columnsDataSourceExpand}
                expandRow={expandRow}
                noDataIndication={
                  loading ? <LoaderComponent style={{}} /> : "No data found!"
                }
                />
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <Pagination
              setPage={setPage}
              page={page}
              pageSize={pageSize}
              setPageSize={setPageSize}
              totalPages={totalPages}
              totalElements={totalElements}
              data={data || []}
              loading={true}
            />
          </div>
        </div>
      </>
  );
};
