import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import Cron from "react-cron-generator";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import AddSchedularParams from "./AddSchedularParams";
import Toast from "../../../components/Toast/Toast";
import axiosInstance from "../../../utils/axiosInstance";

const formSchema = Yup.object().shape({
  schedulerName: Yup.string().required("Scheduler Name is Required*"),
  // triggerTypeId: Yup.string().required("Trigger Type is Required*"),
  // triggerValue: Yup.string()
  //   .required("Trigger Value is Required*")
  //   .test("isValidCronExpression", "Invalid Cron Expression", (value) => {
  //     if (!value) return true; // Don't validate if the field is empty

  //     try {
  //       cronParser.parseExpression(value);
  //       // Additional check for supported cron expressions
  //       if (!isSupportedCronExpression(value)) {
  //         return false;
  //       }
  //       return true;
  //     } catch (error) {
  //       return false;
  //     }
  //   }),
  schedulerTypeId: Yup.string().required("Scheduler Type is Required*"),
  groupCode: Yup.string().required("Group Code is Required*"),
  schedulerConfigId: Yup.string().required(
    "Recon Configurations/Reports is Required*"
  ),
  topic: Yup.string().required("Topic Name is Required*"),
});

// Function to check if a cron expression is supported
function isSupportedCronExpression(value) {
  return !value.includes("unsupported-pattern");
}

const initialFormValues = {
  schedulerName: "",
  triggerTypeId: "1", //1 means Cron Expression
  triggerValue: "",
  schedulerTypeId: "1",
  endpoint: "",
  groupCode: "",
  topic: "",
  activeInd: "true",
  schedulerParams: {},
  schedulerConfigId: "",
};
// const triggerTypeOptions = [
//   {
//     value: "1",
//     label: "Cron Expression",
//   },
//   {
//     value: "2",
//     label: "Fixed Interval",
//   },
// ];
const groupOptions = [
  {
    value: "recon-schedule",
    label: "Recon Schedule",
  },
  {
    value: "reporting-schedule",
    label: "Reporting Schedule",
  },
];
const typeOptions = [
  {
    value: "1",
    label: "Kafka",
  },
  // {
  //   value: "2",
  //   label: "API",
  // },
];
const AddOrEdit = ({
  showModal,
  setShowModal,
  isEdit,
  setIsEdit,
  getData,
  id,
  setId,
  data,
  setData,
}) => {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [reconData, setReconData] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [triggerCronValue, setTriggerCronValue] = useState("");

  const getReconData = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/recon?page=0&size=500`);
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        setReconData(
          response?.data?.reconStatus.map((item) => {
            return { value: item.id, label: item.reconName };
          })
        );
      }
    } catch (err) {}
  }, []);

  const getReportData = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/reports`);
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        setReconData(
          response?.data?.map((item) => {
            return { value: item.id, label: item.reportName };
          })
        );
      }
    } catch (err) {}
  }, []);

  const addTableRows = () => {
    const rowsInput = {
      columnPath: "",
      datasourceId: "",
      isDynamic: false,
      propKey: "",
      propValue: "",
    };
    setRowsData((prevRowsData) => [...prevRowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const updatedRowsData = rowsData.filter((row, i) => i !== index);
    // const updatedRowsData = rowsData.filter((row) => row.propKey !== paramKey);
    setRowsData(updatedRowsData);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    if (name === "isDynamic") {
      const { checked } = evnt.target;
      rowsInput[index][name] = checked;
    } else {
      rowsInput[index][name] = value;
    }
    setRowsData(rowsInput);
  };

  const handleCronChange = (e) => {
    setTriggerCronValue(e);
  };

  // const handleInputChange = (e) => {
  //   let { name, value } = e.target;
  //   if (name === "activeInd") {
  //     value = e.target.value.toString() === "true" ? true : false;
  //   }
  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     [name]: value,
  //   }));
  // };

  const handleEdit = async (id, values) => {
    try {
      setIsLoading(true);
      const data = {
        ...values,
        triggerValue: triggerCronValue && triggerCronValue,
        schedulerParams: rowsData.reduce((acc, row) => {
          if (row.propKey) {
            if (row.isDynamic) {
              acc[row.propKey] = {
                value: row.propValue,
                isDynamic: row.isDynamic,
                datasourceId: row.datasourceId,
                columnPath: row.columnPath,
              };
            } else {
              acc[row.propKey] = {
                value: row.propValue,
                isDynamic: row.isDynamic,
              };
            }
          }
          return acc;
        }, {}),
      };

      let response = await axiosInstance.put(`/scheduler/${id}`, {
        data: data,
      });
      if (response.message.status !== "200") {
        setIsLoading(false);
        setShowModal(false);
        setIsEdit(false);
        setData([]);
        Toast(response.message.description, "error");
      } else {
        setIsLoading(false);
        setShowModal(false);
        setIsEdit(false);
        getData();
        Toast("Record updated!", "success");
      }
    } catch (err) {
      setIsLoading(false);
      setShowModal(false);
      setIsEdit(false);
      Toast(err.response.data.message.description, "error");
    }
  };

  const handleAdd = async (values) => {
    try {
      setIsLoading(true);
      const data = {
        ...values,
        triggerValue: triggerCronValue && triggerCronValue,
        schedulerParams: rowsData.reduce((acc, row) => {
          if (row.propKey) {
            if (row.isDynamic) {
              acc[row.propKey] = {
                value: row.propValue,
                isDynamic: row.isDynamic,
                datasourceId: row.datasourceId,
                columnPath: row.columnPath,
              };
            } else {
              acc[row.propKey] = {
                value: row.propValue,
                isDynamic: row.isDynamic,
              };
            }
          }
          return acc;
        }, {}),
      };
      const res = await axiosInstance.post("/scheduler", { data: data });
      if (res.message.status == 200) {
        setIsLoading(false);
        setShowModal(false);
        getData();
        Toast("Record added!", "success");
      } else {
        Toast(res.message.description, "error");
        setIsLoading(false);
        setShowModal(false);
        setIsEdit(false);
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
      setIsLoading(false);
      setShowModal(false);
      setIsEdit(false);
    }
  };

  const handleSubmit = (values) => {
    if (isEdit) {
      handleEdit(id, values);
    } else {
      handleAdd(values);
    }
  };

  useEffect(() => {
    if (isEdit && id) {
      const item = data?.find((value) => value?.id === id);
      setTriggerCronValue(item?.triggerValue);
      setFormValues(item);
      if (item) {
        if (item.groupCode === "recon-schedule") {
          getReconData();
        } else if (item.groupCode === "reporting-schedule") {
          getReportData();
        }
      }
    }
    // eslint-disable-next-line
  }, [isEdit, id]);

  const validateParams = (array) => {
    // Loop through each object in the array
    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      // Check if propKey exists
      if (!obj.propKey) {
        return false;
      }
      // Check if isDynamic is false and propValue is missing
      if (!obj.isDynamic && !obj.propValue) {
        return false;
      }
      // Check if isDynamic is true and datasourceId or columnPath is missing
      if (obj.isDynamic && (!obj.datasourceId || !obj.columnPath)) {
        return false;
      }
    }
    // If all conditions pass, return true
    return true;
  };

  return (
    <div>
      <Modal
        backdrop={true}
        size="xl"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Formik
          initialValues={formValues}
          enableReinitialize={true}
          validationSchema={formSchema}
          onSubmit={(values, { resetForm }) => {
            if (
              values?.endpoint !== "" ||
              values?.topic !== "" ||
              (triggerCronValue !== "" &&
                triggerCronValue?.trim()?.length >= 11)
            ) {
              if (values?.schedulerTypeId.toString() === "1") {
                values.endpoint = "";
              } else if (values?.schedulerTypeId.toString() === "2") {
                values.topic = "";
              }
              if (
                triggerCronValue?.split(" ")?.length >= 6 &&
                !triggerCronValue?.includes("  ")
              ) {
                if (rowsData.length === 0) {
                  handleSubmit(values);
                  resetForm({ values: "" });
                } else if (rowsData.length > 0 && validateParams(rowsData)) {
                  handleSubmit(values);
                  resetForm({ values: "" });
                }
              }
            }
          }}
        >
          {({ errors, touched, handleSubmit, values, setFieldValue }) => (
            <Form
              id="add-nostro-account"
              className="form"
              // onSubmit={handleSubmit}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h5 className="pl-3">
                    {isEdit ? "Edit Scheduler" : " Add Scheduler"}
                  </h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row justify-content-start align-items-center">
                  <div className="col-12">
                    <label className="form-label">Scheduler Name</label>
                    <Field
                      id="schedulerName"
                      className="form-control rs-input"
                      type="text"
                      placeholder="Enter scheduler Name"
                      name="schedulerName"
                      autoComplete="off"
                    />
                    {errors.schedulerName && touched.schedulerName ? (
                      <div className="field-error">{errors.schedulerName}</div>
                    ) : null}
                  </div>
                </div>
                <div className="row justify-content-start align-items-center mt-3">
                  <div className="col-12">
                    <Cron
                      onChange={handleCronChange}
                      value={triggerCronValue}
                      showResultText={true}
                      showResultCron={true}
                    />
                  </div>
                  {triggerCronValue === "" ? (
                    <div className="field-error ml-2 mt-2">
                      Trigger Value is Required*
                    </div>
                  ) : triggerCronValue?.split(" ")?.length <= 5 ? (
                    <div className="field-error ml-2 mt-2">
                      Cron Expression must be at least 6 field characters!
                    </div>
                  ) : triggerCronValue?.includes("  ") ? (
                    <div className="field-error ml-2 mt-2">
                      Double space is not allowed in Cron Expression!
                    </div>
                  ) : null}
                </div>

                <div className="row justify-content-start align-items-center">
                  <div className="col-6 mt-3">
                    <label className="form-label">Group</label>
                    <Select
                      id="groupCode"
                      classNamePrefix={"groupCode"}
                      options={groupOptions}
                      name={"groupCode"}
                      value={
                        groupOptions
                          ? groupOptions.find(
                              (option) => option.value === values.groupCode
                            )
                          : ""
                      }
                      onChange={(e) => {
                        setFieldValue("groupCode", e.value);
                        setFieldValue("schedulerConfigId", "");
                        if (e.value === "recon-schedule") {
                          getReconData();
                        } else if (e.value === "reporting-schedule") {
                          getReportData();
                        }
                      }}
                    />
                    {errors.groupCode && touched.groupCode ? (
                      <div className="field-error">{errors.groupCode}</div>
                    ) : null}
                  </div>
                  <div className="col-6 mt-3">
                    <label className="form-label">
                      {values?.groupCode === "reporting-schedule"
                        ? "Reports"
                        : values?.groupCode === "recon-schedule"
                        ? "Recon Configurations"
                        : ""}
                    </label>
                    <Select
                      id="schedulerConfigId"
                      classNamePrefix={"schedulerConfigId"}
                      options={reconData}
                      name={"schedulerConfigId"}
                      value={
                        reconData
                          ? reconData.find(
                              (option) =>
                                option.value ===
                                parseInt(values?.schedulerConfigId)
                            )
                          : ""
                      }
                      onChange={(e) =>
                        setFieldValue("schedulerConfigId", e.value)
                      }
                    />
                    {errors.schedulerConfigId && touched.schedulerConfigId ? (
                      <div className="field-error">
                        {errors.schedulerConfigId}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row justify-content-start align-items-center">
                  <div className="col-6 mt-3">
                    <label className="form-label">Scheduler Type</label>
                    <Select
                      id="schedulerTypeId"
                      classNamePrefix={"schedulerTypeId"}
                      options={typeOptions}
                      name={"schedulerTypeId"}
                      value={
                        typeOptions
                          ? typeOptions.find(
                              (option) =>
                                option?.value?.toString() ===
                                values?.schedulerTypeId?.toString()
                            )
                          : ""
                      }
                      onChange={(e) => {
                        setFieldValue("schedulerTypeId", e.value);
                        if (values?.topic === null) {
                          setFieldValue("topic", "");
                        }
                        if (values?.endpoint === null) {
                          setFieldValue("endpoint", "");
                        }
                      }}
                    />
                    {errors.schedulerTypeId && touched.schedulerTypeId ? (
                      <div className="field-error">
                        {errors.schedulerTypeId}
                      </div>
                    ) : null}
                  </div>
                  {values?.schedulerTypeId.toString() === "2" ? (
                    <div className="col-6 mt-3">
                      <label className="form-label">API End Point</label>
                      <Field
                        id="endpoint"
                        className="form-control rs-input"
                        type="text"
                        placeholder="Enter API end point"
                        name="endpoint"
                        autoComplete="off"
                      />

                      {values?.schedulerTypeId.toString() === "2" &&
                        touched?.endpoint &&
                        values?.endpoint === "" && (
                          <div className="field-error">
                            API endpoint is required*
                          </div>
                        )}
                    </div>
                  ) : (
                    <div className="col-6 mt-3">
                      <label className="form-label">Topic Name</label>
                      <Field
                        id="topic"
                        className="form-control rs-input"
                        type="text"
                        placeholder="Topic Name"
                        name="topic"
                        autoComplete="off"
                      />

                      {values?.schedulerTypeId.toString() === "1" &&
                        touched?.topic &&
                        values?.topic === "" && (
                          <div className="field-error">
                            Topic Name is required*
                          </div>
                        )}
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column mt-2">
                  <label className="form-label">Select Active/inActive</label>
                  <Field
                    id="activeInd"
                    name="activeInd"
                    autocomplete="off"
                    render={({ field }) => (
                      <div className="d-flex">
                        <div className="mr-3">
                          <input
                            {...field}
                            id="active"
                            value="true"
                            checked={field.value.toString() === "true"}
                            name="activeInd"
                            type="radio"
                          />
                          <label
                            className="form-check-label ml-1"
                            htmlFor="active"
                          >
                            Active
                          </label>
                        </div>

                        <div className="ml-3">
                          <input
                            {...field}
                            id="inactive"
                            value="false"
                            name="activeInd"
                            checked={field.value.toString() === "false"}
                            type="radio"
                          />
                          <label
                            className="form-check-label ml-1"
                            htmlFor="inactive"
                          >
                            Inactive
                          </label>
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="row mt-3 d-flex justify-content-center align-items-center">
                  <div
                    className={
                      rowsData.find((row) => row.isDynamic === true)
                        ? "col-3 pl-4"
                        : "col-4"
                    }
                  >
                    <div className="text-left">Params Name</div>
                  </div>
                  <div
                    className={
                      rowsData.find((row) => row.isDynamic === true)
                        ? "col-3 pl-4"
                        : "col-4"
                    }
                  >
                    <div className="text-left">Value</div>
                  </div>
                  {rowsData.find((row) => row.isDynamic === true) ? (
                    <>
                      <div className="col-5"></div>
                      <div className="col-0.5"></div>
                    </>
                  ) : (
                    <div className="col-2"></div>
                  )}
                  <div
                    className={
                      rowsData.find((row) => row.isDynamic === true)
                        ? "col-0.5"
                        : "col-2"
                    }
                  >
                    <button
                      id="addRow"
                      className="btn btn-outline-success"
                      type="button"
                      onClick={addTableRows}
                    >
                      +
                    </button>
                  </div>
                </div>
                <AddSchedularParams
                  rowsData={rowsData}
                  setRowsData={setRowsData}
                  deleteTableRows={deleteTableRows}
                  handleChange={handleChange}
                  formValues={formValues}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <ButtonBasic
                    id="cancel"
                    title="Cancel"
                    onClick={() => {
                      setShowModal(false);
                      setFormValues(initialFormValues);
                      setId("");
                    }}
                  />
                  <button
                    id="submit"
                    type="button"
                    name="submit"
                    className={"btn btn-info btn-md mr-2"}
                    onClick={() => handleSubmit()}
                  >
                    {isEdit ? "Update" : "Add"}
                  </button>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
export default AddOrEdit;
