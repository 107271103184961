import React from "react";
import Spinner from "react-bootstrap/Spinner";
import SpinnerStyle from "./SpinnerStyle";

function LoaderComponent() {
  return (
    // <SpinnerStyle className="d-flex justify-content-center">
    //   <Spinner
    //     animation="border"
    //     role="status"
    //     className="spinner-border"
    //     style={{ width: "3rem", height: "3rem", color: "#02bbff" }}
    //   ></Spinner>
    // </SpinnerStyle>
    <div className="d-flex justify-content-center align-items-center">
    <div class="spinner-grow text-info" style={{width: '3rem', height: '3rem'}} role="status">
  <span class="sr-only">Loading...</span>
  </div>
</div>
  );
}

LoaderComponent.prototype = {};

export default LoaderComponent;
