import Toast from "../../components/Toast/Toast";
import axiosInstance from "../axiosInstance";

const logOut = async (message) => {
  try {
    const response = await axiosInstance.post("/users/logout");
    if (response.message.status === "200") {
      localStorage.clear();
      if (message?.status === "451") {
        var serializedState = encodeURIComponent(JSON.stringify(message));
        var newUrl = "/login?state=" + serializedState;
        window.location.href = newUrl;
      } else {
        window.location.href = "/";
      }
    } else {
      console.error("Failed to logout");
      Toast(response.message.description, "error");
    }
  } catch (error) {
    Toast(error.response.data.message.description, "error");
  }
};

export default logOut;
