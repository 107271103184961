import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axiosInstance from "../../../../utils/axiosInstance";
import ButtonBasic from "../../../../components/Buttons/ButtonBasic";
import Toast from "../../../../components/Toast/Toast";
import LoaderComponent from "../../../../components/Spinner/LoaderComponent";
import {
  mapFilters,
  handleFilterChange,
  handleNewFilter,
  filtersParams,
} from "../../../../utils/filtersHelper";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { isArray } from "lodash";
import { Component } from "react";
import isEqual from "lodash/isEqual";
import FilterDropdownStyle from "./FilterDropdownStyle";
import chevronDown from "../../../../assets/images/chevron-down.png";
import Filter from "../../../../components/FilterDropdown/GenericFilter";

export const regionCheck = (data, array, flag) => {
  let arr = data.map((x) => {
    if (flag) {
      x.checked = true;
      // eslint-disable-next-line
    } else if (array?.find((y) => y.value == x.codeValue)) {
      x.checked = true;
    } else {
      x.checked = false;
    }
    let obj = {
      id: x.id,
      label: x.label,
      value: x.codeValue,
      codeValue: x.codeValue,
      checked: x.checked,
    };
    if (x.subClass) {
      obj.subClass = regionCheck(x.subClass, array, x.checked);
    }
    return obj;
  });
  return arr;
};

const EditUserModal = ({
  user,
  user_Id,
  showEditModal,
  setShowEditModal,
  labeledRoles,
  selectedRoles,
  getUsers,
}) => {
  const [filter, setFilter] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [showFilters, setShowFilters] = useState([]);
  const [resourcesData, setResourcesData] = useState([]);
  const [selectedResourceTypes, setSelectedResourceTypes] = useState([]);
  const [selectedResourceValues, setSelectedResourceValues] = useState([]);
  const [reconAccountIds, setReconAccountIds] = useState([]);
  const regionFilter = useRef("");
  const regionRef = useRef([]);
  const [loading, setLoading] = useState(false);
  const [restrictedUser, setRestrictedUser] = useState(user?.restrictedUser);
  const [selectedValue, setSelectedValue] = useState([]);
  const [regionResourcesData, setRegionResourcesData] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isTeamLoading, setIsTeamLoading] = useState(false);

  const formatedResourceTypes = filterData[0]?.filterData?.map((item) => ({
    ...item,
    value: item.codeValue,
    isFixed: item.checked,
  }));

  const resourceTypeOptions = (item) =>
    item &&
    item.map(
      (item) =>
        ({
          label: item.accountLabel,
          value: item.id,
        } || [])
    );

  const handleSelectServices = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleSelectResourceTypes = (e) => {
    setSelectedResourceValues(
      Array.isArray(e) ? e.map((x) => x.codeValue) : []
    );
    let data = user?.userAccess
      ?.map((access) => ({
        resourceType: access?.resourceType,
        resources: resourceTypeOptions(
          access?.resourceData?.map((data) => data)
        ),
      }))
      ?.map((itm) => ({
        [itm.resourceType]: itm.resources,
      }));
    setReconAccountIds(
      data?.filter((itm) => e.find((item) => itm[item.label]))
    );
  };

  const handleResourceOptions = (selectedOptions, resourceType) => {
    setReconAccountIds((prevState) => {
      let data = prevState && isArray(prevState) ? [...prevState] : [];

      if (!prevState?.flatMap((x) => Object.keys(x)).includes(resourceType)) {
        data?.push({ [resourceType]: [...selectedOptions] });
        return data;
      }
      return prevState?.map((itm) => {
        if (itm[resourceType]) {
          itm[resourceType] = [...selectedOptions];
          return itm;
        }
        return itm;
      });
    });
  };

  const getTeams = () => {
    setIsTeamLoading(true);
    axiosInstance
      .get("/team/dropdown")
      .then((res) => {
        const transformedData = res?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setTeamOptions(transformedData);
        setIsTeamLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsTeamLoading(false);
      });
  };

  useEffect(() => {
    setSelectedValue(selectedRoles);
    setRestrictedUser(user?.restrictedUser);
    setSelectedResourceValues(
      filter?.resource_type?.data
        ?.filter((item) => item.checked)
        ?.map((item) => item.codeValue)
    );
  }, [filter?.resource_type?.data, selectedRoles, user?.restrictedUser]);

  useEffect(() => {
    setSelectedResourceTypes(selectedResourceTypes);
  }, [selectedResourceTypes]);

  const preSelectedResources =
    user &&
    user?.userAccess?.map((access) => ({
      resourceType: access?.resourceType,
      resources: resourceTypeOptions(access?.resourceData?.map((data) => data)),
    }));

  useEffect(() => {
    if (user) {
      setReconAccountIds(
        user?.userAccess
          ?.map((access) => ({
            resourceType: access?.resourceType,
            resources: resourceTypeOptions(
              access?.resourceData?.map((data) => data)
            ),
          }))
          ?.map((itm) => ({
            [itm.resourceType]: itm.resources,
          }))
      );
    }
  }, []);

  useEffect(() => {
    getTeams();
  }, []);

  const allRegionsData =
    regionResourcesData?.find &&
    regionResourcesData?.find((item) => item?.filterName === "region");

  const editUser = (values) => {
    const data = {
      user: {
        username: values.username,
        password: values.password,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        empCode: values.empCode,
        mobileNo: values.mobileNo,
        roles: selectedValue,
        officePhone: values.officePhone,
        officePhoneExt: values.officePhoneExt,
        userLoginTypeId: 0,
        restrictedUser: restrictedUser,
        teamId: values.teamId,
        regionIds: restrictedUser ? regionRef.current : [],
        resourceType: restrictedUser ? filter?.resource_type?.filter : [],
        reconAccountIds: restrictedUser
          ? reconAccountIds &&
            resourcesData
              ?.map((item) =>
                reconAccountIds
                  ?.flatMap((itm) =>
                    itm[item.resourceType]?.map((resource) => resource.value)
                  )
                  .filter((value) => value !== undefined)
              )
              .flat()
          : [],
      },
    };
    axiosInstance
      .put(`/users/${user_Id}`, { data })
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          setShowEditModal(false);
          getUsers();
          getFilters(user_Id);
          getUserRegionResources(user_Id);
          Toast(res.message.description, "success");
        }
      })
      .catch((err) => {
        console.log(err);
        Toast(err.response.data.message.description, "error");
      });
  };

  const getFilters = useCallback((user_Id) => {
    axiosInstance
      .get(`users/user-region-resources?user_id=${user_Id}`)
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          let regions = res?.data?.filters?.filter(
            ({ filterName }) => filterName === "region"
          );
          let otherFilters = res?.data?.filters?.filter(
            ({ filterName }) => filterName !== "region"
          );
          // debugger;
          let obj = mapFilters(res.data.filters.filter((x) => x.showOnScreen));
          setFilter(obj);
          const filteredFilters = otherFilters?.filter((x) => x.showOnScreen);
          setFilterData(filteredFilters);

          const flattenCheckedValues = (data) =>
            data.flatMap(({ checked, codeValue, subClass }) => [
              ...(checked ? [codeValue] : []),
              ...(subClass ? flattenCheckedValues(subClass) : []),
            ]);
          regionRef.current = flattenCheckedValues(regions[0].filterData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const getResourcesData = useCallback((selectedResourceValues) => {
    setLoading(true);

    axiosInstance
      .get(
        `/users/resources?region_id=${
          regionRef?.current
        }&resource_type=${selectedResourceValues?.join(",")}`
      )
      .then((res) => {
        if (res.message.status !== "200") {
          setResourcesData([]);
          setLoading(false);
          Toast(res.message.description, "error");
        } else {
          setLoading(false);
          setResourcesData(res?.data || []);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setResourcesData([]);
        setLoading(false);
        Toast(err?.response?.data?.message?.description, "error");
      });
  }, []);

  const getUserRegionResources = async (user_Id) => {
    try {
      let response = await axiosInstance.get(
        `/users/user-region-resources?user_id=${user_Id}`
      );

      if (response?.message?.status !== "200") {
        setRegionResourcesData([]);
        setLoading(false);
        // Toast(response.message.description, "error");
      } else {
        setRegionResourcesData(response?.data?.filters || []);
        setLoading(false);
      }
    } catch (err) {
      // Toast(err.response.data.message.description, "error");
    }
  };

  useEffect(() => {
    if (showEditModal) {
      getFilters(user_Id);
    }
  }, [getFilters, showEditModal, user_Id]);

  useEffect(() => {
    getUserRegionResources(user_Id);
  }, [user_Id]);

  useEffect(() => {
    if (showEditModal && filter?.resource_type?.filter) {
      getResourcesData(filter?.resource_type?.filter);
    }
    // eslint-disable-next-line
  }, [getResourcesData, filter, regionRef?.current, showEditModal]);

  const onChange = (currentNode, selectedNodes) => {
    let oldRegionResourcesDataData = [...regionResourcesData];
    let data = oldRegionResourcesDataData?.map((item) => {
      if (item.filterName === "region") {
        item.filterData = regionCheck(item.filterData, selectedNodes);
      }
      return item;
    });
    setRegionResourcesData([...data]);
    regionRef.current = selectedNodes?.map((item) => item.value);
  };

  function formatData(data) {
    return data?.map((item) => {
      const { codeValue, checked, label, subClass } = item;
      const children = subClass ? formatData(subClass) : undefined;

      return {
        value: codeValue,
        checked,
        label,
        children,
      };
    });
  }

  const formattedData = formatData(allRegionsData?.filterData);
  return (
    <div className="col-md-12">
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <Formik
        initialValues={user}
        enableReinitialize={true}
        // onSubmit={(values) => editUser(values)}
        onSubmit={(values, { resetForm }) => {
          if (
            !restrictedUser ||
            (restrictedUser && regionRef.current?.length > 0)
          ) {
            if (selectedValue?.length > 0 && values?.teamId) {
              editUser(values);
            }
          }
        }}
      >
        {({ values, errors, setFieldValue, touched, handleSubmit }) => (
          <Form id="add-user" className="form" onSubmit={handleSubmit}>
            <Modal
              backdrop={true}
              size="lg"
              show={showEditModal}
              onHide={() => setShowEditModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>Edit User</Modal.Header>
              <Modal.Body scrollable="true">
                <div className="row mt-3">
                  <div className="col-md-4 col-sm-12">
                    <div className="d-flex flex-column">
                      <label className="form-label">First Name</label>

                      <Field
                        id="firstName"
                        className="rs-input"
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                      />
                      <ErrorMessage
                        component="div"
                        name="firstName"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="d-flex flex-column">
                      <label className="form-label">Middle Name</label>

                      <Field
                        id="middleName"
                        className="rs-input"
                        type="text"
                        placeholder="Enter Middle Name"
                        name="middleName"
                      />
                      <ErrorMessage
                        component="div"
                        name="middleName"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="d-flex flex-column">
                      <label className="form-label">Last Name</label>

                      <Field
                        id="lastName"
                        className="rs-input"
                        type="text"
                        placeholder="Enter Last Name"
                        name="lastName"
                      />
                      <ErrorMessage
                        component="div"
                        name="lastName"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <label className="form-label">Email</label>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">
                            Email is read-only and cannot be edited
                          </Tooltip>
                        }
                      >
                        <Field
                          id="email"
                          className="rs-input"
                          type="text"
                          placeholder="Enter Email"
                          name="username"
                          disabled
                        />
                      </OverlayTrigger>
                      <ErrorMessage
                        component="div"
                        name="username"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <label className="form-label">Mobile Number</label>

                      <Field
                        id="mobileNo"
                        className="rs-input"
                        type="text"
                        placeholder="Enter Mobile Number"
                        name="mobileNo"
                      />
                      <ErrorMessage
                        component="div"
                        name="mobileNo"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <label className="form-label">Office Number</label>

                      <Field
                        id="officePhone"
                        className="rs-input"
                        type="text"
                        placeholder="Enter Office Phone"
                        name="officePhone"
                      />
                      <ErrorMessage
                        component="div"
                        name="officePhone"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <label className="form-label">Office Extention</label>

                      <Field
                        id="officePhoneExt"
                        className="rs-input"
                        type="text"
                        placeholder="Enter Office Extension"
                        name="officePhoneExt"
                      />
                      <ErrorMessage
                        component="div"
                        name="officePhoneExt"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <label className="form-label">Employee Code</label>
                      <Field
                        id="empCode"
                        className="rs-input"
                        type="text"
                        placeholder="Employee Code"
                        name="empCode"
                      />
                      <ErrorMessage
                        component="div"
                        name="empCode"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mt-3">
                    <div className="d-flex flex-column">
                      <Select
                        id="team"
                        placeholder="Select Team"
                        options={teamOptions}
                        value={
                          teamOptions
                            ? teamOptions?.find(
                                (option) => option?.value === values?.teamId
                              )
                            : null
                        }
                        classNamePrefix={"team"}
                        onChange={(e) => setFieldValue("teamId", e.value)}
                        isSearchable
                      />
                    </div>
                    {!values?.teamId ? (
                      <div className="field-error">Select Team!</div>
                    ) : null}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <Select
                      id="role"
                      placeholder="Select Role"
                      value={labeledRoles.filter((obj) =>
                        selectedValue.includes(obj.value)
                      )}
                      options={labeledRoles}
                      onChange={handleSelectServices}
                      classNamePrefix={"role"}
                      isMulti
                      isClearable
                      isSearchable
                    />
                    {selectedValue.length === 0 ? (
                      <div className="field-error">
                        Select atleast one role!
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <label className="form-label">User Access</label> <br />
                    <Field
                      id="restrictedUser"
                      type="checkbox"
                      name="restrictedUser"
                      checked={restrictedUser}
                      onChange={(e) => setRestrictedUser(e.target.checked)}
                    />
                    <label className="pl-2">Restricted</label>
                  </div>
                  {restrictedUser && (
                    <>
                      <div className="col-6 filter-item mt-2">
                        {formattedData && (
                          <Container onChange={onChange} data={formattedData} />
                        )}
                        {regionRef.current?.length === 0 &&
                        filterData.length > 0 ? (
                          <div className="field-error">Region is required*</div>
                        ) : null}
                      </div>
                      <div className="col-6 mt-1">
                        {filterData.map(
                          ({
                            filterName,
                            filterType,
                            filterLabel,
                            showOnScreen,
                            filterId,
                          }) => (
                            <div className="filter-item mt-1">
                              <Filter
                                id="resource_type"
                                title={filterLabel}
                                filterId={filterId}
                                type={filterType}
                                data={filter[filterName]?.data}
                                filter={filter[filterName]?.filter}
                                removable={!showOnScreen}
                                onChange={(e) =>
                                  handleFilterChange(
                                    e,
                                    filterName,
                                    filter,
                                    setFilter
                                  )
                                }
                                onRemove={(e) =>
                                  handleNewFilter(
                                    e,
                                    filterData,
                                    showFilters,
                                    setFilter,
                                    setFilterData
                                  )
                                }
                              />
                            </div>
                          )
                        )}
                        {filter?.resource_type?.filter?.length === 0 ? (
                          <div className="field-error">
                            Select atleast one Resource type!
                          </div>
                        ) : null}
                      </div>
                      {resourcesData &&
                        resourcesData?.map((item) => (
                          <>
                            {item.resourceData !== null && (
                              <div
                                className="col-12 mt-3"
                                key={item.resourceType}
                              >
                                <label className="form-label">
                                  Select {item?.resourceType}
                                </label>
                                <Select
                                  id="resourceType"
                                  placeholder="Select Option"
                                  // defaultValue={
                                  //   preSelectedResources?.find(
                                  //     (preSelected) =>
                                  //       preSelected.resourceType ===
                                  //       item.resourceType
                                  //   )?.resources
                                  // }
                                  value={resourceTypeOptions(
                                    item?.resourceData
                                  )?.filter((obj) => {
                                    return reconAccountIds
                                      ?.flatMap((itm) => itm[item.resourceType])
                                      ?.filter((x) => x)
                                      ?.find((x) => x.value === obj.value);
                                  })}
                                  options={resourceTypeOptions(
                                    item?.resourceData
                                  )}
                                  classNamePrefix={"resourceType"}
                                  isMulti
                                  isSearchable
                                  isClearable={true}
                                  className="no-border"
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderRadius: "10px",
                                    }),
                                  }}
                                  onChange={(selectedOptions) =>
                                    handleResourceOptions(
                                      selectedOptions,
                                      item.resourceType,
                                      preSelectedResources
                                    )
                                  }
                                />
                              </div>
                            )}
                          </>
                        ))}
                    </>
                  )}
                               <fieldset className="col-6 mt-2" disabled={restrictedUser}>
                    <div className="filter-item mt-1">
                      <Filter
                        id="systemReports"
                        title={"System Reports"}
                        type={1003}
                        data={[
                          {
                            id: 119002,
                            label: "System Report 1",
                            param1: "0",
                            codeValue: "119002",
                            checked: false,
                            value: "119002",
                          },
                          {
                            id: 119003,
                            label: "System Report 2",
                            param1: "0",
                            codeValue: "119003",
                            checked: false,
                            value: "119003",
                          },
                          {
                            id: 119004,
                            label: "System Report 3",
                            param1: "0",
                            codeValue: "119004",
                            checked: false,
                            value: "119004",
                          },
                        ]}
                        filter={[]}
                      />
                    </div>
                  </fieldset>
                  <div className="col-6 mt-2">
                    <div className="filter-item mt-1">
                      <Filter
                        id="userReports"
                        title={"User Reports"}
                        type={1003}
                        data={[
                          {
                            id: 119002,
                            label: "User Report 1",
                            param1: "0",
                            codeValue: "119002",
                            checked: false,
                            value: "119002",
                          },
                          {
                            id: 119003,
                            label: "User Report 2",
                            param1: "0",
                            codeValue: "119003",
                            checked: false,
                            value: "119003",
                          },
                          {
                            id: 119004,
                            label: "User Report 3",
                            param1: "0",
                            codeValue: "119004",
                            checked: false,
                            value: "119004",
                          },
                        ]}
                        filter={[]}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <ButtonBasic
                    id="cancel"
                    title="Cancel"
                    onClick={() => setShowEditModal(false)}
                  />
                </div>
                <button
                  id="submit"
                  type="submit"
                  name="submit"
                  className={"btn btn-info btn-md mr-2"}
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditUserModal;

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data };
    this.arraysAreEqual = this.arraysAreEqual.bind(this);
  }

  arraysAreEqual(ary1, ary2) {
    return ary1.join("") === ary2.join("");
  }
  componentWillReceiveProps = (nextProps) => {
    if (!(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data });
    }
  };

  shouldComponentUpdate = (nextProps) => {
    return !this.arraysAreEqual(nextProps.data, this.state.data);
  };

  render() {
    const { data, ...rest } = this.props;

    return (
      <FilterDropdownStyle className={`dropdown `}>
        <button
          className={`d-flex align-items-center justify-content-between btn tsg-dropdown w-100 py-1 px-2`}
          type="button"
          id="dropdownMenuButtonRegion"
          data-toggle={"dropdown"}
        >
          <span>Select Region</span>
          <img className="dropdown-icon" src={chevronDown} alt={"chev"} />
        </button>

        <div className={`dropdown-menu fancy-scroll`}>
          <DropdownTreeSelect
            id="dropdownMenuButtonRegion"
            onChange={this.props.onChange}
            className="bootstrap-demo"
            expanded="true"
            showDropdown="always"
            keepOpenOnSelect={true}
            clearSearchOnChange={true}
            inlineSearchInput={true}
            data={this.state.data || []}
            isDefaultValue={true}
            {...rest}
          />
        </div>
      </FilterDropdownStyle>
    );
  }
}
