import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import { useDateTime } from "../../components/Helper/DateTime";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import axiosInstance from "../../utils/axiosInstance";
import Toast from "../../components/Toast/Toast";
import { mapFilters } from "../../utils/filtersHelper";
import { CustomTooltip } from "../../utils/helpers/custom-tooltip";
import style from "./style.module.scss";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { DateFormat } from "../../utils/formatDateTime";
import BootstrapTable from "react-bootstrap-table-next";

const NostroModal = ({
  showModal,
  setShowModal,
  selectedAccount,
  selectedRow,
  dataSource,
}) => {
  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDebitAccount, setSelectedDebitAccount] = useState("");
  const [selectedCreditAccount, setSelectedCreditAccount] = useState("");
  const [selectedDSA, setSelectedDSA] = useState(null);
  const [selectedDSB, setSelectedDSB] = useState(null);
  const [filters, setFilters] = useState({});
  const { date, presistState, setPresistState } = useDateTime();
  const [notes, setNotes] = useState();
  // It's for persisting the notes in modal if user redirect from one modal to another
  const [noteFlag, setNoteFlag] = useState(
    presistState?.noteFlag ? true : false
  );
  const [selectedDataSource, setSelectedDataSource] = useState("");
  const [selectedDSName, setSelectedDSName] = useState("");
  const [adjustmentEntries, setAdjustmentEntries] = useState(null);
  const [categories, setCategories] = useState([]);
  const [debitAccounts, setDebitAccounts] = useState([]);
  const [creditAccounts, setCreditAccounts] = useState([]);
  const [showNotes, setShowNotes] = useState(false);

  const adjustmentEntriesColumns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "transactionDetails",
      text: "Transaction Details",
      formatter: (cell, row, rowIndex) => (
        <div>
          <BootstrapTable
            bordered={false}
            keyField="id"
            wrapperClasses="overflow-y-hidden overflow-x-hidden"
            classes="rs-table table-layout-auto mb-0 table-custom-style"
            columns={[
              {
                dataField: "glAccount",
                text: "GL Account",
                style: { minWidth: "100px" },
                formatter: (cell) => (cell ? cell : "-"),
              },
              {
                dataField: "postingDate",
                text: "Posting Date",
                formatter: (cell) => (cell ? cell : "-"),
              },
              {
                dataField: "description",
                text: "Description",
                style: {
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  minWidth: "342px",
                  lineHeight: "1.5",
                },
                formatter: (cell) => (cell ? cell : "-"),
              },
              {
                dataField: "creditAmount",
                text: "Credit Amount",
                formatter: (cell, row) => (cell ? cell : "-"),
              },
              {
                dataField: "debitAmount",
                text: "Debit Amount",
                formatter: (cell, row) => (cell ? cell : "-"),
              },
              {
                dataField: "entryReference",
                text: "Entry Reference",
                formatter: (cell) => (cell ? cell : "-"),
              },
            ]}
            data={cell || []}
            headerClasses={rowIndex === 0 ? null : "visibility-collapse"}
          />
          {adjustmentEntries?.length - 1 !== rowIndex && <hr />}
        </div>
      ),
    },
  ];
  const manualNostroReconcile = useCallback(async () => {
    if (selectedAccount?.id) {
      try {
        const res = await axiosInstance.get(
          `/accounts/manual-recon-accounts-v2?batch_id=${dataSource?.batchId}&recon_account_ids=${selectedAccount?.id}`
        );
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          if (res?.data) {
            // setApiData(res?.data);
            populateOptions(res?.data);
          }
        }
      } catch (err) {
        Toast(err.response.data.message.description, "error");
      }
    }
  }, [selectedAccount]);

  const populateOptions = (data) => {
    // Transforming data for Select components
    const transformedCategories = data.categories.map((item) => ({
      value: item.id,
      label: item.categoryName,
    }));

    const transformedDebitAccounts = data.debitAccounts.map((item) => ({
      value: item.reconAccountId,
      label: item.name,
    }));

    const transformedCreditAccounts = data.creditAccounts.map((item) => ({
      value: item.reconAccountId,
      label: item.name,
    }));

    // Set state variables
    setCategories(transformedCategories);
    setDebitAccounts(transformedDebitAccounts);
    setCreditAccounts(transformedCreditAccounts);
  };

  const addNote = () => {
    let now = moment();
    let comment = document.getElementById("note").value;
    if (comment !== "") {
      let note = {
        comment: comment,
        author: `${JSON.parse(localStorage.getItem("userData")).firstName} ${
          JSON.parse(localStorage.getItem("userData")).lastName
        }`,
        date: now.format(date),
        time: now.hour() + ":" + now.minute(),
      };
      setNotes((prevState) => [...prevState, note]);
      document.getElementById("note").value = "";
      setNoteFlag(true);
    }
  };

  const getFilters = async () => {
    try {
      const response = await axiosInstance.get(
        `/hierarchical/filters?screen_id=manual_matching`
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        let obj = mapFilters(response?.data?.filters);
        setFilters(obj);
      }
    } catch (e) {
      Toast(e.response.data.message.description, "error");
    }
  };

  const NostroManualMatching = async () => {
    let newNotes = [];
    if (selectedRow?.notes && noteFlag) {
      for (let i = selectedRow?.notes?.length; i < notes?.length; i++) {
        newNotes.push(notes[i]);
      }
    } else {
      newNotes = notes;
    }
    const data = {
      matchFilterApplied: filters?.matching_singleselection?.data
        ?.filter(
          (x) => selectedRow?.status?.toLowerCase() === x?.label?.toLowerCase()
        )
        .map((x) => x?.value)
        .join(""),
      raisedBy: parseInt(JSON.parse(localStorage.getItem("userData"))?.userId),
      notes: newNotes,
      useCaseApplied: "impacting",
      matching_flow: "Nostro-Manual-Matching",
      dsA: dataSource?.datasourceAName,
      dsB: dataSource?.datasourceBName,
      custom_field_1: dataSource?.datasourceA,
      custom_field_2: dataSource?.datasourceB,
      customData: JSON.stringify(adjustmentEntries),
      selected_ds: selectedDSA
        ? selectedDSA?.ds_id
        : selectedDSB
        ? selectedDSB?.ds_id
        : "",
      selected_ds_name: selectedDSA
        ? dataSource?.datasourceAName
        : selectedDSB
        ? dataSource?.datasourceBName
        : "",
      adjustmentCategory: selectedCategory,
      manualMatchTransactions:
        presistState?.adjustmentFlow &&
        (presistState?.selectedRow?.selectedDSA?.length > 0 ||
          presistState?.selectedRow?.selectedDSB?.length > 0)
          ? [
              {
                locatedTransactions: [
                  ...(presistState?.selectedRow?.selectedDSA || []).map(
                    (txn) => ({
                      sourceOutputId: txn?.outputid ?? null,
                      transactionId: txn?.id,
                      datasourceId: dataSource?.datasourceAId,
                    })
                  ),
                  ...(presistState?.selectedRow?.selectedDSB || []).map(
                    (txn) => ({
                      sourceOutputId: txn?.outputid ?? null,
                      transactionId: txn?.id,
                      datasourceId: dataSource?.datasourceBId,
                    })
                  ),
                ],
                batchId: dataSource?.batchId,
                outputId: selectedRow?.outputId,
                reconAccountId: selectedAccount?.reconAccountId,
                matchingData: JSON.stringify([
                  selectedRow?.dsA
                    ?.map((txn) => {
                      const filteredObject = {};
                      let hasNonNullValue = false;
                      dataSource?.headers?.forEach((header) => {
                        if (txn.hasOwnProperty(header)) {
                          if (
                            txn[header] !== null &&
                            txn[header] !== undefined
                          ) {
                            filteredObject[header] = txn[header];
                            hasNonNullValue = true;
                          }
                        }
                      });
                      return hasNonNullValue
                        ? {
                            status: txn["status"],
                            ...filteredObject,
                          }
                        : null;
                    })
                    .filter((obj) => obj !== null),
                  selectedRow?.dsB
                    ?.map((txn) => {
                      const filteredObject = {};
                      let hasNonNullValue = false;
                      dataSource?.headers?.forEach((header) => {
                        if (txn.hasOwnProperty(header)) {
                          if (
                            txn[header] !== null &&
                            txn[header] !== undefined
                          ) {
                            filteredObject[header] = txn[header];
                            hasNonNullValue = true;
                          }
                        }
                      });
                      return hasNonNullValue
                        ? {
                            status: txn["status"],
                            ...filteredObject,
                          }
                        : null;
                    })
                    .filter((obj) => obj !== null),
                ]),
                id: selectedRow?.matchingId,
              },
            ]
          : [
              {
                batchId: dataSource?.batchId,
                outputId: selectedRow?.outputId,
                reconAccountId: selectedAccount?.reconAccountId,
                matchingData: JSON.stringify([
                  selectedRow?.dsA
                    ?.map((txn) => {
                      const filteredObject = {};
                      let hasNonNullValue = false;
                      dataSource?.headers?.forEach((header) => {
                        if (txn.hasOwnProperty(header)) {
                          if (
                            txn[header] !== null &&
                            txn[header] !== undefined
                          ) {
                            filteredObject[header] = txn[header];
                            hasNonNullValue = true;
                          }
                        }
                      });
                      return hasNonNullValue
                        ? {
                            status: txn["status"],
                            ...filteredObject,
                          }
                        : null;
                    })
                    .filter((obj) => obj !== null),
                  selectedRow?.dsB
                    ?.map((txn) => {
                      const filteredObject = {};
                      let hasNonNullValue = false;
                      dataSource?.headers?.forEach((header) => {
                        if (txn.hasOwnProperty(header)) {
                          if (
                            txn[header] !== null &&
                            txn[header] !== undefined
                          ) {
                            filteredObject[header] = txn[header];
                            hasNonNullValue = true;
                          }
                        }
                      });
                      return hasNonNullValue
                        ? {
                            status: txn["status"],
                            ...filteredObject,
                          }
                        : null;
                    })
                    .filter((obj) => obj !== null),
                ]),
                id: selectedRow?.matchingId,
              },
            ],
      accountDetails: {
        creditAccount: selectedCreditAccount,
        debitAccount: selectedDebitAccount,
      },
      adjustmentCategory: selectedCategory,
      creditAccount: selectedCreditAccount,
      debitAccount: selectedDebitAccount,
    };
    if (!selectedDSA && !selectedDSB) {
      Toast("Please select one Data Source", "error");
    } else if (!noteFlag || notes?.length === selectedRow?.notes?.length) {
      Toast("Please add a note", "error");
    } else {
      try {
        let response = await axiosInstance.post("/matching", data);
        if (response.message.status !== "200") {
          Toast(response.message.description, "error");
        }
        Toast(response.message.description, "success");
        setSelectedDSA(null);
        setSelectedDSB(null);
        !presistState?.goBackToLocate && setPresistState(null);
        setShowModal(false);
      } catch (e) {
        Toast(e.response.data.message.description, "error");
      }
    }
  };
  useEffect(() => {
    manualNostroReconcile();
    if (showModal) {
      getFilters();
      setNotes(
        presistState?.adjustmentFlow
          ? presistState?.notes
          : selectedRow?.notes ?? []
      );
    }
  }, [manualNostroReconcile, selectedAccount, showModal]);

  useEffect(() => {
    if (!presistState?.adjustmentFlow && selectedRow?.status === "MISSING") {
      if (
        selectedRow &&
        selectedRow.dsA &&
        selectedRow.dsA.every((item) =>
          Object.values(item).every(
            (value) => value !== undefined && value !== null
          )
        )
      ) {
        setSelectedDSB(null);
        setSelectedDSA({
          ds_name: dataSource?.datasourceA,
          ds_id: dataSource?.datasourceAId,
          ds: JSON.stringify(selectedRow?.dsA) || "",
        });
        setSelectedDSName(dataSource?.datasourceAName);
        setSelectedDataSource({
          ...selectedDataSource,
          id: selectedRow?.matchingId,
          status: selectedRow?.status,
        });
      } else if (
        selectedRow &&
        selectedRow.dsB &&
        selectedRow.dsB.every((item) =>
          Object.values(item).every(
            (value) => value !== undefined && value !== null
          )
        )
      ) {
        setSelectedDSA(null);
        setSelectedDSB({
          ds_name: dataSource?.datasourceB,
          ds_id: dataSource?.datasourceBId,
          ds: JSON.stringify(selectedRow?.dsB) || "",
        });
        setSelectedDSName(dataSource?.datasourceBName);
        setSelectedDataSource({
          ...selectedDataSource,
          id: selectedRow?.matchingId,
          status: selectedRow?.status,
        });
      }
    }
  }, []);

  const dsBRef = useRef(null);
  const dsARef = useRef(null);

  const handleScroll1 = () => {
    if (dsARef.current) {
      dsBRef.current.scrollLeft = dsARef.current.scrollLeft;
    }
  };
  const handleScroll2 = () => {
    if (dsBRef.current) {
      dsARef.current.scrollLeft = dsBRef.current.scrollLeft;
    }
  };
  const handleShowAdjustment = async () => {
    try {
      let requestPayload = {
        data: {
          matchingData:
            presistState?.adjustmentFlow &&
            (presistState?.selectedRow?.selectedDSA?.length > 0 ||
              presistState?.selectedRow?.selectedDSB?.length > 0)
              ? [
                  {
                    ...selectedDataSource,
                    locatedTransactions: [
                      ...(presistState?.selectedRow?.selectedDSA || []).map(
                        (txn) => ({
                          sourceOutputId: txn?.outputid ?? null,
                          transactionId: txn?.id,
                          datasourceId: dataSource?.datasourceAId,
                        })
                      ),
                      ...(presistState?.selectedRow?.selectedDSB || []).map(
                        (txn) => ({
                          sourceOutputId: txn?.outputid ?? null,
                          transactionId: txn?.id,
                          datasourceId: dataSource?.datasourceBId,
                        })
                      ),
                    ],
                  },
                ]
              : [
                  {
                    ...selectedDataSource,
                  },
                ],
          selectedDataSourceName: selectedDSName,
          creditAccount: selectedCreditAccount,
          debitAccount: selectedDebitAccount,
          adjustmentCategory: selectedCategory,
        },
      };
      let response = await axiosInstance.post(
        "/matching/adjustment-entries",
        requestPayload
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        setAdjustmentEntries(response.data);
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
    }
  };

  const handleSelectionDSA = () => {
    setSelectedDSA({
      ds_name: dataSource?.datasourceA,
      ds_id: dataSource?.datasourceAId,
      ds: JSON.stringify(selectedRow?.dsA) || "",
    });
    setSelectedDSB(null);
    setSelectedDSName(dataSource?.datasourceAName);
    setSelectedDataSource({
      ...selectedDataSource,
      id: selectedRow?.matchingId,
      status: selectedRow?.status,
    });
    setAdjustmentEntries(null);
  };

  const handleSelectionDSB = () => {
    setSelectedDSB({
      ds_name: dataSource?.datasourceB,
      ds_id: dataSource?.datasourceBId,
      ds: JSON.stringify(selectedRow?.dsB) || "",
    });
    setSelectedDSA(null);
    setSelectedDSName(dataSource?.datasourceBName);
    setSelectedDataSource({
      ...selectedDataSource,
      id: selectedRow?.matchingId,
      status: selectedRow?.status,
    });
    setAdjustmentEntries(null);
  };

  const formatNumber = (number) => {
    if (isNaN(Number(number))) {
      return number;
    } else {
      number = Number(number);
      return number.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true,
      });
    }
  };

  useEffect(() => {
    if (selectedDSA || selectedDSB) {
      handleShowAdjustment();
    }
  }, [
    selectedCreditAccount,
    selectedDebitAccount,
    selectedCategory,
    selectedDSA,
    selectedDSB,
  ]);

  return (
    <>
      <div className="col-md-12">
        <Modal
          backdrop={true}
          size="xl"
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setSelectedDSA(null);
            setSelectedDSB(null);
            setShowNotes(false);
            !presistState?.goBackToLocate && setPresistState(null);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>Match With Adjustment</Modal.Header>
          <Modal.Body scrollable="true">
            {presistState?.adjustmentFlow && (
              <div className="row">
                <div className="col-8">
                  <Breadcrumbs
                    data={[
                      {
                        id: 0,
                        title: "Locate Transaction",
                        isActive: false,
                      },
                      {
                        id: 1,
                        title: "Match with Adjustments",
                        isActive: true,
                      },
                    ]}
                    history={history}
                    onClick={() => {
                      setPresistState({
                        ...presistState,
                        adjustmentFlow: false,
                        goBackToLocate: true,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className={showNotes ? "col-8" : "col-12"}>
                <div className="row">
                  <div className="col-4">
                    <label style={{ fontSize: "14px" }}>
                      Adjustment Category*
                    </label>
                    <Select
                      placeholder="Select Category"
                      options={categories}
                      value={categories.find(
                        (item) => item.value === selectedCategory
                      )}
                      onChange={(e) => {
                        setSelectedCategory(e.value);
                      }}
                      isSearchable
                      className="no-border mt-2"
                    />
                  </div>

                  {/* Debit Account Select */}
                  <div className="col-4">
                    <label style={{ fontSize: "14px" }}>Debit Account*</label>
                    <Select
                      placeholder=" Debit Account"
                      isDisabled={debitAccounts.length > 0 ? false : true}
                      options={debitAccounts}
                      value={debitAccounts.find(
                        (item) => item.value === selectedDebitAccount
                      )}
                      onChange={(e) => {
                        setSelectedDebitAccount(e.value);
                      }}
                      isSearchable
                      className="no-border mt-2"
                    />
                  </div>

                  {/* Credit Account Select */}
                  <div className="col-4">
                    <label style={{ fontSize: "14px" }}>Credit Account*</label>
                    <Select
                      placeholder="Credit Account"
                      isDisabled={creditAccounts.length > 0 ? false : true}
                      options={creditAccounts}
                      value={creditAccounts.find(
                        (item) => item.value === selectedCreditAccount
                      )}
                      onChange={(e) => {
                        setSelectedCreditAccount(e.value);
                      }}
                      isSearchable
                      className="no-border mt-2"
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="d-flex flex-row">
                      <div className="text-info">{dataSource?.datasourceA}</div>
                    </div>
                    <div
                      onScroll={handleScroll1}
                      ref={dsARef}
                      className={`table-responsive mt-1 mb-4 fancy-scroll ${
                        selectedDSA ? "matching-box-shadow" : ""
                      }`}
                      style={{ overflowY: "hidden" }}
                      onClick={() => {
                        if (selectedRow?.status.toLowerCase() === "missing") {
                          if (!presistState?.adjustmentFlow) {
                            let selectionFlag = true;
                            if (selectedRow && selectedRow?.dsA) {
                              selectedRow?.dsA.forEach((item) => {
                                let allValuesNull = true;

                                Object.values(item).forEach((value) => {
                                  if (value !== null) {
                                    allValuesNull = false;
                                    return;
                                  }
                                });

                                if (allValuesNull) {
                                  selectionFlag = false;
                                }
                              });
                            } else {
                              selectionFlag = false;
                            }

                            if (selectionFlag) {
                              handleSelectionDSA();
                            }
                          } else {
                            let selectionFlag = true;

                            if (selectedRow && selectedRow?.dsA) {
                              selectedRow.dsA.forEach((item) => {
                                let hasNonNullValues = false;

                                Object.values(item).forEach((value) => {
                                  if (value !== null) {
                                    hasNonNullValues = true;
                                    return;
                                  }
                                });

                                if (hasNonNullValues) {
                                  selectionFlag = true;
                                  return; // Exit the loop early if at least one object has non-null values
                                } else {
                                  selectionFlag = false;
                                }
                              });
                            } else {
                              selectionFlag = false;
                            }

                            if (selectionFlag) {
                              handleSelectionDSA();
                            }
                          }
                        } else {
                          handleSelectionDSA();
                        }
                      }}
                    >
                      <table
                        className={`table table-dim-dark table-bordered table-custom-style${style.tableStyle} m-0`}
                      >
                        <thead>
                          {selectedRow?.dsA?.length > 0 && (
                            <tr>
                              {dataSource?.headers?.map((key) => (
                                <th
                                  key={key}
                                  className={`text-info ${
                                    key === "Balance" ||
                                    key === "Debit" ||
                                    key === "Credit"
                                      ? "text-right"
                                      : ""
                                  }`}
                                >
                                  {key
                                    .replace(/([a-z])([A-Z])/g, "$1 $2")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                </th>
                              ))}
                            </tr>
                          )}
                        </thead>
                        <tbody>
                          {selectedRow?.dsA?.map((item) => {
                            const hasNonNullValue = dataSource?.headers?.some(
                              (key) =>
                                item[key] !== null && item[key] !== undefined
                            );
                            if (!hasNonNullValue) {
                              return null;
                            }

                            return (
                              <tr key={item.id}>
                                {dataSource?.headers?.map((key, index) => {
                                  if (
                                    selectedRow?.status === "MISSING" ||
                                    item[key] !== null
                                  ) {
                                    return (
                                      <>
                                        {key === "Balance" ||
                                        key === "Debit" ||
                                        key === "Credit" ? (
                                          <td key={key} className="text-right">
                                            {item[key]
                                              ? formatNumber(item[key])
                                              : null}
                                          </td>
                                        ) : (
                                          <td
                                            key={key}
                                            style={
                                              key === "Description"
                                                ? {
                                                    whiteSpace: "normal", // or 'pre-wrap'
                                                    wordWrap: "break-word",
                                                    minWidth: "322px",
                                                  }
                                                : null
                                            }
                                          >
                                            {key === "Posting Date"
                                              ? DateFormat(date, item[key])
                                              : item[key] ?? "-"}
                                          </td>
                                        )}
                                      </>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="text-danger">
                        {dataSource?.datasourceB}
                      </div>
                    </div>
                    <div
                      onScroll={handleScroll2}
                      ref={dsBRef}
                      className={`table-responsive mt-1 fancy-scroll ${
                        selectedDSB ? "matching-box-shadow" : ""
                      }`}
                      style={{ overflowY: "hidden" }}
                      onClick={() => {
                        if (selectedRow?.status.toLowerCase() === "missing") {
                          if (!presistState?.adjustmentFlow) {
                            let selectionFlag = true;
                            if (selectedRow && selectedRow?.dsB) {
                              selectedRow?.dsB.forEach((item) => {
                                let allValuesNull = true;

                                Object.values(item).forEach((value) => {
                                  if (value !== null) {
                                    allValuesNull = false;
                                    return;
                                  }
                                });

                                if (allValuesNull) {
                                  selectionFlag = false;
                                }
                              });
                            } else {
                              selectionFlag = false;
                            }

                            if (selectionFlag) {
                              handleSelectionDSB();
                            }
                          } else {
                            let selectionFlag = true;

                            if (selectedRow && selectedRow?.dsB) {
                              selectedRow.dsB.forEach((item) => {
                                let hasNonNullValues = false;

                                Object.values(item).forEach((value) => {
                                  if (value !== null) {
                                    hasNonNullValues = true;
                                    return;
                                  }
                                });

                                if (hasNonNullValues) {
                                  selectionFlag = true;
                                  return; // Exit the loop early if at least one object has non-null values
                                } else {
                                  selectionFlag = false;
                                }
                              });
                            } else {
                              selectionFlag = false;
                            }

                            if (selectionFlag) {
                              handleSelectionDSB();
                            }
                          }
                        } else {
                          handleSelectionDSB();
                        }
                      }}
                    >
                      <table
                        className={`table table-dim-dark table-bordered table-custom-style ${style.tableStyle} m-0`}
                      >
                        <thead>
                          {selectedRow?.dsB?.length > 0 && (
                            <tr>
                              {dataSource?.headers?.map((key) => (
                                <th
                                  key={key}
                                  className={`text-info ${
                                    key === "Balance" ||
                                    key === "Debit" ||
                                    key === "Credit"
                                      ? "text-right"
                                      : ""
                                  }`}
                                >
                                  {key
                                    .replace(/([a-z])([A-Z])/g, "$1 $2")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                </th>
                              ))}
                            </tr>
                          )}
                        </thead>
                        <tbody>
                          {selectedRow?.dsB?.map((item) => {
                            const hasNonNullValue = dataSource?.headers?.some(
                              (key) =>
                                item[key] !== null && item[key] !== undefined
                            );

                            if (!hasNonNullValue) {
                              return null;
                            }

                            return (
                              <tr key={item.id}>
                                {dataSource?.headers?.map((key, index) => {
                                  if (item[key] !== null) {
                                    return (
                                      <>
                                        {key === "Balance" ||
                                        key === "Debit" ||
                                        key === "Credit" ? (
                                          <td key={key} className="text-right">
                                            {item[key]
                                              ? formatNumber(item[key])
                                              : null}
                                          </td>
                                        ) : (
                                          <td
                                            key={key}
                                            style={
                                              key === "Description"
                                                ? {
                                                    whiteSpace: "normal", // or 'pre-wrap'
                                                    wordWrap: "break-word",
                                                    minWidth: "322px",
                                                  }
                                                : null
                                            }
                                          >
                                            {key === "Posting Date"
                                              ? DateFormat(date, item[key])
                                              : item[key] ?? "-"}
                                          </td>
                                        )}
                                      </>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="row justify-content-end align-items-end mt-3 mr-1">
                      <CustomTooltip
                        placement="top"
                        tooltipId="button-tooltip-2"
                        disabled={
                          (selectedDSA || selectedDSB) &&
                          selectedCreditAccount &&
                          selectedDebitAccount &&
                          selectedCategory
                            ? false
                            : true
                        }
                        disabledMessage={
                          selectedCreditAccount &&
                          selectedDebitAccount &&
                          selectedCategory
                            ? selectedDSA || selectedDSB
                              ? null
                              : "Select any Data Source"
                            : "Select Adjustment Category,Debit Account and Credit Account"
                        }
                        onClick={handleShowAdjustment}
                        buttonText={"Show Adjustment Entries"}
                      >
                        Show Adjustment Entries
                      </CustomTooltip>
                    </div> */}
                  </div>
                </div>
              </div>
              {showNotes && (
                <div className="col-4">
                  <div className="notes-wrapper">
                    <h5 className="mb-3 color-gray-dim">Notes</h5>
                    <div
                      className="overflow-auto fancy-scroll"
                      style={{ height: "16em" }}
                    >
                      <div className="mt-2">
                        {notes?.map(({ author, comment, date, time }, i) => (
                          <ul className="rs-notes-container" key={i}>
                            <li className="rs-notes-item">
                              <h6>{author}</h6>
                              <div className="note-comment">{comment}</div>
                              <sub>
                                {date} {time}
                              </sub>
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                    <textarea
                      placeholder="Write a Note"
                      rows="3"
                      id="note"
                      className="textArea"
                    ></textarea>
                    <div className="w-100 mt-2">
                      <ButtonBasic
                        classes={"primary px-4 w-100"}
                        title={"Add Notes"}
                        onClick={() => addNote()}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {adjustmentEntries?.length > 0 && (
              <div className="row mt-4 w-100">
                <div className="col-12">
                  <div className="mt-4 color-success mb-2">
                    Manual Adjustment Entries
                  </div>
                  <BootstrapTable
                    keyField="id"
                    data={adjustmentEntries}
                    columns={adjustmentEntriesColumns}
                    bordered={false}
                    classes="rs-table table-layout-auto table-custom-style"
                    headerClasses="d-none"
                    noDataIndication="No data found!"
                    wrapperClasses="overflow-y-auto fancy-scroll"
                  ></BootstrapTable>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <ButtonBasic
                title={showNotes ? "Hide Notes" : "Show Notes"}
                onClick={() => {
                  setShowNotes(!showNotes);
                }}
              />
              <ButtonBasic
                title="Cancel"
                onClick={() => {
                  setShowModal(false);
                  setSelectedDSA(null);
                  setSelectedDSB(null);
                  !presistState?.goBackToLocate && setPresistState(null);
                }}
              />

              <CustomTooltip
                placement="top"
                tooltipId="button-tooltip-3"
                disabled={
                  adjustmentEntries &&
                  selectedCategory &&
                  selectedDebitAccount &&
                  selectedCreditAccount &&
                  noteFlag &&
                  notes.length > 0
                    ? false
                    : true
                }
                disabledMessage={
                  !adjustmentEntries
                    ? "Select the Data Source"
                    : !selectedCategory &&
                      !selectedDebitAccount &&
                      !selectedCreditAccount
                    ? "Choose the Adjustment Category, Credit Account, and Debit Account from the dropdowns"
                    : !noteFlag && !notes.length > 0
                    ? "Add Notes"
                    : null
                }
                onClick={() => {
                  NostroManualMatching();
                }}
                buttonText="Done"
              >
                Done
              </CustomTooltip>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default NostroModal;
