import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axiosInstance from "../../../utils/axiosInstance";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
const ViewModal = ({ id, show, onHide, regions }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [glAccList, setGlAccList] = useState([]);
  const getGlAccounts = async () => {
    axiosInstance
      .get(`/accounts/account-list?type_indicator=gl`)
      .then((res) => {
        setGlAccList(
          res?.data?.accounts?.map((i) => {
            return {
              label: i?.name,
              value: i?.id,
            };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getGlAccounts();
    axiosInstance
      .get(`/cits/${id}`)
      .then((res) => {
        setData(res.data.cits[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    return () => {
      setData({});
      setLoading(true);
    };
  }, [id]);
  return (
    <Modal
      backdrop={true}
      size="xl"
      show={show}
      onHide={() => onHide(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body scrollable="true">
        {loading && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div
          className="row"
          style={{
            opacity: !loading ? "1" : "0.07",
          }}
        >
          <div className="col-12 overflow-auto">
            <div className="row">
              <div className="col-sm-12 col-lg-12">
                <span className="color-gray-dim">Cit Item:</span> {id}
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Name</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div id="name" className="value color-dark">{data?.name}</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">Region</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div id="region" className="value color-dark">
                    {regions.find((item) => item.id === data?.regionId)?.label}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div className="key color-light">GL Account</div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="key-value-wrapper info-tile">
                  <div id="glAccount" className="value color-dark">
                    {glAccList.find((i) => i?.value?.toString() === data?.glAccount?.toString())
                      ?.label ?? "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewModal;
