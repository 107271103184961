import { useContext, useEffect, useState } from "react";
import { Field } from "formik";
import Select from "react-select";
import NewFilter from "../../../../components/FilterDropdown/NewFilter";
import CurrencyFormat from "react-currency-format";
import { useDateTime } from "../../../../components/Helper/DateTime";
import MultiStepFormContext from "./MultiStepFormContext";

const AddCassettesConfiguration = ({
  rowsData,
  deleteTableRows,
  handleChange,
  isCassetteType,
  isReview,
}) => {
  const { currency } = useDateTime();
  const { cassetteUnits } = useContext(MultiStepFormContext);
  const [cassetteOptions, setCassetteOptions] = useState([]);

  useEffect(() => {
    setCassetteOptions(
      cassetteUnits?.map((item) => ({
        id: item.cassetteType,
        label: item.cassetteType,
      })) || []
    );
  }, [cassetteUnits]);

  const formatAmount = (amount) =>
    amount ? (
      <CurrencyFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={`${currency} `}
      />
    ) : (
      "0"
    );
  return rowsData?.map((data, index) => {
    const { billCount, denomination, cassetteType, isNewRow } = data;
    return (
      <div
        key={index}
        className="row mt-3 d-flex justify-content-start  align-items-start"
      >
        <div className="col-md">
          <Field
            className="form-control rs-input"
            type="number"
            placeholder=""
            name="denomination"
            value={denomination}
            onChange={(evnt) => handleChange(index, evnt)}
            disabled={!isNewRow || !!isReview} // Disable for prefilled rows and when in review mode
          />
        </div>
        <div className="col-md">
          <Field
            className="form-control rs-input"
            min="0"
            step="1"
            placeholder=""
            id="billCount"
            name="billCount"
            value={billCount}
            onChange={(evnt) => handleChange(index, evnt)}
            disabled={!!isReview}
          />
        </div>
        {isCassetteType && (
          <div className="col-md">
            <div className="text-left">
              {isReview || !isNewRow ? (
                <Field
                  className="form-control rs-input"
                  type="text"
                  placeholder=""
                  name="cassetteType"
                  value={cassetteType}
                  onChange={(evnt) => handleChange(index, evnt)}
                  disabled={!!isReview || !isNewRow}
                />
              ) : (
                <NewFilter
                  title={
                    cassetteOptions?.find((i) => i.label === cassetteType)
                      ?.label ?? "Select"
                  }
                  type={"checkbox"}
                  field={"label"}
                  data={cassetteOptions}
                  filter={cassetteType}
                  onChange={(e) =>
                    handleChange(index, {
                      target: {
                        value: e,
                        name: "cassetteType",
                      },
                    })
                  }
                  searchField="label"
                />
              )}
              {/* <Field
                className="form-control rs-input"
                type="text"
                placeholder=""
                name="cassetteType"
                value={cassetteType}
                onChange={(evnt) => handleChange(index, evnt)}
                disabled={!!isReview}
              /> */}
            </div>
          </div>
        )}
        <div className="col-md">
          <p className="text-left">{formatAmount(denomination * billCount)}</p>
        </div>
        {!isReview && (
          <div className="col-md">
            <button
              className="btn btn-outline-danger"
              type="button"
              onClick={() => deleteTableRows(index)}
              disabled={!isNewRow}
            >
              x
            </button>
          </div>
        )}
      </div>
    );
  });
};

export default AddCassettesConfiguration;
