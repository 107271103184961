import { Field } from "formik";
import { useEffect, useState } from "react";
import Select from "react-select";
import axiosInstance from "../../../utils/axiosInstance";

const AddSchedularParams = ({
  rowsData,
  setRowsData,
  deleteTableRows,
  handleChange,
  formValues,
}) => {
  const [dataSources, setDataSources] = useState([]);
  useEffect(() => {
    axiosInstance
      .get("/interfaces/datasources")
      .then((res) => {
        setDataSources(
          res.data.datasources.map((x) => ({
            value: x.id,
            label: x.datasource,
          })) || []
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    if (formValues?.schedulerParams) {
      // Extract schedulerParams from formValues
      const schedulerParams = formValues?.schedulerParams || {};

      // Convert schedulerParams to an array of key-value pairs
      const schedulerParamsArray = Object.entries(schedulerParams);

      // Sort the schedulerParamsArray in ascending order based on keys
      schedulerParamsArray.sort((a, b) => a[0].localeCompare(b[0]));

      // Convert schedulerParamsArray to the desired format
      const updatedRowsData = schedulerParamsArray.map(
        ([propKey, propValue]) => ({
          propKey,
          ...propValue,
          propValue: propValue.value,
        })
      );

      // Append the sorted data to the existing rowsData array
      setRowsData((prevRowsData) => [...prevRowsData, ...updatedRowsData]);
    }
  }, [formValues?.schedulerParams]);

  return rowsData?.map((data, index) => {
    const { propKey, propValue, isDynamic, datasourceId, columnPath } = data;
    return (
      <div
        key={index}
        className="row mt-3 d-flex justify-content-center align-items-center"
      >
        <div
          className={
            rowsData.find((row) => row.isDynamic === true) ? "col-3" : "col-4"
          }
        >
          <Field
            id={"propKey" + index}
            className={`form-control rs-input ${!propKey ? "is-invalid" : ""}`}
            type="text"
            placeholder="Param Name"
            name="propKey"
            value={propKey}
            onChange={(evnt) => handleChange(index, evnt)}
          />
        </div>
        <div
          className={
            rowsData.find((row) => row.isDynamic === true) ? "col-3" : "col-4 "
          }
        >
          <Field
            id={"propValue" + index}
            className={`form-control rs-input ${
              !isDynamic && !propValue ? "is-invalid" : ""
            }`}
            type="text"
            placeholder="Param Value"
            name="propValue"
            value={propValue}
            onChange={(evnt) => handleChange(index, evnt)}
          />
        </div>
        <div
          className={
            rowsData.find((row) => row.isDynamic === true) ? "col-1.5" : "col-2"
          }
        >
          <div role="group" aria-labelledby="checkbox-group">
            <label>
              <input
                id={"isDynamic" + index}
                type="checkbox"
                name="isDynamic"
                checked={isDynamic}
                onChange={(evnt) => handleChange(index, evnt)}
              />{" "}
              Dynamic
            </label>
          </div>
        </div>

        {rowsData.find((row) => row.isDynamic === true) && (
          <div className="col-2">
            {isDynamic && (
              <Select
                id="datasourceId"
                classNamePrefix={"datasourceId"}
                options={dataSources}
                name="datasourceId"
                placeholder="Data Source"
                value={dataSources.find(
                  (option) => option.value === datasourceId
                )}
                onChange={(evnt) =>
                  handleChange(index, {
                    target: { name: "datasourceId", value: evnt.value },
                  })
                }
                styles={
                  isDynamic && !datasourceId
                    ? {
                        control: (styles) => ({
                          ...styles,
                          borderColor: "red",
                        }),
                      }
                    : {}
                }
              />
            )}
          </div>
        )}

        {rowsData.find((row) => row.isDynamic === true) && (
          <div className="col-2">
            {isDynamic && (
              <Field
                id={"columnPath" + index}
                className={`form-control rs-input ${
                  isDynamic && !columnPath ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="Property Path"
                name="columnPath"
                value={columnPath}
                onChange={(evnt) => handleChange(index, evnt)}
              />
            )}
          </div>
        )}
        <div
          className={
            rowsData.find((row) => row.isDynamic === true) ? "col-0.5" : "col-2"
          }
        >
          <button
            id={"deleteTableRows" + index}
            className="btn btn-outline-danger"
            type="button"
            onClick={() => deleteTableRows(index)}
          >
            x
          </button>
        </div>
      </div>
    );
  });
};

export default AddSchedularParams;
