import React, { createRef } from "react";
import AddIcon from "../../../assets/images/add-icon.png";
import { nullChecker } from "../../../components/Helper/NullChecker";
import BootstrapTable from "react-bootstrap-table-next";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import EditIcon from "../../../assets/images/edit.png";
import TrashIcon from "../../../assets/images/trash.png";
import { withRouter } from "react-router-dom";
import axiosInstance from "../../../utils/axiosInstance";
// import { baseUrl } from "../../../configuration/apiUrl";
import FilterIcon from "../../../assets/images/filter.png";
import FilterDropdown from "../../../components/FilterDropdown/FilterDropdown";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import AtmDetailModal from "./AtmDetailModal";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ShowForPermission from "../../../utils/permissionsWrapper";
import buttonChecker from "../../../utils/buttonsPermissionsChecker";
import Toast from "../../../components/Toast/Toast";
import withDateTime from "../../../utils/timedatehoc";
import { regionCheck, mapdata } from "../../../utils/regionHelper";
import Pagination from "../../../components/pagination/pagination";

class AtmListManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.filter = createRef();
    this.onSortTable = this.onSortTable.bind(this);
    this.getTotalSize = this.getTotalSize.bind(this);
    this.getSizePerPage = this.getSizePerPage.bind(this);
    this.getActions = this.getActions.bind(this);
    this.editATM = this.editATM.bind(this);
    this.deleteATM = this.deleteATM.bind(this);
    this.pagination = {
      onPageChange: this.onPageChanges.bind(this),
      pageStartIndex: 1, // where to start counting the pages
      totalSize: 0,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      showTotal: true,
      paginationTotalRenderer: this.totalRecords.bind(this),
      alwaysShowAllBtns: true,
      paginationShowsTotal: false,
      sizePerPage: 15,
      page: 1,
      totalPages: 0,
      searchText: "",
      sizePerPageList: [
        {
          text: "15",
          value: 15,
        },
        {
          text: "30",
          value: 30,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
      onSizePerPageChange: this.onSizeChange.bind(this),
    };
    this.columns = [
      { hidden: true, dataField: "deviceId", text: "deviceId" },
      {
        dataField: "terminalId",
        text: "Terminal Id",
        sort: true,
        headerAttrs: { id: "terminalIdSort" },
      },
      {
        dataField: "deviceName",
        dataAlign: "left",
        text: "Device Name",
        sort: true,
        formatter: (cell) => (cell ? cell : "-"),
        headerAttrs: { id: "deviceNameSort" },
      },
      {
        dataField: "deviceLocation",
        // formatter: this.cashDisbursementCount,
        text: "Location",
        formatter: nullChecker,
        sort: true,
        headerAttrs: { id: "locationSort" },
      },
      {
        dataAlign: "left",
        dataField: "deviceRegionName",
        text: "Region",
        sort: true,
        formatter: (cell) => (cell ? cell : "-"),
        headerAttrs: { id: "regionSort" },
      },

      {
        dataField: "action",
        text: "Action",
        formatter: this.getActions,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            e.stopPropagation();
            if (buttonChecker("clk_atm_edit") && e.target.alt === "edit btn") {
              this.editATM(row.deviceId);
            } else if (
              buttonChecker("clk_atm_del") &&
              e.target.alt === "dlt btn"
            ) {
              this.deleteATM(row.deviceId);
            }
          },
        },
      },
    ];

    this.state = {
      modalShow: false,
      atmlist: [],
      regionFilters: "",
      regions: [],
      sortField: "terminalId",
      sortOrder: "asc",
      filterLoader: true,
      loader: true,
      confirmationModal: false,
      limit: 15,
      page: 0,
      deleteDeviceId: undefined,
      isShowDetail: false,
      deviceId: 0,
      deviceDetail: {},
    };
  }

  //#region Table Actions
  onSortTable(type, data) {
    let { sortField, sortOrder } = this.state;
    let page = this.pagination.page;
    if (type === "sort") {
      sortField = data.sortField;
      sortOrder = data.sortOrder;
      this.setState({ loader: true, sortField, sortOrder }, () => {
        this.getDevices(
          this.state.limit,
          page - 1,
          this.filter.current,
          this.state.searchText
        );
      });
    }
  }
  getActions(cell, row, rowIndex) {
    return (
      <div>
        <img
          id={"editIconRow" + rowIndex}
          width={24}
          height={24}
          src={EditIcon}
          className={`right-btn mr-2`}
          alt="edit btn"
          style={{ opacity: buttonChecker("clk_atm_edit") ? "1" : "0.3" }}
        />
        <img
          id={"deleteIconRow" + rowIndex}
          width={24}
          height={24}
          src={TrashIcon}
          className={`right-btn mr-3`}
          alt="dlt btn"
          style={{ opacity: buttonChecker("clk_atm_del") ? "1" : "0.3" }}
        />
      </div>
    );
  }

  editATM(id) {
    buttonChecker("clk_atm_edit") &&
      this.props.history.push(`/update-atm-management?deviceId=${id}`);
  }

  deleteATM(id) {
    this.setState({ confirmationModal: true, deleteDeviceId: id });
  }

  async confrimDeleteDevice() {
    if (buttonChecker("clk_atm_del")) {
      try {
        const response = await axiosInstance.delete(
          `/devices/${this.state.deleteDeviceId}`
        );
        if (response.message.status === "200") {
          const { atmlist } = this.state;
          const _atmList = atmlist.filter(
            (atm) => atm.deviceId !== this.state.deleteDeviceId
          );
          this.setState({
            confirmationModal: false,
            deleteDeviceId: undefined,
            atmlist: _atmList,
          });
        } else {
          Toast(response.message.message, "error");
        }
      } catch (err) {
        Toast(err.response.data.message.description, "error");
      }
    }
  }

  //#endregion
  //#region Pagination Functions
  onPageChanges = async (page) => {
    this.setState({ loader: true }, () => {
      this.setCurrentPage(page);
      let sizePerPage = this.pagination.sizePerPage;
      this.getDevices(
        sizePerPage,
        page - 1,
        this.filter.current,
        this.state.searchText
      );
    });
  };
  setCurrentPage(page) {
    let _options = { ...this.pagination };
    _options["page"] = page;
    this.pagination = _options;
  }
  onSizeChange = async (sizePerPage) => {
    this.setState({ loader: true }, () => {
      this.setCurrentSize(sizePerPage);
      let page = this.pagination.page;
      this.getDevices(
        sizePerPage,
        page - 1,
        this.filter.current,
        this.state.searchText
      );
    });
  };
  setCurrentSize(sizePerPage) {
    let _options = { ...this.pagination };
    _options["size"] = sizePerPage;
    this.pagination = _options;
  }
  totalRecords = () => (
    <span className="d-inline text-muted" style={{ float: "right" }}>
      Total Records: {this.pagination.totalSize}
    </span>
  );

  getTotalSize(response) {
    return response.page ? response.page.totalElements : 0;
  }
  getSizePerPage(response) {
    return response.page ? response.page.size : 15; //change 4 by API response
  }
  //#endregion
  componentDidMount() {
    this.getFilters();
    this.props.dateTime.setRefresh(() => () => {
      this.setState({ filterLoader: true, loader: true }, () => {
        this.getFilters();
      });
    });
  }

  componentWillUnmount() {
    this.props.dateTime.setRefresh(() => () => {});
  }
  async getFilters() {
    try {
      const response = await axiosInstance.get(
        `/hierarchical/filters?screen_id=atm_list`
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        this.setstate({ filterLoader: false });
      } else {
        let _filters = response.data.filters;
        let regions = _filters.filter(
          ({ filterName }) => filterName === "region"
        );

        let filter = "";
        if (regions.length > 0) {
          regions[0].filterData = mapdata(regions[0].filterData);
          regions[0].filterData.forEach((x) => {
            x.checked = x.param1 === "1" ? true : false;
          });
          filter = `&region_id=${regions[0].filterData
            .map(({ codeValue }) => codeValue)
            .join(",")}`;
          this.filter.current = filter;
        }
        this.setState(
          { regions: regions, filterLoader: false, regionFilters: filter },
          () => {
            this.getDevices(this.state.limit, 0, filter, this.state.searchText);
          }
        );
      }
    } catch (e) {
      console.log(e);
      this.setState({ filterLoader: false });
    }
  }
  async getDevices(limit, page, filter, searchText) {
    let Pagination = this.pagination;
    try {
      const { sortField, sortOrder } = this.state;
      let _atmlist = this.state.atmlist;
      let url = `/devices?page=${page}&limit=${limit}${
        this.filter.current ? this.filter.current : filter ? filter : ""
      }&search=${searchText ?? ""}`;
      if (sortOrder && sortField) {
        url += `&sort_order=${sortOrder}&sort_by=${sortField}`;
      }

      // if (this.filter.current) {
      //   url += this.filter.current;
      // } else {
      //   if (filter) {
      //     url += filter;
      //   }
      // }
      const response = await axiosInstance.get(url);
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        this.setState({ loader: false, atmlist: [] });
      } else {
        if (response && response.data && response.data.devices) {
          _atmlist = response.data.devices;
          let pagesize = this.getSizePerPage(response);
          let totalElements = this.getTotalSize(response);
          this.pagination.page = response?.page?.number + 1 || 1;
          Pagination.sizePerPage = pagesize;
          Pagination.totalSize = totalElements;
          Pagination.totalPages = response?.page?.totalPages;
          this.setState({
            atmlist: _atmlist,
            loader: false,
          });
        } else {
          this.setState({ atmlist: [], loader: false });
        }

        this.pagination = {
          ...this.pagination,
          pageSize: Pagination.sizePerPage,
          totalSize: Pagination.totalSize,
          totalPages: Pagination.totalPages,
        };
      }
    } catch (e) {
      console.log("on page change error ", e);
      this.setState({ atmlist: [], loader: false });
    }
  }

  onRegionDropdownChange(value) {
    if (value?.length > 0) {
      //console.log(value);
      this.filter.current = `&region_id=${value
        .map((node) => node.codeValue)
        .join(",")}`;
    } else {
      this.filter.current = "";
    }
    const regions = this.state.regions;
    regions.filterData = regionCheck(this.state.regions[0].filterData, value);
    this.setState({ regions });
  }

  rowEvents = {
    onClick: (e, row, rowIndex) => {
      this.props.history.push(`view-atm-management?deviceId=${row.deviceId}`);
    },
  };

  render() {
    const _region = this.state.regions;

    return (
      <>
        {(this.state.loader || this.state.filterLoader) && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div
          style={{
            opacity:
              !this.state.loader && !this.state.filterLoader ? "1" : "0.07",
          }}
        >
          <div className="row align-items-center d-lg-flex">
            {_region?.map((filter) => {
              return (
                <div
                  className="col-3"
                  style={{ minWidth: "210px" }}
                  key={filter.filterId}
                >
                  <FilterDropdown
                    title={filter.filterLabel}
                    type="checkbox"
                    data={filter.filterData}
                    onDropdownChange={(value) =>
                      this.onRegionDropdownChange(value)
                    }
                  />
                </div>
              );
            })}
            {!this.state.filterLoader && (
              <div className="col d-flex justify-content-start align-items-center">
                <ButtonBasic
                  title="Filter"
                  icon={FilterIcon}
                  onClick={() =>
                    this.setState({ loader: true }, () =>
                      this.getDevices(
                        this.state.limit,
                        0,
                        this.filter.current,
                        this.state.searchText
                      )
                    )
                  }
                />
              </div>
            )}
            <div className="col d-flex justify-content-end align-items-center">
              <ShowForPermission permission="clk_atm_add">
                <ButtonTransparent
                  id="clk_atm_add"
                  onClick={() =>
                    this.props.history.push("/atm-management", {
                      action: "add",
                    })
                  }
                  title={"Add ATM"}
                  icon={AddIcon}
                />
              </ShowForPermission>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <div className="row page-header d-flex justify-content-between align-items-center mb-0">
                <div className="title col-8">All ATMs</div>
                <div className="col-4">
                  <div class="form-group has-search">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input
                      id="searchAtm"
                      type="text"
                      class="form-control"
                      placeholder="Search by Terminal ID or ATM Name"
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          this.getDevices(
                            this.state.limit,
                            0,
                            this.filter.current,
                            e.target.value
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <BootstrapTable
                    id="atmTable"
                    bootstrap4={true}
                    bordered={false}
                    classes="rs-table table-layout-auto mb-0 table-custom-style"
                    wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                    keyField="deviceId"
                    remote={{ pagination: true, sort: true }}
                    data={this.state.atmlist}
                    columns={this.columns}
                    onTableChange={(type, newState) =>
                      this.onSortTable(type, newState)
                    }
                    rowEvents={this.rowEvents}
                    noDataIndication="No data found!"
                  ></BootstrapTable>
                  <Pagination
                    setPage={this.onPageChanges}
                    setPageSize={this.onSizeChange}
                    pageSize={this.pagination.sizePerPage}
                    totalPages={this.pagination.totalPages}
                    totalElements={this.pagination.totalSize}
                    page={this.pagination.page}
                    data={this.state.atmlist || []}
                  />
                </div>
              </div>
            </div>
          </div>
          <ConfirmationModal
            isShow={this.state.confirmationModal}
            title={"Delete ATM "}
            message={"Are you sure you want to delete this atm ?"}
            hideModal={() => {
              this.setState({ confirmationModal: false });
            }}
            confirmAction={() => this.confrimDeleteDevice()}
          />
          <AtmDetailModal
            isShow={this.state.isShowDetail}
            deviceDetail={this.state.deviceDetail}
            hideModal={() => this.setState({ isShowDetail: false })}
          />
        </div>
      </>
    );
  }
}

export default withDateTime(withRouter(AtmListManagement));
