import React from "react";
import TabContainer from "react-bootstrap/TabContainer";
import TabContent from "react-bootstrap/TabContent";
import Nav from "react-bootstrap/Nav";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import VerticalTabsStyle from "./VerticalTabsStyle";
import LoaderComponent from "../Spinner/LoaderComponent";
class VerticalTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountId: undefined,
      query: "",
      list: [],
    };
  }

  componentDidMount() {
    // this.setState({ accountId: this.props?.tabList[0]?.id });
    //   this.props.setAccountById(this.props?.tabList[0]?.id);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.tabList !== this.props.tabList) {
      this.setState({
        list: this.props.tabList,
        accountId: this.props.tabList[0]?.id,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.tabList &&
      props.tabList?.length > 0 &&
      props.tabList &&
      !state.accountId
    ) {
      return {
        accountId: props.tabList[0].id,
      };
    }
  }

  static g;

  getAccountById(value) {
    this.setState({ accountId: value });
    this.props.setAccountById(value);
  }

  searchQuery(value) {
    let filteredData = this.props.tabList.filter(
      (item) =>
        item.accountLabel.toLowerCase().includes(value) ||
        item.accountLabel.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({ query: value, list: filteredData });
  }

  render() {
    const { tabView, loader, collapsed, setCollapsed } = this.props;
    const { list, query } = this.state;
    return (
      <VerticalTabsStyle className="h-100" collapsed={collapsed}>
        <TabContainer
          className="h-100"
          id="left-tabs-example"
          defaultActiveKey="1001"
        >
          <Row className={!collapsed ? "h-100" : ""}>
            <Col
              style={{ display: "block" }}
              xs={!collapsed === true ? 3 : 0}
              sm={!collapsed === true ? 3 : 0}
              md={!collapsed === true ? 3 : 0}
              lg={!collapsed === true ? 3 : 0}
              xl={!collapsed === true ? 3 : 0}
            >
              <div
                className={`collapse-div ${
                  collapsed && "d-flex justify-content-start align-items-center"
                }`}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {collapsed ? "Show Accounts Menu" : "Hide Accounts Menu"}
                    </Tooltip>
                  }
                >
                  <button
                    className="collapse-btn icon"
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    {collapsed ? (
                      <>
                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      </>
                    ) : (
                      <>
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                      </>
                    )}
                  </button>
                </OverlayTrigger>
              </div>
              {loader ? (
                <div className="spinner-center">
                  <LoaderComponent />
                </div>
              ) : collapsed ? null : (
                <div className="nav-wrapper fancy-scroll">
                  <Nav variant="pills" className="flex-column">
                    <div className="d-flex justify-content-end align-items-center">
                      <input
                        type="search"
                        placeholder="Search..."
                        value={query}
                        className="search-input-card w-100 mb-2"
                        onChange={(e) => {
                          this.searchQuery(e.target.value);
                        }}
                      />
                    </div>
                    {list &&
                      list.map((item) => (
                        <Nav.Item
                          key={item.id}
                          className="mt-2"
                          onClick={() => {
                            this.getAccountById(item.id);
                          }}
                        >
                          <Nav.Link
                            eventKey={item.id}
                            active={item.id === this.state.accountId}
                          >
                            {item.accountLabel}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                  </Nav>
                </div>
              )}
            </Col>
            <Col
              sm={collapsed ? 12 : 9}
              md={collapsed ? 12 : 9}
              lg={collapsed ? 12 : 9}
            >
              <TabContent className="h-100">{tabView}</TabContent>
            </Col>
          </Row>
        </TabContainer>
      </VerticalTabsStyle>
    );
  }
}

export default VerticalTabs;
