export default function PeakTime({ theme }) {
  const peakTime = {
    title: {
      text: "Peak Time",
      left: "left",
      textStyle: {
        color: "#777",
        fontSize: "14px",
        fontFamily: "Montserrat Bold",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    toolbox: {
      show: true,
      showTitle: false, // hide the default text so they don't overlap each other
      feature: {
        saveAsImage: {
          show: true,
          title: "Save As Image",
        },
      },
    },
    legend: {
      data: ["Volume"],
      orient: "vertical",
      left: "right",
      top: "top",
      icon: "circle",
      padding: [0, 40, 0, 0],
      textStyle: {
        color: "#777",
        fontFamily: "Montserrat Regular",
        fontSize: "10px",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    yAxis: {
      type: "value",
      splitLine: {
        lineStyle: {
          color: theme === "light" ? "#ccc" : "#333",
        },
      },
    },
    xAxis: {
      type: "category",
      data: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    },
    series: [
      {
        color: "#118AB2",
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data: [
          "9000",
          "9100",
          "9200",
          "9300",
          "9400",
          "9500",
          "9600",
          "9700",
          "9800",
          "9900",
          "10000",
          "10100",
          "10200",
          "10300",
          "9000",
          "8000",
          "7000",
          "6000",
          "5000",
          "4000",
          "3000",
          "2000",
          "1000",
          "100",
        ],
      },
      {
        type: "line",
        data: [
          "9000",
          "9100",
          "9200",
          "9300",
          "9400",
          "9500",
          "9600",
          "9700",
          "9800",
          "9900",
          "10000",
          "10100",
          "10200",
          "10300",
          "9000",
          "8000",
          "7000",
          "6000",
          "5000",
          "4000",
          "3000",
          "2000",
          "1000",
          "100",
        ],
      },
    ],
  };
  return peakTime;
}
