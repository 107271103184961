import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { withRouter } from "react-router";
import logo from "../../assets/images/logo-sm.svg";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import axiosInstance from "../../utils/axiosInstance";
import Toast from "../../components/Toast/Toast";

const twoFactorAuthSchema = Yup.object().shape({
  code: Yup.string().required(),
});

const TwoFactorAuth = () => {
  const location = useLocation();
  const history = useHistory();
  const [authData, setAuthData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (location.state && location.state.twoFactorAuthData) {
      setAuthData(location.state.twoFactorAuthData);
    } else {
      Toast("Two factor authentication data not found", "error");
      history.push("/login");
    }
    setIsLoading(false);
  }, [history, location.state]);

  const handle2fa = async (values) => {
    setIsLoading(true);
    let payload = {
      data: {
        verifyTwoFactorAuthToken: authData?.verifyTwoFactorAuthToken,
        otp: values?.code,
      },
    };
    await axiosInstance
      .post(`/users/verify-totp`, payload)
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          setIsLoading(false);
          Toast(res.message.description, "success");
          history.push("/login");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Toast(error.response.data.message.description, "error");
      });
  };

  return (
    <>
      {isLoading && (
        <div
          className="spinner-center"
          style={{ opacity: 1, top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}

      <div className="auth-container" style={{ opacity: 1 }}>
        <div className="d-flex align-items-center brand-name-wrapper">
          <img
            src={logo}
            alt="Logo"
            width="50"
            style={{ margin: "20px", cursor: "pointer" }}
          />
        </div>
        <div>
          <div className="d-flex align-items-center justify-content-center mt-5">
            <h4 className="font-weight-bold">Two-Factor Authentication</h4>
          </div>
          <div className="card auth-card mt-2" style={{ width: "60%" }}>
            <div className="card-body">
              <div className="row pt-3">
                <div className="col-5">
                  <div className="d-flex align-items-center">
                    <p className="font-weight-bold">
                      {" "}
                      1. Scan the QR Code with an authenticator app
                    </p>
                  </div>
                  <div>
                    <img src={authData?.qrData?.qrCode ?? ""} alt="QR Code" />
                  </div>
                  <div className="mx-5 mt-1 mb-2 font-weight-bold">
                    {authData?.qrData?.qrText}
                  </div>
                </div>
                <div className="col-1">
                  <div
                    className="d-flex align-items-center justify-content-center h-100"
                    style={{ borderLeft: "2px solid #dee2e6" }}
                  ></div>
                </div>
                <div className="col-6">
                  <>
                    <div className="d-flex align-items-center">
                      <p className="font-weight-bold">
                        {" "}
                        2. Enter the code provided by your authenticator app{" "}
                      </p>
                    </div>
                    <div className="container mt-5">
                      <div
                        id="login-row"
                        className="row justify-content-center align-items-center"
                      >
                        <div id="login-column" className="col-md-12">
                          <div id="login-box" className="col-md-12">
                            <Formik
                              initialValues={{ code: "" }}
                              validationSchema={twoFactorAuthSchema}
                              onSubmit={(values) => {
                                handle2fa(values);
                              }}
                            >
                              <Form id="login-form" className="form">
                                <div className="form-group">
                                  <label htmlFor="code" className="text-info">
                                    Authentication Code:
                                  </label>
                                  <br />
                                  <Field
                                    type="text"
                                    name="code"
                                    id="code"
                                    className="form-control rs-input"
                                    placeholder="Enter Code"
                                    style={{ borderRadius: "10px" }}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="code"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="form-group">
                                  <br />
                                  <input
                                    id="submit"
                                    type="submit"
                                    name="submit"
                                    className={"btn btn-info btn-md"}
                                    style={{ width: "100%" }}
                                    value="Verify"
                                  />
                                </div>
                              </Form>
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(TwoFactorAuth);
