import React from "react";
import CardSmStyle from "./CardSmStyle";

class CardSm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { title, value, classes, smallValue = false } = this.props;
    return (
      <CardSmStyle className={`rs-card-sm ${classes}`}>
        <div className="title">{title}</div>
        <div className={smallValue ? "smallValue" : "value"}>{value}</div>
      </CardSmStyle>
    );
  }
}

export default CardSm;
