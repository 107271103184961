import React, { useState, useEffect, useCallback } from "react";
import { Tab, Tabs } from "react-bootstrap";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import ButtonTransparent from "../../../components/Buttons/ButtonTransparent";
import AddIcon from "../../../assets/images/add-icon.png";
import RolePermissions from "./roles-permissions";
import Users from "./user";
import axiosInstance from "../../../utils/axiosInstance";
import ShowForPermission from "../../../utils/permissionsWrapper";
import Toast from "../../../components/Toast/Toast";
import Pagination from "../../../components/pagination/pagination";
const UserProfileManagement = () => {
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [totalElements, setTotalElements] = useState(0);
  const [usersCopy, setUserCopy] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [showAddModal, setShowAddModal] = useState(false);
  const [query, setQuery] = useState("");
  const [roleIds, setRoleIds] = useState([]);

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };
 
  const fetchData = useCallback(() => {
    setLoading(true);
    axiosInstance
      .get(
        `/users?page=${page - 1}&size=${pageSize}&sort_by=${
          sort.sort_by
        }&sort_order=${sort.sort_order}&user_name=${query}${
          roleIds?.length > 0
            ? `&role_ids=${roleIds?.map((x) => x)?.join(",")}`
            : ""
        }`
      )
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
          setLoading(false);
        } else {
          setUsers(res?.data?.users || []);
          setPage(res?.page?.number + 1 || 1);
          setTotalPages(res?.page?.totalPages || 0);
          setPageSize(res?.page?.size || 15);
          setTotalElements(res?.page?.totalElements || 0);
          setLoading(false);
          setUserCopy(res?.data?.users || []);
          setTotalUser(res?.data?.total || 0);
          setInactiveUsers(res?.data?.inActive || 0);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setPage(1);
        setTotalPages(0);
        setPageSize(15);
        setTotalElements(0);
      });
  }, [page, pageSize, sort, query, roleIds]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <div>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div
        style={{
          opacity: !loading ? "1" : "0.07",
        }}
        className="rs-basic-card"
      >
        <div className="row">
          <div className="col-6">
            <div className="d-flex align-items-center">
              <div
                className="br-sm-none"
                style={{
                  paddingRight: "10px",
                  marginRight: "10px",
                  borderRight: "2px solid #777",
                }}
              >
                All Users
              </div>
              <div className="align-items-center d-xs-none d-sm-none d-md-flex">
                <div
                  style={{
                    color: "#ccc",
                    fontSize: "14px",
                    marginRight: "10px",
                  }}
                >
                  {totalUser ?? 0} Total
                </div>
                <div style={{ color: "#ccc", fontSize: "14px" }}>
                  {inactiveUsers ?? 0} inactive
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex justify-content-end align-items-center">
              <div>
                {show && (
                  <ShowForPermission permission="clk_user_add">
                    <ButtonTransparent
                      id="add_user"
                      onClick={() => setShowAddModal(true)}
                      title={"Add"}
                      icon={AddIcon}
                    />
                  </ShowForPermission>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <Tabs
              defaultActiveKey="user"
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
              onSelect={(key) => {
                if (key === "user") setShow(true);
                else setShow(false);
              }}
            >
              <Tab eventKey="user" title="Users">
                {show && (
                  <ShowForPermission permission="clk_users_tab">
                    <Users
                      users={users}
                      usersCopy={usersCopy}
                      setRoleIds={setRoleIds}
                      setUsers={setUsers}
                      getUsers={fetchData}
                      setQuery={setQuery}
                      showAddModal={showAddModal}
                      setShowAddModal={setShowAddModal}
                      onSortTable={onSortTable}
                    />
                    <Pagination
                      page={page}
                      setPage={setPage}
                      totalPages={totalPages}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      totalElements={totalElements}
                    />
                  </ShowForPermission>
                )}
              </Tab>

              <Tab eventKey="permissions" title="Role Permissions">
                {!show && (
                  <ShowForPermission permission="clk_roles_tab">
                    <RolePermissions />
                  </ShowForPermission>
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileManagement;
