import React from "react";
import Switch from "react-switch";
import "./ToggleSwitch.css";

const ToggleSwitch = (props) => {
  const { title = "" } = props;
  return (
    <div className="rs-custom-switch custom-control custom-switch d-flex">
      {/* <label className="custom-control-label" for={id}></label> */}
      <div className="rs-switch-label">{title}</div>
      <Switch
        onChange={props.toggleTheme}
        checked={props.theme === "dark" ? true : false}
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      />
      {/* <input type="checkbox" className="custom-control-input" id={id} /> */}
    </div>
  );
};

export default ToggleSwitch;
