import React from "react";
import { Prompt } from "react-router-dom";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";

export class LeavingModal extends React.Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
  };

  showModal = (location) =>
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });

  closeModal = (callback) =>
    this.setState(
      {
        modalVisible: false,
      },
      callback
    );

  handleBlockedNavigation = (nextLocation, action) => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;
    console.log(shouldBlockNavigation());
    if (!confirmedNavigation && shouldBlockNavigation()) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { navigate } = this.props;
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          () => {
            // Navigate to the previous blocked location with your navigate function
            navigate(lastLocation.pathname);
          }
        );
      }
    });

  render() {
    const { when } = this.props;
    const { modalVisible, lastLocation } = this.state;
    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <ConfirmationModal
          isShow={modalVisible}
          title={`Warning`}
          message={`You have unsaved changes. Are you sure you want to leave?`}
          hideModal={this.closeModal}
          confirmAction={this.handleConfirmNavigationClick}
        />
      </>
    );
  }
}
export default LeavingModal;
