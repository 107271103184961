export const colorPalette = ["#00AF91", "#EF476F", "#FFD166","#D70040", "#118AB2"];
export const balancingSummaryPalette=["#EF476F","#007BFF","#D6A06B","#00AF91"]
export const negativeColorPalette = [
  "#EF476F",
  "#52575D",
  "#845ec2",
  "#142D4C",
];
export const PASTEL_PALETTE = [
  "#FA8072",
  "#FFD700",
  "#98FB98",
  "#AFEEEE",
  "#FFC0CB",
  "#FFFACD",
  "#98FF98",
  "#E0BBE4",
  "#FFD700",
  "#E6E6FA",
  "#FFDAB9",
  "#FF6F61",
  "#C8A2C8",
  "#87CEEB",
];
export const REVERSE_PASTEL = [
  "#87CEEB",
  "#C8A2C8",
  "#FF6F61",
  "#FFDAB9",
  "#E6E6FA",
  "#FFD700",
  "#E0BBE4",
  "#98FF98",
  "#FFFACD",
  "#FFC0CB",
  "#AFEEEE",
  "#98FB98",
  "#FFD700",
  "#FA8072",
];
export const YELLOW_PALETTE = [
  "#FFCA08",
  "#F8931D",
  "#CE8D3E",
  "#EC7016",
  "#9C6A6A",
];
export const DASHBOARD_PALETTE = [
  "#9A77B2",
  "#0077A2",
  "#FF6F61",
  "#7F8C8D",
  "#FFC300",
];
