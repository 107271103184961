import React, { useRef, useState } from "react";
import GoogleMap from "google-map-react";
import { mapsKey } from "../../configuration/mapKey";
import { useDateTime } from "../../components/Helper/DateTime";
const Test = ({ mapStyle, className, onClick, pos, center }) => {
  const { systemSettings } = useDateTime();
  let settingsLat, settingsLng;
  if (systemSettings) {
    settingsLat = systemSettings?.subType?.find(
      (setting) => setting.id === "mapDefaultCentreLatitude"
    );
    settingsLng = systemSettings?.subType?.find(
      (setting) => setting.id === "mapDefaultCentreLongitude"
    );
    settingsLat = parseFloat(settingsLat.selectedValue);
    settingsLng = parseFloat(settingsLng.selectedValue);
  }
  const [zoom] = useState(undefined);
  const defaultLat = center.lat || settingsLat;
  const defaultLng = center.lng || settingsLng;
  const defaultZoom = 6;
  // map setup
  const mapRef = useRef();
  // render the map
  return (
    <div style={mapStyle} className={className}>
      <GoogleMap
        bootstrapURLKeys={{
          key: mapsKey,
        }}
        center={{
          lat: center && center.lat !== "" ? +center.lat : defaultLat,
          lng: center && center.lng !== "" ? +center.lng : defaultLng,
        }}
        zoom={zoom ? zoom : defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
        }}
        onChange={(e) => {
          // console.log(e);
        }}
        onClick={({ lat, lng }) => {
          onClick(lat, lng);
          // setZoom(defaultZoom + 8);
        }}
      >
        {pos && pos.lat && pos.lng && (
          <div lat={pos.lat} lng={pos.lng}>
            <i
              style={{
                color: "red",
                fontSize: "30px",
              }}
              className="fa fa-map-marker"
            ></i>
          </div>
        )}
      </GoogleMap>
    </div>
  );
};

export default Test;
