import styled from "styled-components";

const WorkQueueStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .work-queue-flow-modal th,
  .work-queue-flow-modal td {
    background: #333;
  }

  .notes-wrapper {
    border: 1px solid ${({ theme }) => theme.dropdown_border};
    padding: 10px;
    border-radius: 10px;
  }
`;

const WorkQueueLogStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .work-queue-flow-modal th,
  .work-queue-flow-modal td {
    background: ${({ theme }) => theme.card_background};
  }

  .flow-badge {
    text-transform: uppercase;
    border-radius: 20px;
    padding: 0 20px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .flow-badge.atm {
    background: #00b8d924;
  }
  .flow-badge.switch {
    background: #00af9140;
  }
  .flow-badge.corebank {
    background: #845ec247;
  }
  .flow-badge.visa {
    background: #ffd16645;
  }

  .notes-wrapper {
    border: 1px solid ${({ theme }) => theme.dropdown_border};
    padding: 10px;
    border-radius: 10px;
  }
  .send-btn-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 93%;
    display: flex;
    align-items: center;
    border-left: 1px solid ${({ theme }) => theme.dropdown_border};
  }
  .note-textarea {
    border: 1px solid ${({ theme }) => theme.dropdown_border};
    width: 100%;
    background: transparent;
    border-radius: 4px;
    color: #777;
  }
  .note-textarea::placeholder {
    color: ${({ theme }) => theme.dropdown_border};
    padding: 5px;
    font-family: ${({ theme }) => theme.fonts.family.primary.regular};
  }
`;

export { WorkQueueStyle, WorkQueueLogStyle };
