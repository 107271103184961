import React from "react";
// import { CURRENCY } from "../../constants/Constants";
import { useDateTime } from "../../components/Helper/DateTime";
import CurrencyFormat from "react-currency-format";

// import LoaderComponent from "../../components/Spinner/LoaderComponent";
const DeviceDetailModal = ({ rowData }) => {
  const { currency } = useDateTime();

  const formatAmount = (cell, amount) => {
    const formattedValue = cell < 0 ? Math.abs(cell) : cell;
    return (
      <CurrencyFormat
        value={cell ?? "-"}
        // value={formattedValue ? formattedValue : amount ? "-" : 0}
        displayType={"text"}
        thousandSeparator={true}
        // prefix={`${currency} `}
      />
    );
  };

  return (
    <>
      {/* {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2002 }}
        >
          <LoaderComponent />
        </div>
      )} */}
      <div className="container">
        <div className="row">
          <div className="col-6">
            {" "}
            <h5 className="text-center color-dark pt-2">EJ Data</h5>
          </div>
          <div className="col-6">
            {" "}
            <h5 className="text-center color-dark pt-2">Physical Cash Count</h5>
          </div>
        </div>

        <div className="row d-flex justify-content-around align-items-center mt-3">
          {/* left column starts here */}
          <div className="col-sm-12 col-md-12 col-lg-5">
            <h6 className="text-left text-info pt-2">Cash Load</h6>
            <div className="mt-3 ml-2">
              <div className="row">
                <div className="col-2 ">
                  <p></p>
                </div>
                <div className="col-4">
                  <p className="value color-dark ">Denomination</p>
                </div>
                <div className="col-3">
                  <p className="value color-dark">Count</p>
                </div>
                <div className="col-3">
                  <p className="value color-dark">Total</p>
                </div>
              </div>
              {rowData?.ejCashLoad?.map((item) => {
                return (
                  item && (
                    <>
                      <div className="row" key={item.type}>
                        <div className="col-2 ">
                          <p>{item.type}</p>
                        </div>
                        <div className="col-4">
                          <p className="value color-dark border border-secondary text-center">
                            {item.denom}
                          </p>
                        </div>
                        <div className="col-3">
                          <p className="value color-dark border border-secondary text-center">
                            {item.counts}
                          </p>
                        </div>
                        <div className="col-3">
                          <p className="value color-dark border border-secondary text-center">
                            {formatAmount(item.total)}
                          </p>
                        </div>
                      </div>
                    </>
                  )
                );
              })}
              <div className="row d-flex justify-content-end mt-3">
                <div className="col-5 col-span-3">
                  <h6 className="color-light text-center">Total Cash Load:</h6>
                </div>
                <div className="col-4">
                  <p className="value color-dark border border-secondary text-center">
                    {currency} {formatAmount(rowData?.totalEjCashLoad)}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-0 ml-2">
              <h6 className="text-left text-info pb-3 mt-2">Cash Unload</h6>
              {rowData?.ejCashUnLoad?.map((item) => {
                return (
                  item && (
                    <>
                      <div className="row" key={item.type}>
                        <div className="col-2 ">
                          <p>{item.type}</p>
                        </div>
                        <div className="col-4">
                          <p className="value color-dark border border-secondary text-center">
                            {item.denom}
                          </p>
                        </div>
                        <div className="col-3">
                          <p className="value color-dark border border-secondary text-center">
                            {item.counts}
                          </p>
                        </div>
                        <div className="col-3">
                          <p className="value color-dark border border-secondary text-center">
                            {formatAmount(item.total)}
                          </p>
                        </div>
                      </div>
                    </>
                  )
                );
              })}
              <div className="row d-flex justify-content-end mt-3">
                <div className="col-5 col-span-3">
                  <h6 className="color-light text-center">
                    Total Cash Unload:
                  </h6>
                </div>
                <div className="col-4">
                  <p className="value color-dark border border-secondary text-center">
                    {currency} {formatAmount(rowData?.totalEjCashUnLoad)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* right column starts here */}
          <div className="col-sm-12 col-md-12 col-lg-5">
            <h6 className="text-left text-info pt-2">Cash Load</h6>
            <div className="mt-3 ml-2">
              <div className="row">
                <div className="col-2 ">
                  <p></p>
                </div>
                <div className="col-4">
                  <p className="value color-dark ">Denomination</p>
                </div>
                <div className="col-3">
                  <p className="value color-dark">Count</p>
                </div>
                <div className="col-3">
                  <p className="value color-dark">Total</p>
                </div>
              </div>
              {rowData?.cashReportCashLoad?.map((item) => {
                return (
                  item && (
                    <>
                      <div className="row" key={item.type}>
                        <div className="col-2 ">{/* <p>{item.type}</p> */}</div>
                        <div className="col-4">
                          <p className="value color-dark border border-secondary text-center">
                            {item.denom}
                          </p>
                        </div>
                        <div className="col-3">
                          <p className="value color-dark border border-secondary text-center">
                            {item.counts}
                          </p>
                        </div>
                        <div className="col-3">
                          <p className="value color-dark border border-secondary text-center">
                            {formatAmount(item.total)}
                          </p>
                        </div>
                      </div>
                    </>
                  )
                );
              })}
              <div className="row d-flex justify-content-end mt-3">
                <div className="col-5 col-span-3">
                  <h6 className="color-light text-center">Total Cash Load:</h6>
                </div>
                <div className="col-4">
                  <p className="value color-dark border border-secondary text-center">
                    {currency} {formatAmount(rowData?.totalCashReportCashLoad)}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-0 ml-2">
              <h6 className="text-left text-info pb-3 mt-2">Cash Unload</h6>
              {rowData?.cashReportCashUnload?.map((item) => {
                return (
                  item && (
                    <>
                      <div className="row" key={item.type}>
                        <div className="col-2 ">{/* <p>{item.type}</p> */}</div>
                        <div className="col-4">
                          <p className="value color-dark border border-secondary text-center">
                            {item.denom}
                          </p>
                        </div>
                        <div className="col-3">
                          <p className="value color-dark border border-secondary text-center">
                            {item.counts}
                          </p>
                        </div>
                        <div className="col-3">
                          <p className="value color-dark border border-secondary text-center">
                            {formatAmount(item.total)}
                          </p>
                        </div>
                      </div>
                    </>
                  )
                );
              })}
              <div className="row d-flex justify-content-end mt-3">
                <div className="col-5 col-span-3">
                  <h6 className="color-light text-center">
                    Total Cash Unload:
                  </h6>
                </div>
                <div className="col-4">
                  <p className="value color-dark border border-secondary text-center">
                    {currency}{" "}
                    {formatAmount(rowData?.totalCashReportCashUnload)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center align-items-center mt-4">
          <div className="col-sm-12 col-md-12 col-lg-6 border border-secondary rounded-left">
            <>
              <div className="row mt-2">
                <div className="col-12">
                  <p className="value text-info">Checks</p>
                </div>
                {/* <div className="col-6">
                  <p className="value text-info">Difference</p>
                </div> */}
              </div>
              <div className="row">
                <div className="col-12">
                  {/* <strong className="color-dark">
                    {rowData?.cashLoad !== null && rowData?.cashLoad !== ""
                      ? rowData?.cashLoad
                      : "CASH LOAD: Data not available"}
                  </strong> */}

                  {/* <strong className="color-dark d-block">{item.check}</strong> */}
                  <ol>
                    {rowData?.checks?.map((item) => (
                      <li>{item.check}</li>
                    ))}
                  </ol>
                </div>
                {/* <div className="col-6">
                  <strong className="color-dark">
                    {currency} {formatAmount(rowData?.cashLoadDiff)}
                  </strong>
                </div> */}
              </div>
              {/* <div className="row mt-2 mb-2">
                <div className="col-6">
                  <strong className="color-dark">
                    {rowData?.cashUnload !== null && rowData?.cashUnload !== ""
                      ? rowData?.cashUnload
                      : "CASH UNLOAD: Data not available"}
                  </strong>
                </div>
                <div className="col-6">
                  <strong className="color-dark">
                    {currency} {formatAmount(rowData?.cashUnloadDiff)}
                  </strong>
                </div>
              </div> */}
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeviceDetailModal;
