import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import Pagination from "../../../components/pagination/pagination";
import BadgeCircle from "../../../components/BadgeCircle/BadgeCircle";
import CrossIcon from "../../../assets/images/close.png";
import CheckIcon from "../../../assets/images/done.png";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import ShowForPermission from "../../../utils/permissionsWrapper";
import Toast from "../../../components/Toast/Toast";
import LoaderComponent from "../../../components/Spinner/LoaderComponent";
import { useDateTime } from "../../../components/Helper/DateTime";
import CustomAccordion from "../../../components/Accordion/CustomAccordion";
const RolePermissions = () => {
  const { setRefresh } = useDateTime();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sortDesc, setSortDesc] = useState("");
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isEditable, setIsEditAble] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [mappedPermissions, setMappedPermissions] = useState([]);
  const [editedRoles, setEditedRoles] = useState([]);
  const [groupedPermissions, setGroupedPermissions] = useState([]);

  const handleSorting = () => {
    let type, sorted;
    const sortedResults = groupedPermissions.map((group) => {
      if (sortDesc === "" || sortDesc === "desc") {
        sorted = group.groupPermissions.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        type = "asc";
      } else if (sortDesc === "asc") {
        sorted = group.groupPermissions.sort((a, b) =>
          b.name.localeCompare(a.name)
        );
        type = "desc";
      }
      return {
        ...group,
        groupPermissions: sorted,
      };
    });

    setGroupedPermissions(sortedResults);
    setSortDesc(type);
  };
  const getPermissions = async () => {
    try {
      const response = await axiosInstance.get("/permissions");
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        return response.data;
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const getRolePermissions = async () => {
    try {
      const rolePermissionsResponse = await axiosInstance.get(
        "/roles/role-permissions"
      );
      if (rolePermissionsResponse.message.status !== "200") {
        Toast(rolePermissionsResponse.message.description, "error");
        setLoading(false);
      } else {
        return rolePermissionsResponse.data.roles;
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const mapPermissions = (permissions, rolePermissions, disabled) => {
    const data = [];
    permissions?.forEach((item) =>
      item?.permissions?.forEach((p) => {
        const permissionArray = [];
        for (let i = 0; i < rolePermissions?.length; i++) {
          let check = false;
          const obj = rolePermissions[i]?.permissions.find(
            (per) => per?.id === p?.id
          );
          if (obj) {
            check = true;
          }
          permissionArray?.push({
            role_Id: rolePermissions[i].id,
            check,
          });
        }
        data.push({
          permissionArray,
          name: p.name,
          group: p.group,
          id: p.id,
          disabled,
        });
      })
    );

    return data;
  };

  const makeGroups = (data) => {
    const uniqueGroupData = data?.reduce((accumulator, currentItem) => {
      const groupName = currentItem.group;
      if (
        !groupName ||
        accumulator.some((item) => item.groupName === groupName)
      ) {
        return accumulator;
      }

      const groupPermissions = data.filter((item) => item.group === groupName);
      accumulator.push({ groupName, groupPermissions });
      return accumulator;
    }, []);
    setGroupedPermissions([...uniqueGroupData]);
  };

  const PaginatedData = (data) => {
    // Calculate the index of the first and last item to be displayed on the current page
    const startIndex = (parseInt(currentPage) - 1) * parseInt(pageSize);
    const endIndex = parseInt(startIndex) + parseInt(pageSize);
    setTotalPages(Math.ceil(data.length / pageSize));
    // Slice the data array based on the calculated indexes
    return data.slice(startIndex, endIndex);
  };

  const getTableData = async () => {
    setLoading(true);
    try {
      const [permissionsData, rolePermissionsData] = await Promise.all([
        getPermissions(),
        getRolePermissions(),
      ]);
      if (permissionsData && rolePermissionsData) {
        const data = mapPermissions(permissionsData, rolePermissionsData, true);
        setMappedPermissions([...data]);
        const uniqueGroupData = PaginatedData(data);
        makeGroups(uniqueGroupData);
        setPermissions(permissionsData);
        setRolePermissions(rolePermissionsData);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleRole = (role_Id, permission_Id, permission_Name, isChecked) => {
    const data = [...rolePermissions];
    const index = data.findIndex((per) => per.id === role_Id);
    const role = data[index].permissions.find(
      (role) => role.id === permission_Id
    );
    if (role)
      data[index].permissions.splice(data[index].permissions.indexOf(role), 1);
    else
      data[index].permissions.push({
        id: permission_Id,
        name: permission_Name,
      });

    const mappedData = mapPermissions(permissions, data, false);
    setMappedPermissions([...mappedData]);
    const filteredData = searchPermissions(mappedData, query);
    const paginatedData = PaginatedData(filteredData);
    makeGroups(paginatedData);
    setRolePermissions([...data]);
    handleEditedRoles(role_Id, permission_Id, permission_Name, isChecked);
  };

  // this function will create a list of permissions changed, and then empty it on api response
  const handleEditedRoles = (
    role_Id,
    permission_Id,
    permission_Name,
    isChecked
  ) => {
    const roleIndex = editedRoles.findIndex((role) => role.id === role_Id);
    const role = rolePermissions.find((role) => role.id === role_Id);
    if (roleIndex !== -1) {
      const permissionIndex = editedRoles[roleIndex].permissions.findIndex(
        (permission) => permission.id === permission_Id
      );
      if (permissionIndex !== -1) {
        editedRoles[roleIndex].permissions.splice(permissionIndex, 1);
        if (editedRoles[roleIndex].permissions.length === 0)
          editedRoles.splice(roleIndex, 1);
      } else {
        editedRoles[roleIndex].permissions.push({
          id: permission_Id,
          name: permission_Name,
          isAssigned: !isChecked,
        });
      }
    } else {
      setEditedRoles([
        ...editedRoles,
        {
          id: role_Id,
          name: role.name,
          permissions: [
            {
              id: permission_Id,
              name: permission_Name,
              isAssigned: !isChecked,
            },
          ],
        },
      ]);
    }
  };

  const handleSave = async () => {
    try {
      const response = await axiosInstance.put("/roles/role-permissions", {
        data: { roles: editedRoles },
      });
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        Toast("Role Permissions Updated Successfully", "success");
        setEditedRoles([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getTableData();
    setRefresh(() => () => {
      getTableData();
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, []);

  const searchPermissions = (data, searchTerm) => {
    const filteredData = data.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.group
          .toLowerCase()
          .replaceAll("_", " ")
          .includes(searchTerm.toLowerCase())
    );
    return filteredData;
  };

  useEffect(() => {
    if (searchQuery) {
      setLoading(true);
      const filteredData = searchPermissions(mappedPermissions, query);
      const paginatedData = PaginatedData(filteredData);
      makeGroups(paginatedData);
      setLoading(false);
    } else {
      const paginatedData = PaginatedData(mappedPermissions);
      makeGroups(paginatedData);
    }
  }, [currentPage, pageSize, searchQuery]);

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div
        style={{
          opacity: !loading ? "1" : "0.07",
        }}
      >
        <div className="row mt-3">
          <div className="col-6 offset-6">
            <div className="d-flex justify-content-end align-items-center">
              <input
                type="search"
                placeholder="Search.."
                value={query}
                className="search-input-card d-xs-none d-sm-none d-lg-flex mr-2"
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchQuery(query);
                    setCurrentPage(1);
                  }
                }}
              />
              <ShowForPermission permission="clk_roles_edit">
                <ButtonBasic
                  onClick={() => setIsEditAble(!isEditable)}
                  title={isEditable ? "Cancel" : "Edit"}
                />
                <ButtonBasic onClick={handleSave} title="Save" />
              </ShowForPermission>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="overflow-y-auto fancy-scroll">
              <table className="table table-hover">
                {groupedPermissions.map((group, index) => (
                  <>
                    <CustomAccordion
                      title={group?.groupName
                        ?.split("_")
                        .map(
                          (word) =>
                            word?.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                      defaultActiveKey={index === 0 ? true : false}
                      showDragIcon={false}
                      showRemoveIcon={false}
                    >
                      <tbody>
                        <tr>
                          <td>
                            <thead>
                              <tr>
                                <th
                                  onClick={handleSorting}
                                  style={{ cursor: "pointer" }}
                                >
                                  Permissions{" "}
                                  {sortDesc === "asc" ? (
                                    <i className="fa fa-sort-up"></i>
                                  ) : sortDesc === "desc" ? (
                                    <i className="fa fa-sort-down"></i>
                                  ) : (
                                    <i className="fa fa-sort"></i>
                                  )}
                                </th>
                                {rolePermissions.map((role, i) => (
                                  <th key={i}>{role.name}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {group?.groupPermissions.map((permission, i) => (
                                <tr key={i}>
                                  <td
                                    style={{
                                      minWidth: "200px",
                                      maxWidth: "200px",
                                      textWrap: "wrap",
                                    }}
                                  >
                                    {permission.name}
                                  </td>
                                  {permission.permissionArray.map((role, i) => (
                                    <td
                                      key={i}
                                      align="center"
                                      style={{
                                        alignContent: "center",
                                      }}
                                      className={isEditable ? "" : "disabled"}
                                      onClick={() =>
                                        isEditable &&
                                        handleRole(
                                          role.role_Id,
                                          permission.id,
                                          permission.name,
                                          role.check
                                        )
                                      }
                                    >
                                      <BadgeCircle
                                        style={{
                                          opacity: isEditable ? "1" : "0.5",
                                        }}
                                        color={
                                          role.check ? "success" : "danger"
                                        }
                                        icon={
                                          role.check ? CheckIcon : CrossIcon
                                        }
                                      />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </td>
                        </tr>
                      </tbody>
                    </CustomAccordion>
                  </>
                ))}
              </table>
              <Pagination
                setPage={setCurrentPage}
                totalPages={totalPages}
                pageSize={pageSize}
                page={currentPage}
                showNoOfRecord={false}
                setPageSize={(size)=>{setPageSize(size);setCurrentPage(1);}}  
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RolePermissions;
