import styled from "styled-components";

const TransactionsStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .transaction-flow-modal th,
  .transaction-flow-modal td {
    background: #333;
  }

  .notes-wrapper {
    border: 1px solid ${({ theme }) => theme.dropdown_border};
    padding: 10px;
    border-radius: 10px;
  }
`;

const ATMBalanceStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .transaction-flow-modal th,
  .transaction-flow-modal td {
    background: ${({ theme }) => theme.card_background};
  }

  .notes-wrapper {
    border: 1px solid ${({ theme }) => theme.dropdown_border};
    padding: 10px;
    border-radius: 10px;
  }
  .send-btn-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 93%;
    display: flex;
    align-items: center;
    border-left: 1px solid ${({ theme }) => theme.dropdown_border};
  }
  .note-textarea {
    border: 1px solid ${({ theme }) => theme.dropdown_border};
    width: 100%;
    background: transparent;
    border-radius: 4px;
    color: #777;
  }
  .note-textarea::placeholder {
    color: ${({ theme }) => theme.dropdown_border};
    padding: 5px;
    font-family: ${({ theme }) => theme.fonts.family.primary.regular};
  }
`;

export { TransactionsStyle, ATMBalanceStyle };
