import styled from "styled-components";

const VerticalTabsStyle = styled.div.attrs((props) => ({
  className: props.className,
  collapsed: props.collapsed,
}))`
  position: relative;

  .nav-container {
    position: absolute;
    max-height: calc(100vh - 95px);
    height:${({ collapsed }) => (collapsed ? "0" : "100%")};
    width:100%;
    transition: width 0.3s ease; /* Add a transition for smooth animation */
    overflow: hidden;
  }

  .nav-wrapper {
    background: ${({ theme }) => theme.card_background};
    height: 100%;
    max-height: calc(100vh - 140px);
    padding: 15px 10px;
    border-radius: 8px;
    overflow: auto;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: ${({ theme }) => theme.chartColors.info};
    background-color: ${({ theme }) => theme.modal_background};
  }
  .nav-pills .nav-link {
    color: ${({ theme }) => theme.text.base};
  }

  .collapse-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    top: calc((100vh - 140px) / 2);
    left: -46px;
    position: absolute;
    cursor: pointer;
    z-index: 101;
    background: none;
    border: none;
    outline: none;
    transition: left 0.3s ease; /* Add a transition for smooth animation */
  }

  .icon {
    color: ${({ theme }) => theme.text.base};
    font-size: 20px;
  }

  @media (max-width: 1380px) {
    .nav-container {
      position: absolute;
      z-index: 100;
      max-height: calc(100vh - 95px);
      height: 100%;
      padding: 0;
    }
    .nav-container .nav-wrapper {
      width: 255px;
    }

  }
`;

export default VerticalTabsStyle;
