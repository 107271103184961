import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import CurrencyFormat from "react-currency-format";
import axiosInstance from "../../utils/axiosInstance";
import Pagination from "../../components/pagination/pagination";
import { useDateTime } from "../../components/Helper/DateTime";
import { Modal } from "react-bootstrap";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import moment from "moment";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import { DateTimeFormat } from "../../utils/formatDateTime";

const formatNumber = (row, isColor) => {
  return (
    <CurrencyFormat
      value={row !== null ? row : "-"}
      displayType={"text"}
      thousandSeparator={true}
      style={{ color: isColor }}
    />
  );
};

export const DataCollection = ({
  refresh,
  filter,
  pageFilter,
  setPageFilter,
  dataCollectionId,
  setDataCollectionId,
}) => {
  const { dateTime, setRefresh } = useDateTime();
  const [data, setData] = useState([]);
  const [singelDCData, setSingleDCData] = useState([]);
  const [rowEventData, setRowEventData] = useState({
    fileId: null,
    showModal: false,
    interfaceName: "",
  });
  const [rowId, setRowId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [modalPage, setModalPage] = useState(1);
  const [modalPageSize, setModalPageSize] = useState(10);
  const [modalTotalPages, setModalTotalPages] = useState(0);
  const [modalTotalElements, setModalTotalElements] = useState(0);
  const [modalSort, setModalSort] = useState({ sort_by: "", sort_order: "" });

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const onSortSingleTable = (type, newState) => {
    setPage(1);
    setModalSort({
      sort_by: newState.sortField,
      sort_order: newState.sortOrder,
    });
  };

  const getDataCollection = useCallback(
    (filter, flag) => {
      const order = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
      let url = `/interfaces/status?page=${
        flag ? 0 : page - 1
      }&size=${pageSize}${
        sort.sort_by && sort.sort_order ? order : ""
      }${filter}`;
      axiosInstance
        .get(url)
        .then((res) => {
          if (res.message.status !== "200") {
            setData([]);
            setLoading(false);
            setTotalElements(0);
          } else {
            setData(res?.data?.interfaceStatus || []);
            setPage(res?.page?.number + 1 || 1);
            setTotalPages(res?.page?.totalPages || 0);
            setTotalElements(res?.page?.totalElements || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    },
    // eslint-disable-next-line
    [page, pageSize, sort, filter]
  );

  const getSingleDCDetails = useCallback(
    (flag, id) => {
      setLoadingModal(true);
      const order = `&sort_by=${modalSort.sort_by}&sort_order=${modalSort.sort_order}`;
      let url = `/interfaces/${id}/files?page=${
        flag ? 0 : modalPage - 1
      }&size=${modalPageSize}${
        modalSort.sort_by && modalSort.sort_order ? order : ""
      }`;
      axiosInstance
        .get(url)
        .then((res) => {
          if (res.message.status !== "200") {
            setSingleDCData([]);
            setLoadingModal(false);
            setModalTotalElements(0);
          } else {
            setSingleDCData(res?.data?.interfaceFiles || []);
            setModalPage(res?.page?.number + 1 || 1);
            setModalTotalPages(res?.page?.totalPages || 0);
            setModalTotalElements(res?.page?.totalElements || 0);
            setLoadingModal(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoadingModal(false);
        });
    },
    [modalPage, modalPageSize, modalSort]
  );

  useEffect(() => {
    setLoading(true);
    getDataCollection(filter, false);
  }, [getDataCollection, refresh]);

  useEffect(() => {
    let timerId = setInterval(() => {
      getDataCollection(filter, false);
    }, 5000);
    return () => {
      clearInterval(timerId);
    };
  });

  useEffect(() => {
    if (pageFilter) {
      setLoading(true);
      getDataCollection(filter, true);
      setPageFilter(false);
    }
  }, [pageFilter]);

  useEffect(() => {
    if (singelDCData && rowId !== null) {
      getSingleDCDetails(false, rowId);
    } else {
      return false;
    }
  }, [modalPage, modalPageSize, modalSort, getSingleDCDetails]);

  useEffect(() => {
    if (dataCollectionId) {
      setRowId(dataCollectionId);
      setRowEventData({
        ...rowEventData,
        showModal: dataCollectionId,
      });
      getSingleDCDetails(true, dataCollectionId);
    }
  }, [dataCollectionId]);
  const columnsDataCollection = [
    { hidden: true, dataField: "interfaceId", text: "ID" },
    {
      dataField: "interfaceName",
      text: "Interface Name",
      sort: true,
    },
    {
      dataField: "runDatetime",
      text: "Last Batch Received",
      formatter: (cell) => (cell ? DateTimeFormat(dateTime, cell) : "-"),
      sort: true,
    },
    {
      dataField: "filesProcessed",
      text: " File Received",
      formatter: formatNumber,
      // sort: true,
    },
    {
      dataField: "filesDiscarded",
      text: "Files Discarded",
      formatter: (cell) => formatNumber(cell, cell > 0 ? "red" : ""),
      // sort: true,
    },
    {
      dataField: "recordProcessed",
      text: "Records Read",
      formatter: formatNumber,
      // sort: true,
    },
    {
      dataField: "recordFailed",
      text: "Records Failed",
      formatter: (cell) => formatNumber(cell, cell > 0 ? "red" : ""),
      // sort: true,
    },
    {
      dataField: "recordsInStaging",
      text: "Records In Staging",
      formatter: formatNumber,
      // sort: true,
    },
  ];

  const singelDCColumns = [
    { hidden: true, dataField: "id", text: "ID" },
    { dataField: "filename", text: "File Name", style: { cursor: "default" } },

    {
      dataField: "runDatetime",
      text: "Run Date/Time",
      formatter: (cell) => (cell ? DateTimeFormat(dateTime, cell) : "-"),
      sort: true,
      style: { cursor: "default" },
    },
    {
      dataField: "jobEndDateTime",
      text: "Job End Date/Time",
      formatter: (cell) => (cell ? DateTimeFormat(dateTime, cell) : "-"),
      sort: true,
      style: { cursor: "default" },
    },
    {
      dataField: "recordProcessed",
      text: "Records Read",
      formatter: formatNumber,
      sort: true,
      style: { cursor: "default" },
    },
    {
      dataField: "recordFailed",
      text: "Records Failed",
      formatter: (cell) => formatNumber(cell, cell > 0 ? "red" : ""),
      sort: true,
      style: { cursor: "default" },
    },
    {
      dataField: "recordsInStaging",
      text: "Records In Staging",
      sort: true,
      style: { cursor: "default" },
    },
    {
      dataField: "jobStatus",
      text: "Job Status",
      sort: true,
      style: { cursor: "default" },
    },
    {
      dataField: "fileDiscardStatus",
      text: "File Discard Status",
      formatter: (cell) => {
        return cell ? cell : "-";
      },
      sort: true,
      style: { cursor: "default" },
    },
  ];

  const rowEvent = {
    onClick: (e, row) => {
      let id = row.interfaceId;
      setRowId(id);
      setRowEventData({
        ...rowEventData,
        fileId: id,
        showModal: true,
        interfaceName: row.interfaceName,
      });
      getSingleDCDetails(true, id);
    },
  };

  return (
    <>
      <div className="row mt-3 text-select">
        <h5 className="title text-bright ml-3 mb-4">Data Collection</h5>
        <>
          <div className="col-12">
            <div className="overflow-y-auto">
              <div id="anchor1">
                <BootstrapTable
                  columns={columnsDataCollection}
                  bootstrap4={true}
                  keyField="id"
                  bordered={false}
                  classes="rs-table table-layout-auto table-custom-style table-custom-style"
                  wrapperClasses="overflow-y-auto fancy-scroll"
                  data={data}
                  rowEvents={rowEvent}
                  remote={{ sort: true }}
                  onTableChange={onSortTable}
                  noDataIndication={
                    loading ? <LoaderComponent /> : "No data found!"
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <Pagination
              setPage={setPage}
              page={page}
              pageSize={pageSize}
              setPageSize={setPageSize}
              totalPages={totalPages}
              totalElements={totalElements}
              data={data || []}
            />
          </div>
        </>
      </div>

      <Modal
        backdrop={true}
        size="xl"
        show={rowEventData.showModal}
        onHide={() => {
          setRowEventData({ ...rowEventData, showModal: false });
          setSingleDCData([]);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton> {rowEventData?.interfaceName}</Modal.Header>
        <Modal.Body scrollable="true">
          <div
            className="row"
            style={{ opacity: !loadingModal ? "1" : "0.07" }}
          >
            <div className="col-12">
              <div className="d-flex justify-content-center">
                <div className="overflow-y-auto" style={{ cursor: "default" }}>
                  <BootstrapTable
                    bordered={false}
                    bootstrap4={true}
                    classes="rs-table table-layout-auto mb-0 table-custom-style"
                    wrapperClasses="overflow-y-auto mb-2 fancy-scroll"
                    keyField="id"
                    data={singelDCData}
                    columns={singelDCColumns}
                    remote={{ sort: true }}
                    onTableChange={onSortSingleTable}
                    // noDataIndication="No data found!"
                    noDataIndication={
                      loading ? <LoaderComponent /> : "No data found!"
                    }
                  ></BootstrapTable>
                </div>
              </div>
            </div>
          </div>

          <Pagination
            setPage={setModalPage}
            page={modalPage}
            pageSize={modalPageSize}
            setPageSize={setModalPageSize}
            totalPages={modalTotalPages}
            totalElements={modalTotalElements}
            data={singelDCData || []}
          />
          <div className="d-flex justify-content-end align-items-center">
            <ButtonBasic
              title="Cancel"
              onClick={() => {
                setRowEventData({
                  ...rowEventData,
                  showModal: false,
                });
                setDataCollectionId(null);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
