import React from "react";
import BadgeCircleStyle from "./BadgeCircleStyle";

class BadgeCircle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { icon, color = "purple", size = "lg", style } = this.props;
    return (
      <BadgeCircleStyle color={color}>
        <div
          className={`tsg-badge-rounded ${color} tsg-badge-${size}`}
          {...this.props}
          style={{ ...style, color: "green" }}
        >
          {icon && <img src={icon} alt="icon" />}
        </div>
      </BadgeCircleStyle>
    );
  }
}

export default BadgeCircle;
