import styled from "styled-components";

const SingleInfoCardStyle = styled.div.attrs((props) => ({
  className: props.className,
}))`
  padding: 0.9375rem 1.5625rem;
  margin-top: 0.9375rem;
  background: ${({ theme }) => theme.card_background};
  border-radius: 17px;

  .single-info-card-title {
    font-size: 14px;
    margin-bottom: 15px;
    color: ${({ theme }) => theme.text.base};
    font-family: "Montserrat Semibold";
  }
  .single-info-card-percentage {
    font-size: 41px;
    color: ${({ theme }) => theme.text.bright};
    font-family: "Montserrat Bold";
  }
  .single-info-card-turnout {
    margin-left: 10px;
    img {
      width: 10px;
      margin-right: 4px;
    }
    span {
      font-family: "Montserrat Semibold";
      font-size: 14px;
    }
  }
`;

export default SingleInfoCardStyle;
