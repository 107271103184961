import React, { useState, useEffect, useCallback, useRef } from "react";
import queryString from "query-string";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Filter from "../../components/FilterDropdown/GenericFilter";
import axiosInstance from "../../utils/axiosInstance";
import BadgeCircle from "../../components/BadgeCircle/BadgeCircle";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import Pagination from "../../components/pagination/pagination";
import WorkQueueModal from "./WorkQueueModal";
import BadgeRounded from "../../components/BadgeRounded/BadgeRounded";
import FilterIcon from "../../assets/images/filter.png";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import { useDateTime } from "../../components/Helper/DateTime";
import Toast from "../../components/Toast/Toast";
import { mapdata } from "../../utils/regionHelper";
import { DateTimeFormat } from "../../utils/formatDateTime";
import {
  mapFilters,
  handleFilterChange,
  filtersParams,
  handleNewFilter,
} from "../../utils/filtersHelper";
import AtmReplenishmentModal from "./AtmReplenishmentModal";
const WorkQueue = (props) => {
  const history = props.history;
  const workItemID = props.location.state?.href?.split("/")?.pop();
  //remove location state href data
  if (props.location.state?.href)
    history.replace(props.location.pathname, null);
  const { dateTime, setRefresh } = useDateTime();
  const [workQueue, setWorkQueue] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sort, setSort] = useState({ sort_by: "", sort_order: "" });
  const [showModal, setShowModal] = useState(workItemID ? true : false);
  const [showAtmReplenishmentModal, setShowAtmReplenishmentModal] =
    useState(false);
  const [id, setId] = useState(workItemID ? workItemID : "");
  const [filter, setFilter] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [showFilters, setShowFilters] = useState([]);
  const [regions, setRegions] = useState([]);
  const regionFilter = useRef("");
  // const [selectedUserName, setSelectedUsername] = useState("All");
  const [selectedUser, setSelectedUser] = useState("all");
  const [users, setUsers] = useState([]);

  const getFilters = useCallback(() => {
    let data;
    if (props.location?.state?.data) {
      // eslint-disable-next-line
      data = props.location.state.data;
    }
    axiosInstance
      .get("hierarchical/filters?screen_id=work_queue")
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          let regions = res?.data?.filters?.filter(
            ({ filterName }) => filterName === "region"
          );
          let otherFilters = res?.data?.filters?.filter(
            ({ filterName }) => filterName !== "region"
          );
          // debugger;
          let obj = mapFilters(res.data.filters.filter((x) => x.showOnScreen));
          if (regions.length > 0) {
            regions[0].filterData = mapdata(regions[0].filterData);
            regions[0].filterData.forEach((x) => {
              x.checked = true;
            });
            regionFilter.current = `&region_id=${regions[0].filterData
              .map(({ codeValue }) => codeValue)
              .join(",")}`;
          }

          setFilter(obj);
          // fetchData(obj);
          setRegions(regions);
          const filteredFilters = otherFilters.filter((x) => x.showOnScreen);
          const showFiltersData = otherFilters.filter((x) => !x.showOnScreen);
          setFilterData(filteredFilters);
          setShowFilters(showFiltersData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [props.location.state]);

  const getUsers = async () => {
    try {
      let response = await axiosInstance.get("/users/list");
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        const users = formatUsers(response.data);
        setUsers(users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFilters();
    getUsers();
  }, [getFilters]);

  const formatUsers = (users) => {
    let formattedUsers = users.map((user) => {
      return {
        id: user.id,
        label: user.name,
        param1: "0",
      };
    });
    formattedUsers.unshift({
      id: "all",
      label: "All",
      param1: "1",
    });
    return formattedUsers;
  };

  const rowEvent = {
    onClick: (e, row) => {
      if (row?.itemType === "ATM CASH REPLENISHMENT") {
        setShowAtmReplenishmentModal(true);
        setId(row.id);
      } else {
        setShowModal(true);
        setId(row.id);
      }
    },
  };

  const onSortTable = (type, newState) => {
    setPage(1);
    setSort({ sort_by: newState.sortField, sort_order: newState.sortOrder });
  };

  const fetchWorkQueue = useCallback(
    async (filter, flag, selectedUser = "all") => {
      let allFilters;
      if (filter?.current_date?.filter.length === 0) {
        const newCurrentDateFilter = [new Date().toISOString().slice(0, 10)];
        const updatedFilter = {
          ...filter,
          current_date: {
            ...filter.current_date,
            filter: newCurrentDateFilter,
          },
        };
        allFilters = filtersParams(updatedFilter, null, true);
      } else {
        allFilters = filtersParams(filter, null, true);
      }
      try {
        setLoading(true);
        let sortOrder = "";
        const searchURL = `&search=${searchQuery}`;

        if (sort.sort_by && sort.sort_order) {
          sortOrder = `&sort_by=${sort.sort_by}&sort_order=${sort.sort_order}`;
        }
        let requestUrl = `/work-queue?${allFilters}&page=${
          flag ? 0 : page - 1
        }&size=${pageSize}${sortOrder}${searchQuery ? searchURL : ""}`;
        if (selectedUser !== "all") {
          requestUrl += `&user_id=${selectedUser}`;
        }
        const res = await axiosInstance.get(requestUrl);
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
          setLoading(false);
          setTotalElements(0);
          // setPage(1);
          setError(false);
          setWorkQueue([]);
          setTotalPages(0);
        } else {
          setWorkQueue(res.data);
          setPage(res?.page?.number + 1 || 1);
          setPageSize(res.page.size);
          setTotalPages(res.page.totalPages);
          setTotalElements(res?.page?.totalElements);
          setLoading(false);
          setError(false);
        }
      } catch (error) {
        setWorkQueue([]);
        // setPage(1);
        setTotalPages(0);
        setTotalElements(0);
        setLoading(false);
        setError(false);
      }
    },
    [page, pageSize, sort, searchQuery]
  );

  const handleCloseModal = useCallback(() => {
    setId("");
    setShowModal(false);
  }, []);
  const closeAtmReplenishmentModal = useCallback(() => {
    setId("");
    setShowAtmReplenishmentModal(false);
  }, []);

  useEffect(() => {
    setRefresh(() => () => {
      fetchWorkQueue(filter, true, selectedUser);
    });
    return () => {
      setRefresh(() => () => {});
    };
    // eslint-disable-next-line
  }, [fetchWorkQueue]);
  const Status = (cell, row) => (
    <BadgeRounded
      color={
        cell === "PENDING"
          ? "warning"
          : cell === "DECLINED"
          ? "danger"
          : "success"
      }
      title={cell}
    />
  );

  const columns = [
    { dataField: "id", text: "ID", sort: true },
    {
      dataField: "description",
      text: "Description",
      formatter: (cell) => cell ?? "-",
      sort: false,
      style: {
        whiteSpace: "normal", // or 'pre-wrap'
        wordWrap: "break-word",
        minWidth: "341px",
      },
    },
    {
      dataField: "createDatetime",
      text: "Date & Time",
      formatter: (cell) => DateTimeFormat(dateTime, cell),
      sort: true,
    },
    {
      dataField: "modifiedDatetime",
      text: "Last Modified",
      formatter: (cell) => DateTimeFormat(dateTime, cell),
      sort: true,
    },
    {
      dataField: "itemType",
      text: "Item Type",
      formatter: (cell) => cell ?? "-",
      sort: false,
    },
    {
      dataField: "raisedBy",
      text: "Raised By",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "assignee",
      text: "Assignee",
      formatter: (cell) => cell ?? "-",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: Status,
      sort: true,
    },
  ];

  useEffect(() => {
    const { id } = queryString.parse(props.location.search);
    if (id) {
      setId(id);
      setShowModal(true);
    }
    fetchWorkQueue(filter, false, selectedUser);
  }, [fetchWorkQueue, props.location.search]);

  // const selectedUserName =
  //   users.find((item) => item.id == selectedUser)?.label ?? "All";

  return (
    <div>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}
      <div className="row mb-4 pl-2">
        {filterData.map(
          ({ filterName, filterType, filterLabel, showOnScreen, filterId }) => (
            <div className="filter-item ml-3">
              <label>Select {filterLabel}</label>
              <Filter
                title={filterLabel}
                filterId={filterId}
                type={filterType}
                data={filter[filterName]?.data}
                filter={filter[filterName]?.filter}
                removable={!showOnScreen}
                onChange={(e) =>
                  handleFilterChange(e, filterName, filter, setFilter)
                }
                onRemove={(e) =>
                  handleNewFilter(
                    e,
                    filterData,
                    showFilters,
                    setFilter,
                    setFilterData
                  )
                }
              />
            </div>
          )
        )}
        {/* <div className="filter-item ml-3">
          <label>Select Assignee</label>
          <Filter
            title={selectedUserName !== "" ? selectedUserName : "Users"}
            type={1002}
            data={users}
            filter={selectedUser}
            field="id"
            onChange={(e) => setSelectedUser(e?.id)}
          />
        </div> */}
        <div className="filter-item mt-2 ml-3">
          <label>{""}</label>
          <Filter
            title={""}
            type={1006}
            data={showFilters}
            filter={filterData.map((x) => x.filterId)}
            field="filterId"
            onChange={(e) =>
              handleNewFilter(
                e,
                filterData,
                showFilters,
                filter,
                setFilter,
                setFilterData
              )
            }
          />
        </div>

        <div className="col ml-3 mt-4">
          <ButtonBasic
            title="Filter"
            icon={FilterIcon}
            classes={"btn mt-2"}
            onClick={() => {
              fetchWorkQueue(filter, true, selectedUser);
            }}
          />
        </div>
      </div>

      <div
        style={{
          opacity: !loading ? "1" : "0.07",
        }}
      >
        <div className="row d-flex justify-content-end">
          <div className="col-4">
            <div class="form-group has-search">
              <span class="fa fa-search form-control-feedback"></span>
              <input
                type="search"
                class="form-control"
                placeholder="Search by ID, Description"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchQuery(e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between">
              <div
                style={{ fontSize: "18px" }}
                className="text-bright ml-3 mb-4"
              >
                Work Queue
              </div>

              <div className="col-sm-12 col-lg-6 legend-wrapper d-flex align-items-center mt-sm-2 mt-lg-0 justify-content-end">
                <BadgeCircle color="success" size="md" />
                <div className="legend-title color-gray-dim">
                  Approved: {workQueue?.APPROVED}
                </div>

                <BadgeCircle color="warning" size="md" />
                <div className="legend-title color-gray-dim">
                  Pending: {workQueue?.PENDING}
                </div>

                <BadgeCircle color="danger" size="md" />
                <div className="legend-title color-gray-dim">
                  Declined: {workQueue?.DECLINED}
                </div>
              </div>
            </div>
            <div className="col-12 ">
              <BootstrapTable
                columns={columns}
                bootstrap4={true}
                keyField="id"
                bordered={false}
                classes="rs-table table-layout-auto table-custom-style"
                wrapperClasses="overflow-y-auto fancy-scroll"
                data={workQueue?.workItems || []}
                rowEvents={rowEvent}
                remote={{ sort: true }}
                onTableChange={onSortTable}
                noDataIndication={"No data found!"}
              />

              <Pagination
                setPage={setPage}
                page={page}
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalPages={totalPages}
                totalElements={totalElements}
                data={workQueue?.workItems || []}
              />
            </div>
            <div className="col-md-12">
              <WorkQueueModal
                id={id}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                fetchWorkQueue={fetchWorkQueue}
              ></WorkQueueModal>
            </div>

            {/* atm replenishment modal */}
            <div className="col-md-12">
              <AtmReplenishmentModal
                id={id}
                handleCloseModal={closeAtmReplenishmentModal}
                fetchWorkQueue={fetchWorkQueue}
                show={showAtmReplenishmentModal}
                onHide={closeAtmReplenishmentModal}
              ></AtmReplenishmentModal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkQueue;
