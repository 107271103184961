import { Field } from "formik";
import React, { useEffect, useState } from "react";
import { Collapse, FormCheck } from "react-bootstrap";
import Select from "react-select";
import AddIcon from "../../../../assets/images/add-icon.png";
import ButtonTransparent from "../../../../components/Buttons/ButtonTransparent";
import CustomAccordion from "../../../../components/Accordion/CustomAccordion";
import DSAModal from "./DSAModal";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import GroupingFieldModal from "./GroupingFieldModal";

export const DSA = ({
  values,
  setFieldValue,
  operatorOptions,
  dsaFields,
  dsbFields,
}) => {
  const [open, setOpen] = useState([]);
  const [showDSAModal, setShowDSAModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [dsaObject, setDsaObject] = useState(null);
  const [editObject, setEditObject] = useState(null);
  const [showGroupingFieldModal, setShowGroupingFieldModal] = useState(false);
  const [editGroupingField, setEditGroupingField] = useState(null);
  const [groupingFieldObject, setGroupingFieldObject] = useState(null);
  const [showGroupDeleteModal, setShowGroupDeleteModal] = useState(false);
  const [groupDeleteIndex, setGroupDeleteIndex] = useState(null);

  useEffect(() => {
    setOpen([]);
  }, [values?.id]);

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center">
        {" "}
        {/* Add Button to Add DSA Fetch Criteria Fields */}
        <ButtonTransparent
          id="addDSAField"
          title={"Add Field"}
          icon={AddIcon}
          onClick={() => {
            setEditObject({
              path: `fromFetchCriteria.fields[${
                values?.fromFetchCriteria?.fields?.length > 0
                  ? values?.fromFetchCriteria?.fields?.length
                  : 0
              }]`,
              isNew: true,
            });
            setDsaObject({
              label: "",
              ds_a_field_name: "",
              operator: "",
            });
            setShowDSAModal(true);
          }}
          border
        />
      </div>
      {values?.fromFetchCriteria?.fields?.length > 0 ? (
        values?.fromFetchCriteria?.fields?.map((row, index) => (
          <CustomAccordion
            id={"dsa" + index}
            index={index}
            title={row?.label ?? ""}
            onRemove={() => {
              // remove current object
              setShowDeleteModal(true);
              setDeleteIndex(index);
            }}
            onEdit={() => {
              setDsaObject(row);
              setEditObject({
                path: "fromFetchCriteria.fields[" + index + "]",
                isNew: false,
              });
              setShowDSAModal(true);
            }}
            defaultActiveKey={false}
            showDragIcon={false}
            showEditIcon={true}
          >
            <div className="p-3 my-3">
              <div className="row">
                <fieldset disabled className="col-4">
                  {/*DS A Field Name DropDown For DSA Fetch Criteria */}
                  <div className="workflowModalTitle">DSA Field Name</div>
                  <div title={row?.ds_a_field_name}>
                    <Select
                      options={dsaFields}
                      name={"ds A Field Name"}
                      value={dsaFields?.find(
                        (field) => field.value === row?.ds_a_field_name
                      )}
                      components={{ DropdownIndicator: null }}
                    />
                  </div>
                </fieldset>
                <fieldset disabled className="col-4">
                  {/* Operator DropDown For DSA Fetch Criteria */}
                  <div className="workflowModalTitle">Operator</div>
                  <div
                    title={
                      operatorOptions?.leftCriteriaOperators?.find(
                        (operator) => operator.value === row?.operator
                      )?.label
                    }
                  >
                    <Select
                      options={operatorOptions?.leftCriteriaOperators}
                      name={"Operator"}
                      value={operatorOptions?.leftCriteriaOperators?.find(
                        (operator) => operator.value === row?.operator
                      )}
                      components={{ DropdownIndicator: null }}
                    />
                  </div>
                </fieldset>
                <fieldset disabled className="col-3">
                  {/* DS A Field Value Text Field For DSA Fetch Criteria */}
                  <div className="workflowModalTitle">DSA Field Value</div>
                  <Field
                    title={row?.ds_a_field_value ?? row?.description}
                    className="form-control rs-input highlight"
                    type="text"
                    placeholder={
                      row?.operator === "LT" ||
                      row?.operator === "GT" ||
                      row?.operator === "LTE" ||
                      row?.operator === "GTE"
                        ? "Enter numeric value"
                        : row?.operator === "IN" || row?.operator === "NIN"
                        ? "Multiple comma separated values are allowed"
                        : "DS A Field Value"
                    }
                    value={row?.ds_a_field_value ?? row?.description ?? ""}
                  />
                </fieldset>
                {/* Show and Hide Icon */}
                <div className="col-1 d-flex align-items-center justify-content-around pt-2">
                  <i
                    onClick={() => {
                      setOpen((prev) => {
                        return {
                          ...prev,
                          [index]: !prev[index],
                        };
                      });
                    }}
                    style={{ color: "#118ab2", cursor: "pointer" }}
                    className={
                      "fa" +
                      (open[index] ? " fa-chevron-up" : " fa-chevron-down")
                    }
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <Collapse
                className="mt-3"
                id={"modalAdvancedOptionsDSAUpdate" + index}
                in={open[index] || false}
              >
                <fieldset disabled className="row">
                  <div className="col-4">
                    {/*  Label Text Field For DSA Fetch Criteria */}
                    <div className="workflowModalTitle">Label</div>
                    <Field
                      title={row?.label}
                      className="form-control rs-input highlight"
                      type="text"
                      placeholder="Label"
                      name={`fromFetchCriteria.fields[${index}].label`}
                    />
                  </div>
                  {/* DS B Field Name DropDown this depends on Reverse Lookup */}
                  {row?.hasOwnProperty("ds_b_field_name") && (
                    <div className="col-4">
                      {/* DS B Field Name Dropdown For DSA Fetch Criteria */}
                      <div className="workflowModalTitle">DSB Field Name</div>
                      <div title={row?.ds_b_field_name}>
                        <Select
                          options={dsbFields}
                          name={"ds B Field Name"}
                          value={dsbFields?.find(
                            (field) => field.value === row?.ds_b_field_name
                          )}
                          components={{ DropdownIndicator: null }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-2">
                    {/* Reverse Lookup Checkout Box in DSA for showing DS B Field Name */}
                    <div className="workflowModalTitle">Reverse Lookup</div>
                    <div className="form-check py-2">
                      <FormCheck
                        type="switch"
                        name="reverseLookup"
                        style={{ zIndex: 0 }}
                        checked={row?.hasOwnProperty("ds_b_field_name")}
                      />
                    </div>
                  </div>
                </fieldset>
              </Collapse>
            </div>
          </CustomAccordion>
        ))
      ) : (
        <>
          <div className="text-center my-3">No DSA Fetch Criteria Found</div>
        </>
      )}
      {(values?.matching === "1:M" ||
        values?.matching === "M:M" ||
        values?.matching === "M:1") && (
        <>
          <div className="row">
            <div className="col-4 align-content-center mt-3">
              <div className="title">Grouping Fields</div>
            </div>
            <div className="col-8 align-content-center mt-3">
              <div className="float-right">
                <ButtonTransparent
                  onClick={() => {
                    setEditGroupingField({
                      path: `fromFetchCriteria.grouping_fields[${
                        values?.fromFetchCriteria?.grouping_fields?.length > 0
                          ? values?.fromFetchCriteria?.grouping_fields?.length
                          : 0
                      }]`,
                      isNew: true,
                    });
                    setGroupingFieldObject({
                      ds_a_field_name: "",
                      ds_b_field_name: "",
                    });
                    setShowGroupingFieldModal(true);
                  }}
                  title={"Add Grouping Field"}
                  icon={AddIcon}
                  border
                />
              </div>
            </div>
          </div>
          {values?.fromFetchCriteria?.grouping_fields?.length > 0 ? (
            values?.fromFetchCriteria?.grouping_fields?.map((row, index) => (
              <CustomAccordion
                title={"Grouping Field " + (index + 1)}
                onRemove={() => {
                  // remove current object
                  setShowGroupDeleteModal(true);
                  setGroupDeleteIndex(index);
                }}
                onEdit={() => {
                  setGroupingFieldObject(row);
                  setEditGroupingField({
                    path: "fromFetchCriteria.grouping_fields[" + index + "]",
                    isNew: false,
                  });
                  setShowGroupingFieldModal(true);
                }}
                defaultActiveKey={false}
                showDragIcon={false}
                showEditIcon={true}
              >
                <fieldset disabled className="p-3 my-3">
                  <div className="row">
                    <div className="col-4">
                      {/* DS A Field Name DropDown */}
                      <div className="workflowModalTitle">DSA Field Name</div>
                      <div title={row?.ds_a_field_name}>
                        <Select
                          options={dsaFields}
                          name={"ds A Field Name"}
                          value={dsaFields?.find(
                            (field) => field.value === row?.ds_a_field_name
                          )}
                          components={{ DropdownIndicator: null }}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      {/* DS B Field Name DropDown */}
                      <div className="workflowModalTitle">DSB Field Name</div>
                      <div title={row?.ds_b_field_name}>
                        <Select
                          options={dsbFields}
                          name={"ds B Field Name"}
                          value={dsbFields?.find(
                            (field) => field.value === row?.ds_b_field_name
                          )}
                          components={{ DropdownIndicator: null }}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </CustomAccordion>
            ))
          ) : (
            <>
              <div className="text-center my-3">No Grouping Fields Found</div>
            </>
          )}
        </>
      )}
      {showDSAModal ? (
        <DSAModal
          show={showDSAModal}
          editObject={editObject}
          onHide={() => {
            setShowDSAModal(false);
            setDsaObject(null);
            setEditObject({ isNew: false, path: null });
          }}
          handleSubmit={(path, values) => setFieldValue(path, values)}
          dsaFields={dsaFields}
          dsbFields={dsbFields}
          values={dsaObject}
          operatorOptions={operatorOptions}
        />
      ) : null}
      <ConfirmationModal
        isShow={showDeleteModal}
        title={`Delete DSA Fetch Criteria Field`}
        message={`Are you sure you want to delete this DSA Fetch Criteria Field?`}
        hideModal={() => {
          setShowDeleteModal(false);
        }}
        confirmAction={() => {
          values.fromFetchCriteria.fields.splice(deleteIndex, 1);
          setFieldValue("fromFetchCriteria.fields", [
            ...values.fromFetchCriteria.fields,
          ]);
          setDeleteIndex(null);
          setShowDeleteModal(false);
        }}
      />
      {showGroupingFieldModal ? (
        <GroupingFieldModal
          show={showGroupingFieldModal}
          editObject={editGroupingField}
          onHide={() => {
            setShowGroupingFieldModal(false);
            setGroupingFieldObject(null);
            setEditGroupingField({ isNew: false, path: null });
          }}
          handleSubmit={(path, values) => setFieldValue(path, values)}
          dsaFields={dsaFields}
          dsbFields={dsbFields}
          values={groupingFieldObject}
          operatorOptions={operatorOptions}
          matching={values.matching}
        />
      ) : null}
      <ConfirmationModal
        isShow={showGroupDeleteModal}
        title={`Delete DSA Fetch Criteria Grouping Field`}
        message={`Are you sure you want to delete this DSA Fetch Criteria Grouping Field?`}
        hideModal={() => {
          setShowGroupDeleteModal(false);
        }}
        confirmAction={() => {
          values.fromFetchCriteria.grouping_fields.splice(groupDeleteIndex, 1);
          setFieldValue("fromFetchCriteria.grouping_fields", [
            ...values.fromFetchCriteria.grouping_fields,
          ]);
          setGroupDeleteIndex(null);
          setShowGroupDeleteModal(false);
        }}
      />
    </div>
  );
};
