import React, { useState, useContext } from "react";
export const ContextState = React.createContext({
  date: "",
  time: "",
  dateTime: "",
  currency: "",
  currencyName: "",
  settings: {},
  systemSettings: {},
  presistState: {},
  isMobile: false,
  setDate: () => {},
  setTime: () => {},
  setDateTime: () => {},
  setCurrency: () => {},
  setCurrencyName: () => {},
  setSettings: () => {},
  setSystemSettings: () => {},
  setPresistState: () => {},
  setIsMobile: () => {},
  refresh: () => {},
  setRefresh: () => {},
});
export const useDateTime = () => useContext(ContextState);
const ContextProvider = ({ value, children }) => {
  const [date, setDate] = useState(value?.date);
  const [time, setTime] = useState(value?.time);
  const [dateTime, setDateTime] = useState(value?.dateTime);
  const [currency, setCurrency] = useState(value?.currency);
  const [currencyName, setCurrencyName] = useState(value?.currencyName);
  const [settings, setSettings] = useState(value?.settings);
  const [systemSettings, setSystemSettings] = useState(value?.systemSettings);
  const [presistState, setPresistState] = useState(value?.presistState);
  const [isMobile, setIsMobile] = useState(value?.isMobile);
  const [callback, setCallback] = useState(() => () => {});
  const refresh = () => {
    callback();
  };
  return (
    <ContextState.Provider
      value={{
        date,
        setDate,
        time,
        setTime,
        dateTime,
        setDateTime,
        currency,
        setCurrency,
        currencyName,
        setCurrencyName,
        settings,
        setSettings,
        systemSettings,
        setSystemSettings,
        presistState,
        setPresistState,
        isMobile,
        setIsMobile,
        refresh,
        setRefresh: setCallback,
      }}
    >
      {children}
    </ContextState.Provider>
  );
};
export default ContextProvider;
