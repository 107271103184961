import { useState } from "react";
import { useEffect } from "react";
import NewFilter from "../../../components/FilterDropdown/NewFilter";
import axiosInstance from "../../../utils/axiosInstance";
import ShowForPermission from "../../../utils/permissionsWrapper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const cassetteTypes = [
  {
    id: 1,
    label: "BIL",
  },
  {
    id: 2,
    label: "REJ",
  },
  {
    id: 3,
    label: "RET",
  },
];
const cassetteUnits = [
  {
    id: 1,
    label: "type_1",
  },
  {
    id: 2,
    label: "type_2",
  },
  {
    id: 3,
    label: "type_3",
  },
  {
    id: 4,
    label: "type_4",
  },
  {
    id: 5,
    label: "type_5",
  },
  {
    id: 6,
    label: "type_6",
  },
  {
    id: 7,
    label: "type_7",
  },
  {
    id: 8,
    label: "type_8",
  },
];

const AddCassettesConfiguration = ({
  rowsData,
  deleteTableRows,
  handleChange,
  handleSubmit,
}) => {
  const [denomination, setDenomination] = useState([]);
  const getCassetteConfigFilter = async () => {
    try {
      const res = await axiosInstance.get(
        `/hierarchical/filters?screen_id=atm_cassette_config`
      );
      setDenomination(
        res?.data?.filters?.map((currency) => {
          return {
            id: currency?.filterId,
            label: currency?.filterLabel,
            name: currency?.filterName,
            data: currency?.filterData.map((denomination) => {
              return {
                id: denomination?.id,
                label: denomination?.codeValue,
                checked: denomination?.checked,
              };
            }),
          };
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCassetteConfigFilter();
  }, []);
  return (
    <>
      {rowsData.map((data, index) => {
        const { casUnit, currency, denom, type } = data;
        return (
          <div
            key={index}
            className="row mt-3 d-flex justify-content-center  align-items-center"
          >
            <div className="col-2">
              <div className="text-center">
                <NewFilter
                  id={index + "0"}
                  title={
                    cassetteUnits.find((i) => i.label === casUnit)?.label ??
                    "Select"
                  }
                  type={"checkbox"}
                  field={"label"}
                  data={cassetteUnits}
                  filter={casUnit}
                  onChange={(e) =>
                    handleChange(index, {
                      target: {
                        value: e,
                        name: "casUnit",
                      },
                    })
                  }
                  isSearchable
                  searchField="label"
                />
              </div>
            </div>
            <div className="col-2">
              <NewFilter
                id={index + "1"}
                title={
                  cassetteTypes.find((i) => i.label === type)?.label ?? "Select"
                }
                type={"checkbox"}
                field={"label"}
                data={cassetteTypes}
                filter={type}
                onChange={(e) =>
                  handleChange(index, {
                    target: {
                      value: e,
                      name: "type",
                    },
                  })
                }
                isSearchable
                searchField="label"
              />
            </div>
            <div className="col-2">
              <NewFilter
                id={index + "2"}
                title={
                  denomination.find((i) => i.label === currency)?.label ??
                  "Select"
                }
                type={"checkbox"}
                field={"label"}
                data={denomination}
                filter={currency}
                onChange={(e) =>
                  handleChange(index, {
                    target: {
                      value: e,
                      name: "currency",
                    },
                  })
                }
                isSearchable
                searchField="label"
              />
            </div>
            <OverlayTrigger
              trigger={"hover"}
              placement="top"
              overlay={
                <Tooltip>
                  Please select the currency before selecting the denomination
                </Tooltip>
              }
              show={denomination?.find((i) => i.label === currency) && false}
            >
              <div className="col-2">
                <NewFilter
                  id={index + "3"}
                  title={
                    denomination
                      ?.find((i) => i.label === currency)
                      ?.data?.find(
                        (i) => i.label?.toString() === denom?.toString()
                      )?.label ?? "Select"
                  }
                  type={"checkbox"}
                  field={"label"}
                  data={denomination?.find((i) => i?.label === currency)?.data}
                  filter={denom?.toString()}
                  onChange={(e) =>
                    handleChange(index, {
                      target: {
                        value: e,
                        name: "denom",
                      },
                    })
                  }
                  isSearchable
                  searchField="label"
                  btnClasses={
                    denomination?.find((i) => i.label === currency)
                      ? ""
                      : "disabled"
                  }
                />
              </div>
            </OverlayTrigger>
            <div className="col-2">
              <button
                id="btnDelete"
                className="btn btn-outline-danger"
                type="button"
                onClick={() => deleteTableRows(index)}
              >
                x
              </button>
            </div>
          </div>
        );
      })}
      {handleSubmit && (
        <div className="d-flex mt-5 ml-3">
          <ShowForPermission permission="clk_atm_edit">
            <button
              id="btnSubmit"
              type="submit"
              name="submit"
              className={"btn btn-info btn-md"}
              onClick={handleSubmit}
            >
              Update ATM
            </button>
          </ShowForPermission>
        </div>
      )}
    </>
  );
};

export default AddCassettesConfiguration;
